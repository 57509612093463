function setErrorField(err: any) {
    let errorField = err.response.data.errorFields
    let textError = ""
    errorField.forEach((field:any) => {
        let input = document.getElementById(field.id)
        if(input){
            if(input.classList.contains("input-container")){
                input.classList.add("input-container--error")
            }else{
                input.classList.add("input--error")
            }

        }
        textError += '*' + field.message + '\n'
    })
    return textError
}

export default setErrorField