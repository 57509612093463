import ReactCodeMirror from "@uiw/react-codemirror";
import {dracula} from "@uiw/codemirror-theme-dracula";
import {langs} from "@uiw/codemirror-extensions-langs";

const Predicates = () => {
    return(
        <div className={'api'}>
            <h1>Predicates/compositions</h1>
            <p>The word "predicate" points either to a group or a comparison; this is used to refer to logical units that can be evaluated independently.
                Compositions refer to a very similar concept, except they tend to be used to emphasize the bindings of multiple predicates.</p>
            <p>For example, those are predicates, and also compositions:</p>
            <ReactCodeMirror theme={dracula} value={`Store.Name = 'Starbucks'
(Store.Name = 'Walmart' and Store.OpeningDate > 2020-01-01)`} extensions={[langs.javascript()]} />
            <pre><code></code></pre>
            <p>The following is a composition, but <em>not</em> a predicate:</p>
            <ReactCodeMirror theme={dracula} value={`Store.Name = 'Walmart' and Store.OpeningDate > 2020-01-01`} extensions={[langs.javascript()]} />
            <p>You may ask why. The answer is both simple, and perhaps a bit confusing.
                Predicates can be evaluated logically as a single unit, while compositions require the evaluation of multiple predicates and their binding with logical operators.</p>
        </div>
    )
}

export default Predicates