import {NavigationMobile} from "../../components/common/navigation/navigationMobile";
import {NavigationBase} from "../../components/common/navigation/navigationBase";
import Header from "../../components/home/header";
import React, {useEffect, useState} from "react";
import {Row} from "../../components/common/grid/row";
import {Column} from "../../components/common/grid/column";
import {AnimationOnScroll} from "react-animation-on-scroll";
import FormulasBlock from "../../components/preLaunch/formulasBlock";
import HistoryBlock from "../../components/preLaunch/historyBlock";
import BenefitsBlock from "../../components/preLaunch/benefitsBlock";
import {dracula} from "@uiw/codemirror-theme-dracula";
import {langs} from "@uiw/codemirror-extensions-langs";
import ReactCodeMirror from "@uiw/react-codemirror";
import Newsletter from "../../components/preLaunch/newsletter";
import {Footer} from "../../components/common/navigation/footer";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";
import {ButtonBase} from "../../components/common/buttons/buttonBase";
import {useSavePosScrollBar} from "../../helper/useSavePosScrollBar";
import {contactId} from "../contact";
import {useRefreshItemNav} from "../../helper/useRefreshItemNav";
import {blogId} from "../blog";
import {Link} from "react-router-dom";

const DDALArticle = () => {
    const {t, i18n} = useTranslation()
    const [article, setArticle] = useState<any>('DDAL')
    const {} = useSavePosScrollBar(ddalArticleId)
    const {} = useRefreshItemNav(blogId)
    useEffect(() => {
        if(i18n.language === 'fr'){
            setArticle(<DDALArticleFR/>)
        }
        else{
            setArticle(<DDALArticleEN/>)
        }
    },[i18n])
    return (
        <>
            <header>
                <NavigationMobile/>
                <NavigationBase/>
                <Header titleArticle={t('header:headerDDAL')} preLaunch={true} article={'DDAL'}/>
            </header>
            <Helmet>
                <title>DDAL FREE blog post</title>
                <meta name="description" content="DDAL FREE blog post" />
            </Helmet>
            {article}
            <Row customClass={'jc-center mt-100'}>
                <Column xl={6} lg={10} min={11}>
                    <Newsletter article={true}/>
                </Column>
            </Row>
            <Footer preLaunch={true}/>
        </>
    )
}

const DDALArticleFR = () => {
  return (
      <div className='article-container'>
          <div className={'background-image'}>
              <Row customClass={'jc-center pt-60'}>
                  <Column customClass={''} xl={6}>
                      <p>DDAL signifie « Langage dynamique d’analyse de données » et désigne le bloc de construction gratuit utilisé par des projets ambitieux comme DDAL/EF. <br/>
                          Tant dans son développement que dans ses caractéristiques, DDAL suit des principes spécifiques afin d’être un langage spécifique, concis et facile à utiliser orienté vers l’analyse de données.<br/></p>

                      <p>Le but de cet article ne sera pas de présenter les détails techniques de DDAL.<br/>
                          Au lieu de cela, nous allons nous concentrer sur la philosophie de DDAL et ce que vous pouvez faire avec.<br/></p>

                      <p>Avant d’aller plus loin, nous devons clarifier le fait que DDAL en tant que spécification de langage est distinct de DDAL/EF.<br/>
                          En effet, DDAL/EF constitue un autre outil développé par IMPLEX qui implémente DDAL pour un environnement .NET centré autour d’Entity Framework Core.<br/>
                          Pour des buts de démonstration ou pour servir d’exemple, nous allons à certains moments dans cet article utiliser des propriétés et caractéristiques spécifiques à DDAL/EF.<br/>
                          Cela ne change rien au fait que DDAL/EF est un outil séparé.<br/>
                          Une bonne analogie à ce que nous proposons est similaire à ce qu’a pu proposer Microsoft par le passé :<br/>
                          C# est un standard ouvert et le framework .NET (pas .NET Core) est un écosystème spécifique à Windows soutenu par Microsoft.<br/>
                          La différence réside bien sûr dans le fait que DDAL/EF est offert avec un support intégral avec une licence d’abonnement.</p>

                      <h2>La simplicité comme principe créateur</h2>
                      <p>Tant dans son fonctionnement interne que dans ses usages, DDAL suit les principes de simplicité et de facilité d’utilisation comme des guides fondamentaux.<br/>
                          C’est pourquoi DDAL utilise une syntaxe similaire à du JSON pour des requêtes d’analyse : cela permet à l’utilisateur de naviguer avec une syntaxe familière et aujourd’hui omniprésente.<br/>
                          Dans la même veine, DDAL utilise une syntaxe « plate » extrêmement courte pour ses requêtes de filtre, car ces dernières tendent à être répétitives.<br/>

                          Pour le futur, DDAL vise à simplifier les processus de récupération et d’agrégation des données depuis des bases relationnelles en fournissant des méthodes d’expression du flux de données naturelles.<br/>
                          Prenons par exemple la requête suivante en SQL proverbial qui permet de compter le nombre de produits disponibles dans un magasin :</p>
                      <ReactCodeMirror theme={dracula} className={'test'} value={
                          `SELECT s.name, COUNT(*) AS "product_count"
FROM Store s JOIN StoreProduct sp JOIN Product p ON sp.product_id = p.id ON s.id = sp.store_id
WHERE p.is_available = 1
GROUP BY s.name`} extensions={[langs.sql()]} />
                      <p>Cela peut sembler <i>relativement</i> concis, mais même cette simple requête requiert des jointures imbriquées ainsi qu'une compréhension basique du SQL.<br/>
                          Qui plus est, il est nécessaire d’utiliser les libellés de colonnes SQL ; or dans le contexte du développement pratique, cela constitue souvent une couche d’abstraction supplémentaire par-dessus votre modèle de données.<br/>
                          Regardons maintenant la syntaxe de ce qui constitue grosso modo une requête d’analyse DDAL/EF :</p>
                      <ReactCodeMirror width={'100%'} theme={dracula} value={
                          `my_analysis = cross analysis
{
    main = part
    {
        root = Store,
        groupby /= Name,
        aggregation /= count(Products["Product.IsAvailable is true"])
    }
}`} extensions={[langs.sql()]} />
                      <p className={'mb-0'}>Ici, pas de jointure imbriquée, pas de connaissance supplémentaire requise au-delà de votre modèle de données.<br/>
                          Nous ne mentionnons même pas le fait que les outils basés sur DDAL, comme DDAL/EF, sont généralement (du fait de l’abstraction que constitue DDAL et les ORMs) agnostiques envers votre fournisseur de bases de données relationnelles.<br/>
                          En vérité, plus votre requête est complexe, plus DDAL se montre utile.</p>
                  </Column>
              </Row>
              <Row customClass={'jc-center'}>
                  <Column xl={6}>
                      <h2>Gratuit et largement disponible</h2>
                      <p>DDAL en tant qu’outil de développement et spécification de langage est gratuit et conçu pour être largement disponible.<br/>
                          Cela signifie que, selon les conditions de la licence CC-BY, vous pouvez librement tester, réutiliser et modifier DDAL et toutes les librairies que nous fournissons avec, tant que vous respectez le principe d’attribution.</p>
                      <h2>Ce qui est fourni</h2>
                      <p>Nous, IMPLEX, fournissons les éléments suivants avec la spécification de langage gratuite et libre que constitue DDAL :</p>
                      <ul>
                          <li>La documentation technique et les guides de DDAL</li>
                          <li>La librairie standard et les parsers de DDAL, écrits en C++ à l’aide d’ANTLR.
                              Leur fonctionnement est relativement commun : elles prennent en entrée une requête textuelle (filtre ou analyse) et donnent en sortie un AST C++.
                              Cet AST est conçu comme une structure mémoire extrêmement basique, ce qui permet de l’utiliser dans pratiquement n’importe quel langage ou écosystème.</li>
                          <li >
                              Les parsers comprennent à leur tour :
                              <ul className={'mt-10'}>
                                  <li>Le parser pour les requêtes de filtre, utilisé par le second parser et possiblement directement par vous pour traiter des requêtes de filtre.</li>
                                  <li>Le parser de requêtes d’analyse.</li>
                              </ul>
                          </li>
                      </ul>
                      <h2>Ce que DDAL supporte</h2>
                      <p>Parmi bien d’autres choses encore, DDAL offre un support intégré pour les jetons de langage suivants :</p>
                      <ul>
                          <li>Spécifications de période composites : années, mois…</li>
                          <li>Chemins de propriété avec jointures implicites.</li>
                          <li>Expressions de filtre avec opérateurs logiques et de comparaison.</li>
                          <li>Expressions de filtre internes, gestion implicite des conditions sur des collections.</li>
                          <li>Blocs de propriété (parties, dictionnaires…)</li>
                          <li>Types valeur multiples : numériques, booléens, etc.</li>
                      </ul>
                      <h2>Quelle utilité a DDAL pour <i>vous</i></h2>
                      <p>Nous insistons sur le fait que DDAL n’est pas le même outil que DDAL/EF.<br/>
                          DDAL est une spécification de langage, intrinsèquement à destination des développeurs, alors que DDAL/EF est un outil business pour développeur d’outils industriels ou pour data engineer.<br/>
                          Concrètement, cela signifie que vous devrez implémenter DDAL pour votre écosystème : votre ORM ou gestionnaire de mappage de données, votre SGBD, etc.<br/>
                          Chez IMPLEX, du fait de notre écosystème interne particulier, nous avons fait le choix d’implémenter DDAL pour Entity Framework Core.</p><br/>

                      <Row customClass={'jc-center'}>
                          <Link style={{textDecoration:'none'}} to={'http://ddal.io/customer?id=7'}
                                className="button-light mr-10"><span className="span-dark"></span> <span
                              className="span-light"></span><label>Télécharger</label></Link>
                      </Row>
                  </Column>
              </Row>
          </div>
      </div>
  )
}

export const ddalArticleId = 'ddalArticle'

const DDALArticleEN = () => {
    return(
        <div className='article-container'>
            <div className={'background-image'}>
                <Row customClass={'jc-center pt-60'}>
                    <Column customClass={''} xl={6}>
                        <p>DDAL stands for “Dynamic Data Analysis Language” and is the free, building block of ambitious projects like DDAL/EF.<br/>
                            It follows specific principles throughout in order to become a very specific, concise and easy-to-use language geared towards data analysis.</p>

                        <p>The goal of this article will not be to present the technical intricacies of DDAL.<br/>
                            Instead, we will focus on its design philosophy and what you can do with it.</p>

                        <p>Before going on with this article, we should make clear that DDAL as a language specification is distinct from DDAL/EF, IMPLEX’s own in-house tool which implements DDAL for Entity Framework Core.<br/>
                            There are times when, for the purposes of demonstration, those two will overlap but the key point is that DDAL/EF is a specific, paid, implementation of DDAL for Entity Framework Core.<br/>
                            A good analogy to our business model is not unlike that Microsoft used in the past:<br/>
                            C# is an open standard and .NET Framework was a specific Windows ecosystem supported by Microsoft itself.<br/>
                            The difference is that DDAL/EF is offered with full support under a subscription license and is aimed at specific business needs.</p>

                        <h2>Simplicity as a design principle</h2>
                        <p>Both in its inner workings as in its uses DDAL follows simplicity, conciseness and ease of use a foundational guideline.<br/>
                            This is why DDAL uses a JSON-like syntax for analysis requests: it allows the user to recognize a familiar, ubiquitous way of typing his requests.<br/>
                            In the same vein, DDAL uses a “flat” extremely short syntax for filters because those tend to be repetitive.</p>

                           <p>Going forward, DDAL aims to simplify the act of fetching and aggregating data from relational databases by providing ways to express the flow of data in a way that is most natural.<br/>
                               Take for example the following proverbial SQL syntax to count the number of available products in a store:</p>
                        <ReactCodeMirror theme={dracula} value={
                            `SELECT s.name, COUNT(*) AS "product_count"
FROM Store s JOIN StoreProduct sp JOIN Product p ON sp.product_id = p.id ON s.id = sp.store_id
WHERE p.is_available = 1
GROUP BY s.name`} extensions={[langs.sql()]} />
                        <p>This may look _somewhat_ concise but even this simple request requires nested joins and a basic understanding of SQL.<br/>
                            Besides, you have to use the names of your SQL columns and in the context of real-world development this adds a layer of required knowledge on top of your actual model.<br/>
                            Now let’s look at the syntax of what is essentially a DDAL/EF cross analysis:</p>
                        <ReactCodeMirror theme={dracula} value={
                            `my_analysis = cross analysis
{
    main = part
    {
        root = Store,
        groupby /= Name,
        aggregation /= count(Products["Product.IsAvailable is true"])
    }
}`} extensions={[langs.sql()]} />
                        <p>Here, no inner join, no required knowledge beyond your actual data model.<br/>
                            Not to mention that tools based on DDAL, such as DDAL/EF, ought to be agnostic towards your relational provider thanks to the abstraction layer ORMs provide.<br/>
                            The more complex your query gets, the more useful DDAL becomes.</p>
                    </Column>
                </Row>
                <Row customClass={'jc-center mb-50'}>
                    <Column xl={6}>
                        <h2>Free and widely available</h2>
                        <p>DDAL as a development tool and language specification is free and designed to be widely available.<br/>
                            This means that per the conditions of the CC-BY license, you can freely test, reuse and modify DDAL and all libraries that come with it so long as your final work credits IMPLEX.</p>
                        <h2>What is provided</h2>
                        <p>We, IMPLEX, provide the following with the free specification of DDAL:</p>
                        <ul>
                            <li>The technical documentation and guidelines of DDAL</li>
                            <li>The standard libraries of parsers for DDAL, written in C++ using ANTLR.<br/>
                                Those take a textual request as input and output a C++ AST whose structure allows you to implement your processing in any ecosystem or language.<br/></li>
                            <li >
                                Those parsers are comprised of:
                                <ul className={'mt-10'}>
                                    <li>The parser for filter requests used by the second parser and possibly by yourself to directly process filter requests</li>
                                    <li>The parser for analysis requests</li>
                                </ul>
                            </li>
                        </ul>
                        <h2>What DDAL supports</h2>
                        <p>Among other things, DDAL offers embedded support for the following language tokens : </p>
                        <ul>
                            <li>Compound period specifications (years, months...)</li>
                            <li>Paths with implicit joins</li>
                            <li>Filter expressions using logical and comparison operations, inner filters on collections, etc.</li>
                            <li>Property blocks (parts, dictionaries...)</li>
                            <li>Multiple value types: numerics, booleans, etc.</li>
                        </ul>
                        <h2>What you can use DDAL for</h2>
                        <p>As we mentioned previously, DDAL only refers to the language specification with its standard parsers.<br/>
                            This means that what we provide with DDAL as-is is only a tool that you must implement.</p>
                        <p>At IMPLEX, we have made the choice to implement DDAL for our own needs.<br/>
                            Since we use Entity Framework Core, we implemented it to work with Entity Framework Core. This is how DDAL/EF was born.<br/>
                            If you use another ORM, or don’t way to pay for DDAL/EF, we actively encourage you to implement DDAL for all your needs or wants.</p>
                        <Row customClass={'jc-center'}>
                            <Link style={{textDecoration:'none'}} to={'http://ddal.io/customer?id=7'}
                                  className="button-light mr-10"><span className="span-dark"></span> <span
                                className="span-light"></span><label>Download</label></Link>
                        </Row>
                    </Column>
                </Row>
            </div>
        </div>
    )
}

export default DDALArticle