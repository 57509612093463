import {dracula} from "@uiw/codemirror-theme-dracula";
import {langs} from "@uiw/codemirror-extensions-langs";
import ReactCodeMirror from "@uiw/react-codemirror";

const LogicalOperators = () => {
    return(
        <div className={'api'}>
            <h1>Logical operators</h1>
            <p>Logical operators are used to bind <a href="/api/DDAL/filter/predicates">predicates</a> (<a href="/api/DDAL/filter/groups">groups</a>, <a href="/api/DDAL/filter/comparisons">comparisons</a>, etc) together using a logical operation.
                They possess a priority value which determines the order in which the predicates are evaluated and resolved. Those strictly follow boolean algebra rules.</p>
            <h2>The <code>AND</code> operator</h2>
            <p>This operator will resolve to true if both predicates also resolve to true.</p>
            <p>The AND operator can be written in the following forms:</p>
            <ul>
                <li><code>AND</code>, case-insensitive</li>
                <li><code>&&</code></li>
                <li><code>&</code></li>
            </ul>
            <h2>The <code>OR</code> operator</h2>
            <p>This operator will resolve to true if either or both predicates also resolve to true.</p>
            <p>The OR operator can be writted as:</p>
            <ul>
                <li><code>OR</code>, case-insensitive</li>
                <li><code>||</code></li>
                <li><code>|</code></li>
            </ul>
            <h2>Other operators</h2>
            <p>Other operators, like <code>XOR</code>, may be included at a later date. However, with <code>AND</code> + <code>OR</code>, and negating <a href="/api/DDAL/filter/groups">groups</a>, you can reproduce any other logical operation.
                This is an example of a <code>XOR</code> operation, if you really need it. We want the stores named Starbucks, as well as non-Starbucks store with an owner older than 50:</p>
            <ReactCodeMirror theme={dracula} value={`Store;(Name = "Starbucks" or Owner.Age > 50) and !(Name = "Starbucks" and Owner.Age > 50)`} extensions={[langs.javascript()]} />
        </div>
    )
}

export default LogicalOperators