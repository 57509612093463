import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import VisaLogo from "../../../../style/image/visa-logo.png";
import {Payment, PaymentForm} from "../../../views/payment";
import {Elements} from "@stripe/react-stripe-js";
import {stripePromise} from "../../../../App";
import {useTranslation} from "react-i18next";
import {TailSpin} from "react-loader-spinner";

type ListPaymentMethodProps = {
    subscription?:any;
    setPaymentMethodId?:any;
    payment?:boolean
}

const ListPaymentMethod = ({subscription, setPaymentMethodId, payment}:ListPaymentMethodProps) => {
    const [data, setData] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [message, setMessage] = useState<string>('')
    const [selected, setSelected] = useState<string>('')
    const {t, i18n} = useTranslation()

    useEffect(()=>{
        refreshData()
    },[])

    useEffect(()=>{
        if(data === null)
            return
        if(data.length === 0)
            return
        setSelected(data.find((item:any)=>item.default === true).id)
        setPaymentMethodId(data.find((item:any)=>item.default === true).id)
    },[data])


    let refreshData = () => {
        setLoading(true)
        axios.post('/paymentMethodRetrieve', {token:window.localStorage.getItem('token')}, {params:{doNotCatch :true}}).then(res => {
            if(res.status === 200){
                setLoading(false)
                setData(res.data.paymentMethods)
            }
        }).catch((err)=>{

        })
    }

    let updateDefaultPaymentMethodSubscription = (id:string) => {
        setLoading(true)
        axios.post('updateDefaultPaymentMethodSubscription', {token:window.localStorage.getItem('token'), paymentMethodId: id, subscriptionId:subscription},{params:{doNotCatch :true}}).then((res)=>{
            setLoading(false)
            if(res.status === 200){
                setMessage('Payement effectué avec succès')
                window.location.href = '/customer/?id=3'
            }
            else {

            }

        }).catch((err)=>{
            if(err.response.status === 402){
                axios.post('/create3DSecurePaymentIntent', {token:window.localStorage.getItem('token'), paymentMethodId: id, subscriptionId:subscription, invoiceId:err.response.data.invoiceId},{params:{doNotCatch :true}}).then((res)=>{
                    if(res.status === 200) {
                        setMessage(t('payment:paymentConfirmation'))
                        setTimeout(() => {
                            window.location.href = res.data.link
                        }, 10000)
                    }
                }).catch((err)=>{
                })
            }
            else{
                setMessage('Une erreur est survenue avec cette méthode de payement')
            }

            let p = document.getElementById('message-p')
            if(p){
                p.style.color = 'red'
            }
        })
    }

    let paymentMethodHandle = (id:string) => {
        setSelected(id)
        setPaymentMethodId(id)
    }

    return (
        <div className={'list-payment-method-modal'}>
            {loading ?  <TailSpin color={"#5c83ce"} height={50}  wrapperClass={'spinner-payment'}/> : <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                {data?.length === 0 && <p className={"text-center"}>{t("payment:noPaymentMethod")}</p>}
                {data?.map((item: any, index: number) => {
                    return (
                        <div
                            onClick={() => {payment ? paymentMethodHandle(item.id) : updateDefaultPaymentMethodSubscription(item.id);}}
                            className={item.id === selected ? "payment-method-card-list payment-method-card-list--selected" : "payment-method-card-list"}>
                            <div style={{gap: "30px"}} className={"d-flex ai-center jc-space-between"}>
                                <img style={{width: "40px", height: "15px"}} src={VisaLogo}/>
                                <p>**** **** **** {item.card.last4}</p>
                                <p>Expire
                                    le {item.card.exp_month.toString().length == 1 ? "0" + item.card.exp_month : item.card.exp_month}/{item.card.exp_year}</p>
                            </div>
                        </div>
                    );
                })}
            </div>}
            <p id={'message-p'} className={'text-center'}>{message}</p>
        </div>
    )
}

export default ListPaymentMethod

const PaymentMethodBlock = () => {
    return (
        <div className={'payment-method-block'}>

        </div>
    )
}