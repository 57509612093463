export type SvgProps = {
    refSvg?: any;
    [propName: string]: any;
}

const CloseNodeTreeSvg = ({dClass = "", refSvg, ...props}: SvgProps) => {
    return (
        <svg {...props} version="1.1" x="0" y="0" viewBox="0 0 30 30">
            <path fill="#D3DEE8"
                  d="M9.3 1.7h11.4c4.2 0 7.6 3.4 7.6 7.6v11.4c0 4.2-3.4 7.6-7.6 7.6H9.3a7.6 7.6 0 0 1-7.6-7.6V9.3C1.7 5 5 1.7 9.3 1.7z"/>
            <path fill="none" stroke="#2B587B" strokeWidth="1.9" strokeLinecap="round" d="M10.2 15 19.8 15"/>
            <path className={dClass} fill="none" stroke="#2B587B" strokeWidth="1.9" strokeLinecap="round"
                  d="M15 19.8 15 10.2"/>
        </svg>
    );
};

export {CloseNodeTreeSvg};