import {TailSpin} from "react-loader-spinner";
import {Row} from "../common/grid/row";
import {Column} from "../common/grid/column";
import React, {useEffect, useState} from "react";
import {Dropdown} from "../common/inputs/dropdown";
import axios from "axios";
import {useTranslation} from "react-i18next";

const Invoices = () => {
    const [invoices, setInvoices] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(false)
    const {t, i18n} = useTranslation()

    useEffect(()=>{
        refreshData()
    },[])

    let refreshData = () => {
        setLoading(true)
        axios.post('/invoiceRetrieve', {token:window.localStorage.getItem('token')}, {params:{doNotCatch :true}}).then(res => {
             if(res.status === 200){
                 setLoading(false)
                 setInvoices(res.data.invoices)
             }
        })
    }

    return (
        <div className={'invoices-container'}>
            {loading ? <TailSpin color={"#5c83ce"} wrapperClass={"spinner-loading"}/> : <><Row>
                <h5>{t('customerArea:invoices')}</h5>
            </Row>
            <Row>
                <Column xl={6} min={12}>
                    <a target={'_blank'} href={''}>
                        <Row customClass={'invoice-card invoice-card--title'}>
                            <Column lg={4} min={12}>
                                <p>Numéro</p>
                            </Column>
                            <Column lg={4} min={12}>
                                <p className={'text-center'}>Date</p>
                            </Column>
                            <Column lg={4} min={12}>
                                <p className={'text-end amount'}>Total payé</p>
                            </Column>
                        </Row>
                    </a>
                    {invoices.map((item:any, index:number) => {
                      return(
                          <a target={'_blank'} href={item.hosted_invoice_url}>
                              <Row customClass={'invoice-card'}>
                                  <Column lg={4} min={12}>
                                      <p>{item.number}</p>
                                  </Column>
                                  <Column lg={4} min={12}>
                                      <p className={'text-center'}>{new Date(item.created * 1000).toLocaleDateString()}</p>
                                  </Column>
                                  <Column lg={4} min={12}>
                                      <p className={'text-end amount'}>{item.amount_paid/100}€</p>
                                  </Column>
                              </Row>
                          </a>

                      )
                    })}
                </Column>
            </Row>
            </>}
        </div>
    )
}

export default Invoices