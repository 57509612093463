import {useEffect, useRef, useState} from "react";
import {useSearchParams} from "react-router-dom";


export type NavigationCustomerItem = {
    id:string,
    name:string,
    svg:any ,
    component:any
}

type NavigationCustomerProps = {
    dataSource:NavigationCustomerItem[],
}

const NavigationCustomer = ({dataSource}:NavigationCustomerProps) => {
    const [items, setItems] = useState<NavigationCustomerItem[]>([])
    const [selected, setSelected] = useState<NavigationCustomerItem | null>(null)
    const liRef = useRef<(HTMLLIElement | null)[]>([]);
    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
        setItems(dataSource)
    },[dataSource])

    useEffect(() => {
        if (searchParams.get('id') === undefined || searchParams.get('id') === null)
            return
        if(items.length === 0)
            return
        let itemSelected = items.find(item => item.id == searchParams.get('id'))
        setSelected(itemSelected as NavigationCustomerItem)
    }, [searchParams, items])

    useEffect(()=>{
        if(selected === null) return
        let found = liRef.current.find(li => li?.id == selected.id)
        if(found) {
            liRef.current.forEach(li => {
                li?.classList.remove("selected")
            })
            found.classList.add("selected");

        }
    },[selected])

    let logout = () => {
        window.localStorage.removeItem('token')
        window.location.href = '/'
    }

    return (
        <div className='container-navigation-customer'>
            <div className='navigation'>
                <ul>
                    {items.map((item:NavigationCustomerItem, index ) => {
                        return <li className={index == items.length-1 ? 'mt-80' : ''} ref={ref => {
                            if (ref) {
                                liRef.current[index] = ref;
                            }
                        }}  key={item.id} id={item.id} onClick={() => setSelected(item)}>
                            {item.svg}
                            <a onClick={item.id == '6' ? logout : ()=>{} }>{item.name}</a>
                        </li>
                    })}
                </ul>
            </div>
            <div className='inner-container p-30 w-100'>
                {selected?.component}
            </div>
        </div>
    )
}

export {NavigationCustomer}