import {useEffect, useRef} from "react";

type LoadingProps = {
    refLoading: any;
}
const Loading = ({refLoading}:LoadingProps) => {
    const refContainer = useRef<HTMLDivElement>(null);
    useEffect(() => {
        window.scrollTo(0, 0);
        refLoading.current = refContainer.current;
    })
    return (
        <div ref={refContainer} className="rocket-loading-container">
            <div className="rocket-full">
                <div className="rocket-fire">
                    <div className="rocket-fire-red"></div>
                </div>
                <svg className="rocket" enableBackground="new 0 0 458.17 300" version="1.1" viewBox="0 0 458.17 300"
                     xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <path
                    d="m290.45 59.73c-15.39-11.66-35.02-24.24-59.06-35.01-33-14.77-64.33-21.65-90.58-24.6-14.42-1.62-24.7 13.86-17.35 26.37 2.91 4.95 5.68 10.45 8.12 16.52 6.73 16.76 8.49 31.78 8.84 41.91l150.03-25.19z"
                    fill="#EA5050"/>
                    <path
                        d="m289.97 241.07c-15.45 11.57-35.15 24.06-59.25 34.7-33.07 14.6-64.44 21.31-90.71 24.12-14.42 1.55-24.63-13.99-17.21-26.46 2.93-4.93 5.73-10.42 8.2-16.48 6.82-16.72 8.65-31.73 9.06-41.86l149.91 25.98z"
                        fill="#EA5050"/>

                    <path
                        d="m253.15 244.58c78.47-2.36 146.17-37.72 192.5-69.9 11.21-7.78 14.88-21.44 11.04-33.09-64.12-28.89-186.24-71-331.53-44.13-44.12 8.16-82.5 21.23-114.65 35.33-4.21 13.03-3.47 27.31 2.26 39.9 0.04 0.08 0.08 0.17 0.12 0.25 0.02 0.05 0.04 0.09 0.06 0.14 8.71 18.96 24.85 33.54 44.67 40.06 26.26 8.64 58.76 17.41 96.43 23.48 10.27 1.68 58.45 9.18 99.1 7.96z"
                        fill="#F9AB43"/>
                    <path
                        d="m456.69 141.6c-1.87-5.65-5.49-10.83-10.91-14.63-46.16-32.42-113.68-68.14-192.13-70.91-40.65-1.43-88.86 5.81-99.15 7.41-37.71 5.87-70.25 14.47-96.55 22.98-19.85 6.42-36.08 20.91-44.88 39.83-0.02 0.05-0.04 0.09-0.06 0.14-0.04 0.08-0.08 0.17-0.12 0.25-0.92 2.01-1.71 4.06-2.38 6.14 32.16-14.1 70.54-27.17 114.65-35.33 145.29-26.89 267.42 15.22 331.53 44.12z"
                        fill="#F9AB43"/>
                    <path
                        d="m456.69 141.6c-1.87-5.65-5.49-10.83-10.91-14.63-46.16-32.42-113.68-68.14-192.13-70.91-40.65-1.43-88.86 5.81-99.15 7.41-37.71 5.87-70.25 14.47-96.55 22.98-19.85 6.42-36.08 20.91-44.88 39.83-0.02 0.05-0.04 0.09-0.06 0.14-0.04 0.08-0.08 0.17-0.12 0.25-0.92 2.01-1.71 4.06-2.38 6.14 32.16-14.1 70.54-27.17 114.65-35.33 145.29-26.89 267.42 15.22 331.53 44.12z"
                        fill="#0B0B0B" opacity=".15"/>

                    <path
                        d="m0.09 184.59 0.18-69.84c0.04-15.72 12.82-28.43 28.53-28.38l29.14 0.08-0.32 126.75-29.14-0.08c-15.72-0.04-28.43-12.81-28.39-28.53z"
                        fill="#EA5050"/>

                    <circle cx="284.31" cy="150.4" r="53.33" fill="#80E0F9"/>
                    <circle cx="284.31" cy="150.4" r="40.89" fill="#55B2F2"/>


                    <defs>
                    <path id="a"
                          d="m0.09 184.59 0.18-69.84c0.04-15.72 12.82-28.43 28.53-28.38l29.14 0.08-0.32 126.75-29.14-0.08c-15.72-0.04-28.43-12.81-28.39-28.53z"/>
                </defs>
                    <clipPath id="b">
                    <use overflow="visible" xlinkHref="#a"/>
                </clipPath>
                    <path
                        d="m72.18 114.8c-26.65-0.47-53.31-0.93-79.96-1.4 0.42-3.62 1.63-9.71 5.56-15.97 0.96-1.53 9.56-14.79 24.25-17.12 11.97-1.9 15.47 5.44 28.41 2.1 4.87-1.26 7.57-3.12 11.55-1.84 5.13 1.65 8.55 7.3 9.73 11.89 1.1 4.29-0.2 6 0.18 17.01 0.07 2.29 0.2 4.14 0.28 5.33z"
                        clip-path="url(#b)" fill="#0B0B0B" opacity=".15"/>

                    <path
                        d="m315.66 162.29c-3.63 8.29-10.13 12.56-12.86 14.12-3.03 1.74-12.69 7.27-18.9 3.12-2.84-1.9-4.86-5.76-3.76-7.54 1.49-2.43 7.35 1.22 14.33 0.17 13.94-2.08 20.85-21.12 23.19-19.87 1.28 0.69-0.49 6.55-2 10z"
                        fill="#fff" opacity=".3"/>
            </svg>
            </div>
            <p>Chargement<span className="dot dot1">.</span><span className="dot dot2">.</span><span
                className="dot dot3">.</span></p>

            <svg className="planet1" enableBackground="new 0 0 470.08 346.04" version="1.1" viewBox="0 0 470.08 346.04"
                 xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg">
			<circle cx="235.04" cy="173.02" r="171.53" fill="#A7C4FC"/>
                <g fill="#5C83CE">
				<path
                    d="m22.98 271.85c2.28-4.47 11.7-17.47 49.81-43.14-2.27-6.63-4.12-13.46-5.58-20.47-2.27 1.49-4.51 2.98-6.69 4.46-59.68 40.41-61.71 57.64-56.82 68.14 3.46 7.43 11.74 14.37 40.85 10.81 12.02-1.47 27.6-4.73 47.86-10.49 2.51-0.71 5.07-1.47 7.66-2.24-4.35-5.54-8.34-11.38-12-17.46-44.19 12.67-60.21 11.52-65.09 10.39z"/>
                    <path
                        d="m466.38 65.2c-4.89-10.5-19.41-20.02-88.71-0.32-2.51 0.71-5.07 1.47-7.66 2.24 4.35 5.54 8.34 11.38 12 17.46 44.19-12.68 60.2-11.52 65.09-10.4-2.29 4.47-11.7 17.47-49.81 43.14 2.27 6.63 4.12 13.46 5.58 20.47 2.27-1.49 4.51-2.98 6.69-4.46 59.68-40.39 61.71-57.63 56.82-68.13z"/>
                    <path
                        d="m391.47 121.21c-37.81 24.85-88.97 52.67-144.07 78.35s-109.31 46.96-152.65 59.93c-2.31 0.69-4.52 1.34-6.69 1.96 3.66 6.08 7.65 11.93 12 17.46 44.36-13.23 99.31-34.76 155.84-61.11 56.51-26.33 108.31-54.55 146.96-80.01-1.46-7-3.31-13.84-5.58-20.47-1.86 1.28-3.79 2.57-5.81 3.89z"/>
			</g>

                <circle cx="316.86" cy="174.77" r="39.4" fill="#DCE9F9"/>
        </svg>

            <svg className="planet2" enableBackground="new 0 0 294.52 346.04" version="1.1" viewBox="0 0 294.52 346.04"
                 xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg">
            <path
                d="m133.41 175.04c18.37 16.7 65.11 33.91 158.76-2.59-0.84-4.67-1.91-9.27-3.18-13.77-37.06 14.32-75.38 7.78-95.69-14.83-19.86-22.09-18.31-54.85-18.07-58.83 0.71-11.74 3.71-21.65 6.98-29.32-11.2-2.73-22.9-4.18-34.94-4.18-36.45 0-69.8 13.25-95.52 35.19 13.46 2.7 26.71 7.99 38.07 17.45 27.25 22.7 22.44 51.66 43.59 70.88z"
                fill="#82DBD2"/>
                <path
                    d="m51.54 266.83c-13.66-3.35-26.5-5.34-38.08-6.48 23.31 50.58 74.45 85.69 133.8 85.69 15.6 0 30.63-2.43 44.74-6.93-33.34-16.72-71.62-55.4-140.46-72.28z"
                    fill="#82DBD2"/>
                <path
                    d="m193.3 143.86c20.32 22.61 58.64 29.14 95.69 14.83-14.34-50.8-55.26-90.44-106.79-102.98-3.27 7.67-6.27 17.58-6.98 29.32-0.24 3.98-1.78 36.73 18.08 58.83z"
                    fill="#2AA899"/>
                <path
                    d="m192 339.12c59.45-18.94 102.52-74.6 102.52-140.33 0-8.99-0.81-17.78-2.35-26.33-93.65 36.5-140.4 19.28-158.76 2.59-21.15-19.23-16.34-48.18-43.59-70.88-11.37-9.47-24.62-14.76-38.08-17.46-31.66 27.01-51.74 67.2-51.74 112.07 0 21.98 4.82 42.84 13.46 61.57 11.58 1.13 24.42 3.13 38.08 6.48 68.84 16.88 107.12 55.56 140.46 72.29z"
                    fill="#2AA899"/>

                <circle cx="193.51" cy="61.36" r="61.36" fill="#159680"/>
        </svg>

            <svg className="planet3" enableBackground="new 0 0 294.52 346.04" version="1.1" viewBox="0 0 294.52 346.04"
                 xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg">
            <ellipse transform="matrix(.7071 -.7071 .7071 .7071 -79.214 154.81)" cx="147.26" cy="173.02" rx="147.26"
                     ry="147.26" fill="#80DD85"/>
                <path
                    d="m137.58 105.93c27.17 19.6 72.72 38.51 152.26 30.15-16.39-63.45-74.01-110.32-142.58-110.32-33.44 0-64.27 11.17-88.99 29.95 5.95 1.46 11.93 3.36 17.88 5.8 28.67 11.75 39.56 28.64 61.43 44.42z"
                    fill="none"/>
                <path
                    d="m76.63 274.62c19.27-0.7 40.99-1.48 66.15-11.34 42.65-16.71 42.97-39.01 82.69-56.7 21.53-9.59 48.06-14.88 67.66-13.56 0.88-6.45 1.37-13.03 1.38-19.71-18.78 0.69-49.97-4.72-86.05-2.17-80.19 5.68-94.79 44.42-163.02 48.67-10.92 0.68-23.75 0.5-38.34-1.58 6.69 20.76 17.86 39.49 32.37 55.08 11.47 1.21 23.89 1.79 37.16 1.31z"
                    fill="none"/>
                <path
                    d="m230.67 273.2c-32.34 11.56-43.79 29.96-59.58 45.13 36.2-5.89 67.96-24.96 90.2-52.15-9.42 1.06-19.8 3.15-30.62 7.02z"
                    fill="none"/>
                <path
                    d="m230.67 273.2c10.82-3.87 21.2-5.97 30.62-7.02 16.78-20.51 28.1-45.63 31.84-73.17-19.59-1.33-46.13 3.97-67.66 13.56-39.72 17.69-40.05 39.99-82.69 56.7-25.17 9.86-46.89 10.64-66.15 11.34-13.27 0.48-25.69-0.09-37.16-1.31 26.89 28.88 65.22 46.97 107.79 46.97 8.12 0 16.07-0.68 23.84-1.95 15.78-15.16 27.23-33.56 59.57-45.12z"
                    fill="#3F893D"/>
                <path
                    d="m45.44 219.81c68.23-4.25 82.82-42.99 163.02-48.67 36.08-2.55 67.27 2.86 86.05 2.17 0-0.09 0.01-0.19 0.01-0.28 0-12.76-1.63-25.14-4.68-36.95-79.53 8.36-125.09-10.55-152.26-30.15-21.88-15.78-32.76-32.67-61.43-44.42-5.95-2.44-11.93-4.34-17.88-5.8-35.4 26.89-58.27 69.42-58.27 117.31 0 15.77 2.51 30.96 7.1 45.2 14.59 2.09 27.42 2.27 38.34 1.59z"
                    fill="#3F893D"/>
        </svg>
        </div>
    );
}

export default Loading;