import {SvgProps} from "./arrowSVG";

const ImplexLogoSvg = ({...props}:SvgProps) => {
    return(
        <svg {...props} version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 320.4 132.3" enableBackground="new 0 0 320.4 132.3" xmlSpace="preserve">
<g>
	<g>
		<path fill="#2B587C" d="M51.2,22.3c-0.3,0-0.6-0.3-0.6-0.6v-8.6c0-0.9-0.2-1.7-0.7-2.2c-0.4-0.6-1.1-0.9-1.8-0.9
			c-0.7,0-1.3,0.3-1.7,0.9c-0.5,0.6-0.7,1.4-0.7,2.3v8.4c0,0.3-0.3,0.6-0.6,0.6h-2.8c-0.3,0-0.6-0.3-0.6-0.6v-8.5
			c0-0.6,0-1.1-0.1-1.5c-0.1-0.4-0.2-0.7-0.4-0.9c-0.2-0.2-0.5-0.4-0.8-0.5c-0.3-0.1-0.7-0.1-1.1-0.1c-1.6,0-2.4,1-2.4,3v8.6
			c0,0.3-0.3,0.6-0.6,0.6h-2.8c-0.3,0-0.6-0.3-0.6-0.6V7.7c0-0.3,0.3-0.6,0.6-0.6H36c0.3,0,0.6,0.3,0.6,0.6V9c0,0.1,0.1,0.1,0.2,0
			c0.5-0.8,1-1.4,1.7-1.8C39.2,6.9,40,6.7,41,6.7c0.5,0,1,0,1.4,0.1c0.4,0.1,0.8,0.2,1.1,0.4c0.3,0.2,0.6,0.4,0.9,0.8
			c0.3,0.3,0.5,0.6,0.8,1c0,0.1,0.1,0.1,0.2,0c0.4-0.8,0.9-1.3,1.6-1.7c0.7-0.4,1.5-0.6,2.5-0.6c0.8,0,1.5,0.1,2.1,0.4
			c0.6,0.3,1.2,0.6,1.6,1.1s0.8,1,1.1,1.6c0.3,0.6,0.4,1.3,0.4,2v9.9c0,0.3-0.3,0.6-0.6,0.6L51.2,22.3L51.2,22.3z"/>
        <path fill="#2B587C" d="M60.4,15.7c-0.4,0-0.7,0.3-0.6,0.7c0.1,0.8,0.5,1.5,0.9,2c0.6,0.7,1.4,1,2.4,1c0.7,0,1.2-0.1,1.7-0.5
			c0.4-0.2,0.7-0.5,0.8-0.9c0.1-0.2,0.3-0.4,0.6-0.4h2.8c0.4,0,0.7,0.4,0.6,0.8c-0.5,1.2-1.2,2.1-2.2,2.8c-1.2,0.8-2.6,1.3-4.2,1.3
			c-5,0-7.6-2.8-7.6-8.3c0-1.2,0.2-2.2,0.5-3.2c0.3-0.9,0.8-1.8,1.4-2.4C58.3,8.1,59,7.6,60,7.2c0.9-0.4,1.9-0.5,3.1-0.5
			c2.3,0,4.1,0.8,5.3,2.2c1.1,1.4,1.7,3.4,1.8,6.2c0,0.3-0.3,0.6-0.6,0.6L60.4,15.7L60.4,15.7z M65.4,13.2c0.4,0,0.7-0.4,0.6-0.7
			c-0.1-0.3-0.1-0.5-0.2-0.7c-0.2-0.4-0.4-0.8-0.7-1.1c-0.3-0.3-0.6-0.5-1-0.6c-0.4-0.1-0.8-0.2-1.1-0.2c-0.8,0-1.5,0.3-2.1,0.9
			c-0.4,0.4-0.7,1-0.9,1.7c-0.1,0.4,0.2,0.7,0.6,0.7H65.4z M60.7,4.7L60.3,4c-0.2-0.3-0.1-0.7,0.2-0.8l5.3-3.1
			c0.3-0.2,0.7-0.1,0.8,0.2l0.8,1.4c0.2,0.3,0.1,0.7-0.3,0.9L61.5,5C61.2,5.1,60.9,5,60.7,4.7z"/>
        <path fill="#2B587C" d="M80.8,12.3h2.5c0.3,0,0.6,0.3,0.6,0.6v3.7c0,0.8-0.1,1.6-0.3,2.3c-0.2,0.7-0.6,1.4-1.1,1.9
			c-0.5,0.6-1.1,1-1.9,1.3c-0.8,0.3-1.7,0.5-2.8,0.5c-1.1,0-2.1-0.2-2.8-0.5s-1.4-0.8-1.9-1.3c-0.5-0.5-0.9-1.2-1.1-1.9
			c-0.2-0.7-0.3-1.5-0.3-2.3v-13c0-0.3,0.3-0.6,0.6-0.6h2.7c0.3,0,0.6,0.3,0.6,0.6v2.9c0,0.3,0.3,0.6,0.6,0.6h7.2
			c0.3,0,0.6,0.3,0.6,0.6v1.6c0,0.3-0.3,0.6-0.6,0.6h-7.2c-0.3,0-0.6,0.3-0.6,0.6v5.6c0,1,0.2,1.7,0.5,2.2s0.9,0.8,1.8,0.8
			c0.8,0,1.4-0.3,1.8-0.8s0.6-1.2,0.6-2.2v-3.3C80.2,12.6,80.4,12.3,80.8,12.3z"/>
        <path fill="#2B587C" d="M94.3,6.7v3.4c0,0.4-0.3,0.6-0.7,0.6c-0.2,0-0.4,0-0.6,0c-2,0.1-3.5,1.7-3.5,3.7v7.3
			c0,0.3-0.3,0.6-0.6,0.6h-2.8c-0.3,0-0.6-0.3-0.6-0.6v-14c0-0.3,0.3-0.6,0.6-0.6h2.5c0.3,0,0.6,0.3,0.6,0.6v1.2
			c0.3-0.5,0.7-0.9,1-1.2s0.7-0.5,1.1-0.7c0.4-0.2,0.8-0.2,1.2-0.3c0.4,0,0.8-0.1,1.2-0.1L94.3,6.7"/>
        <path fill="#2B587C" d="M101.9,22.7c-1.2,0-2.3-0.2-3.3-0.6s-1.8-0.9-2.4-1.6c-0.7-0.7-1.2-1.5-1.5-2.5s-0.5-2.1-0.5-3.2
			c0-1.2,0.2-2.3,0.5-3.3s0.9-1.8,1.6-2.5s1.5-1.2,2.4-1.6s2-0.6,3.2-0.6c1.2,0,2.3,0.2,3.2,0.6c0.9,0.4,1.8,0.9,2.4,1.6
			c0.7,0.7,1.2,1.5,1.5,2.5c0.4,1,0.5,2.1,0.5,3.3s-0.2,2.3-0.5,3.3c-0.4,1-0.9,1.8-1.5,2.5s-1.5,1.2-2.4,1.6
			C104.2,22.5,103.1,22.7,101.9,22.7z M101.9,19.3c2.4,0,3.7-1.5,3.7-4.6c0-3.1-1.2-4.6-3.7-4.6s-3.7,1.5-3.7,4.6
			C98.3,17.8,99.5,19.3,101.9,19.3z"/>
        <path fill="#2B587C" d="M111.1,21.7V2.2c0-0.3,0.3-0.6,0.6-0.6h2.8c0.3,0,0.6,0.3,0.6,0.6v19.5c0,0.3-0.3,0.6-0.6,0.6h-2.8
			C111.4,22.3,111.1,22,111.1,21.7z"/>
        <path fill="#2B587C" d="M124.3,22.7c-1.2,0-2.3-0.2-3.3-0.6s-1.8-0.9-2.4-1.6c-0.7-0.7-1.2-1.5-1.5-2.5s-0.5-2.1-0.5-3.2
			c0-1.2,0.2-2.3,0.5-3.3s0.9-1.8,1.6-2.5s1.5-1.2,2.4-1.6s2-0.6,3.2-0.6c1.2,0,2.3,0.2,3.2,0.6c0.9,0.4,1.8,0.9,2.4,1.6
			c0.7,0.7,1.2,1.5,1.5,2.5c0.4,1,0.5,2.1,0.5,3.3s-0.2,2.3-0.5,3.3c-0.4,1-0.9,1.8-1.5,2.5s-1.5,1.2-2.4,1.6
			C126.5,22.5,125.4,22.7,124.3,22.7z M124.3,19.3c2.4,0,3.7-1.5,3.7-4.6c0-3.1-1.2-4.6-3.7-4.6s-3.7,1.5-3.7,4.6
			C120.6,17.8,121.8,19.3,124.3,19.3z"/>
        <path fill="#2B587C" d="M144,20.6c0-0.1-0.1-0.1-0.2,0c-0.3,0.5-0.8,1-1.4,1.3c-0.8,0.4-1.6,0.6-2.6,0.6c-1.2,0-2.3-0.2-3.2-0.7
			s-1.5-1-2.1-1.7s-0.9-1.5-1.1-2.5c-0.2-0.9-0.4-1.9-0.4-2.9c0-1.2,0.2-2.2,0.5-3.2s0.8-1.8,1.4-2.5c0.6-0.7,1.3-1.2,2.1-1.6
			c0.8-0.4,1.7-0.6,2.6-0.6c1.8,0,3.1,0.7,4.1,2c0.1,0.1,0.2,0,0.2-0.1v-1c0-0.3,0.3-0.6,0.6-0.6h2.8c0.3,0,0.6,0.3,0.6,0.6v14.7
			c0,1.1-0.2,2.2-0.6,3c-0.4,0.9-0.9,1.6-1.6,2.2c-0.7,0.6-1.5,1-2.4,1.3s-1.9,0.5-3,0.5s-2-0.1-2.9-0.5c-0.9-0.3-1.7-0.7-2.3-1.3
			c-0.6-0.5-1.2-1.2-1.5-2c-0.3-0.6-0.5-1.3-0.5-2c0-0.4,0.3-0.7,0.6-0.7h2.5c0.3,0,0.6,0.2,0.6,0.5c0.1,0.7,0.4,1.2,0.9,1.6
			c0.6,0.5,1.4,0.8,2.4,0.8c1.1,0,2-0.3,2.7-0.9c0.7-0.6,1.1-1.4,1.1-2.4L144,20.6L144,20.6z M140.7,19.2c1,0,1.8-0.4,2.4-1.1
			c0.6-0.8,1-1.8,1-3.1c0-3.2-1.1-4.9-3.4-4.9s-3.4,1.5-3.4,4.6c0,1.4,0.3,2.4,1,3.2C138.9,18.9,139.7,19.2,140.7,19.2z"/>
        <path fill="#5CB99E" d="M149.8,3.4L149.8,3.4c0-1,0.8-1.9,1.9-1.9h0.3c1,0,1.9,0.8,1.9,1.9l0,0c0,1-0.8,1.9-1.9,1.9h-0.3
			C150.7,5.3,149.8,4.5,149.8,3.4z"/>
        <path fill="#2B587C" d="M149.8,21.7V7.7c0-0.3,0.3-0.6,0.6-0.6h2.8c0.3,0,0.6,0.3,0.6,0.6v13.9c0,0.3-0.3,0.6-0.6,0.6h-2.8
			C150.1,22.3,149.8,22,149.8,21.7z"/>
        <path fill="#2B587C" d="M160,15.7c-0.4,0-0.7,0.3-0.6,0.7c0.1,0.8,0.4,1.5,0.9,2c0.6,0.7,1.4,1,2.4,1c0.7,0,1.2-0.1,1.7-0.5
			c0.4-0.2,0.7-0.5,0.8-0.9c0.1-0.2,0.3-0.4,0.6-0.4h2.8c0.4,0,0.7,0.4,0.6,0.8c-0.5,1.2-1.2,2.1-2.2,2.8c-1.2,0.8-2.6,1.3-4.2,1.3
			c-5.1,0-7.6-2.8-7.6-8.3c0-1.2,0.2-2.2,0.5-3.2c0.3-0.9,0.8-1.8,1.4-2.4c0.6-0.7,1.4-1.2,2.3-1.6c0.9-0.4,1.9-0.5,3.1-0.5
			c2.3,0,4.1,0.8,5.3,2.2c1.1,1.4,1.7,3.4,1.8,6.2c0,0.3-0.3,0.6-0.6,0.6L160,15.7L160,15.7z M165,13.2c0.4,0,0.7-0.4,0.6-0.7
			c-0.1-0.3-0.1-0.5-0.2-0.7c-0.2-0.4-0.4-0.8-0.7-1.1c-0.3-0.3-0.6-0.5-1-0.6c-0.4-0.1-0.8-0.2-1.1-0.2c-0.8,0-1.5,0.3-2.1,0.9
			c-0.4,0.4-0.7,1-0.9,1.7c-0.1,0.4,0.2,0.7,0.6,0.7L165,13.2L165,13.2z"/>
        <g>
			<path fill="#2B587C" d="M188.6,126c0.3,0,0.5,0.2,0.5,0.5c-0.1,0.8-0.2,1.5-0.4,2.1c-0.3,0.8-0.7,1.4-1.2,2
				c-0.5,0.5-1.1,1-1.8,1.2s-1.4,0.4-2.3,0.4s-1.6-0.1-2.3-0.5c-0.7-0.3-1.3-0.7-1.8-1.3c-0.5-0.6-0.9-1.2-1.2-2
				c-0.3-0.8-0.4-1.6-0.4-2.5v-2.5c0-0.1-0.1-0.1-0.1,0c-1.1,1.4-2.5,2.1-4.3,2.1c-1,0-1.9-0.2-2.6-0.6s-1.4-0.9-1.9-1.6
				c-0.5-0.7-0.9-1.5-1.2-2.5c-0.3-0.9-0.4-2-0.4-3.1c0-1.3,0.2-2.4,0.5-3.4s0.8-1.8,1.3-2.5c0.6-0.7,1.2-1.2,2.1-1.6
				c0.8-0.4,1.7-0.6,2.6-0.6c1.8,0,3.1,0.7,4.1,2c0,0.1,0.1,0,0.1,0l0-1.1c0-0.3,0.2-0.5,0.5-0.5h2.8c0.3,0,0.5,0.2,0.5,0.5V126
				c0,1,0.2,1.8,0.5,2.1c0.3,0.4,0.8,0.6,1.4,0.6c0.6,0,1-0.2,1.3-0.6c0.2-0.4,0.4-0.9,0.4-1.7c0-0.3,0.2-0.5,0.5-0.5L188.6,126
				L188.6,126z M174.6,122.2c2.2,0,3.3-1.6,3.3-4.9c0-1.3-0.3-2.3-1-3.1c-0.6-0.8-1.4-1.2-2.4-1.2c-1,0-1.8,0.4-2.4,1.3
				s-0.9,1.9-0.9,3.3C171.2,120.6,172.3,122.2,174.6,122.2z"/>
            <path fill="#2B587C" d="M192.9,124.6v-1.4c0-0.1-0.1-0.1-0.1,0c-1,1.5-2.5,2.3-4.5,2.3c-0.8,0-1.4-0.1-2.1-0.4
				c-0.6-0.3-1.2-0.6-1.6-1.1c-0.4-0.5-0.8-1.1-1.1-1.7s-0.4-1.4-0.4-2.3v-9.4c0-0.3,0.2-0.5,0.5-0.5h3c0.3,0,0.5,0.2,0.5,0.5v8.8
				c0,1.9,0.8,2.8,2.5,2.8c1,0,1.8-0.3,2.3-1s0.8-1.5,0.8-2.5v-8.2c0-0.3,0.2-0.5,0.5-0.5h2.9c0.3,0,0.5,0.2,0.5,0.5v14
				c0,0.3-0.2,0.5-0.5,0.5h-2.7C193.1,125.1,192.9,124.9,192.9,124.6z"/>
            <path fill="#2B587C" d="M207.7,124.7c-0.1-0.2-0.1-0.5-0.1-0.8c0-0.1-0.1-0.1-0.1,0c-0.5,0.5-1,0.9-1.8,1.2
				c-0.8,0.3-1.7,0.5-2.7,0.5c-1.7,0-3-0.4-3.8-1.2c-0.8-0.8-1.2-1.8-1.2-3c0-1.1,0.2-1.9,0.5-2.6s0.8-1.2,1.4-1.5
				c0.6-0.4,1.3-0.6,2.2-0.8s1.8-0.3,2.8-0.5c1-0.1,1.7-0.3,2-0.6c0.4-0.2,0.5-0.6,0.5-1.2c0-0.5-0.2-0.9-0.7-1.2s-1.1-0.4-1.9-0.4
				c-0.9,0-1.6,0.2-2,0.6c-0.3,0.3-0.5,0.7-0.7,1.2c-0.1,0.2-0.3,0.4-0.5,0.4h-2.7c-0.3,0-0.5-0.3-0.5-0.6c0.1-0.6,0.2-1.1,0.4-1.6
				c0.2-0.7,0.6-1.2,1.1-1.7s1.2-0.8,2-1.1c0.8-0.2,1.9-0.4,3.1-0.4c1.2,0,2.2,0.1,3,0.4c0.8,0.3,1.5,0.7,2,1.2s0.9,1.1,1.1,1.9
				s0.3,1.6,0.3,2.5v9c0,0.3-0.2,0.5-0.5,0.5h-2.8C208,125.1,207.8,125,207.7,124.7z M207.5,117.9c0-0.1-0.1-0.1-0.1-0.1
				c-0.2,0.2-0.4,0.3-0.8,0.4c-0.4,0.1-0.9,0.2-1.7,0.4c-1.1,0.2-1.9,0.5-2.4,0.9c-0.4,0.3-0.6,0.8-0.6,1.5c0,1.1,0.6,1.7,1.9,1.7
				c0.5,0,1-0.1,1.4-0.3c0.4-0.2,0.8-0.4,1.1-0.7c0.3-0.3,0.6-0.6,0.8-1c0.2-0.4,0.3-0.8,0.3-1.3L207.5,117.9z"/>
            <path fill="#2B587C" d="M213.3,124.6v-19.5c0-0.3,0.2-0.5,0.5-0.5h2.9c0.3,0,0.5,0.2,0.5,0.5v19.5c0,0.3-0.2,0.5-0.5,0.5h-2.9
				C213.5,125.1,213.3,124.9,213.3,124.6z"/>
            <path fill="#5CB99E" d="M219,106.5L219,106.5c0-1,0.8-1.8,1.9-1.8h0.3c1,0,1.9,0.8,1.9,1.8l0,0c0,1-0.8,1.8-1.9,1.8h-0.3
				C219.8,108.3,219,107.5,219,106.5z"/>
            <path fill="#2B587C" d="M219,124.6v-14c0-0.3,0.2-0.5,0.5-0.5h2.9c0.3,0,0.5,0.2,0.5,0.5v14c0,0.3-0.2,0.5-0.5,0.5h-2.9
				C219.2,125.1,219,124.9,219,124.6z"/>
            <path fill="#2B587C" d="M233.8,115.2h2.7c0.3,0,0.5,0.2,0.5,0.5v3.8c0,0.8-0.1,1.6-0.4,2.3c-0.2,0.7-0.6,1.4-1.1,1.9
				s-1.1,1-1.9,1.3s-1.7,0.5-2.7,0.5c-1.1,0-2-0.2-2.8-0.5c-0.8-0.3-1.4-0.8-1.9-1.3c-0.5-0.6-0.9-1.2-1.1-1.9
				c-0.2-0.7-0.3-1.5-0.3-2.3v-12.9c0-0.3,0.2-0.5,0.5-0.5h2.9c0.3,0,0.5,0.2,0.5,0.5v3c0,0.3,0.2,0.5,0.5,0.5h7.3
				c0.3,0,0.5,0.2,0.5,0.5v1.8c0,0.3-0.2,0.5-0.5,0.5h-7.3c-0.3,0-0.5,0.2-0.5,0.5v5.7c0,1,0.2,1.7,0.5,2.2c0.4,0.5,0.9,0.8,1.8,0.8
				c0.8,0,1.4-0.2,1.8-0.8c0.4-0.5,0.6-1.2,0.6-2.2v-3.4C233.3,115.5,233.5,115.2,233.8,115.2z"/>
            <path fill="#2B587C" d="M242.8,118.7c-0.3,0-0.6,0.3-0.5,0.6c0.1,0.9,0.4,1.6,0.9,2.1c0.6,0.7,1.4,1,2.4,1c0.6,0,1.2-0.2,1.7-0.4
				c0.4-0.2,0.7-0.6,0.9-0.9c0.1-0.2,0.3-0.3,0.5-0.3h3c0.4,0,0.6,0.4,0.5,0.7c-0.5,1.2-1.2,2.2-2.2,2.9c-1.2,0.8-2.6,1.2-4.1,1.2
				c-5,0-7.5-2.7-7.5-8.2c0-1.2,0.2-2.2,0.5-3.2s0.8-1.7,1.4-2.4c0.6-0.7,1.4-1.2,2.3-1.5c0.9-0.4,1.9-0.5,3.1-0.5
				c2.3,0,4.1,0.7,5.3,2.2c1.1,1.4,1.7,3.5,1.8,6.2c0,0.3-0.2,0.5-0.5,0.5L242.8,118.7L242.8,118.7z M247.9,116.2
				c0.3,0,0.6-0.3,0.5-0.6c-0.1-0.3-0.1-0.6-0.2-0.9c-0.2-0.4-0.4-0.8-0.7-1c-0.3-0.3-0.6-0.5-1-0.6s-0.7-0.2-1.1-0.2
				c-0.8,0-1.5,0.3-2.1,0.9c-0.5,0.5-0.8,1.1-0.9,1.8c-0.1,0.3,0.2,0.6,0.5,0.6H247.9z M243.3,107.8l-0.5-0.8
				c-0.1-0.2-0.1-0.6,0.2-0.7l5.4-3.2c0.2-0.1,0.6-0.1,0.7,0.2l0.9,1.6c0.1,0.3,0,0.6-0.2,0.7l-5.9,2.5
				C243.7,108.1,243.4,108.1,243.3,107.8z"/>
		</g>
	</g>
    <g>
		<g>
			<path fill="#2B587C" d="M0,94.8V37.3c0-1.4,1.1-2.5,2.5-2.5H14c1.4,0,2.5,1.1,2.5,2.5v57.5c0,1.4-1.1,2.5-2.5,2.5H2.5
				C1.1,97.3,0,96.2,0,94.8z"/>
            <path fill="#2B587C" d="M99.7,97.3c-1.4,0-2.5-1.1-2.5-2.5V59.5c0-3.8-0.9-6.9-2.7-9.3c-1.8-2.3-4.6-3.7-7.6-3.5
				c-2.8,0.1-5.2,1.4-7.1,3.8c-2,2.5-3,5.7-3,9.5v34.8c0,1.4-1.1,2.5-2.5,2.5H62.9c-1.4,0-2.5-1.1-2.5-2.5V59.8
				c0-2.5-0.2-4.6-0.5-6.2s-0.9-2.9-1.7-3.8c-0.8-0.9-1.8-1.6-3.1-2s-2.8-0.6-4.6-0.6c-6.7,0-10,4.1-10,12.2v35.5
				c0,1.4-1.1,2.5-2.5,2.5H26.5c-1.4,0-2.5-1.1-2.5-2.5V37.4c0-1.4,1.1-2.5,2.5-2.5H37c1.4,0,2.5,1.1,2.5,2.5v5.3
				c0,0.4,0.5,0.5,0.7,0.2c2-3.5,4.3-5.9,7-7.3c3-1.5,6.5-2.3,10.7-2.3c2.1,0,4,0.2,5.7,0.5c1.6,0.3,3.1,0.9,4.4,1.7
				c1.3,0.8,2.6,1.8,3.7,3.1c1.1,1.2,2.2,2.5,3.2,4.1c0.2,0.2,0.5,0.2,0.6,0c1.7-3.2,3.8-5.5,6.5-7C84.6,34,88,33.2,92,33.2
				c3.1,0,6,0.5,8.6,1.6c2.6,1.1,4.9,2.5,6.7,4.4c1.9,1.9,3.3,4.1,4.4,6.7s1.6,5.2,1.6,8.1v40.8c0,1.4-1.1,2.5-2.5,2.5L99.7,97.3
				L99.7,97.3z"/>
            <path fill="#5CB99E" d="M186,94.8V2.9c0-1.4,1.1-2.5,2.5-2.5H200c1.4,0,2.5,1.1,2.5,2.5v91.9c0,1.4-1.1,2.5-2.5,2.5h-11.5
				C187.1,97.3,186,96.2,186,94.8z"/>
            <path fill="#2B587C" d="M227.4,70.4c-1.5,0-2.7,1.3-2.4,2.8c0.6,3.4,1.8,6.3,3.8,8.5c2.5,2.8,5.8,4.2,9.8,4.2
				c2.7,0,5.1-0.6,7.2-1.8c1.6-0.9,2.8-2.2,3.5-3.7c0.4-0.9,1.3-1.4,2.2-1.4h11.8c1.8,0,3,1.8,2.3,3.4c-2,4.8-5,8.6-8.9,11.5
				c-4.8,3.5-10.6,5.2-17.3,5.2c-20.8,0-31.2-11.3-31.2-34c0-4.8,0.7-9.2,2-13.1s3.3-7.2,5.9-10c2.6-2.8,5.7-4.9,9.4-6.4
				s8-2.2,12.8-2.2c9.6,0,16.9,3.1,21.9,9.3c4.6,5.8,7.1,14.2,7.4,25.4c0,1.4-1.1,2.5-2.5,2.5C265.1,70.4,227.4,70.4,227.4,70.4z
				 M247.9,60c1.6,0,2.7-1.4,2.4-3c-0.2-1.1-0.5-2.1-0.9-3.1c-0.7-1.7-1.7-3.2-2.9-4.3c-1.2-1.1-2.5-2-4.1-2.5s-3.1-0.8-4.7-0.8
				c-3.3,0-6.1,1.2-8.6,3.6c-1.8,1.8-3.1,4.2-3.7,7.2c-0.3,1.5,0.9,2.9,2.4,2.9L247.9,60L247.9,60z"/>
            <path fill="#2B587C" d="M301.7,96.1l-8.9-15.3c-0.6-1-2-1-2.5,0L281.5,96c-0.4,0.8-1.3,1.3-2.2,1.3h-13.8c-2,0-3.2-2.2-2.1-3.8
				l17.9-27.2c0.3-0.5,0.3-1.1,0-1.6l-17.2-25.9c-1.1-1.7,0.1-3.9,2.1-3.9h13.9c0.9,0,1.7,0.5,2.1,1.2l8.6,14.8c0.6,1,2,1,2.5,0
				l8.7-14.8c0.5-0.8,1.3-1.2,2.1-1.2h13.1c2,0,3.2,2.2,2.1,3.9l-17.5,25.9c-0.3,0.5-0.3,1.2,0,1.7L320,93.4
				c1.1,1.7-0.1,3.9-2.1,3.9h-14.1C302.9,97.3,302.1,96.8,301.7,96.1z"/>
            <path fill="#2B587C" d="M178,52.6c-1.4-4.1-3.2-7.7-5.6-10.5c-2.4-2.9-5.2-5.1-8.6-6.6c-3.3-1.5-7-2.3-10.9-2.3
				c-7.4,0-13,2.8-16.8,8.3c-0.2,0.3-0.6,0.2-0.6-0.2l-0.1-4.1c0-1.3-1.1-2.4-2.4-2.4h-11c-1.3,0-2.4,1.1-2.4,2.5v80.8v8.7v0.5
				c0,1.4,1.1,2.5,2.5,2.5h11.3c1.4,0,2.5-1.1,2.5-2.5v-9.2c0,0,0,0,0,0V90.2c0-0.3,0.4-0.5,0.6-0.2c4.6,5.9,10.5,8.9,17.9,8.9
				c4.1,0,7.7-0.8,10.9-2.3c3.2-1.6,5.9-3.8,8.1-6.7c2.2-2.9,3.9-6.3,5-10.2s1.7-8.3,1.7-13C180.1,61.5,179.4,56.8,178,52.6z
				 M149.1,84.9c-9.2,0-13.8-6.8-13.8-20.3c0-5.3,1.3-9.6,4-12.9c2.7-3.3,6-5,10-5c4.1,0,7.5,1.8,10.1,5.3c2.6,3.5,3.9,8.1,3.9,13.6
				C163.3,78.4,158.6,84.9,149.1,84.9z"/>
		</g>
	</g>
    <circle fill="#5CB99E" cx="8.2" cy="14.7" r="8.2"/>
    <ellipse fill="#5CB99E" cx="291.9" cy="117.5" rx="8.2" ry="8.2"/>
</g>
</svg>
    )
}

export default ImplexLogoSvg;