import {useEffect, useState} from "react";

function useStickyScrollBar() {
    const [isSticky, setIsSticky] = useState<Boolean>(false);


    useEffect(() => {
        function setStickyBanner() {
            if(window.scrollY > 0) {
                setIsSticky(true)
            } else {
                setIsSticky(false)
            }
        }
        setStickyBanner()
        document.addEventListener('scroll', setStickyBanner)
        return () => {
            document.removeEventListener('scroll', setStickyBanner)
        }
    },[]);

    return {
        isSticky
    };
}

export {useStickyScrollBar};