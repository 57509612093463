
type eyeSvgProps = {
    open: boolean;
    [propName: string]: any;
}
const EyeSvg = ({open,...props}:eyeSvgProps) => {
    return (
        <>
            {!open ? <svg id="Calque_1" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.88 56.4" {...props}>
                <g>
                    <path
                        d="m29.75,18c-.9,0-1.8.2-2.6.4,1.5.8,2.4,2.4,2.4,4.2,0,2.7-2.1,4.9-4.7,4.9-1.6,0-3.1-.9-3.9-2.1-.1.5-.2,1.1-.2,1.6,0,4.9,4,9,9,9s9-4,9-9-4.1-9-9-9Z"
                        stroke-width="0"/>
                    <path
                        d="m54.55,21.9c-5.3-4.9-14.4-11.6-24.8-11.6s-19.5,6.7-24.8,11.6c-3.1,2.9-3.1,7.2,0,10.1,5.3,4.9,14.4,11.6,24.8,11.6s19.5-6.7,24.8-11.6c3.1-2.8,3.1-7.2,0-10.1Zm-24.8,17.9c-7.1,0-12.8-5.7-12.8-12.8s5.7-12.8,12.8-12.8,12.8,5.7,12.8,12.8-5.7,12.8-12.8,12.8Z"
                        stroke-width="0"/>
                    <path
                        d="m29.75,14.2c-7.1,0-12.8,5.7-12.8,12.8s5.7,12.8,12.8,12.8,12.8-5.7,12.8-12.8-5.7-12.8-12.8-12.8Zm0,21.8c-4.9,0-9-4-9-9,0-.5.1-1.1.2-1.6.9,1.3,2.3,2.1,3.9,2.1,2.6,0,4.7-2.2,4.7-4.9,0-1.8-1-3.4-2.4-4.2.8-.3,1.7-.4,2.6-.4,4.9,0,9,4,9,9s-4.1,9-9,9Z"
                        fill="#fff" stroke-width="0"/>
                </g>
                <g>
                    <path
                        d="m48.75,53.5c-1.3,0-2.5-.5-3.4-1.4L4.25,11.1c-1.9-1.9-1.9-4.9,0-6.8.9-.9,2.1-1.4,3.4-1.4s2.5.5,3.4,1.4l41.1,41.1c1.9,1.9,1.9,4.9,0,6.8-.9.8-2.2,1.3-3.4,1.3Z"
                        fill="#010202" stroke-width="0"/>
                    <path
                        d="m7.65,5.7c.5,0,1,.2,1.4.6l41.1,41.1c.7.7.7,2,0,2.7-.4.4-.9.6-1.4.6s-1-.2-1.4-.6L6.25,9c-.7-.7-.7-2,0-2.7.4-.4.9-.6,1.4-.6m0-5.7h0C5.55,0,3.65.8,2.25,2.2c-3,3-3,7.9,0,10.9l41,41.1c1.5,1.5,3.4,2.2,5.4,2.2,2.1,0,4-.8,5.4-2.2,3-3,3-7.9,0-10.9L13.05,2.2c-1.4-1.4-3.4-2.2-5.4-2.2h0Z"
                        fill="#fff" stroke-width="0"/>
                </g>
            </svg>
                :
                <svg {...props} id="Calque_1" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.55 18">
                <path d="m14.78,4.1c-.5,0-1,.1-1.4.2.8.4,1.3,1.3,1.3,2.3,0,1.5-1.1,2.7-2.6,2.7-.9,0-1.7-.5-2.1-1.1-.1.3-.1.6-.1.9,0,2.7,2.2,4.9,4.9,4.9s4.9-2.2,4.9-4.9-2.2-5-4.9-5Z" fill="#020203" stroke-width="0"/>
                <path d="m28.28,6.3C25.38,3.6,20.48,0,14.78,0S4.18,3.6,1.28,6.3c-1.7,1.6-1.7,3.9,0,5.5,2.9,2.6,7.8,6.2,13.5,6.2s10.6-3.6,13.5-6.3c1.7-1.5,1.7-3.9,0-5.4Zm-13.5,9.7c-3.9,0-7-3.1-7-7s3.1-7,7-7,7,3.1,7,7-3.1,7-7,7Z" fill="#020203" stroke-width="0"/>
                </svg>}
        </>
    )
}

export {EyeSvg};