import React, {ReactNode} from "react";

type ColumnProps = {
    children: ReactNode;
    xl?: number;
    md?: number;
    sm?: number;
    min?: number;
    xlPushing?: number;
    mdPushing?: number;
    smPushing?: number;
    minPushing?: number;
    customClass?: string;
    [propName: string]: any;
}

/**
 * Column component
 * @param children - children of the column
 * @param xl - xl size of the column
 * @param md - md size of the column
 * @param sm - sm size of the column
 * @param min - min size of the column
 * @param xlPushing push the column to the right by xl size
 * @param mdPushing push the column to the right by md size
 * @param smPushing push the column to the right by sm size
 * @param minPushing push the column to the right by min size
 * @param customClass - custom class
 * @param props - other props like onBlur, onFocus, etc.
 * @constructor
 */
const Column = ({
    children,
    xl,
    lg,
    md,
    sm,
    min,
    xlPushing,
    lgPushing,
    mdPushing,
    smPushing,
    minPushing,
    customClass = "",
    ...props
}: ColumnProps) => {
    const generatedClass = (): string => {
        let string = "";
        if (xl) {
            string += `col-xl-${xl} `;
        }
        if (xlPushing) {
            string += `col-push-xl-${xlPushing} `;
        }
        if(lg){
            string += `col-lg-${lg} `;
        }
        if(lgPushing){
            string += `col-push-lg-${lgPushing} `;
        }

        if (md) {
            string += `col-md-${md} `;
        }
        if (mdPushing) {
            string += `col-push-md-${mdPushing} `;
        }

        if (sm) {
            string += `col-sm-${sm} `;
        }
        if (smPushing) {
            string += `col-push-sm-${smPushing} `;
        }

        if (min) {
            string += `col-${min} `;
        }
        if (minPushing) {
            string += `col-push-${minPushing} `;
        }

        return string;
    };

    return (
        <div data-testid={"column"} className={generatedClass() + customClass} {...props}>
            {children}
        </div>
    );
};

export {Column};