const AddSvg = () => {
    return (
        <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 100 100" enableBackground="new 0 0 100 100" xmlSpace="preserve">
            <g id="Groupe_797">
                <path id="Tracé_628" d="M97.4,43.5v12.9c0,3.6-2.8,6.5-6.4,6.5c0,0-0.1,0-0.1,0h-28v28c0,3.6-2.8,6.5-6.4,6.5
		c0,0-0.1,0-0.1,0H43.5c-3.6,0-6.5-2.8-6.5-6.4c0,0,0-0.1,0-0.1v-28H9.1c-1.7,0-3.4-0.6-4.6-1.9c-1.2-1.2-1.9-2.9-1.9-4.6V43.5
		c0-1.7,0.7-3.4,1.9-4.6c1.2-1.2,2.9-1.9,4.6-1.9h28v-28c0-1.7,0.6-3.4,1.9-4.6c1.2-1.2,2.9-1.9,4.6-1.9h12.9c1.7,0,3.4,0.6,4.6,1.9
		c1.2,1.2,1.9,2.9,1.9,4.6v28h28C94.5,37,97.4,39.9,97.4,43.5C97.4,43.5,97.4,43.5,97.4,43.5L97.4,43.5z"/>
            </g>
        </svg>
    )
}

export {AddSvg}