import {Row} from "../common/grid/row";
import {Column} from "../common/grid/column";
import performance from "../../../style/image/performance.png";
import {useTranslation} from "react-i18next";
import cloud from "../../../style/image/cloud.png";
import time from "../../../style/image/temps.png";
import thumb from "../../../style/image/pouce.png";
import mouse from "../../../style/image/souris.png";
import interoperabilite from "../../../style/image/interoperabilite.png";
import {AnimationOnScroll} from "react-animation-on-scroll";
import React from "react";
import {ButtonBase} from "../common/buttons/buttonBase";

const BenefitsBlockV2 = () => {
    const {t, i18n} = useTranslation()
    return(
        <div className={"benefitsV2-container"}>
            <AnimationOnScroll animateOnce={true} animateIn={"animate__fadeInLeftBig"}>
                <Row customClass={'jc-center mb-30 pt-50'}>
                    <Column min={12} xl={8}>
                        <h2>{t('prelaunch:benefitsTitle')}</h2>
                        <p className={'mb-40 text-center'}>{t("prelaunch:forDDAL")} <span>«Dynamic Data Analysis Language»</span> {t("prelaunch:firstBlockParagraph1")}</p>
                    </Column>
                </Row>
            </AnimationOnScroll>
            <Row customClass={'jc-center'}>
                <Column min={12} xl={8}>
            <Row customClass={'jc-center'}>
                <Column lg={4}>
                    <AdvantageBlock img={performance} paragraph={t("prelaunch:benefits1Paragraph")} title={t("prelaunch:benefits1")}/>
                </Column>
                <Column lg={4}>
                    <AdvantageBlock img={time} paragraph={t("prelaunch:benefits3Paragraph")} title={t("prelaunch:benefits3")}/>
                </Column>
                <Column lg={4}>
                    <AdvantageBlock img={cloud} paragraph={t("prelaunch:benefits2Paragraph")} title={t("prelaunch:benefits2")}/>
                </Column>

            </Row>
            <Row customClass={'jc-center'}>
                <Column lg={4}>
                    <AdvantageBlock img={interoperabilite} paragraph={t("prelaunch:benefits6Paragraph")} title={t("prelaunch:benefits6")}/>
                </Column>
                <Column lg={4}>
                    <AdvantageBlock img={mouse} paragraph={t("prelaunch:benefits4Paragraph")} title={t("prelaunch:benefits4")}/>
                </Column>
                <Column lg={4}>
                    <AdvantageBlock img={thumb} paragraph={t("prelaunch:benefits5Paragraph")} title={t("prelaunch:benefits5")}/>
                </Column>
            </Row>
                </Column>
            </Row>
        </div>
    )
}

type AdvantageBlockProps = {
    img: any,
    title: string,
    paragraph: string
}

const AdvantageBlock = ({img,title, paragraph}:AdvantageBlockProps) => {
    return(
        <div className={"advantage-block mb-60"}>
            <img src={img}/>
            <h4>{title}</h4>
            <p className={'mt-20'}>{paragraph}</p>
        </div>
    )
}

export {BenefitsBlockV2, AdvantageBlock}