const PersonalDataSvg = () => {
    return(
        <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 23.5 23.5" enableBackground="new 0 0 23.5 23.5" xmlSpace="preserve">
<g id="user-2_curved" transform="translate(0.75 0.75)">
	<path fill="#5F82C1" d="M11,22.8C4.5,22.8-0.8,17.5-0.8,11S4.5-0.8,11-0.8S22.8,4.5,22.8,11S17.5,22.8,11,22.8z M4.4,18.9
		c1.8,1.5,4.1,2.4,6.6,2.4s4.8-0.9,6.6-2.4c-0.3-3.1-4.6-3.4-6.6-3.4S4.7,15.8,4.4,18.9z M11,13.9c4.2,0,7,1.4,7.8,3.7
		c1.5-1.8,2.4-4.1,2.4-6.6c0-5.7-4.6-10.2-10.2-10.2C5.3,0.8,0.8,5.3,0.8,11c0,2.5,0.9,4.8,2.4,6.6C4,15.3,6.8,13.9,11,13.9z
		 M11,11.8c-2.4,0-4.4-2-4.4-4.4s2-4.4,4.4-4.4c2.4,0,4.4,2,4.4,4.4c0,1.2-0.5,2.3-1.3,3.1C13.3,11.3,12.2,11.8,11,11.8L11,11.8z
		 M11,4.4c-0.8,0-1.5,0.3-2.1,0.8C8.4,5.8,8.1,6.6,8.1,7.3c0,1.6,1.3,2.9,2.9,2.9l0,0c0.8,0,1.5-0.3,2.1-0.9s0.9-1.3,0.9-2.1
		C13.9,5.7,12.6,4.4,11,4.4z"/>
</g>
</svg>
    )
}

export {PersonalDataSvg}