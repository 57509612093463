const DateSvg = () => {
    return (
        <svg enableBackground="new 0 0 20 20" version="1.1" viewBox="0 0 20 20" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg">
<path d="M17.5,20h-15C1.1,20,0,18.9,0,17.5v-15C0,1.1,1.1,0,2.5,0h15C18.9,0,20,1.1,20,2.5v15  C20,18.9,18.9,20,17.5,20z" fill="#EA882F"/>
            <text transform="translate(-25.293 12.934)" fill="#FFFFFF" fontFamily="'Comfortaa-Bold'" fontSize="8px" stroke="#FFFFFF" strokeMiterlimit="10" strokeWidth=".75">STR</text>
            <g fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".5383">
<path d="m2.1 12.2c-0.1-0.1-0.1-0.2-0.1-0.3v-3.8c0-0.1 0-0.2 0.1-0.3s0.1-0.1 0.2-0.1h1c0.4 0 0.8 0.1 1.2 0.3 0.3 0.2 0.6 0.5 0.8 0.8 0.2 0.4 0.2 0.8 0.2 1.2s-0.1 0.8-0.3 1.2c-0.2 0.3-0.4 0.6-0.8 0.8-0.3 0.2-0.7 0.3-1.2 0.3h-1c0-0.1-0.1-0.1-0.1-0.1zm1.2-0.5c0.3 0 0.6-0.1 0.9-0.2s0.4-0.3 0.6-0.6c0.1-0.3 0.2-0.6 0.2-0.9s-0.1-0.6-0.2-0.9l-0.6-0.6c-0.3-0.1-0.6-0.2-0.9-0.2h-0.7v3.4h0.7z"/>
                <path d="m10.1 11.9c0 0.1 0 0.1-0.1 0.2s-0.1 0.1-0.2 0.1h-0.2c-0.1 0-0.1-0.1-0.1-0.1l-0.3-0.8h-2l-0.3 0.8c0 0.1-0.1 0.1-0.1 0.1h-0.2c-0.1 0-0.2 0-0.2-0.1-0.1-0.1-0.1-0.1-0.1-0.2v-0.1l1.6-3.8c0.1-0.2 0.2-0.2 0.3-0.2s0.2 0.1 0.3 0.2l1.6 3.9zm-2.7-1.1h1.6l-0.8-2-0.8 2z"/>
                <path d="m13.7 7.8c0.1 0.1 0.1 0.2 0.1 0.2 0 0.1 0 0.1-0.1 0.2s-0.1 0.1-0.2 0.1h-1.2v3.6c0 0.1 0 0.2-0.1 0.2s-0.1 0.1-0.2 0.1-0.2 0-0.2-0.1c-0.1-0.1-0.1-0.1-0.1-0.2v-3.6h-1.2c-0.1 0-0.1 0-0.2-0.1s-0.1-0.1-0.1-0.2 0-0.1 0.1-0.2 0.1-0.1 0.2-0.1h3.1c0 0.1 0.1 0.1 0.1 0.1z"/>
                <path d="m14.9 12.2c-0.1-0.1-0.1-0.1-0.1-0.2v-3.9c0-0.1 0-0.2 0.1-0.2s0.1-0.1 0.2-0.1h2.6c0.1 0 0.2 0 0.2 0.1 0.1 0 0.1 0.1 0.1 0.1 0 0.1 0 0.1-0.1 0.2-0.1 0-0.1 0.1-0.2 0.1h-2.3v1.4h1.6c0.1 0 0.2 0 0.2 0.1 0.1 0.1 0.1 0.1 0.1 0.2s0 0.1-0.1 0.2-0.1 0.1-0.2 0.1h-1.6v1.4h2.3c0.1 0 0.2 0 0.2 0.1 0.1 0 0.1 0.1 0.1 0.2s0 0.2-0.1 0.2c-0.1 0.1-0.1 0.1-0.2 0.1h-2.6c-0.1-0.1-0.1-0.1-0.2-0.1z"/>
</g>
</svg>
    );
}

export default DateSvg;