const SubscriptionSvg = () => {
    return(
        <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 27.5 22" enableBackground="new 0 0 27.5 22" xmlSpace="preserve">
<path id="Tracé_224" fill="#5F82C1" d="M25.7,16V2.3c0-0.2-0.2-0.5-0.5-0.5H2.3C2,1.8,1.8,2,1.8,2.3V16c0,0.2,0.2,0.5,0.5,0.5h22.9
	C25.5,16.5,25.7,16.3,25.7,16L25.7,16z M27.5,2.3V16c0,1.3-1,2.3-2.3,2.3H14.7v1.8h5c0.2,0,0.5,0.2,0.5,0.4c0,0,0,0,0,0v0.9
	c0,0.2-0.2,0.5-0.4,0.5c0,0,0,0,0,0H7.8c-0.2,0-0.4-0.2-0.5-0.4c0,0,0,0,0,0v-0.9c0-0.2,0.2-0.5,0.4-0.5c0,0,0,0,0,0h5v-1.8H2.3
	c-0.6,0-1.2-0.2-1.6-0.7C0.2,17.2,0,16.6,0,16V2.3c0-0.6,0.2-1.2,0.7-1.6C1.1,0.2,1.7,0,2.3,0h22.9c0.6,0,1.2,0.2,1.6,0.7
	C27.3,1.1,27.5,1.7,27.5,2.3z"/>
</svg>
    )
}

export {SubscriptionSvg}