import {useEffect, useRef, useState} from "react";
import {EyeSvg} from "../../../../style/svg/eyeSvg";

type TextboxProps = {
    change?: (e: any) => void;
    value?: string;
    customClass?: string;
    divContainerClass?: string;
    placeholder?: string;
    area?: boolean;
    password?: boolean;
    [propName: string]: any;
}

/**
 * Textbox component
 * @param change - change event
 * @param value - value of the textbox
 * @param customClass - custom
 * @param placeholder - placeholder
 * @param props - other props like onBlur, onFocus, etc.
 * @param area - if true, the textbox will be a textarea
 * @param divContainerClass - custom class for the div container
 * @constructor
 */
const Textbox = ({change = () => {}, divContainerClass, value, customClass = "", placeholder="",area=false, password=false, ...props}: TextboxProps) => {
    const [text, setText] = useState("");
    const divContainerRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const [show, setShow] = useState(false);
    const handleChange = (e: any) => {
        setText(e.currentTarget.value);
        change(e);
    };

    useEffect(() => {
        if (value) {
            setText(value);
        }
        return () => {
            setText("");
        };
    }, [value]);

    let handleFocus = (e: any) => {
        divContainerRef.current?.classList.add("input-container--focus");
    }

    let handleBlur = (e: any) => {
        if(text === "") {
            divContainerRef.current?.classList.remove("input-container--focus");
        }
    }

    useEffect(() => {
        if(text !== "") {
            divContainerRef.current?.classList.add("input-container--focus");
        }
    }, [text]);

    let handleClickLabel = (e: any) => {
        inputRef.current?.focus();
    }

    useEffect(() => {
        if (!password)
            return
        if(inputRef.current) {
            if (show) {
                inputRef.current.type = "text";
            } else {
                inputRef.current.type = "password";
            }
        }

    }, [show]);

    return (
        <div ref={divContainerRef} className={!area?`input-container ${divContainerClass}`:`area-container ${divContainerClass}`}>
            {!area ?
                <div className={'pos-relative'}>
                    <input ref={inputRef} onFocus={handleFocus} onBlur={handleBlur} data-testid={"textbox"}
                           value={text} onChange={handleChange} {...props}
                           className={`input ${customClass}`}/>
                    {password &&<EyeSvg open={show} onClick={() => setShow(!show)}/>}
                </div>

                :
                <textarea  ref={textareaRef} onFocus={handleFocus} onBlur={handleBlur} data-testid={"textbox"}
                                            value={text} onChange={handleChange} {...props}
                               className={`input ${customClass}`}/>
}
            <label onClick={handleClickLabel}>{placeholder}</label>
        </div>

    );
};

export {Textbox};