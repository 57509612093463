import {ContentProps} from "../../views/customerArea";
import {Row} from "../common/grid/row";
import {Column} from "../common/grid/column";
import {AddSvg} from "../../../style/svg/addSvg";
import Visa from "../../../style/image/visa.png";
import Master from "../../../style/image/mastercard.png";
import MasterLogo from "../../../style/image/mastercard-logo.png";
import VisaLogo from "../../../style/image/visa-logo.png";
import React, {useEffect, useState} from "react";
import axios from "axios";
import Modal from "../common/modals/modal";
import AddPaymentMethod from "../common/modals/addPaymentMethod";
import {TailSpin} from "react-loader-spinner";
import {useTranslation} from "react-i18next";

type PaymentMethodChoiceProps = {
    isModal?:boolean
}
const PaymentMethodChoice = ({isModal}:PaymentMethodChoiceProps) => {
    const [defaultPaymentMethod, setDefaultPaymentMethod] = useState<any>(null)
    const [openAddPaymentMethod, setOpenAddPaymentMethod] = useState<boolean>(false)
    const [data, setData] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<boolean>(false)
    const {t, i18n} = useTranslation()

    useEffect(()=>{
        refreshData()
    },[])

    let refreshData = () => {
        setLoading(true)
        axios.post('/paymentMethodRetrieve', {token:window.localStorage.getItem('token')}, {params:{doNotCatch :true}}).then(res => {
            if(res.status === 200){
                setLoading(false)
                setData(res.data.paymentMethods)
            }
        }).catch((err)=>{
            setLoading(false)
          setError(true)
        })
    }

    useEffect(()=>{
        if(data){
            setDefaultPaymentMethod(data.find((item:any)=>item.default))
        }
    },[data])

    let deletePaymentMethod = (id:string) => {
        axios.post('deletePaymentMethod', {paymentMethodId: id}).then((res)=>{
            refreshData()
        }).catch((err)=>{
        })
    }

    let updateDefaultPaymentMethod = (id:string) => {
        axios.post('updateDefaultPaymentMethod', {token:window.localStorage.getItem('token'), paymentMethodId: id}).then((res)=>{
            refreshData()
        }).catch((err)=>{
        })
    }

    let onCloseModal = () => {
        setOpenAddPaymentMethod(false)
    }

    return (
        <div className={isModal ? 'payment-method-container payment-method-container--modal': 'payment-method-container'}>
            {loading ?
                    <TailSpin color={"#5c83ce"} wrapperClass={"spinner-loading"}/> :
                <>
                    <Row>
                        <Column min={12}>
                            <h5>{t('customerArea:paymentMethods')}</h5>
                        </Column>
                    </Row>
                    <Row>
                        <Column min={12}>
                        <button onClick={()=>setOpenAddPaymentMethod(true)} className={'button-add'}><div><AddSvg/>Ajouter un moyen de paiement</div></button>
                        </Column>
                    </Row>
                    {!error ? <>
                        <Row>
                            <Column min={12}>
                                <Card exp_year={defaultPaymentMethod?.card.exp_year}
                                      exp_month={defaultPaymentMethod?.card.exp_month}
                                      last4={defaultPaymentMethod?.card.last4} type={defaultPaymentMethod?.card.brand}/>
                            </Column>
                        </Row>
                        <Row>
                            <Column xl={8} min={12}>
                                {data?.map((item: any, index: number) => {
                                    return (
                                        <Row key={'card-'+index} style={{background: index % 2 === 0 ? "none" : "#F3F7FF"}}
                                             customClass={"payment-method-card-list"}>
                                            <Column lg={6} min={12}>
                                                <Row>
                                                    <Column customClass={"ai-center d-flex responsive-col"} sm={2}
                                                            min={12}>
                                                        <img style={{width: "40px", height: "15px"}} src={VisaLogo}/>
                                                    </Column>
                                                    <Column customClass={"d-flex jc-center responsive-col"} sm={6}
                                                            min={12}>
                                                        <p>**** **** **** {item.card.last4}</p>
                                                    </Column>
                                                    <Column customClass={"d-flex jc-center responsive-col"} sm={4}
                                                            min={12}>
                                                        <p>Expire
                                                            le {item.card.exp_month.toString().length == 1 ? "0" + item.card.exp_month : item.card.exp_month}/{item.card.exp_year}</p>
                                                    </Column>
                                                </Row>
                                            </Column>
                                            <Column lg={6} min={12}>
                                                <Row customClass={'h-100'}>
                                                    <Column customClass={"d-flex jc-center responsive-col ai-center"} sm={6}
                                                            min={12}>
                                                        {item.default ?
                                                            <button
                                                                className={"default-button default-button--disabled"}>{t('customerArea:default')}</button> :
                                                            <button onClick={() => updateDefaultPaymentMethod(item.id)}
                                                                    className={"default-button"}>{t('customerArea:setDefault')}</button>}
                                                    </Column>
                                                    <Column customClass={"d-flex jc-center ai-center responsive-col"} sm={6}
                                                            min={12}>
                                                        <button onClick={() => deletePaymentMethod(item.id)}
                                                                className={"delete-button"}>{t('customerArea:delete')}
                                                        </button>
                                                    </Column>
                                                </Row>
                                            </Column>
                                        </Row>
                                    );
                                })}
                            </Column>
                        </Row>
                    </> : <></>}

                </>
            }

            <Modal eventClose={onCloseModal} open={openAddPaymentMethod}>
                <AddPaymentMethod/>
            </Modal>
        </div>
    )
}

type CardProps = {
    type?:string
    last4?:string
    exp_month?:string
    exp_year?:string
}
const Card = ({type, last4, exp_month, exp_year}:CardProps) => {
    return(
        <div className={'card-container'}>
            <img src={type === 'visa' ? Visa : Master}/>
            <div className={'number-card'}>**** **** **** {last4}</div>
            <div className={'date-card'}>Expire le {exp_month}/{exp_year}</div>
        </div>
    )
}

export {PaymentMethodChoice}