import axios from "axios";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {AssistanceBlock} from "../preLaunch/demonstrator";
import {ContentProps} from "../../views/customerArea";
import {Row} from "../common/grid/row";



const DdalFreeCustomer = ({data, setData}:ContentProps) => {
    const {t, i18n} = useTranslation()
    const [validated, setValidated] = useState<boolean>(false)
    let sendMail = (name:string) => {
        axios.post('/downloadDDAL',{client:data.client.Company.name, fileName:name}, {params:{doNotCatch :true}}).then(res => {
            if(res.status === 200){
            }
        }).catch(err => {

        })
    }

    let validate = () => {
        setValidated(!validated)
    }
    return (
        <div>
            <div className={'ddal-free-download-container'}>
                <p>Source.zip</p>
                <a style={{textDecoration:'none'}} onMouseDown={()=>sendMail('source.zip')} href={'https://www.implex.fr/telechargements/produits/ddal_free/ddal_source.zip'}
                   className={!validated ? "button-light mr-10 disabled" : "button-light mr-10"}><span className="span-dark"></span> <span
                    className="span-light"></span><label>{t("prelaunch:download")}</label></a>
                <p className={'mt-50'}>Binaries.zip</p>
                <a style={{textDecoration:'none'}} onMouseDown={()=>sendMail('Binaries.zip')} href={'https://www.implex.fr/telechargements/produits/ddal_free/ddal_binaries.zip'}
                   className={!validated ? "button-light mr-10 disabled" : "button-light mr-10"}><span className="span-dark"></span> <span
                    className="span-light"></span><label>{t("prelaunch:download")}</label></a>
                <div className={'mt-40 d-flex ai-center'}>
                    <input onChange={validate} id={"cvg"}
                           type={"checkbox"} className={"checkbox"}/>
                    <label className={'p-terms'} htmlFor={"cvg"}>{t('payment:accept')} <a className={'p-0'} href={'https://www.implex.fr/telechargements/politique_confidentialite_implex.pdf'} target={'_blank'}>{t('payment:cvgImplex')}</a>,  <a className={'p-0'} href={i18n.language == "en" ? 'https://www.implex.fr/telechargements/CGV-DDAL-IMPLEX-Siteweb-EN.pdf' : 'https://www.implex.fr/telechargements/CGV-DDAL-IMPLEX-Siteweb.pdf'} target={'_blank'}>{t('payment:cvg')}</a>,  <a className={'p-0'} href={i18n.language == "en" ? 'https://www.implex.fr/telechargements/Contrat_de_licence_Dynamic_Data_Analysis_Language_-_CC_by_4.0-EN.pdf' : 'https://www.implex.fr/telechargements/Contrat_de_licence_Dynamic_Data_Analysis_Language_-_CC_by_4.0.pdf'} target={'_blank'}>{t('payment:licenceDDAL')}</a> </label>
                </div>

            </div>
            <div className={'mt-60'}>
                <AssistanceBlock/>
            </div>
        </div>

    )
}

export default DdalFreeCustomer