import FreeLogoSvg from "../../../style/svg/freeLogoSvg";
import {ProLogoSvg} from "../../../style/svg/proLogoSvg";
import {Row} from "../common/grid/row";
import {Column} from "../common/grid/column";
import {ButtonBase} from "../common/buttons/buttonBase";
import {ContentProps} from "../../views/customerArea";
import Modal from "../common/modals/modal";
import React, {useEffect, useRef, useState} from "react";
import ConfirmationModal from "../common/modals/confirmationModal";
import axios from "axios";
import {TailSpin} from "react-loader-spinner";
import {Dropdown} from "../common/inputs/dropdown";
import {SubscriptionDetails} from "../common/modals/subscriptionDetails";
import {PaymentMethodChoice} from "./paymentMethodChoice";
import AddPaymentMethod from "../common/modals/addPaymentMethod";
import ListPaymentMethod from "../common/modals/listPaymentMethod";
import {useTranslation} from "react-i18next";

const MySubscription = () => {
    const [openCancelConfirmation, setOpenCancelConfirmation] = useState<boolean>(false);
    const [openRenewConfirmation, setOpenRenewConfirmation] = useState<boolean>(false);
    const [openSubscriptionDetails, setOpenSubscriptionDetails] = useState<boolean>(false);
    const [openPaymentMethodChoice, setOpenPaymentMethodChoice] = useState<boolean>(false);
    const subscriptionSelected = useRef()
    const [data, setData] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const divRef = useRef<HTMLDivElement>(null)
    const [trialDays, setTrialDays] = useState<number>(-1)
    const {t, i18n} = useTranslation()

    useEffect(()=>{
        refreshData()
        getTrialDays()

        return () => {
            setData(null)
        }
    },[])

    let refreshData = () => {
        setLoading(true)
        axios.post('/subscriptionRetrieve', {token:window.localStorage.getItem('token')}, {params:{doNotCatch :true}}).then(res => {
            if(res.status === 200){
                setLoading(false)
                setData(res.data.subscriptions)
            }
        })
    }

    let getTrialDays = () => {
        axios.get('/getTrialPeriodRestDay', {params:{doNotCatch :true}}).then(res => {
            if(res.status === 200){
                setTrialDays(res.data.restDay)
            }
        }).catch(err => {

        })

    }

    let onCloseModal = () => {
        setOpenCancelConfirmation(false)
        setOpenRenewConfirmation(false)
        setOpenSubscriptionDetails(false)
        setOpenPaymentMethodChoice(false)
    }

    let cancelSubscription = () => {
        setOpenCancelConfirmation(false)
        axios.post('cancelSubscription', {subscriptionId: subscriptionSelected.current}).then((res)=>{
            refreshData()
        }).catch((err)=>{

        })
    }

    let renewSubscription = () => {
        setOpenRenewConfirmation(false)
        axios.post('resumeSubscription', {subscriptionId: subscriptionSelected.current}).then((res)=>{
            refreshData()
        }).catch((err)=>{

        })
    }

    let updateDefaultPaymentMethodSubscription = () => {
        setOpenPaymentMethodChoice(true)
        /*axios.post('updateDefaultPaymentMethodSubscription', {subscriptionId: subscriptionSelected.current}).then((res)=>{
            console.log(res)
            refreshData()
        }).catch((err)=>{

        })*/
    }

    let handleOpenSubscriptionDetails = (e:any, item:any) => {
            subscriptionSelected.current = item;
            setOpenSubscriptionDetails(true);
    }

    let handleClickTry = () => {
        window.location.href = '/payment?trial=true'
    }

    let handleClickPro = () => {
        window.location.href = '/payment?trial=false'
    }

    return (
        <div className='my-subscription-container'>
            {loading ? <TailSpin color={"#5c83ce"} wrapperClass={"spinner-loading"}/> : <><Row>
                <h5>{t('customerArea:subscribes')}</h5>
            </Row>
                <Row >
                    <Column xl={3} customClass='free-container' min={12} md={5}>
                        <div className={'d-flex ai-center mb-20'}>
                            <div className='divSvg'>
                                <FreeLogoSvg/>
                            </div>
                            <div>
                                <label><span>{t('customerArea:trialFree')}</span>{trialDays == -1 ? null: t('customerArea:inProgress')}</label>
                            </div>
                        </div>
                        <div className={'w-100'}>
                            {trialDays == -1 ? <ButtonBase onClick={handleClickTry} labelMethod={handleClickTry} customClass={"button-light"}>{t('customerArea:try')}</ButtonBase> : <p>{t('customerArea:moreThan')} <span>{trialDays} {t('customerArea:days')}</span></p>}
                        </div>
                    </Column>
                    <Column customClass='pro-container' xl={3} min={12} md={5}>
                        <div className={'d-flex ai-center mb-20'}>
                            <div className='divSvg'>
                                <ProLogoSvg/>
                            </div>
                            <div className='divText'>
                                <p>{t('customerArea:versionPro')} <span>960€/{t('customerArea:year')}</span></p>
                            </div>
                        </div>
                        <div className='w-100'>
                            <ButtonBase onClick={handleClickPro} labelMethod={handleClickPro} customClass={'button-white'}>{t('customerArea:buy')}</ButtonBase>
                        </div>
                    </Column>
                </Row>
                <Row>
                    <Column xl={6} min={12}>
                        {data?.length === 0 ? null : <div ref={divRef} className={"subscription-card subscription-card--title"}>
                            <p style={{width: "130px"}}>Nom</p>
                            <p className={"status--"}>Status</p>
                            <p style={{width: "85px"}}>Date du prochain prélèvement</p>
                            <button style={{visibility: "hidden"}} className={"button--cancel"}></button>
                        </div>}
                            {data?.map((item:any, index:number) => {
                                let actionButton = null
                                switch (item.status){
                                    case 'trialing':
                                    case 'active':
                                        actionButton = item.cancel_at_period_end ? <button onClick={(event)=> {event?.stopPropagation();setOpenRenewConfirmation(true);subscriptionSelected.current=item.id}} className={'button--renew'}>Réactiver</button> :
                                            <button onClick={(event)=> {
                                                event?.stopPropagation()
                                                setOpenCancelConfirmation(true);
                                            subscriptionSelected.current=item.id}} className={'button--cancel'}>{t('customerArea:cancel')}</button>
                                        break;
                                        case 'incomplete_expired':
                                    case 'canceled':
                                        actionButton = <button className={'button--renew button--renew button--renew--disabled'}>{t('customerArea:reactive')}</button>
                                        break;
                                    case 'past_due':
                                    case 'incomplete':
                                    case 'unpaid':
                                        actionButton = <button onClick={(event)=>{
                                            event?.stopPropagation()
                                            subscriptionSelected.current=item.id
                                            updateDefaultPaymentMethodSubscription()
                                        }} className={'button--pay'}>{t('customerArea:pay')}</button>
                                        break;
                                }
                                return(
                                <div ref={divRef} key={'sub-'+index} onClick={(e)=> handleOpenSubscriptionDetails(e,item)}  className={'subscription-card'}>
                                    <p style={{width:'130px'}}>{t('customerArea:subscribe')} {index + 1}</p>
                                    <p className={'status--' + item.status}>{item.status}</p>
                                    <p style={{width:'85px'}}>{item.status == "canceled" || item.cancel_at_period_end ? '-' : new Date(item.current_period_end  * 1000).toLocaleDateString()}</p>
                                    {actionButton}
                                </div>
                                )
                            })})

                    </Column>
                </Row> </>}

            <Modal eventClose={onCloseModal} open={openCancelConfirmation}>
                <ConfirmationModal text={t('customerArea:modalCancel')} confirm={cancelSubscription}/>
            </Modal>
            <Modal eventClose={onCloseModal} open={openRenewConfirmation}>
                <ConfirmationModal text={t('customerArea:modalReactivate')} confirm={renewSubscription}/>
            </Modal>
            <Modal eventClose={onCloseModal} open={openSubscriptionDetails}>
                <SubscriptionDetails subscription={subscriptionSelected.current}/>
            </Modal>
            <Modal eventClose={onCloseModal} open={openPaymentMethodChoice}>
                <ListPaymentMethod subscription={subscriptionSelected.current}/>
            </Modal>
        </div>


    )
}

export {MySubscription}