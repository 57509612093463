import {Row} from "../grid/row";
import {ButtonBase} from "../buttons/buttonBase";
import {useEffect} from "react";
import {Column} from "../grid/column";
import {useTranslation} from "react-i18next";

type SubscriptionDetailsProps = {
    subscription: any
}
const SubscriptionDetails = ({subscription}:SubscriptionDetailsProps) => {
    const {t, i18n} = useTranslation()

    let copyToClipboard = () => {
        navigator.clipboard.writeText(subscription.key)
    }

    return (
        <div className={'details-subscription-container'}>
            <Row>
                <p className={'title'}>{t('prelaunch:subscribe')} DDAL/EF{subscription.name}</p>
            </Row>
            <Row>
                <Column min={12}>
                    <div className={'line'}>
                        <p>{t('customerArea:createdAt')} :</p>
                        <p>{new Date(subscription.created * 1000).toLocaleDateString()}</p>
                    </div>
                </Column>
            </Row>
            <Row>
                <Column min={12}>
                    <div className={'line'}>
                        <p>{t('customerArea:nextPayment')} :</p>
                        <p>{new Date(subscription.current_period_end * 1000).toLocaleDateString()}</p>
                    </div>
                </Column>
            </Row>
            <Row>
                <Column min={12}>
                    <div className={'line'}>
                        <p>{t('customerArea:statut')} :</p>
                        <p className={'status--' + subscription.status}>{subscription.status}</p>
                    </div>
                </Column>
            </Row>
            <Row>
                <Column min={12}>
                    <div className={'line'}>
                        <p>{t('customerArea:licence')} :</p>
                        <p style={{width:'200px', overflow:'auto'}} >{subscription.key}</p>
                        <button onClick={copyToClipboard} className={'button-clipboard'}><svg id={"clipboard"} enableBackground="new 0 0 30 30" version="1.1" viewBox="0 0 30 30">
                            <path id={"clipboard"}
                                  d="m24.1 28.5h-9.4c-2 0-3.7-1.7-3.7-3.7v-14.1c0-2 1.7-3.7 3.7-3.7h9.4c2 0 3.7 1.7 3.7 3.7v14.1c0 2-1.7 3.7-3.7 3.7z"/>
                            <path id={"clipboard"}
                                  d="m14.7 4.8h4.3c-0.2-1.8-1.8-3.3-3.7-3.3h-9.4c-2 0-3.7 1.7-3.7 3.7v14.1c0 2 1.7 3.7 3.7 3.7h2.9v-12.3c0-3.3 2.6-5.9 5.9-5.9z"/>
                        </svg></button>
                    </div>
                </Column>
            </Row>
            <Row>
                <Column min={12}>
                    <div className={'line'}>
                        <p>{t('customerArea:amount')} :</p>
                        <p className={'mount'}>{subscription.invoice ? subscription.invoice.amount_paid / 100 : '-'}€</p>
                    </div>
                </Column>
            </Row>
            <Row>
                <Column min={12}>
                    <div className={'line ai-center'}>
                        <p>{t('customerArea:lastInvoice')} :</p>
                        <a target={'_blank'} href={subscription.invoice?.hosted_invoice_url}>{`${t('customerArea:invoiceNumber')}${subscription.invoice?.number}`}</a>
                    </div>
                </Column>
            </Row>
        </div>
    )
}

export {SubscriptionDetails}