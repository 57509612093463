import {ReactNode} from "react";


type RowProps = {
    children: ReactNode;
    customClass?: string;
    [propName: string]: any;
}

const Row = ({children, customClass = "", ...props}: RowProps) => {
    return (
        <div data-testid={"row"} {...props} className={`row ${customClass}`}>
            {children}
        </div>
    );
};

export {Row};