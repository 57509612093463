import {useEffect, useRef} from "react";

export type SvgProps = {
    refSvg?: any;
    [propName: string]: any;
}
export const ArrowSVG = ({refSvg, ...props}: SvgProps) => {
    const arrowRef = useRef<SVGSVGElement>(null);
    useEffect(() => {
        refSvg.current = arrowRef.current;
    });
    return (
        <svg ref={arrowRef} {...props} enableBackground="new 0 0 30 30" version="1.1" viewBox="0 0 30 30">
            <path
                d="m17.3 23.17 11.75-11.75c1.27-1.27 1.27-3.32 0-4.59s-3.32-1.27-4.59 0l-9.46 9.45-9.46-9.45c-1.27-1.27-3.32-1.27-4.59 0-0.63 0.63-0.95 1.46-0.95 2.29s0.32 1.66 0.95 2.3l11.75 11.75c1.27 1.27 3.33 1.27 4.6 0z"/>
        </svg>
    );
};