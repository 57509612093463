export type SVGProps = {
    [propName: string]: any;
}

const MailSvg = ({...props}:SVGProps) => {
    return(
        <svg {...props} version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 28 28" enableBackground="new 0 0 28 28" xmlSpace="preserve">
<g id="Groupe_21" transform="translate(-676 -4212)">
	<g id="Ellipse_5" transform="translate(676 4212)">
		<circle fill="none" cx="14" cy="14" r="14"/>
        <path fill="#2C358B" d="M14,28C6.3,28,0,21.7,0,14S6.3,0,14,0s14,6.3,14,14S21.7,28,14,28z M14,2C7.4,2,2,7.4,2,14s5.4,12,12,12
			s12-5.4,12-12S20.6,2,14,2z"/>
	</g>
    <g id="envelope" transform="translate(684.496 4221.676)">
		<path id="Tracé_197" fill="#2C358B" d="M11,2.8v4.9c0,0.5-0.4,1-1,1H1c-0.3,0-0.5-0.1-0.7-0.3C0.1,8.2,0,7.9,0,7.7V2.8
			C0.2,3,0.4,3.2,0.6,3.3c1.5,1,2.5,1.7,3.1,2.1c0.2,0.2,0.4,0.3,0.6,0.4C4.4,6,4.6,6.1,4.8,6.1C5,6.2,5.3,6.3,5.5,6.3h0
			c0.2,0,0.5-0.1,0.7-0.2C6.4,6.1,6.6,6,6.8,5.8c0.2-0.1,0.3-0.2,0.6-0.4C8,4.9,9.1,4.2,10.4,3.3C10.6,3.2,10.8,3,11,2.8z M11,1
			c0,0.3-0.1,0.7-0.3,0.9c-0.2,0.3-0.5,0.5-0.8,0.8l-2.9,2L6.8,4.9C6.7,5,6.6,5,6.5,5.1C6.4,5.2,6.3,5.2,6.2,5.3
			C6.1,5.4,5.9,5.4,5.8,5.5c-0.1,0-0.2,0.1-0.3,0.1h0c-0.1,0-0.2,0-0.3-0.1c-0.1,0-0.2-0.1-0.3-0.2C4.7,5.2,4.6,5.1,4.5,5.1
			S4.3,4.9,4.2,4.9L3.9,4.7C3.5,4.4,3,4,2.3,3.5S1.2,2.8,1.1,2.7C0.8,2.5,0.5,2.2,0.3,2C0.1,1.7,0,1.4,0,1.1c0-0.3,0.1-0.6,0.2-0.8
			C0.4,0.1,0.7,0,1,0h9c0.3,0,0.5,0.1,0.7,0.3C10.9,0.5,11,0.7,11,1z"/>
	</g>
</g>
</svg>
    )
}

export default MailSvg;