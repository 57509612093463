import React, {useEffect, useRef, useState} from "react";
import {
    CardElement, CardNumberElement,
    Elements,
    LinkAuthenticationElement,
    PaymentElement,
    useElements,
    useStripe
} from "@stripe/react-stripe-js";
import axios from "axios";
import LogoSvg from "../../style/svg/logo/logoSvg";
import {Row} from "../components/common/grid/row";
import {Column} from "../components/common/grid/column";
import {ButtonBase} from "../components/common/buttons/buttonBase";
import {Textbox} from "../components/common/inputs/textbox";
import {Dropdown, Item} from "../components/common/inputs/dropdown";
import Loading from "./loading";
import {TailSpin} from "react-loader-spinner";
import {formatDateWithOneYear} from "../helper/formatDate";
import {useNavigate, useSearchParams} from "react-router-dom";
import {ConfirmationPayment} from "./confirmationPayment";
import {stripePromise} from "../../App";
import {useTranslation} from "react-i18next";
import ListPaymentMethod from "../components/common/modals/listPaymentMethod";
import {AddSvg} from "../../style/svg/addSvg";
import removeInputError from "../helper/removeInputError";
import setErrorField from "../helper/setErrorField";


type PaymentData = {
    [key:string]: any;
    total:number;
    subTotal:number;
    tax:number;
}

type PromoCodeData = {
    [key:string]: any;
    value:number;
    message:string;
    id:number;
}
const Payment = () => {
    const [clientSecret, setClientSecret] = useState('');
    const [paymentData, setPaymentData] = useState({} as PaymentData)
    const [promoCodeData, setPromoCodeData] = useState<PromoCodeData | null>(null)
    const [codePromo, setCodePromo] = useState<string>("");
    const refPaymentContainer = useRef<HTMLDivElement>(null);
    const refLoading = useRef<HTMLDivElement>(null);
    const [quantity, setQuantity] = useState<number>(1);
    const [loadingQuantity, setLoadingQuantity] = useState<boolean>(false);
    const [quantityData, setQuantityData] = useState<Item[]>([]);
    const [searchParams, setSearchParams] = useSearchParams()
    const [isTrial, setIsTrial] = useState<boolean | null>(false)
    const [confirmationPayment, setConfirmationPayment] = useState<boolean>(false)
    const [total, setTotal] = useState<number | null>(0)
    const {t, i18n} = useTranslation()

    useEffect(() => {
        if (searchParams.get('trial') !== undefined && searchParams.get('trial') !== null) {
            setIsTrial(JSON.parse(searchParams.get("trial") as string) as boolean | null);
        }
    }, [searchParams])

    useEffect(() => {
        setTotal(promoCodeData === null ? paymentData.total : paymentData.total - ((promoCodeData.value / 100) * paymentData.subTotal))
    },[paymentData, promoCodeData])

    let generateQuantity = () => {
        let listTemp = []
        for (let i = 1; i < 11; i++) {
            listTemp.push({id: i, name: i.toString()})
        }
        setQuantityData(listTemp)
    }

    useEffect(() => {
        generateQuantity()
    },[])

    let handleSelected = (item:Item) => {
        setQuantity(item.id)
    }

    useEffect(() => {
        setLoadingQuantity(true)
        axios.post('/createPaymentIntent', {quantity:quantity,token:window.localStorage.getItem('token')},{params:{doNotCatch :true}}).then(res => {
            if(res.status === 200){
                setClientSecret(res.data.clientSecret)
                setPaymentData({
                    total:res.data.total/100,
                    subTotal:res.data.subTotal/100,
                    tax:res.data.tax,
                })
                setLoadingQuantity(false)
            }
        }).catch(err => {
            if(err.response.data.status === 377){
                window.location.href = "/register"
            }
            console.log(err)
        })

    }, [quantity]);

    let handleChange = (e:any) => {
        setCodePromo(e.currentTarget.value);
    }

    let checkPromoCode = (e:any) => {
        axios.post('/checkPromoCode', {code:codePromo},{params:{doNotCatch :true}}).then(res => {
            if(res.status === 200){
                setPromoCodeData({
                    value:res.data.percentage,
                    message:res.data.message,
                    id:res.data.id
                })
            }
        }).catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {
        if(clientSecret){
            document.body.style.overflow = "hidden"
            refLoading.current?.classList.add("rocket-loading-container--close")
            refPaymentContainer.current?.classList.add("payment-container--open")
            setTimeout(() => {
                refLoading.current?.classList.add("rocket-loading-container--hide")
                document.body.style.overflow = "auto"
            },500)
        }

    },[clientSecret])


    return (
        <div style={{overflow:'hidden'}}>
            <div ref={refPaymentContainer} className={"payment-container"}>
                <Row customClass={"jc-center"}>
                    <Column xl={8} customClass={"mb-100"}>
                        <Row>
                            <Column customClass={"jc-center d-flex ai-center"} min={12}>
                                <a href={'/'}><LogoSvg style={{height: "80px", marginTop: "50px"}} blue={true}/></a>
                            </Column>
                        </Row>
                        <Row customClass={"jc-space-between"}>
                            {confirmationPayment ? <ConfirmationPayment quantitySave={quantity} totalSave={total}/> : <><Column customClass={"info-container"} xl={5}>
                                {loadingQuantity ? <div className={"pos-relative w-100 h-100"}>
                                    <TailSpin color={"#5c83ce"} wrapperClass={"spinner-loading"}/>
                                </div> : <>{isTrial ? <Row>
                                    <div className={"d-flex ai-center"}>
                                        <div className={"total-number"}>0€</div>
                                    </div>
                                    <p style={{marginBottom: isTrial ? "0" : "10px"}}
                                       className={"p-period w-100"}>{`${t('payment:trialPeriod')} ${formatDateWithOneYear(new Date(Date.now()).toLocaleDateString())} ${t('payment:to')} ${formatDateWithOneYear(new Date(Date.now()).toLocaleDateString(), false, true)}`}</p>
                                    <div className={"w-100 mt-30"}>
                                        <div className={"payment-info-line"}>
                                            <p>{t('payment:DDALTrial')}</p>
                                            <p>0€</p>
                                        </div>
                                        <div className={"payment-info-line"}>
                                            <p>{t('payment:amount')}</p>
                                            <p>0€</p>
                                        </div>
                                    </div>
                                </Row> : null}
                                    <Row>
                                        {isTrial ? <p style={{fontWeight: "bold", margin: 0, marginTop: "20px"}}
                                                      className={"w-100"}>{`${t('payment:invoice')} ${formatDateWithOneYear(new Date(Date.now()).toLocaleDateString(), false, true)}`}</p> : null}
                                        {isTrial ? null : <div className={"d-flex ai-center"}>
                                            <div
                                                className={"total-number"}>{promoCodeData == null ? paymentData.total : paymentData.total - ((promoCodeData.value / 100) * paymentData.subTotal)}€
                                            </div>
                                            {promoCodeData == null ? null :
                                                <div className={"reduction-number"}>-{promoCodeData.value}%</div>}
                                        </div>}
                                        <p style={{marginBottom: isTrial ? "0" : "10px"}}
                                           className={"p-period w-100"}>{isTrial ? `${t('payment:period')} ${formatDateWithOneYear(new Date(Date.now()).toLocaleDateString(), false, true)} ${t('payment:to')} ${formatDateWithOneYear(new Date(Date.now()).toLocaleDateString(), true, true)}` : `${t('payment:period')} ${formatDateWithOneYear(new Date(Date.now()).toLocaleDateString())} ${t('payment:to')} ${formatDateWithOneYear(new Date(Date.now()).toLocaleDateString(), true)}`}</p>
                                        <div className={"w-100 mt-30"}>
                                            <div className={"payment-info-line"}>
                                                <p>{t('payment:DDALPro')}</p>
                                                {isTrial ? null : <Dropdown selected={handleSelected} value={quantity}
                                                                            dataItem={quantityData}/>}
                                                <p>{paymentData.subTotal}€</p>
                                            </div>
                                            {promoCodeData == null ? null : <div className={"payment-info-line"}>
                                                <p>{t('payment:promoCode')}</p>
                                                <p>-{(promoCodeData.value / 100) * paymentData.subTotal}€</p>
                                            </div>}
                                            <div className={"payment-info-line"}>
                                                <p>TVA ({paymentData.tax * 100}%)</p>
                                                <p>+{paymentData.tax * paymentData.subTotal}€</p>
                                            </div>
                                            <div className={"payment-info-line"}>
                                                <p>{isTrial ?t('payment:nextAmount')  : t('payment:amount')}</p>
                                                <p>{total}€</p>
                                            </div>
                                        </div>
                                        <p style={{fontWeight: "bold", margin: 0, marginTop: "20px"}}
                                           className={"w-100"}>{`${t('payment:invoice')} ${formatDateWithOneYear(new Date(Date.now()).toLocaleDateString(), true, isTrial ? isTrial : false)}`}</p>
                                        <p style={{marginBottom: isTrial ? "0" : "10px"}}
                                           className={"p-period w-100"}>{`${t('payment:period')} ${formatDateWithOneYear(new Date(Date.now()).toLocaleDateString(), true)} ${t('payment:to')} ${formatDateWithOneYear(new Date(Date.now()).toLocaleDateString(), false,isTrial?isTrial:false,true)}`}</p>
                                        <div className={"w-100 mt-30"}>
                                            <div className={"payment-info-line"}>
                                                <p>{t("payment:renewAmount")}</p>
                                                <p>{total?total/2 : 0}€</p>
                                            </div>
                                        </div>
                                        <div className={"add-promo-container"}>
                                            <p>Code promo</p>
                                            <div className={"d-flex jc-space-between"}>
                                                <Textbox change={handleChange}/>
                                                <ButtonBase onClick={checkPromoCode}
                                                            customClass={codePromo === "" ? "button-light disabled": "button-light"}>{t('payment:apply')}</ButtonBase>
                                            </div>
                                            <p>{promoCodeData?.message}</p>
                                        </div>
                                    </Row></>}
                            </Column>
                                <Column customClass={"card-container"} xl={5}>
                            {clientSecret && (<Elements options={{clientSecret: clientSecret, locale: i18n.language == 'en' ? 'en' : 'auto'}} stripe={stripePromise}>
                                <PaymentForm setConfirmationPayment={setConfirmationPayment} trial={isTrial}
                                             quantity={quantity} codeId={promoCodeData?.id} total={total}/>
                            </Elements>)}
                    </Column></>}
                        </Row>
                    </Column>
                </Row>
            </div>
            <Loading refLoading={refLoading}/>
                      </div>

    )
}

type PaymentFormProps = {
    codeId?:number |undefined;
    trial?:boolean | null ;
    quantity?:number;
    setConfirmationPayment:React.Dispatch<React.SetStateAction<boolean>>;
    total?:number | null;
    add?:boolean;
}

const PaymentForm = ({codeId, trial, quantity,setConfirmationPayment, total, add} : PaymentFormProps) => {
    const stripe = useStripe();
    const elements = useElements();
    const [message, setMessage] = useState<string | undefined>('');
    const isDefault = useRef<boolean>(false)
    const navigate = useNavigate();
    const [addNewPaymentMethod, setAddNewPaymentMethod] = useState<boolean>(false)
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string>("")
    const {t, i18n} = useTranslation()
    const [loading, setLoading] = useState<boolean>(false)
    const cvg = useRef<boolean>(false)

    useEffect(() => {
        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get(
            "setup_intent_client_secret"
        );

        const setupIntentId = new URLSearchParams(window.location.search).get(
            "setup_intent"
        );

        const quantityReal = new URLSearchParams(window.location.search).get(
            "quantity"
        );



        if(setupIntentId){
            setConfirmationPayment(true)
            if(add){
                axios.post('/successAddSetupPaymentIntent', {setupIntentId:setupIntentId, default:isDefault.current, token:window.localStorage.getItem('token')},{params:{doNotCatch :true}}).then(res => {
                    if(res.status === 200){
                    }
                })
            }else{

                axios.post('/successSetupIntent', {setupIntentId:setupIntentId, codeId:codeId, trial:trial, quantity:quantityReal, default:isDefault.current,token:window.localStorage.getItem('token')},{params:{doNotCatch :true}}).then(res => {
                    if(res.status === 200){

                    }
                }).catch(err=>{
                    setLoading(false)
                    if(err.response.data.status === 377){
                        window.location.href = "/login"
                    }
                })
            }
        }



        if (!clientSecret) {
            return;
        }

        stripe.retrieveSetupIntent(clientSecret).then(({ setupIntent }) => {
            if(!setupIntent){
                return;
            }
            switch (setupIntent.status) {
                case "succeeded":
                    setMessage("Payment succeeded!");
                    break;
                case "processing":
                    setMessage("Your payment is processing.");
                    break;
                case "requires_payment_method":
                    setMessage("Your payment was not successful, please try again.");
                    break;
                default:
                    setMessage("Something went wrong.");
                    break;
            }
        });
    }, [stripe, quantity,trial, codeId, add]);

    const handleSubmit = async () => {
        setLoading(true)

        if (!stripe || !elements) {
            return;
        }

        const {error } = await stripe.confirmSetup({
            elements,
            confirmParams: {
                return_url: add ? process.env.REACT_APP_RETURN_ADD_PAYMENT_URL ? process.env.REACT_APP_RETURN_ADD_PAYMENT_URL + "?default=" + isDefault.current : "" : process.env.REACT_APP_RETURN_PAYMENT_URL ? process.env.REACT_APP_RETURN_PAYMENT_URL + '?trial=' + trial + "&total=" + total + "&quantity=" + quantity : "",
            },
        });

        if (error?.type === "card_error" || error?.type === "api_error") {
            setMessage(error.message);
        } else {
            console.log(error)
            setMessage("An unexpected error occurred.");
        }
    };

    let checkCvg = (e:any, handleSubmit:any, add:boolean | undefined) => {
        e?.preventDefault()
        if(add){
            handleSubmit()
            return false
        }
        axios.post('/checkCVG', {cvg:cvg.current, lng:i18n.language},{params:{doNotCatch :true}}).then(res => {
            if(res.status === 200){
                handleSubmit()
            }
        }).catch(err => {
            removeInputError('.input--error')
            removeInputError('.input-container--error')
            if(err.response.data.errorFields){
                setMessage(setErrorField(err))
            }
            })
        return false
    }

    let submitWithOldPaymentMethod = () => {
        setLoading(true)
        axios.post('/successSetupIntent', {paymentMethodId:selectedPaymentMethod, codeId:codeId, trial:trial, quantity:quantity, default:isDefault.current,token:window.localStorage.getItem('token')},{params:{doNotCatch :true}}).then(res => {
            if(res.status === 200){
                setConfirmationPayment(true)
            }
        }).catch(err=>{
            setLoading(false)
            if(err.response.data.status === 377){
                window.location.href = "/login"
            }
        })
    }

    let handleCVGChange = (e:any) => {
        cvg.current = e.currentTarget.checked
        console.log(e.currentTarget.checked)
    }

    console.log(add)

    return (
        <div className={'form-container'}>
            {add ? null : <button onClick={() => setAddNewPaymentMethod(!addNewPaymentMethod)} className={"button-add mb-30"}>
                <div><AddSvg/>{!addNewPaymentMethod ? t("payment:addPaymentMethod") : t("payment:choosePaymentMethod")}
                </div>
            </button>}
            {!addNewPaymentMethod && !add ?
                <>
                    <ListPaymentMethod payment={true} setPaymentMethodId={setSelectedPaymentMethod} />
                    {trial ? <p className={"p-terms mt-20"}>
                        {t("payment:terms")}
                    </p> : null}
                    <div className={'d-flex mt-20'}>
                        <input onChange={handleCVGChange} id={"usage-checkbox"}
                               type={"checkbox"} className={"checkbox"}/>
                        <label className={'p-terms'} htmlFor={"usage-checkbox"}>{t('payment:accept')} <a href={'https://www.implex.fr/telechargements/politique_confidentialite_implex.pdf'} target={'_blank'}>Conditions général implex</a>,  <a href={i18n.language == "en" ? 'https://www.implex.fr/telechargements/CGV-DDAL-IMPLEX-Siteweb-EN.pdf' : 'https://www.implex.fr/telechargements/CGV-DDAL-IMPLEX-Siteweb.pdf'} target={'_blank'}>Conditions général de vente</a>,  <a href={'https://www.implex.fr/telechargements/Contrat_de_licence_Dynamic_Data_Analysis_Language_EF.pdf'} target={'_blank'}>Licence DDAL/EF</a> </label>
                    </div>
                    {loading ? <TailSpin color={"#5c83ce"} wrapperClass={"spinner-payment"}/> : <ButtonBase onClick={() => checkCvg(null,submitWithOldPaymentMethod, add)}
                                 customClass="button-light">{add ? t("payment:add") : t("payment:subscribe")}</ButtonBase>}
                </>
                :
                <form id={"subscription"} onSubmit={(e) => checkCvg(e,handleSubmit,add)}>
            <PaymentElement options={{layout: "tabs"}}/>
                    {trial ? <p className={"p-terms"}>
                        {t("payment:terms")}
                    </p> : null}
            <div className={"ai-center d-flex mb-20 mt-20"}>
                {add ? <>
                <input onChange={(e) => isDefault.current = e.currentTarget.checked} id={"usage-checkbox"}
                                type={"checkbox"} className={"checkbox"}/>
                    <label htmlFor={"usage-checkbox"}>{t("payment:defaultPayment")}</label></> : <div className={'d-flex'}>
                    <input onChange={handleCVGChange} id={"cvg"}
                           type={"checkbox"} className={"checkbox"}/>
                    <label className={'p-terms'} htmlFor={"cvg"}>{t('payment:accept')} <a href={'https://www.implex.fr/telechargements/politique_confidentialite_implex.pdf'} target={'_blank'}>{t('payment:cvgImplex')}</a>,  <a href={i18n.language == "en" ? 'https://www.implex.fr/telechargements/CGV-DDAL-IMPLEX-Siteweb-EN.pdf' : 'https://www.implex.fr/telechargements/CGV-DDAL-IMPLEX-Siteweb.pdf'} target={'_blank'}>{t('payment:cvg')}</a>,  <a href={'https://www.implex.fr/telechargements/Contrat_de_licence_Dynamic_Data_Analysis_Language_EF.pdf'} target={'_blank'}>{t('payment:licence')}</a> </label>
                </div>}
            </div>
                    {loading ? <TailSpin color={"#5c83ce"} height={50}  wrapperClass={'spinner-payment'}/> : <ButtonBase labelMethod={() => checkCvg(null,handleSubmit,add)} type={"submit"} form={"subscription"}
                                 customClass="button-light">{add ? t("payment:add") : t("payment:subscribe")}</ButtonBase>}
        </form>
}
            {message && <div className={"mt-20 label-error"}>{message}</div>}
        </div>
    )
}

export {Payment, PaymentForm}