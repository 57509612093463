import React, {useEffect, useState} from "react";
import {Textbox} from "../components/common/inputs/textbox";
import {ButtonBase} from "../components/common/buttons/buttonBase";
import {Row} from "../components/common/grid/row";
import {Column} from "../components/common/grid/column";
import LogoSvg from "../../style/svg/logo/logoSvg";
import {useSearchParams} from "react-router-dom";
import axios from "axios";
import check from "../../style/image/check.png";
import {useTranslation} from "react-i18next";

const ForgotPassword = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const [id, setId] = useState<string | null>()
    const [success, setSuccess] = useState<boolean>(false)
    const [codeError, setCodeError] = useState<any>(null)
    const [password, setPassword] = useState<string>('')
    const [passwordConfirmation, setPasswordConfirmation] = useState<string>('')
    const {t, i18n} = useTranslation()

    useEffect(() => {
        if (searchParams.get('id') === undefined || searchParams.get('id') === null)
            return
        setId(searchParams.get('id'))
    }, [searchParams])

    let handleSubmit = (e:any) => {
        e.preventDefault();
        axios.post('/passwordForgotten', {token:id, password:password, confirmPassword:passwordConfirmation},{params:{doNotCatch :true}}).then(res => {
            console.log(res.data)
            if(res.status === 200){
                console.log(res.data)
                setSuccess(true)
            }
            else {
                setCodeError(res.data)
            }
        }).catch(err => {
            setSuccess(false)
        })
        return false;
    }

    let handleClickLogo = () => {
        window.location.href = '/'
    }

    return (
        <>
            <div className='forgot-password-container'>
                <Row>
                    <Column customClass={"jc-center d-flex ai-center"} min={12}>
                        <LogoSvg onClick={handleClickLogo} style={{height: "80px", marginTop: "50px",cursor:"pointer"}} blue={true}/>
                    </Column>
                </Row>
                <Row customClass={'jc-center d-flex ai-center mt-50'}>
                    <Column customClass={'common-block'} xl={5}>
                        {success ? <SuccessForgotPassword/> : <form onSubmit={handleSubmit} id={"password"}>
                            <Textbox type={"password"} change={(e) => setPassword(e.currentTarget.value)}
                                     customClass={"mb-30"} placeholder={t('customerArea:password')}/>
                            <Textbox type={"password"} change={(e) => setPasswordConfirmation(e.currentTarget.value)}
                                     customClass={"mb-30"} placeholder={t('customerArea:passwordConfirm')}/>
                            <ButtonBase labelMethod={handleSubmit} type={"submit"} form={"password"}
                                        customClass="button-light">{t('customerArea:save')}</ButtonBase>
                        </form>}
                    </Column>
                </Row>

            </div>
        </>
    )
}

const SuccessForgotPassword = () => {
    const {t, i18n} = useTranslation()
    let handleClick = () => {
        window.location.href = '/login'
    }
    return(
        <div className={'success-password-container'}>
            <img src={check}/>
            <p>{t('login:passwordSuccess')}</p>
            <ButtonBase onClick={handleClick} type={"submit"} form={"password"}
                        customClass="button-light">{t('login:login')}</ButtonBase>
        </div>
    )
}

export default ForgotPassword;