import {useContext, useEffect, useState} from "react";
import {NavContext, NavContextType} from "../../App";

function useRefreshItemNav(id:string) {
    const {navItemsNavigationBase, setNavItemsNavigationBase} = useContext(NavContext) as NavContextType;

    useEffect(() => {
        setNavItemsNavigationBase(prevState => {
            return prevState.map(prevItem => {
                if(prevItem.id === id){
                    return {...prevItem, isActive:true}
                }
                return {...prevItem, isActive:false}
            })
        })
    }, []);

    return {
    };
}

export {useRefreshItemNav};