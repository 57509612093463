import {NavItem} from "../navigation/navigationBase";
import React, {useContext} from "react";
import {ModalContext, ModalContextType} from "./modal";

type ConfirmationModalProps = {
    confirm?:any;
    text?:string;
}



const ConfirmationModal = ({confirm,text}:ConfirmationModalProps) => {
    const {handleClose} = useContext(ModalContext) as ModalContextType;
    return (
        <div className={'confirmation-modal-container'}>
            <p>{text}</p>
            <div className={'button-container'}>
                <button onClick={confirm} className={'button-confirmation'}>Oui</button>
                <button onClick={handleClose} className={'button-confirmation'}>Non</button>
            </div>
        </div>
    )
}

export default ConfirmationModal