import {NavigationMobile} from "../components/common/navigation/navigationMobile";
import {NavigationBase} from "../components/common/navigation/navigationBase";
import Header from "../components/home/header";
import React, {useContext, useEffect, useRef} from "react";
import {Footer} from "../components/common/navigation/footer";
import Newsletter from "../components/preLaunch/newsletter";
import {Row} from "../components/common/grid/row";
import {Column} from "../components/common/grid/column";
import {AnimationOnScroll} from "react-animation-on-scroll";
import {useTranslation} from "react-i18next";
import QuestionElement from "../components/preLaunch/QuestionElement";
import {Demonstrator} from "../components/preLaunch/demonstrator";
import {Helmet} from "react-helmet";
import {BenefitsBlockV2} from "../components/preLaunch/BenefitsBlockV2";
import {BenefitsEntityFramework} from "../components/preLaunch/benefitsEntityFramework";
import BenefitsDDAL from "../components/preLaunch/benefitsDDAL";
import {useSavePosScrollBar} from "../helper/useSavePosScrollBar";
import {useRefreshItemNav} from "../helper/useRefreshItemNav";


export const homeId = 'home'
const PreLaunch = () => {
    const {} = useSavePosScrollBar(homeId)
    const {} = useRefreshItemNav(homeId)
    const {t, i18n} = useTranslation()
    return (
        <>
            <header>
                <NavigationMobile/>
                <NavigationBase/>
                <Header preLaunch={false}/>
            </header>
            <Helmet>
                <title>DDAL - Dynamic Data Analysis Language, outil backend, implementation entity framework</title>
                <meta name="description" content="DDAL - Dynamic Data Analysis Language, outil backend, implementation entity framework" />
            </Helmet>
            <div className='prelaunch-container'>
                <div className={'background-image'}>
                    <BenefitsBlockV2/>
                </div>
                <BenefitsEntityFramework/>
                <BenefitsDDAL/>
                <QuestionElement/>
                <Row style={{background:'#f2f7fc'}} customClass={'jc-center mt-60'}>
                    <Column min={12} xl={8}>
                        <Demonstrator/>
                    </Column>
                </Row>
                <div className={'background-end-image'}>
                    <AnimationOnScroll animateOnce={true} animateIn={"animate__fadeInRightBig"}>
                <Row customClass={'jc-center mt-100'}>
                    <Column min={12} xl={8}>
                        <Newsletter/>
                    </Column>
                </Row>
                    </AnimationOnScroll>
                </div>
            </div>
            <Footer preLaunch={true}/>
        </>
    )
}

export default PreLaunch;