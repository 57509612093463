import {NavigationMobile} from "../components/common/navigation/navigationMobile";
import {NavigationBase, NavItemsNavigationBase} from "../components/common/navigation/navigationBase";
import {Row} from "../components/common/grid/row";
import {Column} from "../components/common/grid/column";
import {Footer} from "../components/common/navigation/footer";
import FreeLogoSvg from "../../style/svg/freeLogoSvg";
import {ButtonBase} from "../components/common/buttons/buttonBase";
import {ProLogoSvg} from "../../style/svg/proLogoSvg";
import {CheckSvg} from "../../style/svg/checkSvg";
import {useTranslation} from "react-i18next";
import support from "../../style/image/support.png";
import time from "../../style/image/temps.png";
import multiplateforme from "../../style/image/multiplateforme.png";
import React from "react";
import {discordUrl} from "../../index";
import {AdvantageBlock} from "../components/preLaunch/BenefitsBlockV2";
import {useSavePosScrollBar} from "../helper/useSavePosScrollBar";
import {useRefreshItemNav} from "../helper/useRefreshItemNav";
import {Helmet} from "react-helmet";

export const priceId = 'price'
const Price = () => {
    const {} = useSavePosScrollBar(priceId)
    const {} = useRefreshItemNav(priceId)
    const {t, i18n} = useTranslation()
    let handleTrial = () => {
        window.location.href = '/payment?trial=true'
    }
    let handlePro = () => {
        window.location.href = '/payment?trial=false'
    }

    return(
        <>
            <NavigationMobile blue={true}/>
            <NavigationBase blue={true}/>
            <Helmet>
                <title>DDAL - Price list, Dynamic Data Analysis Language, EF Core</title>
                <meta name="description" content="DDAL EF Core from €960 excl. tax per year, with a renewal fee of €480 excl. tax per year." />
            </Helmet>
            <div className='price-container'>
                <Row>
                    <Column customClass={'jc-center d-flex'} min={12}>
                        <h1><span>{(t('price:ready'))}</span> {(t('price:tryToDDAL'))}</h1>
                    </Column>
                </Row>
                <Row customClass={'mb-100 jc-center ai-end'}>
                    <Column customClass={'column-free'} min={12} sm={5} md={5} xl={3}>
                        <div className='container-free'>
                            <div>
                                <FreeLogoSvg/>
                                <h2>{(t('price:trialFree'))}</h2>
                                <p>{(t('price:onOneMounth'))}</p>
                                <ButtonBase onClick={handleTrial} customClass={'button-light'}>{(t('price:trial'))}</ButtonBase>
                            </div>
                        </div>
                    </Column>
                    <Column customClass={'column-pro'} min={12} sm={5} md={5} xl={3}>
                            <div className='container-pro'>
                                <div>
                                    <ProLogoSvg/>
                                    <h2>{(t('price:versionPro'))}</h2>
                                    <p><span style={{fontWeight:700}}>960</span>€ HT {(t('price:perYear'))}</p>
                                    <ButtonBase onClick={handlePro} customClass={'button-white'}>{(t('price:buy'))}</ButtonBase>
                                </div>
                            </div>
                    </Column>
                </Row>
                <Row customClass={'jc-center mt-100'}>
                    <Column min={12} lg={7} xl={7}>
                        <Row>
                            <Column lg={4} min={12}>
                                <div className={"advantage-block mb-60 text-center"}>
                                    <img src={support}/>
                                    <h4>{t('price:reactifSupport')}</h4>
                                    <p className={'mt-20'}>{t('price:weAreAtYour')} <a target={'_blank'} href={discordUrl}>Discord</a> {t('price:orBy')} <a href={'mailto:support.ddal@implex.fr'}>mail</a></p>
                                </div>
                            </Column>
                            <Column lg={4} min={12}>
                                <AdvantageBlock img={time} title={t('price:dataExport')} paragraph={t('prelaunch:paragraphAnalyst4')}/>
                            </Column>
                            <Column lg={4} min={12}>
                                <AdvantageBlock img={multiplateforme} title={t('price:multiplatform')} paragraph={t('prelaunch:paragraphAnalyst2')}/>
                            </Column>
                        </Row>
                    </Column>


                </Row>
            </div>
            <Footer/>
        </>
    )
}

export {Price}