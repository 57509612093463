import {dracula} from "@uiw/codemirror-theme-dracula";
import {langs} from "@uiw/codemirror-extensions-langs";
import ReactCodeMirror from "@uiw/react-codemirror";

const CollectionSpecifiers = () => {
    return (
        <div className={'api'}>
            <h1>Collection specifiers</h1>
            <p>Collection specifiers are used to, indeed, specify the behaviour to adopt when encountering collections in <a href="/api/DDAL/filter/propertyPaths">property paths</a>.
                They are used by adding a colon after a collection in a property path, followed by the name of the collection specifier you want to use.
                It is also possible to omit the collection specifier entirely, in which case <code>{`any`}</code> will be the default one.</p>
            <p>Let's showcase a few examples to clear it up:</p>
            <ReactCodeMirror theme={dracula} value={`Store;Products:any.Name = 'Apples'`} extensions={[langs.javascript()]} />
            <p>This will filter stores who sell <em>at least</em> one product named "Apples". Compare that to:</p>
            <ReactCodeMirror theme={dracula} value={`Store;Products:all.Name = 'Apples'`} extensions={[langs.javascript()]} />
            <p>The above example will filter stores who <em>only</em> sell products named "Apples".</p>
            <p>As you can see, collection specifiers are very useful and easy to understand. They don't require anything fancy, and you can simply chain the nested properties after them.
                By default, if you omit the collection specifier, DDAL's language specification understands it to mean "any". For instance, the following are equivalent:</p>
            <ReactCodeMirror theme={dracula} value={`Store;Products:any.Name = 'Apples'
Store;Products.Name = 'Apples'`} extensions={[langs.javascript()]} />
            <p>As of today, the available collection specifiers are:</p>
            <ol>
                <li><code>{`any`}</code>, which means "at least one fulfills the comparison".</li>
                <li><code>{`all`}</code>, which means "all of the items fulfill the comparison".</li>
                <li><code>{`count`}</code>, which changes your comparison and is the only collection specifier allowed to terminate a <a href="/api/DDAL/filter/propertyPaths">property path</a>.
                    With the others, you have to add nested properties, but count is sufficient in itself. Take the following example:<pre><code>{`Store;Products:count > 10`}</code></pre>
                    <p>This example will filter the stores which have at least 10 products, regardless of what those products are.</p>
                </li>
            </ol>
            <p>Of those, only <code>{`count`}</code> is allowed to end a path. The others must be followed by other nested properties which will actually be used for the comparison.</p>
            <h2>Nested collections</h2>
            <p>Collection specifiers can be used, or omitted, in an arbitrary fashion among nested collections. For example:</p>
            <ReactCodeMirror theme={dracula} value={`Store;Products.Stores:any.Products:all.Stores:count = 2`} extensions={[langs.javascript()]} />
            <p>This will filter stores which have at least one product which has at least one store which has all products sold in exactly two stores.
                Obviously, this example does not make a lot of sense, but it's syntax is valid and showcases just how much you can nest things.</p>
            <h2>"Inner" filters</h2>
            <p>"Inner" filters are especially useful with collection specifiers to refine what you're trying to process.
                "Inner" filters are in fact simply <a href="/api/DDAL/filter/embeddedFilters">embedded filters</a> which follow a collection property path, with or without a collection specifier.
                Let's take a look at those two equivalent examples:</p>
            <ReactCodeMirror theme={dracula} value={`Store;Products(Name = 'Apples').ExpirationDate < TODAY
                Store;Products:any(Product;Name = 'Apples').ExpirationDate < TODAY`} extensions={[langs.javascript()]} />
            <p>These two examples will filter stores which sell at least one product relating to expired apples.
                You should also note that the second example, more verbose, explicitly indicates which collection specifier to use as well as the context marker for the inner filter. </p>
            <p>I advise you to read <a href="/api/DDAL/filter/embeddedFilters">this page</a> for more information on inner filters. It's important to note though that embedded/inner filters are especially useful with the <code>{`count`}</code> collection specifier:</p>
            <ReactCodeMirror theme={dracula} value={`Store;Products:count(Name = 'Apples' & ExpirationDate < TODAY) > 5`} extensions={[langs.javascript()]} />
            <p>As mentioned before, count has to end the path, so the <code>{`ExpirationDate`}</code> check was put inside the embedded filter.
                This example will filter the stores who sell at least 5 products relating to expired apples.</p>
        </div>
    )
}

export default CollectionSpecifiers