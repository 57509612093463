const IntroductionDDAL = () => {
    return (
        <div className={'api'}>
            <h1>Introduction to DDAL: the language and specification</h1>
            <p>DDAL, for <em>Dynamic Data Analysis Language</em> is a language specification that's meant for general use and use in application back-end.
                It is thus designed for simplicity. DDAL strives to find the best possible compromise between features and ease of use.
                To this end, much of the heavy lifting is left to the actual implementation of DDAL.</p>
            <p>A few basics before you're left to explore:</p>
            <ul>
                <li>DDAL is case-insensitive. For example, the keywords <code>CROSS ANALYSIS</code> or <code>EQUALS</code> can be written lowercase. Even writing <code>CROSS analysis</code> is valid.</li>
                <li>DDAL is currently "split" into two sub-languages: the filter sub-language and the analysis sub-language.
                    Both can be used on their own, but filter requests can be embedded in analysis requests to enrich your analysis by filtering your dataset.</li>
            </ul>
            <p>Good starting points are the <a href="/api/DDAL/filter/basics">basics for the filter language</a> and its equivalent for analysis requests.
                As a reminder, we will be using the sample model in the <a href="/api/DDAL/introduction">DDAL's global introduction</a> for our examples and showcases.</p>
            <p>DDAL is distinct from any specific implementation, most of all from DDAL/EF which is a business product for specific needs.</p>
        </div>
    )
}

export default IntroductionDDAL