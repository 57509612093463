import {dracula} from "@uiw/codemirror-theme-dracula";
import {langs} from "@uiw/codemirror-extensions-langs";
import ReactCodeMirror from "@uiw/react-codemirror";

const PropertyPaths = () => {
    return(
        <div className={'api'}>
            <h1>Property paths</h1>
            <p>Property paths constitute the left arm/operand of a <a href="/api/DDAL/filter/comparisons">comparison</a>.
                These are used to describe which specific data point in the actual data model should be the comparison target.
                They are built using the textual identifiers of properties (whatever that means, for example the name of CLR properties in DDAL/EF) separated by dots for nested properties.
                Note that property paths are not "free-form" but derive from a <a href="/api/DDAL/filter/contextMarker">context</a>.</p>
            <h2>Examples</h2>
            <p>Valid context markers would be either <code>{`Store`}</code> or <code>{`Owner`}</code>. If we want to filter all "Starbucks" stores:</p>
            <ReactCodeMirror theme={dracula} value={`Store;Name = 'Starbucks'`} extensions={[langs.javascript()]} />
            <p>Similarly, we can filter all owners older than 30:</p>
            <ReactCodeMirror theme={dracula} value={`Owner;Age > 30`} extensions={[langs.javascript()]} />
            <p>Properties can be nested, for example we can filter stores <em>whose</em> owner is "John Smith". They can be nested indefinitely:</p>
            <ReactCodeMirror theme={dracula} value={`Store;Owner.FullName = 'John Smith'`} extensions={[langs.javascript()]} />
            <p>Finally we can make an example with logical operators, groups, etc.
                Let us filter the stores whose owner is between 20 and 50 years old, or the store name is "Walmart" regardless of the owner's age:</p>
            <ReactCodeMirror theme={dracula} value={`Store;Name = 'Walmart' | (Owner.Age > 20 & Owner.Age < 50)`} extensions={[langs.javascript()]} />
            <h2>Collections</h2>
            <p>Collections are first-class citizens in DDAL's "filter language".
                They are used like any other property, although their behaviour can be made explicit using <a href="/api/DDAL/filter/collectionSpecifers">collection specifiers</a>.
                For example, it's perfectly valid to write <code>{`Store;Products.Name = 'Oranges'`}</code>. It means <em>stores with at least one product named "Oranges"</em>.
                I advise again to read the page regarding <a href="/api/DDAL/filter/collectionSpecifers">collection specifiers</a> for more information on this specific topic.</p>
        </div>
    )
}

export default PropertyPaths