const EmbeddedFilters = () => {
    return (
        <div className="api">
            <h1>Embedded filters</h1>
            <p>Embedded filters relate to filter requests that are not parsed and executed in a standalone fashion but, indeed, <em>embedded</em> in another larger context.
                Although the concept is larger, this is almost one of the following two cases:</p>
            <ol>
                <li>An "inner" filter, that is a filter expression on a <a href="/api/DDAL/filter/collectionSpecifiers">collection</a> item within a <a href="/api/DDAL/filter/propertyPaths">property path</a></li>
                <li>A filter in an analysis request</li>
            </ol>
            <p>In all cases though, <a href="/api/DDAL/filter/contextMarker">context markers</a> can be omitted for ease-of-use and legibility in embedded filters.</p>
        </div>
    )
}

export default EmbeddedFilters