import ReactCodeMirror from "@uiw/react-codemirror";
import { langs } from '@uiw/codemirror-extensions-langs';
import {dracula} from "@uiw/codemirror-theme-dracula";

const CrossAnalysis = () => {
    return (
        <div className={'api'}>
            <h1>Cross analysis in DDAL for Entity Framework Core</h1>
            <p>DDAL/EF implements a multipart cross-analysis feature, mostly used to draw charts or fetch aggregated data.
                In order to successfully execute an analysis request, a specific structure must be observed.
                As said above, cross analyses in DDAL/EF are multipart with mostly independent parts. Parts should be considered much like series in charts.
                Each part should contain specific properties that are referenced further along in this page.</p>
            <p>Let us start with a simple but somewhat exhaustive example, which we will explain in depth:</p>
            <ReactCodeMirror theme={dracula} value={
                `example = cross analysis
{
    period = days + months + years -> absent,
    
    first_part = part
    {
        root = Store,
        
        filter %= "Revenue > 5000",
        groupby /= Name,
        aggregation /= count(Products["Name = Apples"])
    },
    
    second_part = part
    {
        root = Product -> absent,
        
        aggregation /= count()
    },
    
    third_part = part
    {
        root = Product,
        
        groupby /= ExpirationDate,
        aggregation /= count()
    }
}`} extensions={[langs.javascript()]} />
            <p>We'll break the "example" cross analysis request in each part.
                We should just note before we do that however that we have specified a period for the entire request based on days, months and years. Periods always apply to the entire request.</p>
            <ol>
                <li>The first part <em>counts the number of products named apples</em> (<strong>aggregation</strong> property) of stores (<strong>root</strong> property) with more than 5000 in revenue (<strong>filter</strong> property), grouped by <em>the name of the store</em> (<strong>groupby</strong> property)</li>
                <li>The second part showcases a simpler cases: we simply counts the number of <em>all</em> products</li>
                <li>The third part makes use of the <em>period</em> property because we are grouping on a date property.
                    We count the number of products (from the pool of <em>all</em> the products) grouped by their expiration date brought back to years, months and days.</li>
            </ol>
            <p>Let us also look at what the raw JSON result might look like if we had actual data:</p>
                <ReactCodeMirror theme={dracula} value={
                    `{
  "example": {
    "first_part": {
      "root": "Store",
      "filter": "Revenue > 5000",
      "groupby": "Name",
      "aggregation": "count(Products[\\"Name = Apples\\"])",
      "result": [
        {
          "X": "Starbucks",
          "Y": 10
        },
        {
          "X": "Walmart",
          "Y": 5
        },
        {
          "...": "..."
        }
      ]
    },
    "second_part": {
      "aggregation": "count()",
      "result": 84
    },
    "third_part": {
      "root": "Product",
      "groupby": "ExpirationDate",
      "aggregation": "count()",
      "result": [
        {
          "X": "2020-01-01T00:00:00.000Z",
          "Y": 5
        },
        {
          "X": "2020-01-02T00:00:00.000Z",
          "Y": 0
        },
        {
          "X": "2020-01-03T00:00:00.000Z",
          "Y": 0
        },
        {
          "...": "..."
        },
        {
          "X": "2023-08-28T00:00:00.000Z",
          "Y": 2
        }
      ]
    }
  }
}`} extensions={[langs.json()]} />
            <p>Let us note the few important things:</p>
            <ol>
                <li>An element named "result" is injected in each part. It can contain either a single number, if there is no groupby, or a list of objects with XY properties.</li>
                <li>Properties are all present <em>by default</em> but are indeed removed from the output if marked absent (such as period in the example). See <a href="/api/DDAL/analysis/responseMarker">this page</a>.</li>
                <li>When specifying a period, DDAL/EF will fill in the gaps according to your period by adding Y0 values to the result.
                    If you specify "years" for instance, DDAL would add <code>{`{X:2022, Y:0}`}</code> to a dataset starting in 2021, ending in 2023 and with no data for 2022.</li>
            </ol>
            <p>I hope this example and its thorough explanation makes things clearer. The next section will provide references and documentation for each special property regarding cross analysis requests.</p>
            <h2>Reference</h2>
            <h3>Period</h3>
            <p>The period property is the only property specified in the root of a cross analysis, which applies to all parts.
                Its type, should be, indeed, a <a href="/api/DDAL/analysis/properties?scrollTo=standard-property-values">period value</a>.</p>
            <p>This property is optional and only applies to parts with a date <code>{`groupby`}</code>. If absent, dates will be in raw form without formatting, gap-filling or period grouping other than the actual date.</p>
            <h3>Root</h3>
            <p>The root property defines the root context (model entity) for the entire part. This property is textual, case-sensitive, and should match exactly the CLR name of the entity you want to use.</p>
            <p>This property is required.</p>
            <h3>Filter</h3>
            <p>The filter property changes, indeed filters, the root dataset the part will work with. This property should be an enclosed <a href="/api/DDAL/analysis/properties?scrollTo=filter-expression-properties">filter expression</a>.</p>
            <p>This property is optional. If unspecified, no filter is applied. It should also be noted that the context marker within the expression is implicit.</p>
            <h3>GroupBy</h3>
            <p>The groupby property defines which property should be used for the X labels in a 2d analysis. The actual property can be nested in a collection, deep in the hierarchy, etc.
                DDAL/EF will add joins as needed. This property is a <a href="/api/DDAL/analysis/properties?scrollTo=property-paths">path property</a>.</p>
            <p>This property is optional. If absent, no grouping will be applied and the result will be a numeric value depending on your aggregation.</p>
            <h3>Aggregation</h3>
            <p>The aggregation property is used by DDAL/EF to know <em>how</em> and especially <em>what</em> should be aggregated. It is an property of <a href="/api/DDAL/analysis/properties?scrollTo=aggregations">aggregation type</a>.</p>
            <p>This property is required. The final result will depend on the presence or absence of a groupby.</p>
        </div>
    )
}

export default CrossAnalysis