const StringSvg = () => {
    return (
        <svg enableBackground="new 0 0 20 20" version="1.1" viewBox="0 0 20 20" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg">
<path d="M17.5,20h-15C1.1,20,0,18.9,0,17.5v-15C0,1.1,1.1,0,2.5,0h15C18.9,0,20,1.1,20,2.5v15  C20,18.9,18.9,20,17.5,20z" fill="#5C83CE"/>
            <text transform="translate(-25.293 12.934)" fill="#FFFFFF" fontFamily="'Comfortaa-Bold'" fontSize="8px" stroke="#FFFFFF" strokeMiterlimit="10" strokeWidth=".75">STR</text>

            <g fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".75">
<path d="m2.4 12.8c-0.4-0.2-0.7-0.5-0.9-0.9v-0.2c0-0.1 0-0.2 0.1-0.2 0.1-0.1 0.1-0.1 0.2-0.1h0.1c0.1 0 0.2 0 0.2 0.1s0.1 0.1 0.2 0.2c0.1 0.2 0.3 0.4 0.6 0.5s0.6 0.2 0.9 0.2c0.4 0 0.7-0.1 1-0.3s0.4-0.4 0.4-0.8c0-0.3-0.1-0.6-0.4-0.8-0.2-0.2-0.6-0.3-1.1-0.4-0.5 0.1-1-0.1-1.4-0.4s-0.6-0.8-0.6-1.3c0-0.3 0.1-0.6 0.3-0.9 0.2-0.2 0.5-0.4 0.8-0.5 0.4-0.1 0.7-0.2 1.2-0.2s0.8 0.1 1.2 0.3c0.3 0.2 0.6 0.5 0.8 0.8v0.3c0 0.1 0 0.2-0.1 0.3-0.1 0-0.1 0.1-0.2 0.1s-0.2 0-0.2-0.1-0.2-0.1-0.2-0.2c-0.2-0.3-0.4-0.4-0.6-0.6-0.2-0.1-0.5-0.1-0.8-0.1-0.4 0-0.7 0.1-1 0.2s-0.4 0.4-0.4 0.7 0.1 0.5 0.3 0.7 0.6 0.3 1.2 0.4 1.1 0.3 1.5 0.6 0.5 0.7 0.5 1.3c0 0.4-0.1 0.7-0.3 1-0.1 0.2-0.4 0.4-0.7 0.5s-0.7 0.2-1.1 0.2c-0.6 0-1-0.1-1.5-0.4z"/>
                <path d="m12.3 7c0.1 0.1 0.1 0.2 0.1 0.3s0 0.2-0.1 0.3-0.2 0.1-0.3 0.1h-1.7v5.1c0 0.1 0 0.2-0.1 0.3s-0.2 0.1-0.3 0.1-0.2 0-0.3-0.1-0.1-0.2-0.1-0.3v-5.2h-1.7c-0.1 0-0.2 0-0.3-0.1-0.1 0-0.1-0.1-0.1-0.2s0-0.2 0.1-0.3 0.2-0.1 0.3-0.1h4.2c0.1 0 0.2 0 0.3 0.1z"/>
                <path d="m18.5 12.7c0 0.1 0 0.2-0.1 0.3s-0.2 0.1-0.3 0.1c-0.2 0-0.3-0.1-0.4-0.2l-1.4-2.2h-1.1v2c0 0.1 0 0.2-0.1 0.3s-0.2 0.1-0.3 0.1-0.2 0-0.3-0.1-0.1-0.2-0.1-0.3v-5.4c0-0.1 0-0.2 0.1-0.3s0.2-0.1 0.3-0.1h1.4c0.4 0 0.7 0.1 1.1 0.2s0.6 0.4 0.7 0.7c0.2 0.3 0.3 0.6 0.3 1s-0.1 0.7-0.3 1-0.5 0.5-0.8 0.7l1.2 2c0 0.1 0.1 0.2 0.1 0.2zm-2.3-2.8c0.4 0 0.7-0.1 0.9-0.3s0.4-0.5 0.4-0.8c0-0.4-0.1-0.6-0.4-0.9-0.2-0.2-0.5-0.3-0.9-0.3h-1v2.3h1z"/>
</g>

</svg>
    )
}

export default StringSvg