import React from "react";

type CheckboxProps = {
    [propName: string]: any;
}
const Checkbox = ({...props}: CheckboxProps) => {
    return (
        <input {...props} type="checkbox" className="checkbox"/>
    );
};

export {Checkbox};