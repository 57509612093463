
function formatDateWithOneYear(dateString:string, addOneYear = false, add30Days = false, addTwoYears = false) {
    const months = [
        'janvier', 'février', 'mars', 'avril', 'mai', 'juin',
        'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'
    ];

    const [day, monthIndex, year] = dateString.split('/').map(Number);
    const monthName = months[monthIndex - 1]; // Les mois commencent à 1

    let newYear = year;
    let newMonthIndex = monthIndex;
    let newDay = day;

    if (addOneYear) {
        newYear += 1;
    }
    if(addTwoYears){
        newYear += 2;
    }

    if (add30Days) {
        const originalDate = new Date(year, monthIndex - 1, day);
        originalDate.setDate(originalDate.getDate() + 30);
        newDay = originalDate.getDate();
        newMonthIndex = originalDate.getMonth() + 1;
        newYear = originalDate.getFullYear();
        if(addOneYear){
            newYear += 1;
        }
        if(addTwoYears){
            newYear += 2;
        }
    }

    return `${newDay} ${months[newMonthIndex - 1]} ${newYear}`;
}

export {formatDateWithOneYear};