import {Textbox} from "../common/inputs/textbox";
import {Dropdown} from "../common/inputs/dropdown";
import {ButtonBase} from "../common/buttons/buttonBase";
import europeanPhoneCountries from "../../../data-form/european-phone-number.json";
import {Row} from "../common/grid/row";
import {Column} from "../common/grid/column";
import {ContentProps} from "../../views/customerArea";
import React, {useRef, useState} from "react";
import ConfirmationModal from "../common/modals/confirmationModal";
import Modal from "../common/modals/modal";
import axios from "axios";
import {useTranslation} from "react-i18next";

type PersonnelData = {
    [key:string]: any;
    lastName: string;
    firstName: string,
    email: string,
    phoneId: string,
    phoneNumber: string,
    password: '',
    confirmPassword: ''
}

const DataPersonal = ({data,setData}:ContentProps) => {
    const dataPersonal = useRef<PersonnelData>({} as PersonnelData)
    const [openSendData, setOpenSendData] = useState<boolean>(false);
    const {t, i18n} = useTranslation()

    let updateData = (id:string, value:string) => {
        dataPersonal.current[id] = value
        data[id] = value
    }

    let sendData = () => {
        axios.post('/updatePersonalInfo', dataPersonal.current, {params:{doNotCatch :true}}).then(res => {
            if(res.status === 200){
                setOpenSendData(false)
            }
        }).catch(err => {

        })
    }

    let onCloseModal = () => {
        setOpenSendData(false)
    }

    return (
        <div className='data-personal-container'>
            <Row customClass={'mb-30'}>
                <Column min={12} xl={4}>
                    <Textbox id={'lastName'} change={(e)=>updateData('lastName',e.currentTarget.value)} placeholder={t('customerArea:name')} value={data.lastName}/>
                </Column>
                <Column customClass={'margin-responsive'} min={12} xl={4}>
                    <Textbox id={'firstName'} change={(e)=>updateData('firstName',e.currentTarget.value)} placeholder={t('customerArea:firstName')} value={data.firstName}/>
                </Column>
            </Row>
            <Row customClass={'mb-30'}>
                <Column min={12} xl={8}>
                    <Textbox id={'email'} change={(e)=>updateData('email',e.currentTarget.value)} placeholder={t('customerArea:email')} value={data.email}/>
                </Column>
            </Row>
            <Row customClass={'mb-50'}>
                <Column min={12} xl={3} >
                    <Dropdown id={'phoneId'} selected={(item)=>updateData('phoneId', item.id)} placeholder={t('customerArea:codePhone')} value={data.phoneId} dataItem={europeanPhoneCountries}/>
                </Column>
                <Column customClass={'margin-responsive'} min={12} xl={5}>
                    <Textbox id={'phoneNumber'} change={(e)=>updateData('phoneNumber',e.currentTarget.value)} placeholder={t('customerArea:phone')} value={data.phoneNumber}/>
                </Column>
            </Row>
            <Row customClass={'mb-30'}>
                <Column min={12} xl={4}>
                    <Textbox password={true} id={'password'} type={'password'} change={(e)=>updateData('password',e.currentTarget.value)} placeholder={t('customerArea:password')}/>
                </Column>
            </Row>
            <Row customClass={'mb-30'}>
                <Column min={12} xl={4}>
                    <Textbox password={true} id={'confirmPassword'} type={'password'} change={(e)=>updateData('confirmPassword',e.currentTarget.value)} placeholder={t('customerArea:passwordConfirm')}/>
                </Column>
            </Row>
            <Row >
                <Column min={12} xl={8}>
                    <ButtonBase onClick={()=>setOpenSendData(true)} customClass={'button-light mt-30'}>{t('customerArea:save')}</ButtonBase>
                </Column>
            </Row>
            <Modal eventClose={onCloseModal} open={openSendData}>
                <ConfirmationModal text={t('customerArea:modalConfirmPersonnel')} confirm={sendData}/>
            </Modal>
        </div>
    )
}

export {DataPersonal}