const BasicsDDAL = () => {
    return(
        <div className={'api'}>
            <h1>Analysis language basics</h1>
            <p>At its core, an analysis request/expression is simply a <a href="/api/DDAL/analysis/properties">property</a> which should describe <em>what</em> you want out of your analysis.
                Indeed, DDAL is mostly a declarative language; the actual process of <em>how</em> to analyze your data is left to your specific implementation of DDAL.</p>
            <p>In a conflicting way, the analysis language is more complex than the filter language, yet it has also far less core features.
                Since an analysis request is simply made of properties, <a href="/api/DDAL/analysis/properties">this page</a> is the main page for examples and understanding the analysis syntax.
                Your specific implementation (like DDAL/EF is) will determine the names you will need to use to customize the behaviour of your analysis.</p>
        </div>
    )
}
export default BasicsDDAL