import {SvgProps} from "./arrowSVG";

const LinkedinSvg = ({...props}:SvgProps) => {
    return(
        <svg {...props} version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 30 30" enableBackground="new 0 0 30 30" xmlSpace="preserve">
<g id="Groupe_14" transform="translate(-39.438 -4326.438)">
	<g id="Ellipse_4" transform="translate(39.438 4326.438)">
		<circle fill="none" cx="15" cy="15" r="15"/>
        <path fill="#2C358B" d="M15,30C6.7,30,0,23.3,0,15S6.7,0,15,0s15,6.7,15,15S23.3,30,15,30z M15,2C7.8,2,2,7.8,2,15s5.8,13,13,13
			s13-5.8,13-13S22.2,2,15,2z"/>
	</g>
    <g enableBackground="new    ">
		<path fill="#2C358B" d="M49.9,4337.8c-0.4,0-0.7-0.1-1-0.4s-0.4-0.5-0.4-0.9c0-0.4,0.1-0.6,0.4-0.9s0.6-0.3,1-0.3
			c0.4,0,0.7,0.1,1,0.3c0.2,0.2,0.4,0.5,0.4,0.9s-0.1,0.6-0.4,0.9C50.6,4337.6,50.3,4337.8,49.9,4337.8z M51.1,4346.4h-2.3v-7.5h2.3
			V4346.4z"/>
        <path fill="#2C358B" d="M60.2,4346.4h-2.3v-4.2c0-1.2-0.4-1.7-1.2-1.7c-0.4,0-0.7,0.1-1,0.5s-0.4,0.7-0.4,1.2v4.3H53v-7.5h2.3v1.2
			h0c0.5-0.9,1.3-1.4,2.4-1.4c1.7,0,2.5,1,2.5,3.1L60.2,4346.4L60.2,4346.4z"/>
	</g>
</g>
</svg>
    )
}

export default LinkedinSvg;