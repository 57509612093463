import {SvgProps} from "./arrowSVG";

const ProLogoSvg = ({...prop}:SvgProps) => {
    return(
        <svg {...prop} version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 112.7 105" enableBackground="new 0 0 112.7 105" xmlSpace="preserve">
<path d="M111.7,30.4L94.8,3c-1.2-1.9-3.2-3-5.4-3h-66c-2.2,0-4.3,1.1-5.4,3L1,30.4c-1.4,2.3-1.2,5.2,0.4,7.3
	c0,0,0,0,0,0l49.9,64.8c1,1.3,2.5,2.2,4.2,2.4c0.3,0,0.6,0.1,0.8,0.1c1.4,0,2.8-0.5,3.9-1.3c0.5-0.3,0.8-0.7,1.2-1.2l49.9-64.8
	C113,35.6,113.1,32.7,111.7,30.4z M55.6,5h1.6l19.3,25.8H36.2L55.6,5z M33.7,25.9L24.8,5.1c0,0,0,0,0-0.1h24.6L33.7,25.9z
	 M56.3,94.8l-20.8-59h41.6L56.3,94.8z M63.4,5H88c0,0,0,0,0,0.1L79,25.9L63.4,5z M30.2,35.8l20.3,57.6L6.1,35.8H30.2z M82.5,35.8
	h24.1L62.1,93.4L82.5,35.8z M106.1,30.8H82.4l9.7-22.6L106.1,30.8z M20.6,8.1l9.7,22.6H6.6L20.6,8.1z"/>
</svg>
    )
}
export {ProLogoSvg}