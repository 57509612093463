const LogoutSvg = () => {
    return(
        <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 21.4 22" enableBackground="new 0 0 21.4 22" xmlSpace="preserve">
            <g id="md-log-out" transform="translate(-4.5 -4.5)">
                <path id="Tracé_225" fill="#5F82C1" d="M13.6,16.6h8.6l-1.5,1.5c-0.4,0.4-0.4,1.1,0,1.5l0,0c0.4,0.4,1.1,0.4,1.5,0l3.3-3.3
		c0.5-0.5,0.5-1.3,0-1.8l-3.3-3.3c-0.4-0.4-1.1-0.4-1.5,0l0,0c-0.4,0.4-0.5,1.1,0,1.6l1.5,1.5h-8.6c-0.6,0-1.1,0.5-1.1,1.1l0,0
		C12.5,16.1,13,16.6,13.6,16.6z"/>
                <path id="Tracé_226" fill="#5F82C1" d="M15.5,24.2c-2.3,0-4.6-0.9-6.2-2.6c-3.4-3.4-3.4-8.9,0-12.3c0,0,0,0,0,0
		c3.2-3.2,8.2-3.4,11.7-0.7C21.4,9,22,9,22.4,8.6l0,0c0.5-0.5,0.5-1.3-0.1-1.7c-0.2-0.1-0.4-0.3-0.5-0.4c-5.4-3.8-13.1-2-16.2,4.2
		c-1.9,3.9-1.3,8.6,1.5,11.9c3.8,4.5,10.2,5.1,14.7,1.9c0.2-0.1,0.4-0.3,0.5-0.4c0.5-0.4,0.5-1.2,0.1-1.7l0,0
		c-0.4-0.4-1-0.4-1.5-0.1C19.4,23.6,17.5,24.2,15.5,24.2z"/>
                <path id="Tracé_227" fill="#5F82C1" d="M25.6,15.6L25.6,15.6L25.6,15.6L25.6,15.6L25.6,15.6z"/>
            </g>
        </svg>
    )
}

export {LogoutSvg}