import {dracula} from "@uiw/codemirror-theme-dracula";
import {langs} from "@uiw/codemirror-extensions-langs";
import ReactCodeMirror from "@uiw/react-codemirror";

const Values = () => {
    return (
        <div className={'api'}>
            <h1>Comparison values</h1>
            <p>Comparison values constitute the right arm/operand of a comparison. They are the reference value to which a specific data point (from the left arm property path) is compared to.
                As mentioned on the comparison page, any value can be compared to any property/data point as long as the expression is valid both syntactically and semantically.</p>
            <h2>Value formats</h2>
            <p>Values can have different formats, most of them are implicit. By default, a value format defaults to a string if:</p>
            <ol>
                <li>It cannot be cast to another format (integer, float...)</li>
                <li>It is not a reserved keyword (and, equals, or...) If you need to use a reserved keyword for a string value, enclose it in simple quotes.</li>
            </ol>
            <h3>Integers</h3>
            <p>Integers are automatically recognized if you type a numeric value without a floating point. Example:</p>
            <ReactCodeMirror theme={dracula} value={`Store;Owner.Age = 25`} extensions={[langs.javascript()]} />
            <h3>Floats</h3>
            <p>Floats are automatically recognized if you type a numeric value with a floating point. Example:</p>
            <ReactCodeMirror theme={dracula} value={`Store;Revenue > 1254.78 or TaxRate = .2`} extensions={[langs.javascript()]} />
            <p>Leading zeros can be omitted, as per the above example.</p>
            <h3>Booleans</h3>
            <p>Booleans are recognized from a value that is either <code>{`true`}</code>/<code>{`yes`}</code> or <code>{`false`}</code>/<code>{`no`}</code>. Example:</p>
            <ReactCodeMirror theme={dracula} value={`Store;Owner.IsAlive = yes or Products:any.IsAvailable is false`} extensions={[langs.javascript()]} />
            <p>The <code>{`equals`}</code> comparison operator can be written using <code>{`is`}</code> which makes it a bit nicer in terms of syntax with booleans.</p>
            <h3>Strings</h3>
            <p>Strings are the default format and a value is a recognized as a string as long as its not an otherwise reserved keyword (or contains whitespace).
                Still, even in simple cases, I recommend that you always use simple quotes for your strings. Example:</p>
            <ReactCodeMirror theme={dracula} value={`Store;Name = Starbucks or Owner.FullName = 'John Smith'`} extensions={[langs.javascript()]} />
            <p>In the above example I did <em>not</em> enclose "Starbucks" to showcase what strings are, but I still recommend you do it.</p>
            <h3>Dates</h3>
            <p>Dates are automatically recognized as long as they're written in standard ISO format <code>{`yyyy-mm-dd`}</code>. Example:</p>
            <ReactCodeMirror theme={dracula} value={`Store;OpeningDate > 2020-01-01`} extensions={[langs.javascript()]} />
            <h3>Dynamic dates</h3>
            <p>Dynamic dates are perhaps the most confusing, or otherwise complex value format.
                They can be compared to any date property/data point, but unlike standard dates they are anchored to the parsing (and most likely execution) datetime of the request instead of being fixed.</p>
            <p>Dynamic dates are made of an anchor and 0 to N date operations.
                An anchor determines the base time point <em>relative to the parsing datetime</em> of the request; date operations permit the addition or subtraction or days, months, years... from the anchor.</p>
            <h4>Dynamic date anchors</h4>
            <p>There are different anchors included in DDAL's language specification. Those are:</p>
            <ul>
                <li><code>{`TODAY`}</code>: exact date of parsing/execution.</li>
                <li><code>{`START_OF_YEAR`}</code>, <code>{`STOY`}</code>, <code>{`STARTOFYEAR`}</code>: first day of the parsing date's current year.</li>
                <li><code>{`END_OF_YEAR`}</code>, <code>{`EOY`}</code>, <code>{`ENDOFYEAR`}</code>: last day of the parsing date's current year.</li>
                <li><code>{`START_OF_MONTH`}</code>, <code>{`STOM`}</code>, <code>{`STARTOFMONTH`}</code>: first day of the parsing date's current month.</li>
                <li><code>{`END_OF_MONTH`}</code>, <code>{`EOM`}</code>, <code>{`ENDOFMONTH`}</code>: last day of the the parsing date's current month.</li>
                <li><code>{`START_OF_WEEK`}</code>, <code>{`STOW`}</code>, <code>{`STARTOFWEEK`}</code>: first day of the parsing date's current week.
                    This would be, in an English locale, 2023-08-20 if the parsing date is 2023-08-23.</li>
                <li><code>{`END_OF_WEEK`}</code>, <code>{`EOW`}</code>, <code>{`ENDOFWEEK`}</code>: last day of the parsing date's current week.
                    This would be, in an English locale, 2023-08-26 is the parsing date is 2023-08-23.</li>
            </ul>
            <p>Dynamic date anchors can be used without further operations, for example:</p>
            <ReactCodeMirror theme={dracula} value={`Product;ExpirationDate = TODAY`} extensions={[langs.javascript()]} />
            <p>This will list all products set to expire today.</p>
            <h4>Dynamic date operations</h4>
            <p>Dynamic dates operations can be added after an anchor to move the reference point. Let's say you want to list stores which opened exactly yesterday, without having a fixed date. You can write this:</p>
            <ReactCodeMirror theme={dracula} value={`Store;OpeningDate = TODAY-1@D`} extensions={[langs.javascript()]} />
            <p>Let's decompose the parts:</p>
            <ol>
                <li><code>{`TODAY`}</code> is the anchor.</li>
                <li><code>{`-`}</code> is the subtraction operator.</li>
                <li><code>{`1`}</code> is the amount to subtract.</li>
                <li><code>{`@`}</code> is the separator between amount and unit; this is required for technical reasons.</li>
                <li><code>{`D`}</code> is the unit marker for days.</li>
            </ol>
            <p>Operators can be either <code>{`+`}</code> or <code>{`-`}</code> (addition or subtraction). The available units are:</p>
            <ul>
                <li><code>{`Y`}</code> for years.</li>
                <li><code>{`M`}</code> for months.</li>
                <li><code>{`W`}</code> for weeks.</li>
                <li><code>{`D`}</code> for days.</li>
            </ul>
            <p>It is possible to chain multiple operations, for example if you want to get data for stores which opened between the 5th and 10th of February:</p>
            <ReactCodeMirror theme={dracula} value={`Store;OpeningDate > START_OF_YEAR+1@M+5@D and OpeningDate < START_OF_YEAR+1@M+10@D`} extensions={[langs.javascript()]} />
            <h4>Quick note on dynamic dates</h4>
            <p>Dynamic dates are handled directly by DDAL's parser libraries; this means that client software (like DDAL/EF) sees no difference between a fixed and a dynamic date.
                The parser uses the standard C++ chrono library to calculate dates.</p>
        </div>
    )
}

export default Values