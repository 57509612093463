import ReactCodeMirror from "@uiw/react-codemirror";
import {dracula} from "@uiw/codemirror-theme-dracula";
import {langs} from "@uiw/codemirror-extensions-langs";

const Groups = () => {
    return(
<div className={'api'}>
    <h1>Groups</h1>
    <p>Groups are logical constructions in filter requests meant to separate a <a href="/api/DDAL/filter/predicates">composition</a> from other predicates.
        This is most useful, for example, to change the priority of operators.
        Groups are simply declared by wrapping a composition, in parentheses, like so:</p>
    <ReactCodeMirror theme={dracula} value={`Store;Owner.Age > 20 and (OpeningDate < 2020-01-01 or OpeningDate > 2022-01-01)`} extensions={[langs.javascript()]} />
    <p>The group will be evaluated "first" even though the <code>{`OR`}</code> logical operator has less priority than <code>{`AND`}</code>.</p>
    <h2>Inverting groups</h2>
    <p>Groups are especially useful to invert conditions. This is pretty straightforward:</p>
    <ReactCodeMirror theme={dracula} value={`Store;Owner.Age < 30 and !(OpeningDate < 2020-01-01)`} extensions={[langs.javascript()]} />
    <p>This will give back stores whose owner is less than 30 years old and which opened no sooner than 2020.
        Obviously in this case, the inverted group is equivalent to <code>{`OpeningDate > 2020-01-01`}</code>.</p>
</div>
    )
}

export default Groups