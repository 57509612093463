import {Link as LinkScroll} from "react-scroll/modules";
import {dracula} from "@uiw/codemirror-theme-dracula";
import {langs} from "@uiw/codemirror-extensions-langs";
import ReactCodeMirror from "@uiw/react-codemirror";

const ListingAnalysis = () => {
    return (
        <div className={'api'}>
            <h1>Listing analysis</h1>
            <p>DDAL/EF implements the concept of listing analysis from DDAL's specification. It is mostly used to fetch raw data and possibly display it in a simple table.
                Contrary to <a href="/api/DDALEF/analysis/cross">cross analyses</a>, there is no parts in a listing analysis but there are different targets for listing.</p>
            <p>When executing listing analysis from one or multiple target inputs, with possibly a filter, DDAL/EF will output textual results with index matching.
                This means that, if you list a store name and the name of its owner, the strings in the result for both of these at index 0 will be "linked" to each other.
                I think the examples later in this page will prove more effective at conveying this idea.</p>
            <p>It should also be noted that DDAL/EF supports multiple settings for listing analyses that allow to tweak or change the way results are projected.
                The two main settings for that are:</p>
            <ul>
                <li><code>{`coalesce`}</code>, which tells DDAL/EF that everything under specific collection properties should be joined in a single in a line.</li>
                <li><code>{`cartesian`}</code>, which tells DDAL/EF whether we want a cartesian projection when we encounter collections or whether we want a custom house-made projection
                    Cartesian projection are better when you have few lines, and can give a very concise output with <code>{`coalesce`}</code> ; the custom projection is best for legibility and a high amount of lines.</li>
            </ul>
            <p>As usual, let us start with a quick but somewhat exhaustive example:</p>
            <ReactCodeMirror theme={dracula} value={`example = listing analysis
                {
                    root = Store,

                    filter %= "Revenue > 5000",

                    target /= Name
                }`} extensions={[langs.javascript()]} />
            <p>With the following sample results, in JSON, which will include the input properties:</p>
            <ReactCodeMirror theme={dracula} value={`{
                "example": {
                "root": "Store",
                "filter": "Revenue > 5000",
                "target": "Name",
                "result": [
                "Starbucks",
                "Walmart"
                ]
            }
            }`} extensions={[langs.javascript()]} />
            <p>Here is another example with multiple targets:</p>
            <ReactCodeMirror theme={dracula} value={`example2 = listing analysis
                {
                    root = Store,

                    filter %= "Revenue > 5000",

                    targets = dic
                {
                    store_name /= Name,
                    owner_name /= Owner.Name,
                    clients_names /= Clients.Name,
                    products_names /= Products.Name
                }
                }`} extensions={[langs.javascript()]} />
            <p>And the results, first in a table for more legibility. Notice the results are properly linked to each other:</p>
            <table>
                <thead>
                <tr>
                    <th>clients_names</th>
                    <th>owner_name</th>
                    <th>products_names</th>
                    <th>store_name</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Joe Walter</td>
                    <td>John Smith</td>
                    <td>-</td>
                    <td>Starbucks</td>
                </tr>
                <tr>
                    <td>Nathan Gates</td>
                    <td>John Smith</td>
                    <td>-</td>
                    <td>Starbucks</td>
                </tr>
                <tr>
                    <td>-</td>
                    <td>John Smith</td>
                    <td>Apples</td>
                    <td>Starbucks</td>
                </tr>
                <tr>
                    <td>-</td>
                    <td>John Smith</td>
                    <td>Oranges</td>
                    <td>Starbucks</td>
                </tr>
                <tr>
                    <td>-</td>
                    <td>Donald Johnson</td>
                    <td>Apples</td>
                    <td>Walmart</td>
                </tr>
                <tr>
                    <td>-</td>
                    <td>Donald Johnson</td>
                    <td>Potatoes</td>
                    <td>Walmart</td>
                </tr>
                <tr>
                    <td>-</td>
                    <td>Donald Johnson</td>
                    <td>Pickles</td>
                    <td>Walmart</td>
                </tr>
                </tbody>
            </table>
            <p>This kind of projection is supported by DDAL/EF and is the combination of "no coalesce" and custom non-cartesian projection.
                The actual JSON result would be:</p>
            <ReactCodeMirror theme={dracula} value={`{
                "example2": {
                "root": "Store",
                "filter": "Revenue > 5000",
                "target": "Name",
                "result": {
                "store_name": [
                "Starbucks",
                "Starbucks",
                "Starbucks",
                "Starbucks",
                "Walmart",
                "Walmart",
                "Walmart"
                ],
                "owner_name": [
                "John Smith",
                "John Smith",
                "John Smith",
                "John Smith",
                "Donald Johnson",
                "Donald Johnson",
                "Donald Johnson"
                ],
                "clients_names": [
                "Joe Walter",
                "Nathan Gates",
                "-",
                "-",
                "-",
                "-",
                "-"
                ],
                "products_names": [
                "-",
                "-",
                "Apples",
                "Oranges",
                "Apples",
                "Potatoes",
                "Pickles"
                ]
            }
            }
            }`} extensions={[langs.javascript()]} />
            <p>Finally, here is the same example but with the product names coalesced and a cartesian projection:</p>
            <ReactCodeMirror theme={dracula} value={`example2 = listing analysis
                {
                    root = Store,

                    filter %= "Revenue > 5000",

                    targets = dic
                {
                    store_name /= Name,
                    owner_name /= Owner.Name,
                    clients_names /= Clients.Name,
                    products_names /= Products.Name
                },
                    coalesce = dic
                {
                    products /= Products
                },

                    cartesian = yes
                }`} extensions={[langs.javascript()]} />
            <p>Results in table format:</p>
            <table>
                <thead>
                <tr>
                    <th>clients_names</th>
                    <th>owner_name</th>
                    <th>products_names</th>
                    <th>store_name</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Joe Walter</td>
                    <td>John Smith</td>
                    <td>Apples;Oranges</td>
                    <td>Starbucks</td>
                </tr>
                <tr>
                    <td>Nathan Gates</td>
                    <td>John Smith</td>
                    <td>Apples;Oranges</td>
                    <td>Starbucks</td>
                </tr>
                <tr>
                    <td></td>
                    <td>Donald Johnson</td>
                    <td>Apples;Potatoes;Pickles</td>
                    <td>Walmart</td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                </tbody>
            </table>
            <p>Note that the second line of "Starbucks" could have been removed if we coalesced the client names, yielding us:</p>
            <table>
                <thead>
                <tr>
                    <th>clients_names</th>
                    <th>owner_name</th>
                    <th>products_names</th>
                    <th>store_name</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Joe Walter;Nathan Gates</td>
                    <td>John Smith</td>
                    <td>Apples;Oranges</td>
                    <td>Starbucks</td>
                </tr>
                <tr>
                    <td></td>
                    <td>Donald Johnson</td>
                    <td>Apples;Potatoes;Pickles</td>
                    <td>Walmart</td>
                </tr>
                </tbody>
            </table>
            <p>For reference, the coalesce dictionary would look like:</p>
            <ReactCodeMirror theme={dracula} value={`coalesce = dic
                {
                    products /= Products,
                    clients /= Clients
                }`} extensions={[langs.javascript()]} />
            <p>Note two things:</p>
            <ul>
                <li>The name of the property in the coalesce dictionary is irrelevant.</li>
                <li><code>{`Coalesce`}</code> applies to a single collection and will coalesce any target whose final property is <em>after</em> the collection <em>only if</em> there isn't another collection after it in the path.
                    Go to <LinkScroll to={'coalesce'} spy={true} smooth={true} className="link-scroll">this section</LinkScroll> further in this page for more details.</li>
            </ul>
            <h2>The root</h2>
            <p>The root property simply defines which context we're using for the listing. This should be the exact case-sensitive name of the CLR entity you want to use.</p>
            <p>This property is textual and required.</p>
            <h2>The filter property</h2>
            <p>The filter property defines the base filter for the root dataset.</p>
            <p>This property is optional ; it is a <a href="/api/DDAL/analysis/properties?scrollTo=filter-expression-properties">filter expression</a> property.</p>
            <h2>Targets</h2>
            <p>Targets in a DDAL/EF listing analysis can be written in short-form or dictionary form.
                The short form allows only one single target and is meant for quick querying.
                The dictionary form however also for any number of targets. They are written like this:</p>
            <ReactCodeMirror theme={dracula} value={`target /= Name`} extensions={[langs.javascript()]} />
            <ReactCodeMirror theme={dracula} value={`targets = dic
                {
                    name /= Name,
                    revenue /= Revenue
                }`} extensions={[langs.javascript()]} />
            <p>Note that those two forms are mutually exclusive: you must one or the other.
                If you use the short form, the name of the resulting target or "column" in a table projection will be empty.
                If you use the dictionary form, the names of the resulting targets will be the same as the property names, sorted alphabetically.</p>
            <p>Targets, whether short form or dictionary form, are required (either one of them) and are <a href="/api/DDAL/analysis/properties?scrollTo=property-paths">path properties</a>.</p>
            <h3>Inner filters in targets</h3>
            <p>Like any path property, it is possible to add inner filters in targets, and DDAL/EF supports it. Quick example, a request on owners with the following targets:</p>
            <ReactCodeMirror theme={dracula} value={`targets = dic
                {
                    name /= Name,
                    store_high_revenue_names /= Stores["Revenue > 5000"].Name
                }`} extensions={[langs.javascript()]} />
            <p>Which would output the names of all owners, and the name of the stores they own with more than 5000 in revenue.</p>
            <h2 id={'coalesce'}>Coalesce</h2>
            <p>As of version 1, coalesce is one of the two properties which allow you to customize and tweak the formatting of your output results.
                Like the examples higher in this page show, coalesce is a dictionary in which you specify the collections (from the root) which, when encountered in a property path,
                will merge the results of all targets ending directly under this collection.</p>
            <p>Here is an example:</p>
            <ReactCodeMirror theme={dracula} value={`example = listing analysis
            {
                root = Product,

                targets = dic
            {
                name /= Name,
                stores_names /= Stores.Name,
                clients /= Stores.Clients.Name
            },
                coalesce = dic
            {
                stores /= Stores
            }
                }`} extensions={[langs.javascript()]} />
            <p>With the following result, table form:</p>
            <table>
                <thead>
                <tr>
                    <th>clients</th>
                    <th>name</th>
                    <th>stores_names</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Joe Walter</td>
                    <td>Apples</td>
                    <td>Starbucks;Walmart</td>
                </tr>
                <tr>
                    <td>Nathan Gates</td>
                    <td>Apples</td>
                    <td>Starbucks;Walmart</td>
                </tr>
                <tr>
                    <td>Joe Walter</td>
                    <td>Oranges</td>
                    <td>Starbucks</td>
                </tr>
                <tr>
                    <td>Nathan Gates</td>
                    <td>Oranges</td>
                    <td>Starbucks</td>
                </tr>
                <tr>
                    <td></td>
                    <td>Potatoes</td>
                    <td>Walmart</td>
                </tr>
                <tr>
                    <td></td>
                    <td>Pickles</td>
                    <td>Walmart</td>
                </tr>
                </tbody>
            </table>
            <p>As you can see, the stores' names are coalesced because they are <em>directly</em> under the coalesced collection "Stores".
                The names of the stores' clients however, are not. If we wanted to coalesce them as well, we would need to add a property like <code>{`clients /= Stores.Clients`}</code> in the coalesce dictionary.</p>
            <p>This property is a <a href="/api/DDAL/analysis/properties?scrollTo=blocks">dictionary</a> of <a href="/api/DDAL/analysis/properties?scrollTo=property-paths">path properties</a> and is not required ; if absent no coalesce is applied anywhere.</p>
            <h2>Cartesian v. Custom projection</h2>
            <p>DDAL/EF offers two types of projections for listing requests, which can be combined with coalesce clauses to produce different kinds of output.
                These types of projections dictate the behavior of DDAL/EF when encountering collections in listing requests.</p>
            <p>By default, the "custom" projection. This means you have to explicitly specify a cartesian projection, should you want that.
                This is done by setting a boolean property named "cartesian" to true in the root of your listing analysis request, like so:</p>
            <ReactCodeMirror theme={dracula} value={`example = listing analysis
                {
                    cartesian = yes
                }`} extensions={[langs.javascript()]} />
            <p><code>{`Yes`}</code> and <code>{`true`}</code> are equivalent for booleans; in this scenario I tend to prefer "yes" as it's closer to natural language but it makes no difference.</p>
            <h3>Cartesian projection</h3>
            <p>The first projection, called cartesian projection is very similar to what you would get with a full SQL query.
                This means that all the properties with a 1-1 relationship to the root entity will be repeated with each item in each collection. For example:</p>
            <table>
                <thead>
                <tr>
                    <th>stores</th>
                    <th>product_names</th>
                    <th>clients_names</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Starbucks</td>
                    <td>Apples</td>
                    <td>Joe Walter</td>
                </tr>
                <tr>
                    <td>Starbucks</td>
                    <td>Apples</td>
                    <td>Nathan Gates</td>
                </tr>
                <tr>
                    <td>Starbucks</td>
                    <td>Oranges</td>
                    <td>Joe Walter</td>
                </tr>
                <tr>
                    <td>Starbucks</td>
                    <td>Oranges</td>
                    <td>Nathan Gates</td>
                </tr>
                <tr>
                    <td>Walmart</td>
                    <td>Apples</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Walmart</td>
                    <td>Potatoes</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Walmart</td>
                    <td>Pickles</td>
                    <td></td>
                </tr>
                </tbody>
            </table>
            <p>As you can see, we have two same-level collections: <em>products</em> and <em>clients</em>.
                This means that the properties directly under our root, such as the name of the store, will be listed as many times as the cross product of the length of all the same-level collections.
                In this case 2x2=4 times. Then, both clients and products will have to be listed as many times as there are items in the other collections (in this instance only 2 times). The following is equivalent:</p>
            <table>
                <thead>
                <tr>
                    <th>stores</th>
                    <th>product_names</th>
                    <th>clients_names</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Starbucks</td>
                    <td>Apples</td>
                    <td>Joe Walter</td>
                </tr>
                <tr>
                    <td>Starbucks</td>
                    <td>Oranges</td>
                    <td>Joe Walter</td>
                </tr>
                <tr>
                    <td>Starbucks</td>
                    <td>Apples</td>
                    <td>Nathan Gates</td>
                </tr>
                <tr>
                    <td>Starbucks</td>
                    <td>Oranges</td>
                    <td>Nathan Gates</td>
                </tr>
                <tr>
                    <td>Walmart</td>
                    <td>Apples</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Walmart</td>
                    <td>Potatoes</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Walmart</td>
                    <td>Pickles</td>
                    <td></td>
                </tr>
                </tbody>
            </table>
            <p>This projection has advantages:</p>
            <ul>
                <li>It is better if you have fewer items, especially if you coalesce a lot of targets. This will shorten your output align everything together.</li>
                <li>It looks more "natural" because we are used to it.</li>
            </ul>
            <p>But it also has disadvantages:</p>
            <ul>
                <li>The more lines and items you have in your collections, the more verbose it becomes and the less legible it is.
                    This is especially true if you have a lot of same-level collections.</li>
                <li>It is inherently less legible if you don't have a trivial amount of data because the separation is not always clear.</li>
            </ul>
            <h3>"Custom" projection</h3>
            <p>The second type of projection, simply called "custom" projection, is an associate data table projection tailor-made for DDAL.
                It emphasizes legibility and a clear distinction between elements, especially for large datasets.
                The difference with a cartesian projection is that, instead of repeating the items for same-level collections, a placeholder (the dash character <code>{`-`}</code> in v1) is inserted.
                Take the following example:</p>
            <table>
                <thead>
                <tr>
                    <th>stores</th>
                    <th>product_names</th>
                    <th>clients_names</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Starbucks</td>
                    <td>-</td>
                    <td>Joe Walter</td>
                </tr>
                <tr>
                    <td>Starbucks</td>
                    <td>-</td>
                    <td>Nathan Gates</td>
                </tr>
                <tr>
                    <td>Starbucks</td>
                    <td>Apples</td>
                    <td>-</td>
                </tr>
                <tr>
                    <td>Starbucks</td>
                    <td>Oranges</td>
                    <td>-</td>
                </tr>
                <tr>
                    <td>Walmart</td>
                    <td>Apples</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Walmart</td>
                    <td>Potatoes</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Walmart</td>
                    <td>Pickles</td>
                    <td></td>
                </tr>
                </tbody>
            </table>
            <p>In the above example the reduction in line count is not visible; but when you have even slightly more elements, it can very quickly reduce the number of lines compared to an exponential cartesian projection.
                This is because each collection item is listed only once and never repeated for each item in other same-level collections.
                The placeholder basically means "this line is not currently listing this collection". Note that this is only for <em>same-level collections</em>: here products and clients are both under the root entity "Store".
                If we were listing more nested collections, instead of another one on the same level, the projection would resemble the cartesian projection more. For example, starting from products as root:</p>
            <table>
                <thead>
                <tr>
                    <th>product_name</th>
                    <th>store_names</th>
                    <th>store_clients_names</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Apples</td>
                    <td>Starbucks</td>
                    <td>Joe Walter</td>
                </tr>
                <tr>
                    <td>Apples</td>
                    <td>Starbucks</td>
                    <td>Nathan Gates</td>
                </tr>
                <tr>
                    <td>Apples</td>
                    <td>Walmart</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Oranges</td>
                    <td>Starbucks</td>
                    <td>Joe Walter</td>
                </tr>
                <tr>
                    <td>Oranges</td>
                    <td>Starbucks</td>
                    <td>Nathan Gates</td>
                </tr>
                <tr>
                    <td>Potatoes</td>
                    <td>Walmart</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Pickles</td>
                    <td>Walmart</td>
                    <td></td>
                </tr>
                </tbody>
            </table>
            <p>This type of projection has the following advantages:</p>
            <ul>
                <li>A lot better if you have a high count of items; the more items the better it is compared to the cartesian projection.</li>
                <li>It is more legible because each item in same-level collections is clearly distinguished.</li>
            </ul>
            <p>And the following drawbacks:</p>
            <ul>
                <li>Not as good if you have fewer items because the placeholder items can clutter the display somewhat.</li>
                <li>Does not have as strong a synergy with coalesced collections as the cartesian projection.</li>
            </ul>
        </div>
    );
}

export default ListingAnalysis;