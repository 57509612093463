import {NavigationMobile} from "../../components/common/navigation/navigationMobile";
import {NavigationBase} from "../../components/common/navigation/navigationBase";
import Header from "../../components/home/header";
import {Row} from "../../components/common/grid/row";
import {Column} from "../../components/common/grid/column";
import ReactCodeMirror from "@uiw/react-codemirror";
import {dracula} from "@uiw/codemirror-theme-dracula";
import {langs} from "@uiw/codemirror-extensions-langs";
import Newsletter from "../../components/preLaunch/newsletter";
import {Footer} from "../../components/common/navigation/footer";
import React, {useEffect, useState} from "react";
import {ButtonBase} from "../../components/common/buttons/buttonBase";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {Helmet} from "react-helmet";
import {useSavePosScrollBar} from "../../helper/useSavePosScrollBar";
import {ddalArticleId} from "./ddalArticle";
import {useRefreshItemNav} from "../../helper/useRefreshItemNav";
import {blogId} from "../blog";

export const ddalEFId = 'ddalEF'

const DDALEFArticle = () => {
    const {t, i18n} = useTranslation()
    const [article, setArticle] = useState<any>('DDAL')
    let navigate = useNavigate()
    const {} = useSavePosScrollBar(ddalEFId)
    const {} = useRefreshItemNav(blogId)

    let handleClick = () => {
        console.log('test')
        navigate('/payment?trial=true')
    }

    useEffect(() => {
        if(i18n.language === 'fr'){
            setArticle(<DDALEFArticleFR handleClick={handleClick}/>)
        }
        else{
            setArticle(<DDALEFArticleEN handleClick={handleClick}/>)
        }
    },[i18n])


    return (
        <>
            <header>
                <NavigationMobile/>
                <NavigationBase/>
                <Header titleArticle={'DDAL/EF : L\'implémentation d\'Implex de DDAL pour Entity Framework Core'} preLaunch={true} article={'DDALEF'}/>
            </header>
            <Helmet>
                <title>DDAL EF Core blog post</title>
                <meta name="description" content="DDAL EF Core blog post" />
            </Helmet>
            {article}
            <Row customClass={'jc-center mt-100'}>
                <Column xl={6} lg={10} min={11}>
                    <Newsletter article={true}/>
                </Column>
            </Row>
            <Footer preLaunch={true}/>
        </>
    );
}

type ArticleProps = {
    handleClick?:any;
}

const DDALEFArticleFR = ({handleClick}:ArticleProps) => {
    const {t, i18n} = useTranslation()
    return (
        <div className='article-container'>
            <div className={'background-image'}>
                <Row customClass={'jc-center pt-60'}>
                    <Column customClass={''} xl={6} lg={10} min={11}>
                        <p>Comme nous l'avons vu précedemment dans un article dédié à <a >DDAL en tant que spécification de langage</a>, DDAL/EF est l'implémentation d'IMPLEX de DDAL pour Entity Framework Core.<br/>
                            DDAL/EF est un produit dont la licence fonctionne selon un système d'abonnement, orienté vers des besoins concrets liés à l'analyse de données dans un environnement .NET.
                        </p>

                        <h2>La genèse de DDAL/EF</h2>

                        <p>Avant même de s'appeler DDAL/EF, le concept même vit le jour avec e-Data, notre application web fonctionnant en tandem avec l'application principale d'IMPLEX : Split PMQ.<br/>
                            Dès ses tous débuts, e-Data a été conçu comme un service web dédié à la consultation et disposant de fonctionnalités poussées d'analyse de données.<br/>
                            Fidèles à notre propre philosophie, nous ne voulions pas limiter les possibilités de l'analyse à des limites arbitraires et inflexibles.<br/>
                            Nous voulions créer un outil simple, accessible, mais qui permettrait tout de même à nos utilisateurs d'interagir avec l'intégralité de notre modèle de données.</p>

                        <p>
                            C'est ainsi qu'est né un proto-DDAL, bien plus simple et informel qu'il ne l'est aujourd'hui.<br/>
                            L'idée d'origine consistait à créer une interface par dessus cet outil back-end, ce dernier ayant la charge de traiter des demandes exprimés de manière presque naturelle.<br/>
                            Puisque nous utilisions déjà Entity Framework Core, nous avons instinctivement fait le choix de réutiliser les propriétés de code de notre modèle pour diriger et encadrer l'analyse de données.<br/>
                            Entity Framework Core est capable de :
                        </p>
                        <ul>
                            <li>Lier des propriétés de code à des colonnes en base relationnelle.</li>
                            <li>Travailler avec plusieurs fournisseurs de données relationnelles comme SQL Server ou PostgreSQL.</li>
                            <li>Résoudre des jointures.</li>
                        </ul>
                        <p>De ce fait, une majeure partie de notre outil était déjà en place grâce aux fonctionnalités d’Entity Framework Core.
                            Le concept d’un outil d’analyse multifournisseur, simple et proche du modèle de données concret était né.</p>

                        <p>Nous avons depuis effectué un long chemin, surtout maintenant que DDAL est devenu son propre langage avec DDAL/EF comme composant indépendant orienté vers une architecture microservice.<br/>
                            Malgré cela, nous avons démarré avec une approche humble, ancrée dans le réel et une vision simple. Pour cela, nous sommes reconnaissants.</p>

                        <h2>Comment fonctionne DDAL/EF</h2>
                        <p>Comme dit précédemment, DDAL/EF réutilise la syntaxe de DDAL, similaire à du JSON pour les analyses et similaire à du langage naturel pour les filtres.<br/>
                            DDAL/EF consulte ensuite les propriétés pour trouver celles avec des noms spécifiques qui lui indiquent comment procéder.<br/>
                            Par exemple, si vous souhaitez lister certaines propriétés d’un magasin, vous devez simplement lister les propriétés dans un dictionnaire nommé `targets`. Par exemple :</p>
                        <ReactCodeMirror theme={dracula} value={
                            `my_analysis = listing analysis
{
    root = Store,
    targets = dic
    {
        name /= Name,
        owner_name /= Owner.Name
    }
}`} extensions={[langs.sql()]} />
                        <p>Du point de vue des parsers, `targets` est un dictionnaire comme n’importe quel autre.<br/>
                            C’est seulement lorsque l’AST est traité plus loin dans le "pipeline" par DDAL/EF que ce dictionnaire prend une signification particulière.<br/>
                            Les cibles sont ensuites résolues selon leur chemin de propriété (partie droite), notés par l’opérateur spécial `/=`.<br/>
                            De façon similaire, `root` n’est en fait qu’une propriété textuelle qui indique à DDAL/EF de résoudre l’entité `Store` depuis son nom simple.</p>

                        <p>
                            Mais n’entrons pas trop vite dans les détails techniques ; la documentation devrait suffire pour cela.<br/>
                            Le but de cette section est, plus humblement, de vous présenter pourquoi DDAL/EF est innovant et comment il simplifie l’acquisition et l’agrégation de vos données..</p>

                        <p>Avant tout, vous avez certainement remarqué qu’il n’y a pas de jointures dans l’exemple précédent, alors que nous sommes passés "au travers" d’une entité nommée `Owner`.<br/>
                            La raison pour cela est que DDAL/EF fonctionne en récupérant autant d’information que possible via Entity Framework Core.<br/>
                            Si votre modèle est correctement configuré, comme il le devrait, EF Core connaît déjà les noms de vos clés étrangères et leur structure.<br/>
                            Ainsi, cela vous permet de les ignorer entièrement grâce à DDAL/EF afin de vous concentrer sur l’expression naturelle de ce que vous souhaitez obtenir.<br/>
                            Cette idée est un thème central : chaque information qui peut être implicite devient implicite afin que vous ayez moins de "code" à écrire et moins de soucis à vous faire.</p>

                        <p>
                            Au cœur du fonctionnement interne de DDAL/EF se trouve une fonctionnalité particulière d’Entity Framework Core.<br/>
                            Cette fonctionnalité, c’est la capacité dont dispose Entity Framework Core à compiler des arbres d’expression LINQ en requêtes SQL.<br/>
                            Fondamentalement, ce que réalise DDAL/EF c’est "deviner" ce que vous souhaitez obtenir grâce à votre requête.<br/>
                            Ceci afin de générer un arbre LINQ qui représente fidèlement votre intention ; il s’agit ensuite de le compiler et de le faire exécuter par Entity Framework Core.<br/>
                            Bien sûr, du post-traitement rentre aussi en jeu, tout spécialement pour les analyses de liste, mais une large partie de valeur ajoutée de DDAL/EF se trouve dans sa capacité à créer des arbres LINQ fiables.
                        </p>

                        <p>
                            La conséquence de ce "design" est simple : DDAL/EF est automatiquement agnostique vis-à-vis de votre fournisseur de base de données relationnelle.<br/>
                            Qu’il s’agisse de SQL Server, de MySQL, de PostgreSQL ou d’autre chose encore, vous pouvez utiliser la même requête pour n’importe quel fournisseur supporté par Entity Framework Core.<br/>
                            Cela vous permet de récupérer vos données d’une façon très simple tout en effectuant des opérations basiques (et pas si basiques) sur ces dernières : agrégations, listes…<br/>
                            Ce processus est notamment utile lorsque vous souhaitez que ces données soient ensuite injectées dans un pipeline de traitement faisant usage d’outils plus complexes.<br/>
                            Dans bien des cas, DDAL/EF devrait plus être vu comme un point d’entrée pour la récupération de vos données que comme un outil complexe pour les vétérans de l’analyse de données.<br/>
                            C’est particulièrement vrai pour les _data engineers_, par exemple, qui sont moins proches du cœur de métier que par exemple des développeurs.<br/>
                            Pour ces derniers, un des usages principaux de DDAL/EF réside dans la mise à disposition d’un outil back-end qui leur permet de simplifier grandement le passage de leur interface front-end à des données exploitables.
                        </p>

                        <p>
                            Bien sûr, l’inconvénient de cette démarche centrée autour d’Entity Framework Core réside dans le fait que votre infrastructure doit être centrée autour d’Entity Framework Core ou au moins accommoder un tel modèle de données.<br/>
                            C’est une conséquence logique de l’historique de DDAL/EF et du fait qu’il a débuté comme un outil interne pour nos besoins spécifiques.<br/>
                            C’est aussi la raison pour laquelle nous avons fait de DDAL une spécification de langage gratuite, libre, open source et redistribuable.<br/>
                        </p>
                    </Column>
                </Row>
                <Row customClass={'jc-center mb-100'}>
                    <Column xl={6} lg={10} min={11}>
                        <h2>Avez-vous besoin de DDAL/EF ?</h2>
                        <p>Avec tout ce que nous avons dit, nous arrivons à la question que vous vous posez probablement. La réponse est un _oui_ clair et certain, mais conditionnel.
                            Il s’agira pour vous de réfléchir, voire de discuter avec nous, pour identifier votre usage spécifique. Néanmoins, voici nos cas d’usage les plus fréquents :</p>
                        <ul>
                            <li>Vous avez un modèle .NET existant et vous avez besoin d’un outil pour récupérer en masse des données d’une façon simple.</li>
                            <li>Vous êtes un développeur et vous n’avez ni les compétences ni le temps de concevoir votre propre solution de récupération et de transformation de vos données.
                                Cela implique que vous soyez prêts à créer votre propre interface afin de rendre l’utilisation de DDAL plus accessible pour vos utilisateurs finaux.</li>
                            <li>Vous avez un large écosystème .NET et vous êtes à la recherche d’outils qui s’y intégreront bien.</li>
                            <li>Vous êtes un data engineer et vous devez récupérer des données depuis un modèle de données .NET et vous ne pouvez ou ne voulez pas requêter directement vos bases relationnelles.</li>
                            <li>Vous êtes à la recherche d’une solution qui soit indépendante de votre SGBD et vous avez besoin d’un back-end pour vos données.</li>
                        </ul>

                        <p>Dans tous les cas, nous offrons un essai de 30 jours avec un support complet et un outil de démonstration sur notre page principale. A vos claviers !</p>
                        <ButtonBase onClick={handleClick}  style={{}} customClass="button-light mt-20">{t('price:trial')} DDAL/EF</ButtonBase>
                    </Column>
                </Row>
            </div>
        </div>
    )
}

const DDALEFArticleEN = ({handleClick}:ArticleProps) => {
    const {t, i18n} = useTranslation()
    return(
        <div className='article-container'>
            <div className={'background-image'}>
                <Row customClass={'jc-center pt-60'}>
                    <Column customClass={''} xl={6} lg={10} min={11}>
                        <p>As we’ve said previously in [an article dedicated to DDAL the language](DDAL-en.md), DDAL/EF is IMPLEX’s implementation of DDAL for Entity Framework Core.<br/>
                            This is a subscription-based product tailored towards business needs for developers & data engineers.
                        </p>

                        <h2>How it started</h2>

                        <p>Before even being called DDAL/EF, the very concept started with e-Data, our web application which works alongside IMPLEX’s main application, Split PMQ.<br/>
                            From its very inception, e-Data was conceived as a read-only web service with extensive data analysis features.<br/>
                            Following our own philosophy, we didn’t want to limit the possibilities of data analysis to set boundaries or hard-coded properties.<br/>
                            We wanted to create something that would be simple, but would also allow our users to interact with the entirety of our data model should they wish to.</p>

                        <p>
                            This led to the creation of a proto-DDAL, which was much simpler and less formalized but worked in the same way.<br/>
                            The idea was to design a UI on top of it, in the front-end, while the proto-DDAL would sit in the backend and resolve the requests that way.<br/>
                            Since we had already made the choice to use Entity Framework Core as our ORM, our instinct drove us to reuse our code properties in order to direct our analysis requests.<br/>
                            Given that Entity Framework Core can link code properties to database columns, work with multiple providers and resolve joins and many more things, the stage was set.<br/>
                            The concept of a cross-provider, simple, close to the model data analysis tool was born.
                        </p>
                        <p>We’ve gone a long way from then now that DDAL is a language specification and DDAL/EF is an independent component, especially tailored for microservices.<br/>
                            Nonetheless, we started with a humble approach, anchored in reality and with a simple vision. For that we are very thankful.</p>

                        <h2>How it works</h2>
                        <p>As we’ve said, DDAL/EF reuses DDAL’s syntax which is JSON-like for analyses and flat, natural-like for filters.<br/>
                            It then simply looks for properties with specific names and resolves them this way.<br/>
                            For instance, if you wish to list some properties of a store, you simply list your property paths in a dictionary named ’targets’. Look at this:</p>
                        <ReactCodeMirror theme={dracula} value={
                            `my_analysis = listing analysis
{
    root = Store,
    targets = dic
    {
        name /= Name,
        owner_name /= Owner.Name
    }
}`} extensions={[langs.sql()]} />
                        <p>From the point of view of the parsers, ’targets’ is a dictionary that’s just like any other.<br/>
                            It’s only when the AST is processed by DDAL/EF later in the pipeline that it comes to mean something.<br/>
                            The targets are then resolved because they are property paths, noted by their special assignment operator ’/=’.<br/>
                            In the same vein ’root’ is just a textual property which tells DDAL/EF to resolve the entity ’Store’ from its simple name.</p>

                        <p>But let’s not get into the technical details here; the documentation should serve you well enough.<br/>
                            The goal of this section is to present you why DDAL/EF is innovative and how it simplifies data acquisition and aggregation.</p>

                        <p>First of all, you may have noticed that there is no join in our above example, despite us skipping over an entity called ’Owner’.<br/>
                            The reason for that is that DDAL/EF works by leveraging as much information from Entity Framework Core as possible.<br/>
                            If your model is set up correctly, and it should, EF Core already knows the names and structure of your foreign keys.<br/>
                            This allows you to skip them entirely when speaking to DDAL/EF.<br/>
                            This core idea is present in everything; every information that can be made implicit is made implicit so that you have less to type, less to worry yourself about.</p>

                        <p>
                            The core of all those inner workings resides in the fact that Entity Framework Core can compile LINQ expression trees to SQL queries.<br/>
                            What DDAL/EF does is infer what you want to get from your request, create a LINQ tree with that intent, compile it and feed it to Entity Framework Core.<br/>
                            Sometimes there is post-processing too, especially for listing analyses, but a large part of the added value nonetheless lies in creating reliable LINQ trees.
                        </p>

                        <p>
                            The immediate consequence of this design is that DDAL/EF is provider-agnostic, which means you can use the same request for any relational provider supported by Entity Framework Core.<br/>
                            This allows you to acquire data in a very simple way and perform some basic (and not so basic) operations on it: aggregations, lists…<br/>
                            A key use is to then feed this fetched data into a downstream pipeline making use of more complex tools.<br/>
                            DDAL/EF is more of an entry point for data engineers than it is a data manipulation engine for data analysis veterans.
                        </p>

                        <p>
                            Now, of course, the only drawback of this approach is that your ecosystem must be centered around, or at least accommodate, the use of a .NET data model.<br/>
                            This is another consequence of DDAL/EF’s history and how it started as an in-house tool for our specific needs.<br/>
                            This is also why we made DDAL the language specification into a free, open source, redistributable standard.
                        </p>
                    </Column>
                </Row>
                <Row customClass={'jc-center mb-100'}>
                    <Column xl={6} lg={10} min={11}>
                        <h2>Do you have a use for DDAL/EF ?</h2>
                        <p>This is probably the main question that you started reading with. The answer is a resounding but conditional yes. These are our most common use cases:</p>
                        <ul>
                            <li>You have an existing .NET data model and you need a tool to acquire data en masse in a simple way from it.</li>
                            <li>You are a developer and you do not have the skills or the time to design your own data acquisition solution.
                                This means you’re okay with creating your own user-facing data analysis/charting interface but need a tool that will allow you to fetch data with as little complexity as possible.</li>
                            <li>You have a large .NET ecosystem and are looking for tools that integrate well with it.</li>
                            <li>You are a data engineer and you need to pull data from a .NET data model as an entry point to a pipeline but can’t or don’t want to directly fetch from the database.</li>
                            <li>You are looking to create a solution that is provider-agnostic and you need a back-end to fetch data.</li>
                        </ul>

                        <p>In any case, we have a 30-day free trial with full support and a demonstration tool on our main page. Feel free to use both of those!</p>
                        <ButtonBase  onClick={handleClick} style={{}} customClass="button-light mt-20">{t('price:trial')} DDAL/EF</ButtonBase>
                    </Column>
                </Row>
            </div>
        </div>
    )
}
export default DDALEFArticle;