const Comparisons = () => {
    return (
    <div className={'api'}>
        <h1>Comparisons</h1>
        <p>Comparisons constitute the core element of filter requests. Comparisons can be grouped up or bound together with <a href="/api/DDAL/filter/logicalOperators">logical operators</a> to create complex <a href="Predicates.md">compositions</a>.</p>
        <p>By itself, a comparison is pretty simple. It is made of three components, from left to right (the order is important):</p>
        <ol>
            <li>The <a href="/api/DDAL/filter/propertyPaths">property path</a>, which indicates the actual data we want to compare (what we want to compare).</li>
            <li>A <a href="/api/DDAL/filter/comparisonOperator">comparison operator</a>, which indicates the comparison operation (how we want to compare).</li>
            <li>A <a href="/api/DDAL/filter/values">value</a>, which indicates the reference value for the comparison (what we want to compare <em>to</em>).</li>
        </ol>
        <p>Below is a simple example:</p>
        <pre><code>Store.Name equals 'Starbucks'</code></pre>
        <h2>Syntax vs semantics</h2>
        <p>Any combination of property path + comparison operator + value is syntactically valid. This means that:</p>
        <ol>
            <li>No parsing error will be thrown.</li>
            <li>Any combination is compliant with DDAL's standard, excluding obvious cases like the <code>GREATER THAN</code> operator with a string value.</li>
        </ol>
        <p>That being said, there is a major difference between what's valid in terms of syntax and semantics. Take the following example:</p>
        <pre><code>Store.Name = 0</code></pre>
        <p>This example is syntactically valid: it contains the right operands (property path, comparison operator, value).
            However it is very likely that it is not semantically valid because you can't compare a data point which is a string to an integer.
            It will be up to the specific implementation you're using (or creating) to make sure the semantics of a request are valid.</p>
    </div>
  )
}

export default Comparisons