import React, {createContext, ReactNode, useEffect} from "react";
import {CrossSvg} from "../../../../style/svg/crossSvg";
import {createPortal} from "react-dom";

type ModalProps = {
    open?:boolean;
    eventClose?:any;
    children?: ReactNode;
    [propName: string]: any;
}

export type ModalContextType = {
    handleClose: () => void;
}

export const ModalContext = createContext<ModalContextType>({} as ModalContextType)

const Modal = ({open,eventClose, children,...props}:ModalProps) => {
    useEffect(()=>{
        if(open){
            document.body.style.overflow = 'hidden'
        }
        else{
            document.body.style.overflow = 'auto'
        }
    },[open])

    let handleClose = () => {
        eventClose()
        document.body.style.overflow = 'auto'
    }
    return createPortal(<>{open && <div className={"modal-container"}>
        <div {...props} className={"modal-box"}>
            <button onClick={handleClose} className={'cross-close-button'}><CrossSvg/></button>
            <ModalContext.Provider value={{handleClose}}>
                {children}
            </ModalContext.Provider>
        </div>
    </div>}</>, document.body)


}

export default Modal