const Introduction = () => {
    return (
        <div className={'api'}>
            <h1 id="quick-introduction-to-ddal-ef-ddal-for-entity-framework-core">Quick introduction to DDAL/EF: DDAL for Entity Framework Core</h1>
            <p>DDAL/EF, short for &quot;DDAL for Entity Framework Core&quot; is the official business implementation of the DDAL standard.
                It is specifically made for .NET environments making extensive use of LINQ and Entity Framework Core.</p>
            <p>DDAL/EF works by declaring some property names as &quot;special&quot; and interpreted for analysis requests. You can see the pages under the &quot;analysis&quot; section.
                Filter requests do not require any further explanation; they will simply be executed against the given model.
                In any case, any time a context is required (mostly in analysis requests), this should be understood as an entity in your CLR model.</p>
            <p>Indeed, DDAL/EF works by hooking itself with your CLR model and your Entity Framework Core configuration.
                Using those elements, it is able to make smart joins, identify your collections, format your data, etc.</p>
            <p>Good starting points for DDAL/EF are:</p>
            <ul>
                <li>The <a href="/api/DDALEF/deployment">deployment page</a> to get started.</li>
                <li>The pages for <a href="/api/DDALEF/analysis/cross">cross analysis</a> requests and <a href="/api/DDALEF/analysis/listing">listing analysis</a> requests.</li>
            </ul>
            <p>It is of course more then recommended to be familiar with DDAL&#39;s overall syntax and features. Check <a href="/api/DDAL/introduction">this page</a> if you haven&#39;t already.</p>
        </div>
    )
}

export default Introduction;
