const SocietySvg = () => {
    return(
        <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 23.5 23.5" enableBackground="new 0 0 23.5 23.5" xmlSpace="preserve">
<path fill="#5F82C1" d="M18.3,23.5H5.2c-2.4,0-4.4-2-4.4-4.4v-6.2C0.3,12.6,0,12.1,0,11.5c0-1.8,1.4-3.3,3.2-3.4L3.8,3l0-1.4
	C3.8,0.8,4.6,0,5.5,0h2c0.9,0,1.7,0.8,1.7,1.7v1.5l0.6,4.7l2.7-2.4c0.2-0.2,0.6-0.3,0.9-0.3c0.8,0,1.4,0.6,1.4,1.4v1l2.3-2
	c0.2-0.2,0.6-0.3,0.9-0.3l0,0c0.4,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1v1.6h0.7l0,0c0.9,0,1.8,0.4,2.4,1s1,1.5,1,2.4
	c0,0.6-0.3,1.1-0.8,1.4v6.2C22.7,21.5,20.7,23.5,18.3,23.5z M3.4,9.6c-1,0-1.9,0.9-1.9,1.9c0,0,0,0.1,0.1,0.1c0.4,0,0.8,0.3,0.8,0.8
	c0,0,0,0.1,0,0.2v6.6c0,1.6,1.3,2.9,2.9,2.9h13.2c1.6,0,2.9-1.3,2.9-2.9v-6.7c0,0,0-0.1,0-0.1c0-0.2,0.1-0.4,0.2-0.6
	c0.1-0.1,0.3-0.2,0.5-0.2c0,0,0.1,0,0.1-0.1c0-0.5-0.2-1-0.6-1.3s-0.8-0.6-1.3-0.6h-1.5c-0.4,0-0.8-0.3-0.8-0.8V6.8l-3,2.7
	c-0.1,0.1-0.3,0.2-0.5,0.2H14c-0.4,0-0.8-0.3-0.8-0.8V6.8l-3,2.7c-0.1,0.1-0.3,0.2-0.5,0.2H9.1C8.7,9.6,8.4,9.3,8.4,9L7.7,3.2V1.7
	c0-0.1-0.1-0.2-0.2-0.2h-2c-0.1,0-0.2,0.1-0.2,0.2v1.5L4.6,9c0,0.4-0.4,0.7-0.8,0.7H3.4z M17.9,6.6L17.9,6.6L17.9,6.6z"/>
</svg>
    )
}

export {SocietySvg}