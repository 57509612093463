import {NodeRendererProps, Tree, TreeApi} from "react-arborist";
import {Textbox} from "../common/inputs/textbox";
import {Row} from "../common/grid/row";
import {Column} from "../common/grid/column";
import React, {ElementType, useEffect, useRef, useState} from "react";
import {ButtonBase} from "../common/buttons/buttonBase";
import {Treeview} from "../common/inputs/treeview";
import ClientSvg from "../../../style/svg/clientSvg";
import StringSvg from "../../../style/svg/stringSvg";
import FloatSvg from "../../../style/svg/floatSvg";
import BoolSvg from "../../../style/svg/boolSvg";
import ObjSvg from "../../../style/svg/objSvg";
import DateSvg from "../../../style/svg/dateSvg";
import JsonView from "react18-json-view";
import {use} from "i18next";
import axios from "axios";
import {IntSvg} from "../../../style/svg/intSvg";
import {useTranslation} from "react-i18next";
import QuestionMark from "../../../style/svg/questionMark";
import {discordUrl} from "../../../index";

type Example = {
    title:string,
    request:string,
    description:string,
}

const Demonstrator = () => {
    let dataTree = {
        root: {
            index: "root",
            isFolder: true,
            children: ["store", "product", "owner", "client"],
            data: {name: "Root item"}
        },
        store: {
            index: "store",
            isFolder: true,
            children: ["id","name", "openingDate", "taxRate", "revenue"],
            data: {name: "Store", stateIcon: <ObjSvg/>, checked: false}
        },
        name: {
            index: "name",
            children: [],
            data: {name: "Name", checked: false, stateIcon: <StringSvg/>},
            isFolder: false
        },
        openingDate: {
            index: "openingDate",
            children: [],
            data: {name: "OpeningDate", stateIcon: <DateSvg/>, checked: false}
        },
        revenue: {
            index: "revenue",
            children: [],
            data: {name: "Revenue", checked: false, stateIcon: <FloatSvg/>}
        },
        taxRate: {
            index: "taxRate",
            children: [],
            data: {name: "TaxRate", checked: false, stateIcon: <FloatSvg/>}
        },
        product: {
            index: "product",
            children: ["id","name", "expirationDate", "isAvailable"],
            data: {name: "Product", checked: false , stateIcon: <ObjSvg/>},
            isFolder: true
        },
        expirationDate: {
            index: "expirationDate",
            children: [],
            data: {name: "ExpirationDate", checked: false, stateIcon: <DateSvg/>}
        },
        isAvailable: {
            index: "isAvailable",
            children: [],
            data: {name: "IsAvailable", checked: false, stateIcon: <BoolSvg/>}
        },
        owner: {
            index: "owner",
            children: ["id","fullName", "age", "isAlive"],
            data: {name: "Owner", checked: false, stateIcon: <ObjSvg/>},
            isFolder: true
        },
        fullName: {
            index: "fullName",
            children: [],
            data: {name: "FullName", checked: false, stateIcon: <StringSvg/>}
        },
        age: {
            index: "age",
            children: [],
            data: {name: "Age", checked: false, stateIcon: <IntSvg/>}
        },
        isAlive: {
            index: "isAlive",
            children: [],
            data: {name: "IsAlive", checked: false, stateIcon: <BoolSvg/>}
        },
        client: {
            index: "client",
            children: ["id","name"],
            data: {name: "Client", checked: false, stateIcon: <ObjSvg/>},
            isFolder: true
        },
        id:{
          index: "id",
            children: [],
            data: {name: "Id", checked: false, stateIcon: <IntSvg/>}
        }
    };
    const {t, i18n} = useTranslation()

    const [examples, setExamples] = useState<Example[]>([
        {
            title: t("demonstrator:example1"),
            request: 'products_by_expiration_year = cross analysis\n' +
                '{\n' +
                '\tperiod = years,\n' +
                '\n' +
                '\tmain = part\n' +
                '\t{\n' +
                '\t\troot = Product,\n' +
                '\t\tgroupby /= ExpirationDate,\n' +
                '\t\taggregation /= count()\n' +
                '\t}\n' +
                '}',
            description: t("demonstrator:example1Description")
        },
        {
            title: t("demonstrator:example2"),
            request: 'stores_with_revenue_and_product_count = cross analysis\n' +
                '{\n' +
                '\trevenue = part\n' +
                '\t{\n' +
                '\t\troot = Store,\n' +
                '\t\tgroupby /= Name,\n' +
                '\t\taggregation /= sum(Revenue)\n' +
                '\t},\n' +
                '\t\n' +
                '\tproduct_count = part\n' +
                '\t{\n' +
                '\t\troot = Store,\n' +
                '\t\tgroupby /= Name,\n' +
                '\t\taggregation /= count(Products)\n' +
                '\t}\n' +
                '}',
            description: t("demonstrator:example2Description")
        },
        {
            title: t("demonstrator:example3"),
            request: 'owners_with_number_of_stores_revenue_above_50000 = cross analysis\n' +
                '{\n' +
                '\tmain = part\n' +
                '\t{\n' +
                '\t\troot = Owner,\n' +
                '\t\tgroupby /= FullName,\n' +
                '\t\taggregation /= count(Stores["Revenue > 50000"])\n' +
                '\t}\n' +
                '}',
            description: t("demonstrator:example3Description")
        },
        {
            title: t("demonstrator:example4"),
            request: 'list_stores_with_their_products = listing analysis\n' +
                '{\n' +
                '\troot = Store,\n' +
                '\ttargets = dic\n' +
                '\t{\n' +
                '\t\tstore_name /= Name,\n' +
                '\t\tproduct_name /= Products.Product.Name\n' +
                '\t}\n' +
                '}',
            description: t("demonstrator:example4Description")
        },
        {
            title: t("demonstrator:example5"),
            request: 'list_expiration_date_of_apples = listing analysis\n' +
                '{\n' +
                '\troot = Product,\n' +
                '\tfilter %= "Name = \'Apples\'",\n' +
                '\ttarget /= Id\n' +
                '}',
            description: t("demonstrator:example5Description")
        },
        {
            title: t("demonstrator:example6"),
            request: 'list_owners_stores_and_available_products_compact = listing analysis\n' +
                '{\n' +
                '\troot = Owner,\n' +
                '\ttargets = dic\n' +
                '\t{\n' +
                '\t\towner_name /= FullName,\n' +
                '\t\tstore_name /= Stores.Name,\n' +
                '\t\tproduct_names /= Stores.Products["Product.IsAvailable = true"].Product.Name\n' +
                '\t},\n' +
                '\tcoalesce = dic\n' +
                '\t{\n' +
                '\t\tp /= Stores.Products\n' +
                '\t},\n' +
                '\tcartesian = yes\n' +
                '}',
            description: t("demonstrator:example6Description")
        }
    ])

    const treeRef = useRef<TreeApi<node>>(null)
    const treeRef2 = useRef<TreeApi<node>>(null)

    const [resultJSON, setResultJSON] = useState({})
    const [request, setRequest] = useState<string>('')
    const [success, setSuccess] = useState<boolean>(false)
    const [selectedExample, setSelectedExample] = useState<Example | null>(null)
    const refAssistance = useRef<HTMLDivElement>(null)
    const timeout = useRef<any>(null);


    useEffect(() => {
        const divAvecClasseTree = document.querySelectorAll('.tree');
        if (divAvecClasseTree) {
            divAvecClasseTree.forEach((div) => {
                const divParent = div.parentElement;
                if (divParent) {
                    divParent.style.height = 'fit-content'
                    divParent.style.width = '100%'
                }
            })
        }

        treeRef.current?.closeAll()
        treeRef2.current?.closeAll()

        let demonstrator = window.localStorage.getItem('demonstrator')
        if(demonstrator === 'true'){
            setSuccess(true)
        }
    },[])

    let nodeRenderer : ElementType<NodeRendererProps<node>> = ({ node, style}) => {
        let openFolder = () => {
            if(node.isOpen){
                node.close()
            }
            else {
                node.open()
            }
        }
        return (
            <div style={style}>
                <label onClick={openFolder}  className={node.isLeaf ? '' : 'parent'}>{node.data.name}</label>
            </div>
        )
    }

    let changeRequest = (e:any) => {
        setRequest(e.currentTarget.value)
    }

    useEffect(() => {
        setRequest(selectedExample?.request || '')
    },[selectedExample])

    let sendRequest = () => {
        axios.post('/requestDDAL', {request: request}).then((res) => {
            setResultJSON(res.data)
        })
    }

    let sendEmail = (email:string) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if(emailRegex.test(email)){
            axios.post('/addEmailToNewsletter', {email: email}).then((res) => {
                setSuccess(true)
                window.localStorage.setItem('demonstrator', 'true')
            })
        }
        else {
            let input = document.getElementById('email')
            if(input){
                input.classList.add("input--error")
            }
        }

    }


    return (
        <div className={'demonstrator-container'}>
            <Row customClass={'jc-center'}>
                <h2>{t("demonstrator:test")}</h2>
            </Row>
            <Row customClass={'common-block-tree'}>
                {success ? <>
                <Row customClass="mb-30">
                <Column min={12}>
                    <div className={'assistance-block-static'}>
                            <h5 style={{marginTop: 0}}>{t("demonstrator:help")}</h5>
                            <div className={"inner-block inner-block--blue"}>
                               <AssistanceBlock/>
                            </div>
                    </div>
                        </Column>
                </Row>
                    <Row customClass={'jc-space-between'}>
                        <Column lg={6} min={12}>
                            <h5 style={{marginTop: 0}}>{t("demonstrator:dataModel")}</h5>
                            <div className={"inner-block h-100"}>
                                <Treeview checkBox={false} dataSource={dataTree}/>
                            </div>
                        </Column>
                        <Column lg={6} min={12}>
                            <h5 style={{marginTop: 0}}>{t("demonstrator:examples")}</h5>
                            <div className={"inner-block"}>
                                {examples.map((item, index) => {
                                  return <ExampleCard className={selectedExample?.title == item.title ? 'example-card example-card--selected' : 'example-card'} title={item.title} key={index} onClick={()=>setSelectedExample(item)}/>
                                })}
                            </div>
                        </Column>
                    </Row>
                    <Row>
                        <Column  min={12}>
                            <div className={'d-flex'}>
                                <h5>{t("demonstrator:request")}</h5>
                            </div>

                            <p>{selectedExample?.description}</p>
                        </Column>
                    </Row>
                    <Row customClass={"ai-center"}>
                        <Column xl={10} min={12}>
                            <Textbox value={selectedExample?.request} change={changeRequest} area={true}/>
                        </Column>
                        <Column customClass={"jc-center d-flex"} xl={2} min={12}>
                            <ButtonBase onClick={sendRequest} labelMethod={sendRequest} customClass="button-light">{t("demonstrator:send")}</ButtonBase>
                        </Column>
                    </Row>
                    <Row>
                        <Column min={12}>
                            <h5>{t("demonstrator:result")}</h5>
                        </Column>
                        <Column customClass={"inner-block inner-block--blue"} min={12}>
                            <JsonView src={resultJSON}/>
                        </Column>
                    </Row>
                </> : <MailForm sendEmail={sendEmail}/>}
            </Row>
        </div>
    )
}

type node ={
    name:string,
}

type MailFormProps = {
    sendEmail: (e:any) => void;
}

type ExampleCardProps = {
    title:string,
    [propName: string]: any;
}

const ExampleCard = ({title, ...props}:ExampleCardProps) => {

return(
        <div className={'example-card'} {...props}>
            {title}
        </div>
    )
}


const AssistanceBlock = () => {
    const {t, i18n} = useTranslation()
    return(
        <div className={'assistance-block-static'}>
            <p className={'mt-0'}>{t("demonstrator:doc")} <a target={'_blank'} href={'/api/base'}>{t("demonstrator:here")}</a></p>
            <p>{t("demonstrator:contactUs")}</p>
            <p><a href={'mailto:support.ddal@implex.fr'}>support.ddal@implex.fr</a></p>
            <p><a target={'_blank'} href={discordUrl}>{discordUrl}</a></p>
        </div>
    )
}

const MailForm = ({sendEmail} : MailFormProps) => {
    const [email, setEmail] = useState<string>('')
    const {t, i18n} = useTranslation()
    return(
        <div className={'mail-form-container'}>
            <p className={'p-title'}>{t("demonstrator:emailEntry")}</p>
            <p>{t("demonstrator:authorize")}</p>
            <p className={'warning mb-40'}>{t("demonstrator:warningUsage")} <a href={'mailto:support.ddal@implex.fr'}>e-mail</a> {t("demonstrator:warningUsage2")} <a href={discordUrl}>{t("demonstrator:warningUsage3")}</a> </p>
            <Textbox id={"email"} change={(e)=>setEmail(e.currentTarget.value)} placeholder={t("login:email")}/>
            <ButtonBase onClick={()=>sendEmail(email)} customClass="button-light mt-40">{t("demonstrator:send")}</ButtonBase>
        </div>
    )
}


export {AssistanceBlock,Demonstrator}