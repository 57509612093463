import {Row} from "../common/grid/row";
import {Column} from "../common/grid/column";
import {Textbox} from "../common/inputs/textbox";
import {ButtonBase} from "../common/buttons/buttonBase";
import React, {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import axios from "axios";

type preLaunchContactData = {
    [key:string]: any;
    lastName: string;
    firstName: string;
    company: string;
    email: string;
    lng: string;
}

type NewsletterProps = {
    article?: boolean;
}

const Newsletter = ({article}:NewsletterProps) => {
    const {t, i18n} = useTranslation()
    const dataContact = useRef<preLaunchContactData>({
        lastName: "",
        firstName: "",
        company: "",
        email: "",
        lng: i18n.language
    } as preLaunchContactData);
    const formRef = useRef<HTMLFormElement>(null);
    const [success, setSuccess] = React.useState<boolean>(false);
    const [error, setError] = useState<string>("");
    let handleSubmit = (e:any) => {
        e.preventDefault();
        axios.post('/preLaunch', dataContact.current, {params:{doNotCatch :true}}).then(res => {
            if(res.status === 200){
                setSuccess(true);
            }
        }).catch(err => {
            if(err.response.data.errorFields.length > 0){
                let errorField = err.response.data.errorFields
                let textError = ""
                errorField.forEach((field:any) => {
                    let input = document.getElementById(field.id)
                    if(input){
                        input.classList.add("input--error")
                    }
                    textError += '*' + field.message + '\n'
                })
                setError(textError)
            }
            else {
                setError(err.response.data.message)
            }

        })
        return false;
    }

    let handleChange = (value:any, property : string) => {
        dataContact.current[property] = value;
    }

    let labelClick = () => {
        handleSubmit(new Event('submit'));
    }
    return(
        <div id={'newsletter'} className={article ? "newsletter-container newsletter-container--article" : "newsletter-container"}>
            <section>
                <Row customClass='jc-center'>
                    <h2>{t('prelaunch:registerNewsletter')}</h2>
                    <p>{t('newsletter:subtitle')}</p>
                </Row>
                <Row customClass='jc-center'>
                    <Column xl={article ? 8 : 6 }>
                        <form ref={formRef} id={'preLaunchContact'} onSubmit={handleSubmit}>
                            <div className={"double-textbox"}>
                                <Textbox id="lastName"
                                         placeholder={t('input:lastname')} change={(e)=>handleChange(e.currentTarget.value,'lastName')}></Textbox>
                                <Textbox id="firstName"
                                         placeholder={t('input:firstname')} change={(e)=>handleChange(e.currentTarget.value,'firstName')}></Textbox>
                            </div>
                            <Textbox change={(e)=>handleChange(e.currentTarget.value,'company')} customClass='mb-30' id='society' placeholder={t('input:company')}/>
                            <Textbox change={(e)=>handleChange(e.currentTarget.value,'email')} customClass='mb-30' id='email' placeholder={t('input:email')}/>
                            <p className={'margin-bottom-responsive'} style={{fontWeight:'bold'}}>{t("newsletter:obligatory")}</p>
                            {
                                success? <div className='text-center'>
                                    <p style={{fontWeight:"bold", color:"#1f9902"}}>{t('newsletter:succesMessage')}</p>
                                </div> : <>
                                    <p className='text-center' style={{fontWeight:"500", color:"#ff0000"}}>{error}</p>
                                    <ButtonBase labelMethod={labelClick} type={"submit"} form={"preLaunchContact"} customClass='button-light'>{t('input:register')}</ButtonBase>
                                </>
                            }
                        </form>
                    </Column>
                </Row>
            </section>
        </div>
    )
}

export default Newsletter;