import {NavigationMobile} from "../components/common/navigation/navigationMobile";
import {NavigationBase} from "../components/common/navigation/navigationBase";
import React, {useEffect, useRef, useState} from "react";
import {NavigationCustomer} from "../components/customerArea/navigationCustomer";
import {PersonalDataSvg} from "../../style/svg/navigationCustomer/personalDataSvg";
import {DataPersonal} from "../components/customerArea/dataPersonal";
import {SocietySvg} from "../../style/svg/navigationCustomer/societySvg";
import {MySociety} from "../components/customerArea/mySociety";
import {SubscriptionSvg} from "../../style/svg/navigationCustomer/subscriptionSvg";
import {MySubscription} from "../components/customerArea/mySubscription";
import {InvoiceSvg} from "../../style/svg/navigationCustomer/invoiceSvg";
import {LogoutSvg} from "../../style/svg/navigationCustomer/logoutSvg";
import {Row} from "../components/common/grid/row";
import {Column} from "../components/common/grid/column";
import {Footer} from "../components/common/navigation/footer";
import axios from "axios";
import {PaymentMethodChoice} from "../components/customerArea/paymentMethodChoice";
import {PaymentMethodSvg} from "../../style/svg/navigationCustomer/paymentMethodSvg";
import {useNavigate} from "react-router-dom";
import Invoices from "../components/customerArea/invoices";
import Loading from "./loading";
import {useTranslation} from "react-i18next";
import DdalFreeCustomer from "../components/customerArea/ddalFreeCustomer";
import DDALFree from "../../style/svg/ddal-free";
import DDALFreeSVG from "../../style/svg/ddal-free";
import {useSavePosScrollBar} from "../helper/useSavePosScrollBar";


export type ContentProps = {
    data:any;
    setData:any;
}

const CustomerArea = () => {
    const [data, setData] = useState<any>(null)
    const [navItemsCustomer, setNavItemsCustomer] = useState<any>([])
    const refLoading = useRef<HTMLDivElement>(null);
    const refCustomerArea = useRef<HTMLDivElement>(null);
    let navigate = useNavigate()
    const {t, i18n} = useTranslation()

    useEffect(() => {
        document.body.style.overflow = "hidden"
        axios.post('/dataPersonal', {token:window.localStorage.getItem('token')}, {params:{doNotCatch :true}}).then(res => {
            if(res.status === 200){
                setData(res.data)
            }
        }).catch(err => {
            window.localStorage.removeItem('token')
            navigate('/login')
        })


        const clientSecret = new URLSearchParams(window.location.search).get(
            "setup_intent_client_secret"
        );
        const isDefault = new URLSearchParams(window.location.search).get(
            "setup_intent_client_secret"
        );
        const setupIntentId = new URLSearchParams(window.location.search).get(
            "setup_intent"
        );

        if (clientSecret) {
            axios.post('/successAddSetupPaymentIntent', {setupIntentId:setupIntentId, default:isDefault},{params:{doNotCatch :true}}).then(res => {
                if(res.status === 200){
                    navigate('/customer/?id=4')
                }
            }).catch(err=>{
                if(err.response.data.status === 377){
                    window.location.href = "/login"
                }
            })
        }

        return () => {
            setData(null)
        }

    }, [])

    useEffect(() => {
        if(data === null)
            return
        let navItemsCustomerTest=[
            {id:'1', name:t('customerArea:myDataPersonnel'), svg:<PersonalDataSvg/>, component:<DataPersonal data={data.client} setData={setData}/>},
            {id:'2', name:t('customerArea:myCompany'), svg:<SocietySvg/>, component:<MySociety data={data.client.Company} setData={setData}/>},
            {id:'3', name:t('customerArea:mySubscribes'), svg:<SubscriptionSvg/>, component:<MySubscription/>},
            {id:'4', name: t('customerArea:myPaymentMethods'), svg:<PaymentMethodSvg/>, component:<PaymentMethodChoice/>},
            {id:'5', name:t('customerArea:myInvoices'), svg:<InvoiceSvg/>, component:<Invoices/>},
            {id:'7', name:'DDAL Free', svg:<DDALFreeSVG/>, component:<DdalFreeCustomer setData={setData} data={data}/>},
            {id:'6', name:t('customerArea:signOut'), svg:<LogoutSvg/>, component:''},
        ]

        setNavItemsCustomer(navItemsCustomerTest)

        if(data !== null){
            refLoading.current?.classList.add("rocket-loading-container--close")
            refCustomerArea.current?.classList.add("payment-container--open")
            setTimeout(() => {
                refLoading.current?.classList.add("rocket-loading-container--hide")
                document.body.style.overflow = "auto"
            },500)
        }
    },[data])


    return (
        <>
            <div ref={refCustomerArea}>
                <NavigationMobile blue={true}/>
                <NavigationBase blue={true}/>
                <div className='customer-area-container'>
                    <Row customClass={'jc-center'}>
                        <Column xl={4}>
                            <h1>{t('customerArea:hello')} <span>{data?.client?.firstName},</span></h1>
                        </Column>
                        <Column customClass='p-30 p-30-responsive' min={12}>
                            <NavigationCustomer dataSource={navItemsCustomer}/>
                        </Column>
                    </Row>
                </div>
                <Footer preLaunch={true}/>
            </div>
            <Loading refLoading={refLoading}/>
        </>
    )
}
export {CustomerArea}