import {Row} from "../common/grid/row";
import {Column} from "../common/grid/column";
import syntaxe from "../../../style/image/syntaxe.png";
import exportImg from "../../../style/image/rapidement.png";
import sgbd from "../../../style/image/sgbd.png";
import puzzle from "../../../style/image/puzzle.png";
import json from "../../../style/image/json.png";
import sauvegarde from "../../../style/image/sauvegarde.png";
import recuperation from "../../../style/image/recuperation.png";
import graphique from "../../../style/image/graphique.png";
import analyse from "../../../style/image/analyse.png";
import {useTranslation} from "react-i18next";
import {ButtonBase} from "../common/buttons/buttonBase";
import React from "react";
import {Link} from "react-router-dom";
import {ddalArticleId} from "../../views/articles/ddalArticle";
import {ddalEFId} from "../../views/articles/DDALEFArticle";

const BenefitsEntityFramework = () => {
    const {t, i18n} = useTranslation()
    return(
        <div className="benefitsEntityFramework-container">
            <Row customClass={'jc-center mb-40'}>
                <Column min={12} xl={8}>
                    <Row>
                        <Column min={12}>
                            <h2>{t('prelaunch:entityFrameworkCore')}</h2>
                            <p>{t('prelaunch:entityFrameworkCoreParagraph1')}</p>
                        </Column>

                    </Row>
                    <Row customClass={'common-block-tree mt-50'}>
                        <h3>{t('prelaunch:forAnalyst')}</h3>
                        <Row customClass={'mt-30 jc-center gap-10'}>
                            <BenefitsEntityFrameworkBlock title={t('prelaunch:paragraphAnalyst1')} img={syntaxe}/>
                            <BenefitsEntityFrameworkBlock title={t('prelaunch:paragraphAnalyst2')} img={sgbd}/>
                            <BenefitsEntityFrameworkBlock title={t('prelaunch:paragraphAnalyst3')} img={json}/>
                            <BenefitsEntityFrameworkBlock title={t('prelaunch:paragraphAnalyst4')} img={exportImg}/>
                            <BenefitsEntityFrameworkBlock title={t('prelaunch:paragraphAnalyst5')} img={puzzle}/>
                        </Row>
                    </Row>
                    <Row customClass={'common-block-tree mt-50'}>
                        <h3>{t('prelaunch:forDeveloper')}</h3>
                        <Row customClass={'mt-30 jc-center gap-10'}>
                            <BenefitsEntityFrameworkBlock title={t('prelaunch:paragraphDeveloper1')} img={analyse}/>
                            <BenefitsEntityFrameworkBlock title={t('prelaunch:paragraphDeveloper2')} img={sauvegarde}/>
                            <BenefitsEntityFrameworkBlock title={t('prelaunch:paragraphDeveloper3')} img={recuperation}/>
                            <BenefitsEntityFrameworkBlock title={t('prelaunch:paragraphDeveloper4')} img={graphique}/>
                        </Row>
                    </Row>
                </Column>
            </Row>
            <Row customClass={'jc-center gap-20'}>
                <Link style={{textDecoration:'none'}} to={'/payment?trial=true'}
                      className="button-orange"><span className="span-dark"></span> <span
                    className="span-light"></span><label>{t("header:trial")}</label></Link>
                <Link style={{ textDecoration:'none'}} onMouseDown={()=> window.localStorage.setItem(ddalEFId,'0')} to={'blog/ddalef'}
                      className="button-orange"><span className="span-dark"></span> <span
                    className="span-light"></span><label>{t('prelaunch:moreInformation')}</label></Link>
            </Row>
        </div>
    )
}
type BenefitsEntityFrameworkBlockProps = {
    title: string;
    img: string;
}

const BenefitsEntityFrameworkBlock = ({title,img}:BenefitsEntityFrameworkBlockProps) => {
    return(
        <Column customClass={'benefit-block'} min={12} lg={4}>
            <div className={'d-flex ai-center jc-center'}>
                <img className={'mr-20'} src={img}/>
                <h4>{title}</h4>
            </div>
        </Column>
    )
}

export {BenefitsEntityFramework, BenefitsEntityFrameworkBlock};