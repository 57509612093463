import {Row} from "../components/common/grid/row";
import {Column} from "../components/common/grid/column";
import check from "../../style/image/check.png";
import {ButtonBase} from "../components/common/buttons/buttonBase";
import React, {useEffect, useState} from "react";
import {Link, useSearchParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useSavePosScrollBar} from "../helper/useSavePosScrollBar";

type ConfirmationPaymentProps = {
    quantitySave?: number | null;
    totalSave?: number | null;
}

const ConfirmationPayment = ({totalSave, quantitySave}:ConfirmationPaymentProps) => {
    const {} = useSavePosScrollBar('confirmationPayment')
    const [quantity, setQuantity] = useState<number>(0);
    const [total, setTotal] = useState<number | null>(null);
    const [searchParams, setSearchParams] = useSearchParams()
    const {t, i18n} = useTranslation()

    useEffect(() => {
        if (searchParams.get('total') !== undefined && searchParams.get('total') !== null) {
            setTotal(JSON.parse(searchParams.get("total") as string) as number | null);
        }
        else {
            if(totalSave)
                setTotal(totalSave)
        }

        if(searchParams.get('quantity') !== undefined && searchParams.get('quantity') !== null){
            setQuantity(JSON.parse(searchParams.get("quantity") as string) as number);
        }
        else{
            if(quantitySave)
                setQuantity(quantitySave)
        }
    }, [searchParams, quantitySave, totalSave]);



    return (
        <div>
            <Row customClass={'jc-center'}>
                <Column customClass={'confirmation-container'} xl={6}>
                    <img src={check}/>
                    <h2>{t('payment:thanks')} {quantity} {t('payment:subscribeDDAl')}</h2>
                    <p>{t('payment:mailConfirmation')}</p>
                    <p className={'p-ammount-title'}>{t('payment:amountCourant')}</p>
                    <p className={'p-ammount'}>{total}€</p>
                    <p className={'mb-50'}>{t('payment:returnCustomerArea')}</p>
                    <Link to={'/customer/?id=3'} className="button-light"><span className='span-dark'></span> <span className='span-light'></span><label>{t('payment:customerArea')}</label></Link>
                </Column>
            </Row>

        </div>
    )
}

export {ConfirmationPayment}