import {SvgProps} from "./arrowSVG";

const TelephoneSvg = ({...props}:SvgProps) => {
    return(
        <svg {...props} version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 28 28" enableBackground="new 0 0 28 28" xmlSpace="preserve">
<g id="Groupe_19" transform="translate(-676 -4212)">
	<g id="Ellipse_5" transform="translate(676 4212)">
		<circle fill="none" cx="14" cy="14" r="14"/>
        <path fill="#2C358B" d="M14,28C6.3,28,0,21.7,0,14S6.3,0,14,0s14,6.3,14,14S21.7,28,14,28z M14,2C7.4,2,2,7.4,2,14s5.4,12,12,12
			s12-5.4,12-12S20.6,2,14,2z"/>
	</g>
    <g id="phone_solid" transform="translate(681.643 4217.643)">
		<path id="Tracé_169" fillRule="evenodd" clipRule="evenodd" fill="#2C358B" d="M3.6,2.5c0.8-0.8,2-0.8,2.7,0
			C6.5,2.7,6.6,2.8,6.7,3l0.9,2c0.2,0.3,0.2,0.7,0,1.1l-0.5,1C6.9,7.5,7,7.9,7.3,8.1l1.3,1.3c0.3,0.3,0.7,0.3,1,0.2l1-0.5
			c0.3-0.2,0.7-0.2,1.1,0l2,0.9c1,0.4,1.4,1.6,0.9,2.6c-0.1,0.2-0.2,0.4-0.4,0.6l-0.5,0.5c-1.3,1.4-3.4,1.5-4.9,0.4
			c-1.1-0.9-2.2-1.8-3.3-2.8c-1-1-2-2.1-2.8-3.3c-1.1-1.5-1-3.7,0.4-5L3.6,2.5z"/>
	</g>
</g>
</svg>
    )
}

export default TelephoneSvg;