import ReactCodeMirror from "@uiw/react-codemirror";
import {dracula} from "@uiw/codemirror-theme-dracula";
import {langs} from "@uiw/codemirror-extensions-langs";

const Exploration = () => {
    return (
        <div className={'api'}><h1>Exploration</h1>
        <p>Exploration is a feature offered with DDAL/EF which allows you to quickly retrieve metadata and information on your types.
            This is especially useful when you are building a front-end with eg charts and visuals for your data analysis.</p>
        <p>As of version 1, exploration can only be done with a custom deployment or by calling DDAL/EF's binaries directly.
            The docker image does not expose any API request to explore types due to the high amount of options available.</p>
        <p>It is probably best to serve with an example of exploration result on the <code>Store</code> entity of the sample model.
            We will use a depth of 2:</p>
            <ReactCodeMirror theme={dracula} value={`[
            {
                "id": null,
                "type": "string",
                "member": "Name",
                "label": "Name",
                "values": [
                    "Starbucks",
                    "Walmart"
                ],
                "children": null
            },
            {
                "id": null,
                "type": "date",
                "member": "OpeningDate",
                "label": "Opening date",
                "values": null,
                "children": null
            },
            {
                "id": null,
                "type": "number",
                "member": "Revenue",
                "label": "Revenue",
                "values": null,
                "children": null
            },
            {
                "id": null,
                "type": "number",
                "member": "TaxRate",
                "label": "Revenue",
                "values": null,
                "children": null
            },
            {
                "id": null,
                "type": "object",
                "member": "Owner",
                "label": "Owner",
                "values": null,
                "children": [
                    {
                        "id": null,
                        "type": "string",
                        "member": "FullName",
                        "label": "Full name",
                        "values": null,
                        "children": null
                    },
                    {
                        "id": null,
                        "type": "number",
                        "member": "Age",
                        "label": "Age",
                        "values": null,
                        "children": null
                    },
                    {
                        "id": null,
                        "type": "boolean",
                        "member": "IsAlive",
                        "label": "Is alive?",
                        "values": null,
                        "children": null
                    },
                    {
                        "id": null,
                        "type": "enumerable",
                        "member": "Stores",
                        "label": "Stores",
                        "values": null,
                        "children": [
                            "..."
                        ]
                    }
                ]
            },
            {
                "id": null,
                "type": "enumerable",
                "member": "Products",
                "label": "Products",
                "values": null,
                "children": [
                    {
                        "id": null,
                        "type": "string",
                        "member": "Name",
                        "label": "Name",
                        "values": null,
                        "children": null
                    },
                    {
                        "id": null,
                        "type": "date",
                        "member": "ExpirationDate",
                        "label": "Expiration date",
                        "values": null,
                        "children": null
                    },
                    {
                        "id": null,
                        "type": "boolean",
                        "member": "IsAvailable",
                        "label": "Is available?",
                        "values": null,
                        "children": null
                    }
                ]
            },
            {
                "id": null,
                "type": "enumerable",
                "member": "Clients",
                "label": "Clients",
                "values": null,
                "children": [
                    {
                        "id": null,
                        "type": "string",
                        "member": "Name",
                        "label": "Name",
                        "values": null,
                        "children": null
                    }
                ]
            }
]`} extensions={[langs.javascript()]} />
        <p>As you can see, exploration allows you to retrieve (in JSON or directly as a set of <code>ExplorationInfo</code> objects) recursive informations on a given type.
            Let us go through the different parts of an exploration info item:</p>
        <ul>
            <li>id: If you instruct the explorator to use ids, a unique integer id will be generated for each node.
                This is especially useful if you have a front-end which needs to uniquely identify each node.
                In the above example, we did not require ids.</li>
            <li>type: an enumeration item indicating the type of the current property among: boolean, string, date, number, enum, enumerable, object
                How this appears in JSON will depend on whether you have set a custom serializer up.</li>
            <li>member: The literal CLR name of the property as it is in code.</li>
            <li>label: By default, same as member. But if a localization (resx) file is present for the class which contains the property,
                then the explorator will try to fetch the label from a translation line where the key is the property name.
                For example, if I have the following translation lines (for french):</li>
        </ul>
        <table>
            <thead>
            <tr>
                <th>Key</th>
                <th>Value</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>Name</td>
                <td>Nom</td>
            </tr>
            <tr>
                <td>OpeningDate</td>
                <td>Date d'ouverture</td>
            </tr>
            <tr>
                <td>Revenue</td>
                <td>Revenu</td>
            </tr>
            </tbody>
        </table>
        <p>Then the nodes pointing on "Name" will have their label set to "Nom", similarly for the opening date, etc.</p>
        <ul>
            <li>values: If you override the value resolver in code, or set enums to be auto-resolved and the node is an enum, then this will contain a set of values relating to the node property.
                This is mostly useful for autocompletion on your frontend, for instance.
                For enums, by default the autocompletion will look like this (for instance with the <code>ExplorationInfo</code> type enum):</li>
        </ul>
            <ReactCodeMirror theme={dracula} value={` [
            {
                "enumMember": "Boolean",
                "enumLabel": "Boolean",
                "enumValue": 0
            },
            {
                "enumMember": "String",
                "enumLabel": "String",
                "enumValue": 1
            },
            "..."
 ]`} extensions={[langs.javascript()]} />
        <p>Similar to the label field above, enum values can have a label attached to them using a resx file for the enum.</p>
        <ul>
            <li>children: For objects and enumerable of objects, if the max depth has not been reached yet, this will open another set of exploration informations relating to the object type.</li>
        </ul>
        <h2>Options</h2>
        <p>This section will list all the available options in the current version for explorators.
            Those options can be accessed at any time as properties on your explorator object after you have instantiated it using a service provider.
            Note the only feature of the service provider required is the resolution of localizers for arbitrary types. This is used for translated labels.</p>
        <h3>Depth</h3>
        <p>Default value: <code>3</code>.
            Depth controls the recursive depth for object properties. At 3, you will 3 levels of depth including the root type.
            The top example in this page uses a depth of 2.</p>
        <p>This value has a hard cap with a value of 10. Any higher value will make no difference.</p>
        <h3>Use IDs</h3>
        <p>Default value: <code>false</code>.
            If set to true, the explorator will initialize an <code>ulong</code> value and increment it to generate unique ids for your nodes.
            If set to false, the ids will be <code>null</code>.</p>
        <h3>Autoresolve enums</h3>
        <p>Default value: <code>true</code>.
            If set to true, enum properties will automatically be injected a set of values corresponding to all their possible values.
            Otherwise the <em>value</em> field will be set to <code>null</code>.</p>
        <h3>Value resolver</h3>
        <p>Default value: <code>null</code>.
            You can overriden this delegate to generate values &amp; pseudo-autcompletion for any node.
            The delegate takes a member info as single argument and returns an enumerable of object which will be injected in the <code>values</code> field of the node.</p>
        <h3>Always explored items</h3>
        <p>It is possible for certain properties/types to ignore the max depth and have set to be always explored. To this end you can use:</p>
        <ul>
            <li><code>AlwaysExploredTypes</code> to have certain types be always explored.</li>
            <li><code>AlwaysExploredMembers</code> to have certain members be always explored.</li>
            <li><code>AlwaysExploredMemberNames</code> to have members with a given name be always explored.</li>
        </ul>
        <h3>Never explored items</h3>
        <p>It is possible for certain properties/types to always be ignored and unexplored regardless of max depth. You can use:</p>
        <ul>
            <li><code>NeverExploredTypes</code> to never explore certain types.</li>
            <li><code>NeverExploredMembers</code> to never explore certain members.</li>
            <li><code>NeverExploredMemberNames</code> to never explore members with a given name.</li>
        </ul>
        <h3>Never included items</h3>
        <p>Aside from not exploring, you can also exclude some types to even be included in the final result.
            This means you can, for instance, entirely exclude strings, enum members, etc.
            To achieve this, you can use:</p>
        <ul>
            <li><code>NeverIncludedTypes</code> to always exclude certain types</li>
            <li><code>NeverIncludedMembers</code> to exclude some members</li>
            <li><code>NeverIncludedMemberNames</code> to exclude all members with a given name</li>
        </ul>
        </div>
    );
}

export default Exploration;