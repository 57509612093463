import {useEffect, useRef} from "react";
import {useTranslation} from "react-i18next";
import {discordUrl} from "../../../index";

const QuestionElement = () => {
    const refContainer = useRef<HTMLDivElement>(null)
    const {t, i18n} = useTranslation()
    let handleClick = () => {
        if(refContainer.current){
            if(refContainer.current.classList.contains('question-container--open')) {
                refContainer.current.classList.remove("question-container--open");
                refContainer.current.classList.add("question-container--close");
                setTimeout(() => {
                    if(refContainer.current) {
                        refContainer.current.classList.remove("question-container--close");
                    }
                },245)

            }
            else {
                refContainer.current.classList.add("question-container--open");
            }
        }
    }

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (refContainer.current && !refContainer.current.contains(event.target)) {
                if(refContainer.current.classList.contains('question-container--open')) {
                    refContainer.current.classList.remove("question-container--open");
                    refContainer.current.classList.add("question-container--close");
                    setTimeout(() => {
                        if (refContainer.current) {
                            refContainer.current.classList.remove("question-container--close");
                        }
                    }, 245)
                }
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [refContainer]);

    return (
        <div ref={refContainer} onClick={handleClick}  className={'question-container'}>
            <div className={'inner-div'}>
                <p>{t('question:subtitle')}</p>
                <a href={'mailto:sales.ddal@implex.fr'}>sales.ddal@implex.fr</a>
                <a href={'mailto:support.ddal@implex.fr'}>support.ddal@implex.fr</a>
                <a target={'_blank'} href={discordUrl}>{discordUrl}</a>
            </div>
            <div className={'title-div'}>
                <p>{t('question:title')}</p>
            </div>
        </div>
    )
}

export default QuestionElement