import {useEffect, useState} from "react";

function useSavePosScrollBar(name:string) {
    const [scrollPosition, setScrollPosition] = useState(-1);
    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };
    useEffect(() => {
        if(window.localStorage.getItem(name) === null) {
            window.scrollTo(0, 0);
        }
        else {
            window.scrollTo(0, parseInt(window.localStorage.getItem(name) as string));
        }

        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if(scrollPosition !== -1) {
            window.localStorage.setItem(name,scrollPosition.toString());
        }

    }, [scrollPosition]);

    return {
    };
}

export {useSavePosScrollBar};