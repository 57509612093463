import React, {useEffect, useRef, useState} from "react";
import {ControlledTreeEnvironment, DraggingPosition, Tree, TreeItem, TreeItemIndex} from "react-complex-tree";
import {Checkbox} from "./checkbox";
import {CloseNodeTreeSvg} from "../../../../style/svg/closeNodeTreeSvg";

export type DataTreeViewType = {
    name?: string;
    stateIcon?: any;
    icon?: any;
    checked?: boolean;
}

type TreeviewProps = {
    dataSource?: Record<TreeItemIndex, TreeItem<DataTreeViewType>>;
    checkBox?: boolean;
    recursiveChecked?: boolean;
}

const Treeview = ({dataSource, checkBox, recursiveChecked}: TreeviewProps) => {
    const [data, setData] = useState<Record<TreeItemIndex, TreeItem<DataTreeViewType>>>({});
    const [selectedItems, setSelectedItems] = useState<TreeItemIndex[]>([]);
    const [focusedItem, setFocusedItem] = useState<string | number>();
    const [expandedItems, setExpandedItems] = useState<Array<string | number>>([]);
    const [checkedItems, setCheckedItems] = useState<Array<string | number>>([]);
    const shifted = useRef<boolean>(false);
    const ctrled = useRef<boolean>(false);
    const expandTimeout = useRef<Array<any>>([]);
    const collapseTimeout = useRef<Array<any>>([]);

    useEffect(() => {
        if (dataSource === undefined) return;
        setData(dataSource);

        return () => {
            setData({});
        };
    }, [dataSource]);


    useEffect(() => {
        let ul = document.getElementById(`li-treeview-${expandedItems[expandedItems.length - 1]}`)?.querySelector("ul");
        let height = ul?.getBoundingClientRect().height;
        if (ul === null || ul === undefined || height === undefined) return;
        collapseTimeout.current.forEach(timeout => clearTimeout(timeout));
        ul.style.height = `0px`;
        expandTimeout.current[0] = setTimeout(() => {
            if (ul === null || ul === undefined || height === undefined) return;
            ul.style.height = `${height}px`;
        }, 1);
        expandTimeout.current[1] = setTimeout(() => {
            if (ul === null || ul === undefined || height === undefined) return;
            ul.style.height = `100%`;
        }, 300);

    }, [expandedItems]);

    useEffect(() => {
        document.addEventListener("keydown", (e) => {
            if (e.key === "Shift") shifted.current = true;
        });
        document.addEventListener("keyup", (e) => {
            if (e.key === "Shift") shifted.current = false;
        });

        document.addEventListener("keydown", (e) => {
            if (e.key === "Control") ctrled.current = true;
        });
        document.addEventListener("keyup", (e) => {
            if (e.key === "Control") ctrled.current = false;
        });
        return () => {
            document.removeEventListener("keydown", () => {});
            document.removeEventListener("keyup", () => {});
        };
    }, []);


    let handleSelectedItems = (item: TreeItemIndex) => {
        if (shifted.current) {
            let parentItemsIndex = Object.keys(data).filter(item => {
                let itemData = data[item];
                if (itemData.children?.includes(selectedItems[0] as string)) return true;
            });
            if (parentItemsIndex.length === 0) {
                setSelectedItems([item]);
                return;
            }
            let firstItem = data[parentItemsIndex[0]].children?.indexOf(selectedItems[0] as string);
            let lastItem = data[parentItemsIndex[0]].children?.indexOf(item as string);
            if (firstItem === undefined || lastItem === undefined) return;
            let newSelectedItems = data[parentItemsIndex[0]].children?.slice(Math.min(firstItem, lastItem), Math.max(firstItem, lastItem) + 1);
            if (newSelectedItems === undefined) return;
            setSelectedItems(newSelectedItems);
        }
        else if (ctrled.current) {
            if (selectedItems.includes(item)) {
                setSelectedItems(selectedItems.filter(selectedItem => selectedItem !== item));
            }
            else {
                setSelectedItems([...selectedItems, item]);
            }
        }
        else {
            setSelectedItems([item]);
        }
    };

    let checkedChildItems = (e: any, item: TreeItem) => {
        data[item.index].children?.forEach(child => {
            data[child].data.checked = e.currentTarget.checked;
            checkedItems.push(child);
            setCheckedItems([...checkedItems]);
            if (!recursiveChecked)
                return;
            if (data[child].isFolder)
                checkedChildItems(e, data[child]);
        });
    };

    let unCheckedChildItems = (e: any, item: TreeItem) => {
        data[item.index].children?.forEach(child => {
            data[child].data.checked = e.currentTarget.checked;
            let index = checkedItems.indexOf(child);
            if (index !== -1) checkedItems.splice(index, 1);
            setCheckedItems([...checkedItems]);
            if (!recursiveChecked)
                return;
            if (data[child].isFolder)
                unCheckedChildItems(e, data[child]);
        });
    };

    let handleCheckedItems = (e: any, item: TreeItem) => {
        item.data.checked = e.currentTarget.checked;
        if (e.currentTarget.checked) {
            if (item.isFolder) {
                checkedChildItems(e, item);
            }
            else {
                setCheckedItems([...checkedItems, item.index]);
            }
            return;
        }
        else {
            if (item.isFolder) {
                unCheckedChildItems(e, item);
            }
            else {
                setCheckedItems(checkedItems.filter(checkedItemIndex => checkedItemIndex !== item.index));
            }

            return;
        }
    };

    return (
        <ControlledTreeEnvironment
            items={data}
            canDragAndDrop={true}
            canDropOnFolder={true}
            canDropOnNonFolder={true}
            onDrop={(items: TreeItem[], target: DraggingPosition) => {}}
            onFocusItem={(item: TreeItem) => setFocusedItem(item.index)}
            onExpandItem={(item: TreeItem) => {
                setExpandedItems([...expandedItems, item.index]);
            }}
            onCollapseItem={(item: TreeItem) => {
                let ul = document.getElementById(`li-treeview-${item.index}`)?.querySelector("ul");
                if (ul === null || ul === undefined) return;
                expandTimeout.current.forEach(timeout => clearTimeout(timeout));
                ul.style.transition = `all 0s ease-in-out`;
                collapseTimeout.current[0] = setTimeout(() => {
                    if (ul === null || ul === undefined) return;
                    ul.style.height = `${ul.getBoundingClientRect().height}px`;
                }, 10);
                collapseTimeout.current[1] = setTimeout(() => {
                    if (ul === null || ul === undefined) return;
                    ul.style.transition = `all 0.3s ease-in-out`;
                }, 20);
                collapseTimeout.current[2] = setTimeout(() => {
                    if (ul === null || ul === undefined) return;
                    ul.style.height = `0px`;
                }, 30);
                collapseTimeout.current[3] = setTimeout(() => {
                    setExpandedItems(expandedItems.filter(expandedItemIndex => expandedItemIndex !== item.index));
                }, 400);

            }}
            getItemTitle={(item: TreeItem) => item.data.name}
            viewState={{
                "tree-1": {
                    focusedItem,
                    expandedItems,
                    selectedItems
                }
            }}
            renderItemTitle={({title}) => <span>{title}</span>}
            renderItemArrow={({item, context}) =>
                item.isFolder ? context.isExpanded ?
                    <CloseNodeTreeSvg data-testid={`arrow-treeview-${item.index}`} dClass={"open-treeview"}
                                      onClick={() => {context.collapseItem();}}/> :
                    <CloseNodeTreeSvg data-testid={`arrow-treeview-${item.index}`}
                                      onClick={() => {context.expandItem();}}/> : null
            }
            renderItem={({title, arrow, context, children, item}) =>
                <li
                    {...context.itemContainerWithChildrenProps}
                    id={`li-treeview-${item.index}`}
                    style={{
                        margin: 0,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start"
                    }}
                >
                    <div data-testid={`main-item-treeview-${item.index}`}
                         className={context.isSelected ? "item-treeview item-treeview--selected" : "item-treeview"}
                         id={item.index as string} {...context.itemContainerWithoutChildrenProps} >
                        {arrow}
                        <div data-testid={`inner-item-treeview-${item.index}`}
                             className="inner-icon-container" {...context.interactiveElementProps}
                             onClick={() => {
                                 handleSelectedItems(item.index);
                             }}>
                            {checkBox ?
                                <Checkbox data-testid={`checkbox-treeview-${item.index}`} checked={item.data.checked}
                                          onChange={(e: any) => {
                                              handleCheckedItems(e, item);
                                          }}/> : <>
                                    {item.data.stateIcon}
                                    {item.data.icon}
                                </>}

                            {title}
                        </div>
                    </div>
                    {children}
                </li>
            }
            renderTreeContainer={({children, containerProps}) =>
                <div {...containerProps}>{children}</div>}
            renderItemsContainer={({children, containerProps}) =>
                <ul {...containerProps}>{children}</ul>
            }
        >
            <Tree treeId="tree-1" rootItem="root" treeLabel="Tree Example"/>
        </ControlledTreeEnvironment>
    );
};

export {Treeview};