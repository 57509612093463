import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'normalize.css';
import "./style/main.css";
import { BrowserRouter } from 'react-router-dom';
import axios from "axios";
import './i18n';
import "animate.css/animate.min.css";

axios.defaults.baseURL = process.env.NODE_ENV === 'development' ? `${process.env.REACT_APP_ADRESS_SERVER}:${process.env.REACT_APP_PORT_SERVER_EXPRESS}/backend` : `${process.env.REACT_APP_ADRESS_SERVER}`;

axios.defaults.headers.common['content-type'] = 'application/json';

axios.interceptors.request.use(config => {
    return config;
}, error => Promise.reject(error));

axios.defaults.headers.common['Authorization'] = window.localStorage.getItem('token');
axios.interceptors.response.use(response => response, error => {
    console.log(error.response.config.params.doNotCatch);
    if (error.response.config.params.doNotCatch) {
        console.log('do not catch');
        return Promise.reject(error);
    }

    switch (error.response.status) {
        case 401:
            window.localStorage.removeItem('token')
            window.location.replace('/login');
            break;
        case 403:
            window.location.replace('/forbidden')
            break;
        case 404:
            window.location.replace('/*')
            break;
        case 500:
            alert('Server error');
            console.log(error);
            break;
        default:
            alert('Unknown error');
            console.log(error);
            break;
    }

    return Promise.reject(error);
})

window.onbeforeunload = function () {
    window.scrollTo(0, 0);
}

export const discordUrl = 'https://discord.gg/7jGyVSB4H3';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <BrowserRouter>
            <App/>
      </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();