import {Textbox} from "../common/inputs/textbox";
import {Dropdown} from "../common/inputs/dropdown";
import europeanPhoneCountries from "../../../data-form/european-countries.json";
import europeanTaxCountries from "../../../data-form/european-tax-number.json";
import {ButtonBase} from "../common/buttons/buttonBase";
import React, {useRef, useState} from "react";
import {Row} from "../common/grid/row";
import {Column} from "../common/grid/column";
import {ContentProps} from "../../views/customerArea";
import axios from "axios";
import {useTranslation} from "react-i18next";
import ConfirmationModal from "../common/modals/confirmationModal";
import Modal from "../common/modals/modal";

type SocietyData = {
    [key:string]: any;
    name: string;
    adress: string,
    postalCode: string,
    city: string,
    country: string,
    taxId: string,
    taxValue: string
}

const MySociety = ({data, setData}:ContentProps) => {
    const [openSendData, setOpenSendData] = useState<boolean>(false);
     const societyData = useRef<SocietyData>({} as SocietyData)
    const {t, i18n} = useTranslation()
    let updateData = (id:string, value:string) => {
        societyData.current[id] = value
        data[id] = value
    }

    let sendData = () => {
        axios.post('/updateSocietyInfo', societyData.current, {params:{doNotCatch :true}}).then(res => {
            if(res.status === 200){
                setOpenSendData(false)
            }
        }).catch(err => {

        })
    }

    let onCloseModal = () => {
        setOpenSendData(false)
    }

    return (
        <div className='my-society-container'>
            <Row customClass={'mb-30'}>
                <Column xl={6}>
                    <Textbox id={'name'} change={(e)=>updateData('name',e.currentTarget.value)} placeholder={t('customerArea:name')} value={data.name}/>
                </Column>
            </Row>
            <Row customClass={'mb-30'}>
                <Column xl={6}>
                    <Textbox id={'adress'} placeholder={t('customerArea:adress')} change={(e)=>updateData('adress',e.currentTarget.value)} value={data.adress}/>
                </Column>
            </Row>
            <Row customClass={'mb-30'}>
                <Column xl={2}>
                    <Textbox id={'postalCode'} change={(e)=>updateData('postalCode',e.currentTarget.value)} placeholder={t('customerArea:zipCode')} value={data.postalCode}/>
                </Column>
                <Column customClass={'margin-responsive'} xl={4}>
                    <Textbox id={'city'} change={(e)=>updateData('city',e.currentTarget.value)} placeholder={t('customerArea:city')} value={data.city}/>
                </Column>
            </Row>
            <Row customClass={'mb-30'}>
                <Column xl={6}>
                    <Dropdown activeSearch={true} id={'country'} selected={(item)=>updateData('country', item.id)} placeholder={t('customerArea:country')} value={data.country} dataItem={europeanPhoneCountries}/>
                </Column>
            </Row>
            <Row  customClass={'mb-30'} >
                <Column customClass={'margin-responsive'} xl={6}>
                    <Textbox id={'taxValue'} change={(e)=>updateData('taxValue',e.currentTarget.value)} placeholder={t('customerArea:taxNumber')} value={data.taxValue}/>
                </Column>
            </Row>

            <Row>
                <Column xl={6}>
                    <ButtonBase labelMethod={()=>setOpenSendData(true)} onClick={()=>setOpenSendData(true)} customClass='button-light mt-30'>{t('customerArea:save')} </ButtonBase>
                </Column>
            </Row>
            <Modal eventClose={onCloseModal} open={openSendData}>
                <ConfirmationModal text={t('customerArea:modalConfirmPersonnel')} confirm={sendData}/>
            </Modal>
        </div>
    )
}

export {MySociety}