const IntSvg = () => {
    return (
        <svg enableBackground="new 0 0 20 20" version="1.1" viewBox="0 0 20 20" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg">
<path d="M17.5,20h-15C1.1,20,0,18.9,0,17.5v-15C0,1.1,1.1,0,2.5,0h15C18.9,0,20,1.1,20,2.5v15  C20,18.9,18.9,20,17.5,20z" fill="#7BC97D"/>
            <text transform="translate(-25.293 12.934)" fill="#FFFFFF" fontFamily="'Comfortaa-Bold'" fontSize="8px" stroke="#FFFFFF" strokeMiterlimit="10" strokeWidth=".75">STR</text>
            <g fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".75">
                <path d="m2.5 13.1c-0.1-0.1-0.1-0.2-0.1-0.3v-5.4c0-0.1 0-0.2 0.1-0.3s0.2-0.1 0.3-0.1 0.2 0 0.3 0.1 0.1 0.2 0.1 0.3v5.4c0 0.1 0 0.2-0.1 0.3s-0.2 0.1-0.3 0.1c-0.2 0-0.2 0-0.3-0.1z"/>
                <path d="m5.9 13.1c-0.1-0.1-0.1-0.2-0.1-0.3v-5.4c0-0.1 0-0.2 0.1-0.3s0.2-0.1 0.3-0.1h0.2s0.1 0.1 0.1 0.1l3.5 4.6v-4.3c0-0.1 0-0.2 0.1-0.3s0.2-0.1 0.3-0.1 0.2 0 0.3 0.1 0.1 0.2 0.1 0.3v5.4c0 0.1 0 0.2-0.1 0.3s-0.2 0.1-0.3 0.1h-0.2c-0.1 0-0.1-0.1-0.1-0.1l-3.5-4.7v4.4c0 0.1 0 0.2-0.1 0.3s-0.2 0.1-0.3 0.1-0.2 0-0.3-0.1z"/>
                <path d="m17.7 7.1c0.1 0.1 0.1 0.2 0.1 0.3s0 0.2-0.1 0.3-0.2 0.1-0.3 0.1h-1.7v5.1c0 0.1 0 0.2-0.1 0.3s-0.2 0.1-0.3 0.1-0.2 0-0.3-0.1-0.1-0.2-0.1-0.3v-5.2h-1.7c-0.1 0-0.2 0-0.3-0.1s-0.1-0.2-0.1-0.3 0-0.2 0.1-0.3c0 0 0.1-0.1 0.2-0.1h4.3c0.1 0 0.2 0.1 0.3 0.2z"/>
</g>
</svg>

    )
}

export {IntSvg}