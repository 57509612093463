import {useTranslation} from "react-i18next";
import {NavigationMobile} from "../components/common/navigation/navigationMobile";
import {NavigationBase} from "../components/common/navigation/navigationBase";
import {Footer} from "../components/common/navigation/footer";
import React from "react";
import {Column} from "../components/common/grid/column";
import {Row} from "../components/common/grid/row";
import ddalIgm from "../.././style/image/image-page-ddal.jpg";
import ddalProIgm from "../.././style/image/image-page-ddal-ef.jpg";
import CircleArrow from "../../style/svg/circleArrow";
import {blob} from "stream/consumers";
import {useSavePosScrollBar} from "../helper/useSavePosScrollBar";
import {useRefreshItemNav} from "../helper/useRefreshItemNav";
import {Helmet} from "react-helmet";

export const blogId = 'blog'
const Blog = () => {
    const {} = useSavePosScrollBar(blogId)
    const {} = useRefreshItemNav(blogId)
    const {t, i18n} = useTranslation()
    return (
            <>
                <header>
                    <NavigationMobile blue={true}/>
                    <NavigationBase blue={true}/>
                </header>
                <Helmet>
                    <title>DDAL Blog - Contact Dynamic Data Analysis Language Team</title>
                    <meta name="description" content="Contact the DDAL EF Core team to request assistance" />
                </Helmet>
                <div className='blog-container'>
                    <Row customClass={'jc-center mb-60'}>
                        <Column customClass={'center-items-column'} xl={5}>
                            <h1><span>Blog DDAL</span></h1>
                        </Column>
                    </Row>
                    <Row>
                        <Column xl={8}>
                            <Row customClass={'jc-center gap-40'}>
                                <Column customClass={'d-flex'} xl={3} lg={4} sm={6} min={12}>
                                    <BlockBlog text={t('blog:subtitleDDAL')} title={t('blog:titleDDAL')} img={ddalIgm} link={'blog/ddal'}/>
                                </Column>
                                <Column customClass={'d-flex'} xl={3} lg={4} sm={6} min={12}>
                                    <BlockBlog text={t('blog:subtitleDDALPro')} title={t('blog:titleDDALPro')} img={ddalProIgm} link={'blog/ddalef'}/>
                                </Column>
                            </Row>
                        </Column>
                    </Row>

                </div>
                <Footer preLaunch={false}/>
        </>
    )
}

type BlockBlogProps = {
    title?: string;
    img?: string;
    text?: string;
    link?: string;
}

const BlockBlog = ({title,text,img, link}:BlockBlogProps) => {
    return(
        <div className='block'>
            <a href={link}>
                <div className={'container'}>
                    <h2>{title}</h2>
                    <img src={img}/>
                </div>
                <div className={'h-100 p-20 pos-relative'}>
                    <p>{text}</p>
                    <div className={'svg-container'}>
                        <CircleArrow/>
                    </div>
                </div>
            </a>
        </div>
    )
}

export default Blog