const ObjSvg = () => {
    return (
        <svg enableBackground="new 0 0 20 20" version="1.1" viewBox="0 0 20 20" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg">
<path d="M17.5,20h-15C1.1,20,0,18.9,0,17.5v-15C0,1.1,1.1,0,2.5,0h15C18.9,0,20,1.1,20,2.5v15  C20,18.9,18.9,20,17.5,20z" fill="#6ED3C7"/>
            <text transform="translate(-25.293 12.934)" fill="#FFFFFF" fontFamily="'Comfortaa-Bold'" fontSize="8px" stroke="#FFFFFF" strokeMiterlimit="10" strokeWidth=".75">STR</text>
            <g fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".7056">
<path d="m3 12.6c-0.4-0.2-0.8-0.6-1-1-0.3-0.5-0.4-1-0.4-1.6s0.1-1.1 0.4-1.6c0.2-0.4 0.6-0.8 1-1 0.5-0.3 1-0.4 1.6-0.4s1.1 0.1 1.5 0.4c0.5 0.2 0.8 0.6 1.1 1 0.3 0.5 0.4 1 0.4 1.6s-0.1 1.1-0.4 1.6-0.6 0.8-1.1 1.1-1 0.4-1.6 0.4-1-0.2-1.5-0.5zm2.7-0.6c0.3-0.2 0.6-0.5 0.8-0.8s0.3-0.7 0.3-1.2-0.1-0.8-0.3-1.2c-0.1-0.3-0.4-0.6-0.8-0.8-0.3-0.2-0.7-0.3-1.1-0.3s-0.8 0.1-1.2 0.3c-0.3 0.2-0.6 0.5-0.8 0.8-0.1 0.4-0.2 0.8-0.2 1.2s0.1 0.8 0.3 1.2 0.5 0.6 0.8 0.8 0.7 0.3 1.2 0.3c0.3 0 0.7-0.1 1-0.3z"/>
                <path d="m9.4 12.8c-0.1-0.1-0.1-0.2-0.1-0.3v-5c0-0.1 0-0.2 0.1-0.3s0.2-0.1 0.3-0.1h1.8c0.3 0 0.6 0.1 0.9 0.2s0.5 0.3 0.6 0.6c0.1 0.2 0.2 0.5 0.2 0.8 0 0.2-0.1 0.5-0.2 0.7s-0.3 0.4-0.5 0.5c0.3 0.1 0.6 0.2 0.8 0.5s0.3 0.5 0.3 0.9c0 0.3-0.1 0.6-0.2 0.9s-0.4 0.5-0.7 0.6c-0.3 0.2-0.6 0.2-1 0.2h-2c-0.1-0.1-0.2-0.1-0.3-0.2zm2.1-3.3c0.3 0 0.5-0.1 0.7-0.2s0.3-0.4 0.3-0.6c0-0.3-0.1-0.5-0.3-0.6s-0.4-0.2-0.7-0.2h-1.4v1.7h1.4zm0.1 2.7c0.4 0 0.6-0.1 0.9-0.3 0.2-0.2 0.3-0.4 0.3-0.8 0-0.3-0.1-0.5-0.3-0.7s-0.5-0.2-0.9-0.2h-1.6v2h1.6z"/>
                <path d="m15.6 12.8c-0.3-0.2-0.5-0.4-0.6-0.6-0.2-0.3-0.2-0.6-0.2-0.9 0-0.1 0-0.2 0.1-0.3s0.2-0.1 0.3-0.1 0.2 0 0.3 0.1 0.1 0.2 0.1 0.3c0 0.3 0.1 0.6 0.3 0.8s0.4 0.3 0.7 0.3 0.5-0.1 0.7-0.3 0.3-0.5 0.3-0.8v-3.8c0-0.1 0-0.2 0.1-0.3s0.2-0.1 0.3-0.1 0.2 0 0.3 0.1 0.1 0.2 0.1 0.3v3.8c0 0.4-0.1 0.7-0.2 0.9-0.2 0.3-0.4 0.5-0.6 0.6-0.3 0.2-0.6 0.2-0.9 0.2-0.5 0-0.8-0.1-1.1-0.2z"/>
</g>
</svg>
    )
}

export default ObjSvg;