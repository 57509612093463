import {NavigationMobile} from "../components/common/navigation/navigationMobile";
import {NavigationBase} from "../components/common/navigation/navigationBase";
import React, {useRef, useState} from "react";
import {Row} from "../components/common/grid/row";
import {Column} from "../components/common/grid/column";
import {Textbox} from "../components/common/inputs/textbox";
import {Dropdown} from "../components/common/inputs/dropdown";
import EuropeanCountries from "../../data-form/european-countries.json";
import EuropeanPhoneNumber from "../../data-form/european-phone-number.json";
import EuropeanTaxNumber from "../../data-form/european-tax-number.json";
import {ButtonBase} from "../components/common/buttons/buttonBase";
import {Footer} from "../components/common/navigation/footer";
import axios from "axios";
import {useTranslation} from "react-i18next";
import astronautConfirmation from "../../style/image/astronaute-confirmation.png";
import setErrorField from "../helper/setErrorField";
import removeInputError from "../helper/removeInputError";
import {minimalSetup} from "@uiw/codemirror-extensions-basic-setup";
import {useSavePosScrollBar} from "../helper/useSavePosScrollBar";
import {useRefreshItemNav} from "../helper/useRefreshItemNav";
import {Helmet} from "react-helmet";

type RegisterData = {
    [key:string]: any;
    lastName: string;
    firstName: string;
    society: string;
    phoneId: string;
    phoneNumber: string;
    email: string;
    password: string;
    confirmPassword: string;
    country: string;
    city: string;
    postalCode: string;
    address: string;
    taxId: string;
    taxValue: string;
    newsletter: boolean;
    usage: boolean;
    lng:string;
}

export const registerId = 'register'

const Register = () => {
    const {} = useSavePosScrollBar(registerId)
    const {} = useRefreshItemNav(registerId)
    const {t, i18n} = useTranslation()
    const dataRegister = useRef<RegisterData>({} as RegisterData);
    const [success, setSuccess] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    let handleChange = (value:any, property : string) => {
        dataRegister.current[property] = value;
    }

    let handleSelectCountry = (item:any) => {
        dataRegister.current.country = item.id;
        console.log(item)
        let taxId = EuropeanTaxNumber.find((tax:any) => {
            console.log('test')
            let country = tax.countries.find((country:any) => {
                return country.id === item.id
            })
            if(country){
                return true
            }
            else
                return false
        })
        if(taxId){
            dataRegister.current.taxId = taxId.id
        }
        else{
            dataRegister.current.taxId = "none"
        }
    }

    let handleSubmit = (e:any) => {
        e.preventDefault();
        dataRegister.current.lng = i18n.language
        axios.post('/register', dataRegister.current, {params:{doNotCatch :true}}).then(res => {
            if(res.status === 200){
                setSuccess(true)
            }
        }).catch(err => {
            removeInputError('.input--error')
            removeInputError('.input-container--error')
            if(err.response.data.errorFields){
                setError(setErrorField(err))
            }
        })
        return false;
    }


    return (
        <>
            <NavigationMobile blue={true}/>
            <NavigationBase blue={true}/>
            <Helmet>
                <title>DDAL - Create Account for Dynamic Data Analysis Language</title>
                <meta name="description" content="Create your account to access DDAL, the ultimate data analysis language" />
            </Helmet>
            <div className='register-container'>
                {success ? <SuccessRegister/> : <><Row customClass="jc-center">
                    <Column min={12} customClass="title-container">
                        <h1><span>{t("register:welcome")}</span></h1>
                        <p>{t("register:subtitle")}</p>
                    </Column>
                </Row>
                    <Row customClass='jc-center'>
                    <Column xl={4} md={10} min={12} customClass="form-container">
                <form onSubmit={handleSubmit} id={"register"}>
                    <div className={"double-textbox"}>
                        <Textbox id="lastName" change={(e) => {handleChange(e.currentTarget.value, "lastName");}}
                                 placeholder={t('input:lastname')}></Textbox>
                        <Textbox divContainerClass={'margin-top-30-sm'} id="firstName" change={(e) => {handleChange(e.currentTarget.value, "firstName");}}
                                 placeholder={t('input:firstname')}></Textbox>
                    </div>
                    <div>
                        <p>{t("register:companyDetails")}</p>
                        <Textbox id="name" change={(e) => {handleChange(e.currentTarget.value, "society");}}
                                 customClass="mb-30" placeholder={t("register:nameSociety")}></Textbox>
                        <div className={"fiscal-container"}>
                            <Textbox  id="taxValue" change={(e) => {handleChange(e.currentTarget.value, "taxValue");}}
                                     placeholder={t("register:taxNumber")}></Textbox>
                        </div>
                        <Textbox id="adress" change={(e) => {handleChange(e.currentTarget.value, "adress");}}
                                 customClass="mb-30" placeholder={t("register:societyAdress")}></Textbox>
                        <div className={"double-textbox"}>
                            <Textbox id="postalCode" change={(e) => {handleChange(e.currentTarget.value, "postalCode");}}
                                     placeholder={t("register:societyZipCode")}></Textbox>
                            <Textbox divContainerClass={'margin-top-30-sm'}  id="city" change={(e) => {handleChange(e.currentTarget.value, "city");}}
                                     placeholder={t("register:societyCity")}></Textbox>
                        </div>
                        <Dropdown activeSearch={true} selected={(item) => {handleSelectCountry(item);}} dataItem={EuropeanCountries}
                                  placeholder={t("register:societyCountry")}></Dropdown>
                    </div>
                    <div>
                        <p>{t("register:contactDetails")}</p>
                        <Textbox id="email" change={(e) => {handleChange(e.currentTarget.value, "email");}}
                                 customClass="mb-30" placeholder={t('input:email')}></Textbox>
                        <Dropdown id={'phoneId'} selected={(item) => {handleChange(item.id, "phoneId");}} customClass="mb-30"
                                  dataItem={EuropeanPhoneNumber} placeholder={t("register:codePhone")} activeSearch={true} ></Dropdown>
                        <Textbox id="phoneNumber" change={(e) => {handleChange(e.currentTarget.value, "phoneNumber");}}
                                 customClass="mb-30" placeholder={t("register:phone")}></Textbox>
                    </div>
                    <div>
                        <p>{t("register:passwordTitle")}</p>
                        <Textbox id="password" change={(e) => {handleChange(e.currentTarget.value, "password");}}
                                 customClass="mb-30" placeholder={t("register:password")} type='password'></Textbox>
                        <Textbox change={(e) => {handleChange(e.currentTarget.value, "confirmPassword");}}
                                 customClass="mb-30" placeholder={t("register:passwordConfirm")} type='password'></Textbox>
                    </div>
                    <div className={'ai-center d-flex mb-10'}>
                        <input onChange={(e)=>handleChange(e.currentTarget.checked, 'newsletter')} id={'remember-checkbox'} type={"checkbox"} className={'checkbox'}/>
                        <label htmlFor={'remember-checkbox'} >{t("register:newsletter")}</label>
                    </div>
                    <div className={'ai-center d-flex mb-20'}>
                        <input onChange={(e)=>handleChange(e.currentTarget.checked, 'usage')} id={'usage-checkbox'} type={"checkbox"} className={'checkbox'}/>
                        <label htmlFor={'usage-checkbox'} >{t("register:accept")} <a>{t("register:cgu")}</a></label>
                    </div>
                    <label className='label-error'>{error}</label>
                    <ButtonBase type={"submit"} labelMethod={handleSubmit} form={"register"} customClass="button-light mt-20">{t("register:continue")}</ButtonBase>
                </form>
                <p className="text-center">{t("register:noAccount")}<a href={'/login'}> {t("register:register")}</a></p>
            </Column>
        </Row></>
}
            </div>
            <Footer/>
        </>
    )
}


const SuccessRegister = () => {
    const {t, i18n} = useTranslation()
    return(
        <div className={'success-container'}>
            <p>{t('register:successMessage')}</p>
            <p>{t('register:successMessageSub')}</p>
            <img src={astronautConfirmation} />
        </div>
    )
}

export {Register}