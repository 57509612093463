import {dracula} from "@uiw/codemirror-theme-dracula";
import {langs} from "@uiw/codemirror-extensions-langs";
import ReactCodeMirror from "@uiw/react-codemirror";

const responseMarker = () => {
    return(
        <div className={'api'}>
            <h1>Response markers</h1>
            <p>Response markers specify to an implementation of DDAL how to format the response.
                DDAL's specification signals that JSON (or similar languages) should mirror the request structure as closely as possible while injecting the results where they fit best.
                This choice was made to allow arbitrary data to be transferred back and forth, and to ease up front-end development by delegating the storage of temporary data to the back-end.
                Quick example:</p>
            <ReactCodeMirror theme={dracula} value={`response_markers = dic
                {
                    present_property = 0 -> present,
                    absent_property = 'example' -> absent,

                    present_implicit_property = true
                }`} extensions={[langs.javascript()]} />
            <p>The response markers can be omitted, meaning <code>{`present`}</code> by default. The above example will look like:</p>
            <ReactCodeMirror theme={dracula} value={`            {
                "response_markers": 
                {
                    "present_property": 0,
                    "present_implicit_property": true
                }
            }`} extensions={[langs.javascript()]} />
        </div>
    )
}

export default responseMarker