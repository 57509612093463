import {useSearchParams} from "react-router-dom";
import {NavigationMobile} from "../components/common/navigation/navigationMobile";
import {NavigationBase} from "../components/common/navigation/navigationBase";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {Footer} from "../components/common/navigation/footer";
import astronautConfirmation from "../../style/image/astronaute-confirmation.png";
import {ButtonBase} from "../components/common/buttons/buttonBase";
import {Textbox} from "../components/common/inputs/textbox";
import {useSavePosScrollBar} from "../helper/useSavePosScrollBar";

const MailValidation = () => {
    const {} = useSavePosScrollBar('mailValidation')
    const [searchParams, setSearchParams] = useSearchParams()
    const [id, setId] = useState<string | null>()
    const [success, setSuccess] = useState<boolean>(false)
    const [codeError, setCodeError] = useState<any>(null)

    useEffect(() => {
        if (searchParams.get('id') === undefined || searchParams.get('id') === null)
            return
        setId(searchParams.get('id'))
    }, [searchParams])

    useEffect(() => {
        if (id === undefined || id === null)
            return
        axios.get(`/validateMail?token=${id}`,{params:{doNotCatch :true}}).then(res => {
            if(res.status === 200){
                setSuccess(true)
            }
            else {
                setCodeError(res.data)
            }
        }).catch(err => {
            console.log(err.response.data.status)
            setCodeError(err.response.data)
            setSuccess(false)
        })
    },[id])

    return(
        <>
            <NavigationMobile blue={true}/>
            <NavigationBase blue={true}/>
            <div className='validation-mail-container'>
                {success?<MailValidationSuccess/>:<MailValidationError code={codeError} token={id}/>}
            </div>
            <Footer/>
        </>
    )

}

const MailValidationSuccess = () => {
    return(
        <div className={'success-container'}>
            <p>Votre compte a bien été validé</p>
            <img src={astronautConfirmation} />
        </div>
    )
}

type MailValidationErrorProps = {
    code:any;
    token?:string | null;
}

const MailValidationError = ({code,token}:MailValidationErrorProps) => {
    const [email, setEmail] = useState<string>('')
    let handleClick = () => {
        axios.post('/regenMailToken', {token:token, email:email},{params:{doNotCatch :true}}).then(res => {
            if(res.status === 200){

            }
        }).catch(err => {

        })
    }

    return(
        <div className={'success-container'}>
            <p>{code?.status != 377 ? 'Le lien a expiré': 'Code invalide'}</p>
            {code?.status != 377 ?
                <>
                    <Textbox customClass={'mb-30'} placeholder={'email'} change={(e)=> setEmail(e.currentTarget.value)}/>
                    <ButtonBase onClick={handleClick} customClass="button-light">Renvoyez un lien de validation</ButtonBase>
                </>
                : null}
            <img src={astronautConfirmation} />
        </div>
    )
}

export default MailValidation