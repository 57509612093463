import {SvgProps} from "./arrowSVG";

const GpsSvg = ({...props} : SvgProps) => {
    return(
        <svg {...props} version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 28 28" enableBackground="new 0 0 28 28" xmlSpace="preserve">
<g id="Groupe_18" transform="translate(-676 -4212)">
	<g id="Ellipse_5" transform="translate(676 4212)">
		<circle fill="none" cx="14" cy="14" r="14"/>
        <path fill="#2C358B" d="M14,28C6.3,28,0,21.7,0,14S6.3,0,14,0s14,6.3,14,14S21.7,28,14,28z M14,2C7.4,2,2,7.4,2,14s5.4,12,12,12
			s12-5.4,12-12S20.6,2,14,2z"/>
	</g>
    <g id="pin" transform="translate(685.121 4218.715)">
		<path id="Tracé_168" fill="#2C358B" d="M4.9,0.3c1.3,0,2.5,0.5,3.5,1.4c0.9,0.9,1.5,2.2,1.4,3.4c0,0.8-0.2,1.6-0.5,2.3
			C8.9,8.4,8.5,9.2,8,10c-0.5,0.8-1,1.5-1.4,2.1c-0.5,0.7-0.9,1.2-1.2,1.6l-0.5,0.6c-0.1-0.1-0.3-0.3-0.5-0.6s-0.6-0.8-1.2-1.5
			c-0.5-0.8-1-1.5-1.5-2.2C1.2,9.2,0.8,8.4,0.5,7.5C0.2,6.8,0,6,0,5.2c0-1.3,0.5-2.5,1.4-3.4C2.3,0.8,3.6,0.3,4.9,0.3z M4.9,6.9
			c0.5,0,0.9-0.2,1.2-0.5c0.7-0.7,0.7-1.7,0-2.4c0,0,0,0,0,0C5.8,3.6,5.3,3.5,4.9,3.5c-0.9,0-1.7,0.8-1.7,1.7c0,0,0,0,0,0
			C3.1,6.1,3.9,6.9,4.9,6.9L4.9,6.9L4.9,6.9z"/>
	</g>
</g>
</svg>
    )
}

export default GpsSvg;