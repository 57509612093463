import {Row} from "../grid/row";
import {Column} from "../grid/column";
import LogoSvg from "../../../../style/svg/logo/logoSvg";
import LinkedinSvg from "../../../../style/svg/linkedinSvg";
import ImplexLogoSvg from "../../../../style/svg/implexLogoSvg";
import GpsSvg from "../../../../style/svg/gpsSvg";
import TelephoneSvg from "../../../../style/svg/telephoneSvg";
import MailSvg from "../../../../style/svg/mailSvg";
import React, {ReactNode, useContext} from "react";
import {NavContext, NavContextType} from "../../../../App";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

type FooterProps = {
    preLaunch?:boolean;
}
const Footer= ({preLaunch}:FooterProps) => {
    const {t, i18n} = useTranslation()
    const {navItemsNavigationBase, setNavItemsNavigationBase} = useContext(NavContext) as NavContextType;
    return(
        <footer>
        <Row customClass={`container-principal-footer ${preLaunch ? 'preLaunch-padding' : ''}`}>
            <Column customClass={'column-logo ai-center'} min={12} xl={2} lg={2} md={12}>
                <LogoSvg className={"logo-ddal"} blue={true}/>
                <p className={'p-under-logo'}>{t("prelaunch:forDDAL")} <span>«Dynamic Data Analysis Language»</span> {t("prelaunch:firstBlockParagraph1")}</p>
                <a target={'_blank'} href={'https://www.linkedin.com/showcase/dynamic-data-analysis-language/'}>
                    <LinkedinSvg style={{height:"30px", marginBottom:"30px"}}/>
                </a>
            </Column>
            <Column customClass={'nav-footer'} xl={2} lg={2} md={3}>
                <div style={{margin:"0 auto", width:'fit-content'}}>
                    <p className={'p-footer'} >Liens utiles</p>
                    <a className={'mt-10'} href={'https://www.implex.fr/telechargements/politique_confidentialite_implex.pdf'} target={'_blank'}>{t("footer:confidentiality")}</a>
                    <a className={'mt-10'} href={i18n.language == "en" ? 'https://www.implex.fr/telechargements/CGV-DDAL-IMPLEX-Siteweb-EN.pdf' : 'https://www.implex.fr/telechargements/CGV-DDAL-IMPLEX-Siteweb.pdf'} target={'_blank'}>{t("footer:cvg")}</a>
                    <a className={'mt-10'} href={'https://www.implex.fr/telechargements/Implex_Qualicy_Policy.pdf'} target={'_blank'}>{t("footer:qualityPolitic")}</a>
                    <a className={'mt-10'} href={i18n.language == "en" ? 'https://www.implex.fr/telechargements/Contrat_de_licence_Dynamic_Data_Analysis_Language_-_CC_by_4.0-EN.pdf' : 'https://www.implex.fr/telechargements/Contrat_de_licence_Dynamic_Data_Analysis_Language_-_CC_by_4.0.pdf'} target={'_blank'}>{t("footer:licence")} DDAL</a>
                    <a className={'mt-10'} href={'https://www.implex.fr/telechargements/Contrat_de_licence_Dynamic_Data_Analysis_Language_EF.pdf'} target={'_blank'}>{t("footer:licence")} DDAL/EF</a>
                </div>
            </Column>
            <Column customClass={preLaunch ? 'nav-footer nav-footer--preLaunch' : 'nav-footer'} min={12} xl={2} lg={2} md={3}>
                <div style={{width:'fit-content'}}>
                    <p className={'p-footer'} >{t('footer:plan')}</p>
                    {
                        navItemsNavigationBase.map((item, index) => {
                            if(index >= navItemsNavigationBase.length - 1) {
                                return null
                            }
                            return(
                                item.isVisible&& <Link className={`item ${item.isActive ? "item--active" : ""}`} onMouseDown={()=> {item.isActive = true;}} to={item.link} key={index}>{item.label}</Link>
                            )
                        })
                    }
                    {window.localStorage.getItem('token') ? <Link onMouseDown={()=> {navItemsNavigationBase[navItemsNavigationBase.length-1].isActive = true;}} to={'/customer'}>{t('navigation:customerArea')}</Link> : <Link onMouseDown={()=> {navItemsNavigationBase[navItemsNavigationBase.length-1].isActive = true;}} to={navItemsNavigationBase[navItemsNavigationBase.length-1].link}>{navItemsNavigationBase[navItemsNavigationBase.length-1].label}</Link>}
                </div>
            </Column>
            <Column customClass={'column-information-contact'} min={12} xl={2} lg={2} md={6}>
                <p className={'p-footer'} >{t('footer:information')}</p>
                <a target={'_blank'} href={'https://www.implex.fr'}><ImplexLogoSvg style={{height:'50px'}}/></a>
                <LineWithIconSvg location={true} mail={false} tel={false} icon={<GpsSvg style={{height:'30px'}}/>}>
                    39 Chemin du Moulin Carron <br/> 69570 DARDILLY
                </LineWithIconSvg>
                <LineWithIconSvg tel={true} mail={false} location={false} icon={<TelephoneSvg style={{height:'30px'}}/>}>
                    +33472180990
                </LineWithIconSvg>
                <LineWithIconSvg mail={true} tel={false} location={false} icon={<MailSvg style={{height:'30px'}}/>}>
                    commercial@implex.fr
                </LineWithIconSvg>
            </Column>
        </Row>
        </footer>
    )
}

type LineWithIconSvgProps = {
    icon: any,
    children: ReactNode,
    mail?:boolean,
    tel?:boolean,
    location?:boolean
}
const LineWithIconSvg = ({icon, children, mail, tel, location}:LineWithIconSvgProps) => {
    return(
        <a href={mail ? 'mailto:commercial@implex.fr' : tel ? 'tel:+33472180990' : location ? "https://goo.gl/maps/wWGJrGzBcEo3iiQW9" : ""} target={location ? "_blank" : ""} className={'d-flex ai-center container-p-svg'}>
            {icon}
            <p className={'ml-10'}>{children}</p>
        </a>
    )
}

export {Footer};