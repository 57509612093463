import {dracula} from "@uiw/codemirror-theme-dracula";
import {langs} from "@uiw/codemirror-extensions-langs";
import ReactCodeMirror from "@uiw/react-codemirror";

const Examples = () => {
    return (
        <div className={'api'}>
            <h1>Examples</h1>
<p>Here are some examples of DDAL/EF analyses with the sample model.
These are especially made to discover DDAL/EF in conjunction with a test instance.
For a more extensive documentation, check these pages:</p>
<ul>
<li><a href="/api/DDAL/introduction">Introduction to the language specification of DDAL</a></li>
<li><a href="/api/DDALEF/introduction">Introduction to the workings of DDAL/EF</a></li>
</ul>
<p><strong>Note that those examples use association entities for N-N relationships</strong>.
This is because they are especially tailored for use with the public test instance on <a href="https://ddal.io">https://ddal.io</a>.</p>
<h2>Count the number of products for each store</h2>
            <ReactCodeMirror theme={dracula} value={`test = cross analysis
{
    main = part
    {
        root = Store,
        groupby /= Name,
        aggregation /= count(Products)
    }
}`} extensions={[langs.javascript()]} />
<h2>List the stores and their owners using a cartesian projection</h2>
            <ReactCodeMirror theme={dracula} value={`test = listing analysis
{
    root = Store,
    cartesian = yes,

    targets = dic
    {
        store_name /= Name,
        owner_name /= Owner.FullName
    }
}`} extensions={[langs.javascript()]} />
<h2>List the stores with their products incl. expiration date on a single line</h2>
            <ReactCodeMirror theme={dracula} value={`test = listing analysis
{
    root = Store,
    cartesian = yes,

    targets = dic
    {
        store_names /= Name,
        product_name /= Products.Product.Name,
        product_expiry /= Products.Product.ExpirationDate
    },

    coalesce = dic
    {
        products /= Products
    }
}`} extensions={[langs.javascript()]} />
<h2>Count the number of non-expired products for stores</h2>
            <ReactCodeMirror theme={dracula} value={`test = cross analysis
{
    main = part
    {
        root = Store,
        groupby /= Name,
        aggregation /= count(Products["ExpirationDate > TODAY"])
    }
}`} extensions={[langs.javascript()]} />
<h2>Get the average age of owners owning a store selling unexpired potatoes</h2>
            <ReactCodeMirror theme={dracula} value={`test = cross analysis
{
    main = part
    {
        root = Store,
        filter %= "Products:any["Product.ExpirationDate > TODAY"].Name = 'Potatoes'",
        aggregation /= avg(Owner.Age)
    }
}`} extensions={[langs.javascript()]} />
<p>Note two things:</p>
<ol>
<li>I took <em>Store</em> as root entity because it's the most "central" entity.
We could have gone from any other entity though, namely <em>Owner</em>.</li>
<li>The "any" collection specifier in the filter could have been omitted as it's the default one. This would look like:
<code>{`Products["Product.ExpirationDate > TODAY"].Name = 'Potatoes'`}</code></li>
</ol>
<p>As a remainder, we have to specify "Product" because <em>Products</em> is a collection of association entities (ie <em>StoreProduct</em>).</p>
<h2>Group products by their expiration year</h2>
            <ReactCodeMirror theme={dracula} value={`test = cross analysis
{
    period = years,

    main = part
{
    root = Product,
    groupby /= ExpirationDate,
    aggregation /= count()
}
}`} extensions={[langs.javascript()]} />
        </div>
    )
}

export default Examples;