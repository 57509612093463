const BoolSvg = () =>{
    return(
        <svg enableBackground="new 0 0 20 20" version="1.1" viewBox="0 0 20 20" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg">
<path d="M17.5,20h-15C1.1,20,0,18.9,0,17.5v-15C0,1.1,1.1,0,2.5,0h15C18.9,0,20,1.1,20,2.5v15  C20,18.9,18.9,20,17.5,20z" fill="#C65555"/>
            <text transform="translate(-25.293 12.934)" fill="#FFFFFF" fontFamily="'Comfortaa-Bold'" fontSize="8px" stroke="#FFFFFF" strokeMiterlimit="10" strokeWidth=".75">STR</text>
            <g fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".4954">
<path d="m1.5 12c-0.1-0.1-0.1-0.1-0.1-0.2v-3.6c0-0.1 0-0.1 0.1-0.2 0.1 0 0.2-0.1 0.2-0.1h1.3c0.2 0 0.4 0 0.6 0.1 0.2 0.2 0.4 0.3 0.5 0.5 0.1 0.1 0.1 0.3 0.1 0.5s0 0.3-0.1 0.5c-0.1 0.1-0.2 0.2-0.4 0.3l0.6 0.3c0.1 0.2 0.2 0.4 0.2 0.6s-0.1 0.5-0.2 0.6-0.3 0.5-0.5 0.6-0.4 0.2-0.7 0.2h-1.4s-0.1-0.1-0.2-0.1zm1.5-2.4c0.2 0 0.4-0.1 0.5-0.2 0.1 0 0.2-0.2 0.2-0.4s-0.1-0.3-0.2-0.4-0.3-0.2-0.5-0.2h-1v1.2h1zm0.1 2c0.2 0 0.4-0.1 0.6-0.2s0.2-0.3 0.2-0.5-0.1-0.4-0.2-0.5-0.3-0.2-0.6-0.2h-1.1v1.4h1.1z"/>
                <path d="m6.3 11.8c-0.3-0.2-0.6-0.4-0.7-0.8-0.2-0.3-0.3-0.7-0.3-1.1s0.1-0.8 0.3-1.1c0.2-0.2 0.4-0.5 0.7-0.6 0.3-0.2 0.7-0.3 1.1-0.3s0.8 0.1 1.1 0.3 0.6 0.4 0.7 0.8 0.3 0.6 0.3 1-0.1 0.8-0.3 1.1-0.4 0.6-0.7 0.8-0.7 0.3-1.1 0.3c-0.4-0.1-0.8-0.2-1.1-0.4zm1.9-0.4c0.2-0.1 0.4-0.3 0.6-0.6s0.2-0.5 0.2-0.8-0.1-0.6-0.2-0.8-0.3-0.5-0.6-0.6c-0.2-0.1-0.5-0.2-0.8-0.2s-0.6 0.1-0.8 0.2-0.4 0.3-0.5 0.6c-0.2 0.2-0.3 0.5-0.3 0.8s0.1 0.6 0.2 0.8 0.3 0.4 0.6 0.6c0.2 0.1 0.5 0.2 0.8 0.2s0.6-0.1 0.8-0.2z"/>
                <path d="m11.4 11.8c-0.3-0.2-0.6-0.4-0.7-0.8-0.2-0.3-0.3-0.7-0.3-1.1s0.1-0.8 0.3-1.1 0.4-0.6 0.7-0.8 0.7-0.3 1.1-0.3 0.8 0.1 1.1 0.3 0.6 0.4 0.7 0.8c0.2 0.3 0.3 0.7 0.3 1.1s-0.1 0.8-0.3 1.1-0.4 0.6-0.7 0.8-0.7 0.3-1.1 0.3-0.8-0.1-1.1-0.3zm1.9-0.4c0.2-0.1 0.4-0.3 0.6-0.6s0.2-0.5 0.2-0.8-0.1-0.6-0.2-0.8-0.3-0.4-0.6-0.6c-0.2-0.1-0.5-0.2-0.8-0.2s-0.6 0.1-0.8 0.2-0.4 0.3-0.6 0.6c-0.1 0.2-0.2 0.5-0.2 0.8s0.1 0.6 0.2 0.8 0.3 0.4 0.6 0.6c0.2 0.1 0.5 0.2 0.8 0.2s0.6-0.1 0.8-0.2z"/>
                <path d="m15.9 12c-0.1-0.1-0.1-0.1-0.1-0.2v-3.6c0-0.1 0-0.1 0.1-0.2 0 0 0.1-0.1 0.2-0.1s0.1 0 0.2 0.1 0.1 0.1 0.1 0.2v3.3h1.9c0.1 0 0.1 0 0.2 0.1s0.1 0.1 0.1 0.2 0 0.1-0.1 0.2-0.1 0.1-0.2 0.1h-2.2c-0.1 0-0.2-0.1-0.2-0.1z"/>
</g>
</svg>
    )
}

export  default BoolSvg;