const CircleArrow =  () => {
    return (
        <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 94.4 94.4" enableBackground="new 0 0 94.4 94.4" xmlSpace="preserve">
<path fill="#5C83CE" d="M85.9,43.3L67.3,32.6c-2.7-1.6-6.1,0.4-6.1,3.5v6.3H10.4c-2.5,0-4.5,2-4.5,4.5c0,2.5,2,4.5,4.5,4.5h50.8v6.3
	c0,3.1,3.4,5.1,6.1,3.5l18.6-10.7C88.6,48.8,88.6,44.8,85.9,43.3z"/>
</svg>
    )
}

export default CircleArrow