
export type LogoSvgProps = {
    blue: Boolean;
    [propName: string]: any;
}
const LogoSvg = ({blue,...props}:LogoSvgProps) =>{
    return(
        <svg {...props} version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 337.5 105.4" enableBackground="new 0 0 337.5 105.4" xmlSpace="preserve">
<g>
	<g>
		<g>
			<path fill={blue ? "#2D358C" : "#FFFFFF"} d="M322.4,78c0.3,0,0.5,0,0.8,0h-1.6C321.8,78,322.1,78,322.4,78z"/>
            <path fill={blue ? "#2D358C" : "#FFFFFF"} d="M297.7,53.3c0,0.3,0,0.5,0,0.8v-1.6C297.7,52.8,297.7,53.1,297.7,53.3z"/>
            <path fill={blue ? "#2D358C" : "#FFFFFF"} d="M322.4,68.1"/>
            <path fill={blue ? "#2D358C" : "#FFFFFF"} d="M322.4,68.1c-7.9,0-14.3-6.2-14.7-13.9h0V33.5v-8.3c0-1.7-1.3-3-3-3h-3.9c-1.7,0-3,1.3-3,3v27.3v1.6
				c0.2,5.2,1.9,10,4.9,14c1.4,1.9,3.1,3.6,5,5c3.9,2.9,8.7,4.7,13.9,4.9h1.6h11.3c1.7,0,3-1.3,3-3v-3.9c0-1.7-1.3-3-3-3L322.4,68.1
				z"/>
		</g>
        <g>
			<path fill={blue ? "#2D358C" : "#FFFFFF"} d="M119.9,87.1h2.6c0.7,0,1.2,0.1,1.7,0.2s0.9,0.4,1.3,0.7c1,0.9,1.5,2.2,1.5,4c0,0.6-0.1,1.1-0.2,1.6
				c-0.1,0.5-0.3,0.9-0.5,1.3c-0.2,0.4-0.5,0.7-0.8,1c-0.3,0.2-0.6,0.4-0.9,0.6s-0.6,0.2-1,0.3c-0.4,0.1-0.8,0.1-1.2,0.1h-2.6
				c-0.4,0-0.6-0.1-0.8-0.2s-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5-0.1-0.8v-7.2c0-0.4,0.1-0.7,0.3-0.9
				C119.1,87.2,119.4,87.1,119.9,87.1z M120.6,88.7v6.6h1.5c0.3,0,0.6,0,0.8,0s0.4-0.1,0.6-0.1c0.2-0.1,0.4-0.2,0.5-0.3
				c0.7-0.6,1-1.5,1-2.9c0-1-0.1-1.7-0.4-2.2s-0.7-0.8-1.1-0.9c-0.4-0.1-0.9-0.2-1.5-0.2H120.6z"/>
            <path fill={blue ? "#2D358C" : "#FFFFFF"} d="M130.3,97.3l0.2-0.4l-2.2-5.7c-0.1-0.3-0.2-0.6-0.2-0.7c0-0.2,0-0.3,0.1-0.4c0.1-0.1,0.2-0.2,0.3-0.3
				c0.1-0.1,0.3-0.1,0.4-0.1c0.3,0,0.5,0.1,0.6,0.3c0.1,0.2,0.3,0.4,0.4,0.7l1.5,4.5l1.5-4.2c0.1-0.3,0.2-0.6,0.3-0.8
				s0.2-0.3,0.3-0.4c0.1-0.1,0.3-0.1,0.4-0.1c0.1,0,0.3,0,0.4,0.1c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.1,0.3,0.1,0.4
				c0,0.1,0,0.2-0.1,0.4s-0.1,0.3-0.1,0.5l-2.4,6.2c-0.2,0.5-0.4,1-0.6,1.3c-0.2,0.3-0.5,0.6-0.8,0.7c-0.3,0.2-0.8,0.3-1.3,0.3
				c-0.5,0-0.9-0.1-1.2-0.2c-0.3-0.1-0.4-0.3-0.4-0.6c0-0.2,0.1-0.4,0.2-0.5c0.1-0.1,0.3-0.2,0.6-0.2c0.1,0,0.2,0,0.3,0
				c0.1,0,0.2,0,0.3,0c0.2,0,0.4,0,0.5-0.1s0.2-0.2,0.3-0.3C130.1,97.8,130.2,97.6,130.3,97.3z"/>
            <path fill={blue ? "#2D358C" : "#FFFFFF"} d="M137.7,90.7v0.2c0.3-0.4,0.7-0.7,1.1-0.9c0.4-0.2,0.8-0.3,1.3-0.3c0.5,0,0.9,0.1,1.3,0.3s0.7,0.5,0.8,0.9
				c0.1,0.2,0.2,0.5,0.2,0.7c0,0.3,0.1,0.6,0.1,1V96c0,0.4-0.1,0.6-0.3,0.8s-0.4,0.3-0.7,0.3c-0.3,0-0.5-0.1-0.7-0.3
				s-0.3-0.5-0.3-0.8v-3c0-0.6-0.1-1.1-0.3-1.4c-0.2-0.3-0.5-0.5-1-0.5c-0.3,0-0.6,0.1-0.9,0.3c-0.3,0.2-0.5,0.5-0.6,0.8
				c-0.1,0.3-0.1,0.8-0.1,1.5V96c0,0.4-0.1,0.6-0.3,0.8c-0.2,0.2-0.4,0.3-0.7,0.3c-0.3,0-0.5-0.1-0.6-0.3S136,96.3,136,96v-5.3
				c0-0.3,0.1-0.6,0.2-0.8c0.2-0.2,0.4-0.3,0.6-0.3c0.2,0,0.3,0,0.4,0.1s0.2,0.2,0.3,0.3S137.7,90.5,137.7,90.7z"/>
            <path fill={blue ? "#2D358C" : "#FFFFFF"} d="M148.9,96c-0.4,0.3-0.9,0.6-1.3,0.8c-0.4,0.2-0.9,0.3-1.4,0.3c-0.5,0-0.9-0.1-1.2-0.3
				c-0.4-0.2-0.6-0.4-0.8-0.8c-0.2-0.3-0.3-0.7-0.3-1c0-0.5,0.2-0.9,0.5-1.3c0.3-0.4,0.8-0.6,1.3-0.7c0.1,0,0.4-0.1,0.9-0.2
				s0.9-0.2,1.2-0.3c0.3-0.1,0.7-0.2,1.1-0.3c0-0.5-0.1-0.8-0.3-1.1c-0.2-0.2-0.5-0.3-1.1-0.3c-0.5,0-0.8,0.1-1,0.2
				c-0.2,0.1-0.4,0.3-0.6,0.6c-0.2,0.3-0.3,0.4-0.4,0.5c-0.1,0.1-0.2,0.1-0.4,0.1c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.5
				c0-0.3,0.1-0.6,0.3-0.9c0.2-0.3,0.6-0.6,1.1-0.7c0.5-0.2,1.1-0.3,1.8-0.3c0.8,0,1.4,0.1,1.9,0.3s0.8,0.5,1,0.9
				c0.2,0.4,0.3,1,0.3,1.6c0,0.4,0,0.8,0,1.1s0,0.6,0,1c0,0.3,0.1,0.7,0.2,1.1c0.1,0.4,0.2,0.6,0.2,0.7c0,0.2-0.1,0.4-0.3,0.5
				c-0.2,0.2-0.4,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3C149.3,96.6,149.1,96.4,148.9,96z M148.8,93.4c-0.3,0.1-0.7,0.2-1.2,0.3
				c-0.5,0.1-0.9,0.2-1.1,0.2c-0.2,0.1-0.4,0.2-0.6,0.3c-0.2,0.1-0.3,0.4-0.3,0.6c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.5,0.3,0.8,0.3
				c0.4,0,0.7-0.1,1-0.2c0.3-0.2,0.5-0.4,0.7-0.6c0.2-0.3,0.2-0.7,0.2-1.4V93.4z"/>
            <path fill={blue ? "#2D358C" : "#FFFFFF"} d="M158.5,93.5v2.4c0,0.4-0.1,0.7-0.3,0.9c-0.2,0.2-0.4,0.3-0.7,0.3c-0.3,0-0.5-0.1-0.7-0.3
				s-0.3-0.5-0.3-0.9V93c0-0.5,0-0.8,0-1.1c0-0.3-0.1-0.5-0.3-0.6c-0.1-0.2-0.4-0.2-0.7-0.2c-0.6,0-1,0.2-1.2,0.6
				c-0.2,0.4-0.3,1-0.3,1.8v2.5c0,0.4-0.1,0.7-0.3,0.9c-0.2,0.2-0.4,0.3-0.7,0.3c-0.3,0-0.5-0.1-0.7-0.3c-0.2-0.2-0.3-0.5-0.3-0.9
				v-5.2c0-0.3,0.1-0.6,0.2-0.8c0.2-0.2,0.4-0.3,0.6-0.3c0.2,0,0.5,0.1,0.6,0.3s0.3,0.4,0.3,0.7v0.2c0.3-0.4,0.7-0.7,1-0.8
				c0.4-0.2,0.8-0.3,1.2-0.3c0.5,0,0.9,0.1,1.2,0.3s0.6,0.5,0.8,0.8c0.3-0.4,0.6-0.7,1-0.8s0.7-0.3,1.2-0.3c0.5,0,0.9,0.1,1.3,0.3
				c0.4,0.2,0.6,0.5,0.8,0.8c0.2,0.3,0.2,0.8,0.2,1.6v3.6c0,0.4-0.1,0.7-0.3,0.9c-0.2,0.2-0.4,0.3-0.7,0.3c-0.3,0-0.5-0.1-0.7-0.3
				c-0.2-0.2-0.3-0.5-0.3-0.9v-3.1c0-0.4,0-0.7-0.1-0.9c0-0.2-0.1-0.4-0.3-0.6c-0.1-0.2-0.4-0.2-0.7-0.2c-0.2,0-0.5,0.1-0.7,0.2
				c-0.2,0.1-0.4,0.3-0.5,0.6C158.6,92.2,158.5,92.7,158.5,93.5z"/>
            <path fill={blue ? "#2D358C" : "#FFFFFF"} d="M165.5,88.8c-0.3,0-0.5-0.1-0.7-0.2c-0.2-0.2-0.3-0.4-0.3-0.7c0-0.3,0.1-0.5,0.3-0.6
				c0.2-0.2,0.4-0.3,0.6-0.3c0.2,0,0.4,0.1,0.6,0.2c0.2,0.2,0.3,0.4,0.3,0.7c0,0.3-0.1,0.5-0.3,0.7
				C165.9,88.7,165.7,88.8,165.5,88.8z M166.4,90.7V96c0,0.4-0.1,0.6-0.3,0.8s-0.4,0.3-0.7,0.3c-0.3,0-0.5-0.1-0.7-0.3
				s-0.3-0.5-0.3-0.8v-5.2c0-0.4,0.1-0.6,0.3-0.8c0.2-0.2,0.4-0.3,0.7-0.3c0.3,0,0.5,0.1,0.7,0.3C166.3,90.1,166.4,90.4,166.4,90.7z
				"/>
            <path fill={blue ? "#2D358C" : "#FFFFFF"} d="M174.8,94.8c0,0.2-0.1,0.5-0.2,0.7c-0.1,0.3-0.3,0.5-0.6,0.7s-0.6,0.4-1.1,0.6s-0.9,0.2-1.4,0.2
				c-1.1,0-2-0.3-2.6-1c-0.6-0.7-0.9-1.5-0.9-2.6c0-0.7,0.1-1.4,0.4-2s0.7-1,1.3-1.3s1.2-0.5,2-0.5c0.5,0,0.9,0.1,1.3,0.2
				c0.4,0.1,0.7,0.3,1,0.5c0.3,0.2,0.5,0.5,0.6,0.7c0.1,0.2,0.2,0.5,0.2,0.7c0,0.2-0.1,0.4-0.2,0.6c-0.2,0.2-0.4,0.2-0.6,0.2
				c-0.2,0-0.3,0-0.4-0.1c-0.1-0.1-0.2-0.2-0.3-0.4c-0.2-0.3-0.5-0.6-0.7-0.8c-0.2-0.2-0.6-0.3-0.9-0.3c-0.5,0-1,0.2-1.3,0.6
				c-0.3,0.4-0.5,1-0.5,1.7c0,0.3,0,0.7,0.1,0.9c0.1,0.3,0.2,0.5,0.4,0.7c0.2,0.2,0.4,0.4,0.6,0.5c0.2,0.1,0.5,0.2,0.7,0.2
				c0.4,0,0.7-0.1,0.9-0.3c0.3-0.2,0.5-0.4,0.7-0.8c0.1-0.2,0.2-0.4,0.4-0.5c0.1-0.1,0.3-0.2,0.5-0.2c0.2,0,0.4,0.1,0.6,0.3
				C174.7,94.4,174.8,94.6,174.8,94.8z"/>
            <path fill={blue ? "#2D358C" : "#FFFFFF"} d="M181.2,87.1h2.6c0.7,0,1.2,0.1,1.7,0.2c0.5,0.1,0.9,0.4,1.3,0.7c1,0.9,1.5,2.2,1.5,4
				c0,0.6-0.1,1.1-0.2,1.6c-0.1,0.5-0.3,0.9-0.5,1.3c-0.2,0.4-0.5,0.7-0.8,1c-0.3,0.2-0.6,0.4-0.9,0.6c-0.3,0.1-0.6,0.2-1,0.3
				c-0.4,0.1-0.8,0.1-1.2,0.1h-2.6c-0.4,0-0.6-0.1-0.8-0.2s-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5-0.1-0.8v-7.2c0-0.4,0.1-0.7,0.3-0.9
				C180.4,87.2,180.7,87.1,181.2,87.1z M181.9,88.7v6.6h1.5c0.3,0,0.6,0,0.8,0c0.2,0,0.4-0.1,0.6-0.1c0.2-0.1,0.4-0.2,0.5-0.3
				c0.7-0.6,1-1.5,1-2.9c0-1-0.1-1.7-0.4-2.2s-0.7-0.8-1.1-0.9c-0.4-0.1-0.9-0.2-1.5-0.2H181.9z"/>
            <path fill={blue ? "#2D358C" : "#FFFFFF"} d="M194.6,96c-0.4,0.3-0.9,0.6-1.3,0.8c-0.4,0.2-0.9,0.3-1.4,0.3c-0.5,0-0.9-0.1-1.2-0.3
				c-0.4-0.2-0.6-0.4-0.8-0.8c-0.2-0.3-0.3-0.7-0.3-1c0-0.5,0.2-0.9,0.5-1.3c0.3-0.4,0.8-0.6,1.3-0.7c0.1,0,0.4-0.1,0.9-0.2
				s0.9-0.2,1.2-0.3c0.3-0.1,0.7-0.2,1.1-0.3c0-0.5-0.1-0.8-0.3-1.1c-0.2-0.2-0.5-0.3-1.1-0.3c-0.5,0-0.8,0.1-1,0.2
				c-0.2,0.1-0.4,0.3-0.6,0.6c-0.2,0.3-0.3,0.4-0.4,0.5s-0.2,0.1-0.4,0.1c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.5
				c0-0.3,0.1-0.6,0.3-0.9c0.2-0.3,0.6-0.6,1.1-0.7c0.5-0.2,1.1-0.3,1.8-0.3c0.8,0,1.4,0.1,1.9,0.3s0.8,0.5,1,0.9
				c0.2,0.4,0.3,1,0.3,1.6c0,0.4,0,0.8,0,1.1s0,0.6,0,1c0,0.3,0.1,0.7,0.2,1.1c0.1,0.4,0.2,0.6,0.2,0.7c0,0.2-0.1,0.4-0.3,0.5
				c-0.2,0.2-0.4,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3S194.8,96.4,194.6,96z M194.4,93.4c-0.3,0.1-0.7,0.2-1.2,0.3
				c-0.5,0.1-0.9,0.2-1.1,0.2c-0.2,0.1-0.4,0.2-0.6,0.3c-0.2,0.1-0.3,0.4-0.3,0.6c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.5,0.3,0.8,0.3
				c0.4,0,0.7-0.1,1-0.2c0.3-0.2,0.5-0.4,0.7-0.6c0.2-0.3,0.2-0.7,0.2-1.4V93.4z"/>
            <path fill={blue ? "#2D358C" : "#FFFFFF"} d="M197.8,89.8h0.2v-1.1c0-0.3,0-0.5,0-0.7c0-0.2,0.1-0.3,0.1-0.4c0.1-0.1,0.2-0.2,0.3-0.3
				c0.1-0.1,0.3-0.1,0.4-0.1c0.2,0,0.4,0.1,0.6,0.3c0.1,0.1,0.2,0.3,0.2,0.4c0,0.2,0.1,0.4,0.1,0.7v1.2h0.7c0.3,0,0.5,0.1,0.6,0.2
				s0.2,0.3,0.2,0.5c0,0.2-0.1,0.4-0.3,0.5c-0.2,0.1-0.5,0.1-0.8,0.1h-0.3v3.4c0,0.3,0,0.5,0,0.7c0,0.2,0.1,0.3,0.2,0.4
				c0.1,0.1,0.2,0.1,0.4,0.1c0.1,0,0.3,0,0.4-0.1c0.2,0,0.3-0.1,0.4-0.1c0.1,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.3,0.2,0.4
				c0,0.3-0.2,0.5-0.5,0.7c-0.3,0.2-0.8,0.2-1.4,0.2c-0.6,0-1-0.1-1.3-0.3s-0.5-0.5-0.6-0.8c-0.1-0.3-0.1-0.8-0.1-1.4v-3.5h-0.2
				c-0.3,0-0.5-0.1-0.6-0.2s-0.2-0.3-0.2-0.5c0-0.2,0.1-0.3,0.2-0.5C197.3,89.9,197.6,89.8,197.8,89.8z"/>
            <path fill={blue ? "#2D358C" : "#FFFFFF"} d="M207.2,96c-0.4,0.3-0.9,0.6-1.3,0.8c-0.4,0.2-0.9,0.3-1.4,0.3c-0.5,0-0.9-0.1-1.2-0.3
				c-0.4-0.2-0.6-0.4-0.8-0.8c-0.2-0.3-0.3-0.7-0.3-1c0-0.5,0.2-0.9,0.5-1.3c0.3-0.4,0.8-0.6,1.3-0.7c0.1,0,0.4-0.1,0.9-0.2
				s0.9-0.2,1.2-0.3c0.3-0.1,0.7-0.2,1.1-0.3c0-0.5-0.1-0.8-0.3-1.1c-0.2-0.2-0.5-0.3-1.1-0.3c-0.5,0-0.8,0.1-1,0.2
				c-0.2,0.1-0.4,0.3-0.6,0.6c-0.2,0.3-0.3,0.4-0.4,0.5c-0.1,0.1-0.2,0.1-0.4,0.1c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.5
				c0-0.3,0.1-0.6,0.3-0.9c0.2-0.3,0.6-0.6,1.1-0.7c0.5-0.2,1.1-0.3,1.8-0.3c0.8,0,1.4,0.1,1.9,0.3s0.8,0.5,1,0.9
				c0.2,0.4,0.3,1,0.3,1.6c0,0.4,0,0.8,0,1.1c0,0.3,0,0.6,0,1c0,0.3,0.1,0.7,0.2,1.1c0.1,0.4,0.2,0.6,0.2,0.7c0,0.2-0.1,0.4-0.3,0.5
				c-0.2,0.2-0.4,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3C207.6,96.6,207.4,96.4,207.2,96z M207.1,93.4c-0.3,0.1-0.7,0.2-1.2,0.3
				c-0.5,0.1-0.9,0.2-1.1,0.2c-0.2,0.1-0.4,0.2-0.6,0.3c-0.2,0.1-0.3,0.4-0.3,0.6c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.5,0.3,0.8,0.3
				c0.4,0,0.7-0.1,1-0.2c0.3-0.2,0.5-0.4,0.7-0.6c0.2-0.3,0.2-0.7,0.2-1.4V93.4z"/>
            <path fill={blue ? "#2D358C" : "#FFFFFF"} d="M220.5,95.8l-0.5-1.2h-4l-0.5,1.3c-0.2,0.5-0.3,0.8-0.5,1c-0.1,0.2-0.3,0.3-0.6,0.3
				c-0.2,0-0.5-0.1-0.7-0.3c-0.2-0.2-0.3-0.4-0.3-0.6c0-0.1,0-0.3,0.1-0.4s0.1-0.3,0.2-0.6l2.5-6.4c0.1-0.2,0.2-0.4,0.3-0.7
				c0.1-0.3,0.2-0.5,0.3-0.6s0.3-0.3,0.4-0.4c0.2-0.1,0.4-0.2,0.7-0.2c0.3,0,0.5,0.1,0.7,0.2c0.2,0.1,0.3,0.2,0.4,0.4
				c0.1,0.2,0.2,0.3,0.3,0.5s0.2,0.4,0.3,0.8l2.6,6.3c0.2,0.5,0.3,0.8,0.3,1c0,0.2-0.1,0.4-0.3,0.6c-0.2,0.2-0.4,0.3-0.7,0.3
				c-0.2,0-0.3,0-0.4-0.1c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.2-0.2-0.2-0.4C220.7,96.1,220.6,96,220.5,95.8z M216.6,93.1h2.9
				l-1.5-4L216.6,93.1z"/>
            <path fill={blue ? "#2D358C" : "#FFFFFF"} d="M225.6,90.7v0.2c0.3-0.4,0.7-0.7,1.1-0.9c0.4-0.2,0.8-0.3,1.3-0.3c0.5,0,0.9,0.1,1.3,0.3s0.7,0.5,0.8,0.9
				c0.1,0.2,0.2,0.5,0.2,0.7c0,0.3,0.1,0.6,0.1,1V96c0,0.4-0.1,0.6-0.3,0.8s-0.4,0.3-0.7,0.3c-0.3,0-0.5-0.1-0.7-0.3
				s-0.3-0.5-0.3-0.8v-3c0-0.6-0.1-1.1-0.3-1.4c-0.2-0.3-0.5-0.5-1-0.5c-0.3,0-0.6,0.1-0.9,0.3c-0.3,0.2-0.5,0.5-0.6,0.8
				c-0.1,0.3-0.1,0.8-0.1,1.5V96c0,0.4-0.1,0.6-0.3,0.8c-0.2,0.2-0.4,0.3-0.7,0.3c-0.3,0-0.5-0.1-0.6-0.3s-0.3-0.5-0.3-0.8v-5.3
				c0-0.3,0.1-0.6,0.2-0.8c0.2-0.2,0.4-0.3,0.6-0.3c0.2,0,0.3,0,0.4,0.1s0.2,0.2,0.3,0.3S225.6,90.5,225.6,90.7z"/>
            <path fill={blue ? "#2D358C" : "#FFFFFF"} d="M236.8,96c-0.4,0.3-0.9,0.6-1.3,0.8c-0.4,0.2-0.9,0.3-1.4,0.3c-0.5,0-0.9-0.1-1.2-0.3
				c-0.4-0.2-0.6-0.4-0.8-0.8c-0.2-0.3-0.3-0.7-0.3-1c0-0.5,0.2-0.9,0.5-1.3c0.3-0.4,0.8-0.6,1.3-0.7c0.1,0,0.4-0.1,0.9-0.2
				s0.9-0.2,1.2-0.3c0.3-0.1,0.7-0.2,1.1-0.3c0-0.5-0.1-0.8-0.3-1.1c-0.2-0.2-0.5-0.3-1.1-0.3c-0.5,0-0.8,0.1-1,0.2
				c-0.2,0.1-0.4,0.3-0.6,0.6c-0.2,0.3-0.3,0.4-0.4,0.5c-0.1,0.1-0.2,0.1-0.4,0.1c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.5
				c0-0.3,0.1-0.6,0.3-0.9c0.2-0.3,0.6-0.6,1.1-0.7c0.5-0.2,1.1-0.3,1.8-0.3c0.8,0,1.4,0.1,1.9,0.3s0.8,0.5,1,0.9
				c0.2,0.4,0.3,1,0.3,1.6c0,0.4,0,0.8,0,1.1c0,0.3,0,0.6,0,1c0,0.3,0.1,0.7,0.2,1.1c0.1,0.4,0.2,0.6,0.2,0.7c0,0.2-0.1,0.4-0.3,0.5
				c-0.2,0.2-0.4,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3C237.2,96.6,237,96.4,236.8,96z M236.7,93.4c-0.3,0.1-0.7,0.2-1.2,0.3
				c-0.5,0.1-0.9,0.2-1.1,0.2c-0.2,0.1-0.4,0.2-0.6,0.3c-0.2,0.1-0.3,0.4-0.3,0.6c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.5,0.3,0.8,0.3
				c0.4,0,0.7-0.1,1-0.2c0.3-0.2,0.5-0.4,0.7-0.6c0.2-0.3,0.2-0.7,0.2-1.4V93.4z"/>
            <path fill={blue ? "#2D358C" : "#FFFFFF"} d="M240.4,96v-7.9c0-0.4,0.1-0.6,0.2-0.8s0.4-0.3,0.7-0.3c0.3,0,0.5,0.1,0.7,0.3s0.3,0.5,0.3,0.8V96
				c0,0.4-0.1,0.6-0.3,0.8c-0.2,0.2-0.4,0.3-0.7,0.3c-0.3,0-0.5-0.1-0.7-0.3S240.4,96.3,240.4,96z"/>
            <path fill={blue ? "#2D358C" : "#FFFFFF"} d="M245.8,97.3l0.2-0.4l-2.2-5.7c-0.1-0.3-0.2-0.6-0.2-0.7c0-0.2,0-0.3,0.1-0.4c0.1-0.1,0.2-0.2,0.3-0.3
				c0.1-0.1,0.3-0.1,0.4-0.1c0.3,0,0.5,0.1,0.6,0.3c0.1,0.2,0.3,0.4,0.4,0.7l1.5,4.5l1.5-4.2c0.1-0.3,0.2-0.6,0.3-0.8
				s0.2-0.3,0.3-0.4c0.1-0.1,0.3-0.1,0.4-0.1c0.1,0,0.3,0,0.4,0.1c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.1,0.3,0.1,0.4
				c0,0.1,0,0.2-0.1,0.4s-0.1,0.3-0.1,0.5l-2.4,6.2c-0.2,0.5-0.4,1-0.6,1.3c-0.2,0.3-0.5,0.6-0.8,0.7c-0.3,0.2-0.8,0.3-1.3,0.3
				c-0.5,0-0.9-0.1-1.2-0.2c-0.3-0.1-0.4-0.3-0.4-0.6c0-0.2,0.1-0.4,0.2-0.5s0.3-0.2,0.6-0.2c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0
				c0.2,0,0.4,0,0.5-0.1s0.2-0.2,0.3-0.3C245.5,97.8,245.6,97.6,245.8,97.3z"/>
            <path fill={blue ? "#2D358C" : "#FFFFFF"} d="M257.2,94.7c0,0.5-0.1,0.9-0.4,1.3c-0.2,0.4-0.6,0.6-1.1,0.8c-0.5,0.2-1.1,0.3-1.7,0.3
				c-0.7,0-1.2-0.1-1.7-0.3c-0.5-0.2-0.8-0.5-1-0.8c-0.2-0.3-0.3-0.6-0.3-0.9c0-0.2,0.1-0.4,0.2-0.5c0.1-0.1,0.3-0.2,0.5-0.2
				c0.2,0,0.3,0,0.4,0.1c0.1,0.1,0.2,0.2,0.3,0.4c0.2,0.3,0.4,0.6,0.7,0.7c0.3,0.2,0.6,0.2,1.1,0.2c0.4,0,0.7-0.1,0.9-0.2
				c0.2-0.2,0.4-0.3,0.4-0.6c0-0.3-0.1-0.6-0.4-0.7s-0.6-0.3-1.2-0.4c-0.6-0.2-1.1-0.3-1.5-0.5s-0.7-0.4-0.9-0.7
				c-0.2-0.3-0.4-0.6-0.4-1c0-0.4,0.1-0.7,0.3-1c0.2-0.3,0.5-0.6,1-0.8c0.4-0.2,0.9-0.3,1.5-0.3c0.5,0,0.9,0,1.3,0.1
				c0.4,0.1,0.7,0.2,0.9,0.4s0.4,0.3,0.6,0.5c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2-0.1,0.4-0.2,0.5c-0.1,0.1-0.3,0.2-0.6,0.2
				c-0.2,0-0.3-0.1-0.5-0.2s-0.3-0.3-0.5-0.5c-0.1-0.2-0.3-0.3-0.5-0.4c-0.2-0.1-0.4-0.2-0.8-0.2c-0.3,0-0.6,0.1-0.8,0.2
				s-0.3,0.3-0.3,0.5c0,0.2,0.1,0.3,0.2,0.5c0.2,0.1,0.4,0.2,0.6,0.3c0.3,0.1,0.6,0.2,1.1,0.3c0.6,0.1,1,0.3,1.4,0.5
				c0.4,0.2,0.6,0.4,0.8,0.7C257.1,94.1,257.2,94.4,257.2,94.7z"/>
            <path fill={blue ? "#2D358C" : "#FFFFFF"} d="M259.6,88.8c-0.3,0-0.5-0.1-0.7-0.2c-0.2-0.2-0.3-0.4-0.3-0.7c0-0.3,0.1-0.5,0.3-0.6
				c0.2-0.2,0.4-0.3,0.6-0.3c0.2,0,0.4,0.1,0.6,0.2c0.2,0.2,0.3,0.4,0.3,0.7c0,0.3-0.1,0.5-0.3,0.7
				C260.1,88.7,259.9,88.8,259.6,88.8z M260.5,90.7V96c0,0.4-0.1,0.6-0.3,0.8s-0.4,0.3-0.7,0.3c-0.3,0-0.5-0.1-0.7-0.3
				s-0.3-0.5-0.3-0.8v-5.2c0-0.4,0.1-0.6,0.3-0.8c0.2-0.2,0.4-0.3,0.7-0.3c0.3,0,0.5,0.1,0.7,0.3C260.5,90.1,260.5,90.4,260.5,90.7z
				"/>
            <path fill={blue ? "#2D358C" : "#FFFFFF"} d="M268.3,94.7c0,0.5-0.1,0.9-0.4,1.3c-0.2,0.4-0.6,0.6-1.1,0.8c-0.5,0.2-1.1,0.3-1.7,0.3
				c-0.7,0-1.2-0.1-1.7-0.3c-0.5-0.2-0.8-0.5-1-0.8c-0.2-0.3-0.3-0.6-0.3-0.9c0-0.2,0.1-0.4,0.2-0.5c0.1-0.1,0.3-0.2,0.5-0.2
				c0.2,0,0.3,0,0.4,0.1c0.1,0.1,0.2,0.2,0.3,0.4c0.2,0.3,0.4,0.6,0.7,0.7c0.3,0.2,0.6,0.2,1.1,0.2c0.4,0,0.7-0.1,0.9-0.2
				c0.2-0.2,0.4-0.3,0.4-0.6c0-0.3-0.1-0.6-0.4-0.7s-0.6-0.3-1.2-0.4c-0.6-0.2-1.1-0.3-1.5-0.5c-0.4-0.2-0.7-0.4-0.9-0.7
				c-0.2-0.3-0.4-0.6-0.4-1c0-0.4,0.1-0.7,0.3-1c0.2-0.3,0.5-0.6,1-0.8c0.4-0.2,0.9-0.3,1.5-0.3c0.5,0,0.9,0,1.3,0.1
				c0.4,0.1,0.7,0.2,0.9,0.4s0.4,0.3,0.6,0.5c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2-0.1,0.4-0.2,0.5c-0.1,0.1-0.3,0.2-0.6,0.2
				c-0.2,0-0.3-0.1-0.5-0.2c-0.1-0.1-0.3-0.3-0.5-0.5c-0.1-0.2-0.3-0.3-0.5-0.4s-0.4-0.2-0.8-0.2c-0.3,0-0.6,0.1-0.8,0.2
				c-0.2,0.1-0.3,0.3-0.3,0.5c0,0.2,0.1,0.3,0.2,0.5c0.2,0.1,0.4,0.2,0.6,0.3s0.6,0.2,1.1,0.3c0.6,0.1,1,0.3,1.4,0.5
				c0.4,0.2,0.6,0.4,0.8,0.7C268.2,94.1,268.3,94.4,268.3,94.7z"/>
            <path fill={blue ? "#2D358C" : "#FFFFFF"} d="M275.3,88.2v7.1h4c0.3,0,0.6,0.1,0.7,0.2s0.3,0.4,0.3,0.6c0,0.2-0.1,0.4-0.3,0.6s-0.4,0.2-0.7,0.2h-4.8
				c-0.4,0-0.7-0.1-0.9-0.3s-0.3-0.5-0.3-0.9v-7.5c0-0.4,0.1-0.7,0.3-0.9c0.2-0.2,0.4-0.3,0.7-0.3c0.3,0,0.5,0.1,0.7,0.3
				C275.2,87.4,275.3,87.7,275.3,88.2z"/>
            <path fill={blue ? "#2D358C" : "#FFFFFF"} d="M286.1,96c-0.4,0.3-0.9,0.6-1.3,0.8c-0.4,0.2-0.9,0.3-1.4,0.3c-0.5,0-0.9-0.1-1.2-0.3
				c-0.4-0.2-0.6-0.4-0.8-0.8c-0.2-0.3-0.3-0.7-0.3-1c0-0.5,0.2-0.9,0.5-1.3c0.3-0.4,0.8-0.6,1.3-0.7c0.1,0,0.4-0.1,0.9-0.2
				c0.5-0.1,0.9-0.2,1.2-0.3c0.3-0.1,0.7-0.2,1.1-0.3c0-0.5-0.1-0.8-0.3-1.1c-0.2-0.2-0.5-0.3-1.1-0.3c-0.5,0-0.8,0.1-1,0.2
				s-0.4,0.3-0.6,0.6c-0.2,0.3-0.3,0.4-0.4,0.5s-0.2,0.1-0.4,0.1c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.5
				c0-0.3,0.1-0.6,0.3-0.9c0.2-0.3,0.6-0.6,1.1-0.7s1.1-0.3,1.8-0.3c0.8,0,1.4,0.1,1.9,0.3c0.5,0.2,0.8,0.5,1,0.9
				c0.2,0.4,0.3,1,0.3,1.6c0,0.4,0,0.8,0,1.1s0,0.6,0,1c0,0.3,0.1,0.7,0.2,1.1c0.1,0.4,0.2,0.6,0.2,0.7c0,0.2-0.1,0.4-0.3,0.5
				c-0.2,0.2-0.4,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3S286.3,96.4,286.1,96z M286,93.4c-0.3,0.1-0.7,0.2-1.2,0.3
				c-0.5,0.1-0.9,0.2-1.1,0.2c-0.2,0.1-0.4,0.2-0.6,0.3s-0.3,0.4-0.3,0.6c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.5,0.3,0.8,0.3
				c0.4,0,0.7-0.1,1-0.2c0.3-0.2,0.5-0.4,0.7-0.6c0.2-0.3,0.2-0.7,0.2-1.4V93.4z"/>
            <path fill={blue ? "#2D358C" : "#FFFFFF"} d="M291.3,90.7v0.2c0.3-0.4,0.7-0.7,1.1-0.9c0.4-0.2,0.8-0.3,1.3-0.3c0.5,0,0.9,0.1,1.3,0.3
				c0.4,0.2,0.7,0.5,0.8,0.9c0.1,0.2,0.2,0.5,0.2,0.7c0,0.3,0.1,0.6,0.1,1V96c0,0.4-0.1,0.6-0.3,0.8s-0.4,0.3-0.7,0.3
				c-0.3,0-0.5-0.1-0.7-0.3s-0.3-0.5-0.3-0.8v-3c0-0.6-0.1-1.1-0.3-1.4c-0.2-0.3-0.5-0.5-1-0.5c-0.3,0-0.6,0.1-0.9,0.3
				c-0.3,0.2-0.5,0.5-0.6,0.8c-0.1,0.3-0.1,0.8-0.1,1.5V96c0,0.4-0.1,0.6-0.3,0.8c-0.2,0.2-0.4,0.3-0.7,0.3c-0.3,0-0.5-0.1-0.6-0.3
				s-0.3-0.5-0.3-0.8v-5.3c0-0.3,0.1-0.6,0.2-0.8s0.4-0.3,0.6-0.3c0.2,0,0.3,0,0.4,0.1c0.1,0.1,0.2,0.2,0.3,0.3
				S291.3,90.5,291.3,90.7z"/>
            <path fill={blue ? "#2D358C" : "#FFFFFF"} d="M304.6,91v5.3c0,0.6-0.1,1.1-0.2,1.6c-0.1,0.4-0.3,0.8-0.6,1.1c-0.3,0.3-0.7,0.5-1.1,0.6
				c-0.5,0.1-1,0.2-1.7,0.2c-0.6,0-1.2-0.1-1.7-0.3c-0.5-0.2-0.9-0.4-1.1-0.7c-0.3-0.3-0.4-0.6-0.4-0.9c0-0.2,0.1-0.4,0.2-0.5
				c0.2-0.1,0.3-0.2,0.5-0.2c0.3,0,0.5,0.1,0.7,0.4c0.1,0.1,0.2,0.2,0.3,0.4s0.2,0.2,0.3,0.3c0.1,0.1,0.3,0.2,0.5,0.2
				s0.4,0.1,0.6,0.1c0.5,0,0.8-0.1,1.1-0.2s0.4-0.3,0.5-0.6c0.1-0.2,0.2-0.5,0.2-0.8c0-0.3,0-0.7,0-1.3c-0.3,0.4-0.6,0.7-1,0.9
				s-0.8,0.3-1.3,0.3c-0.6,0-1.1-0.2-1.6-0.5c-0.5-0.3-0.8-0.7-1.1-1.3s-0.4-1.2-0.4-1.9c0-0.5,0.1-1,0.2-1.5s0.4-0.8,0.6-1.1
				c0.3-0.3,0.6-0.5,1-0.7c0.4-0.2,0.8-0.2,1.2-0.2c0.5,0,1,0.1,1.3,0.3c0.4,0.2,0.7,0.5,1.1,0.9v-0.2c0-0.3,0.1-0.6,0.2-0.7
				s0.4-0.3,0.6-0.3c0.4,0,0.6,0.1,0.7,0.3C304.6,90.2,304.6,90.6,304.6,91z M299.3,93.3c0,0.7,0.2,1.3,0.5,1.7s0.7,0.6,1.3,0.6
				c0.3,0,0.6-0.1,0.9-0.2s0.5-0.4,0.7-0.7s0.3-0.7,0.3-1.2c0-0.7-0.2-1.3-0.5-1.7s-0.8-0.6-1.3-0.6c-0.5,0-0.9,0.2-1.3,0.6
				C299.5,92,299.3,92.6,299.3,93.3z"/>
            <path fill={blue ? "#2D358C" : "#FFFFFF"} d="M311.2,96v-0.2c-0.2,0.3-0.4,0.5-0.7,0.7c-0.2,0.2-0.5,0.3-0.8,0.4c-0.3,0.1-0.6,0.1-1,0.1
				c-0.4,0-0.8-0.1-1.2-0.3s-0.6-0.4-0.8-0.8c-0.2-0.4-0.3-0.9-0.3-1.7v-3.6c0-0.4,0.1-0.6,0.2-0.8c0.2-0.2,0.4-0.3,0.7-0.3
				c0.3,0,0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.5,0.3,0.8v2.9c0,0.4,0,0.8,0.1,1.1s0.2,0.5,0.4,0.7s0.4,0.2,0.8,0.2c0.3,0,0.6-0.1,0.9-0.3
				c0.3-0.2,0.5-0.4,0.6-0.7c0.1-0.3,0.2-0.8,0.2-1.7v-2.2c0-0.4,0.1-0.6,0.3-0.8c0.2-0.2,0.4-0.3,0.7-0.3s0.5,0.1,0.7,0.3
				c0.2,0.2,0.2,0.5,0.2,0.8V96c0,0.3-0.1,0.6-0.2,0.8s-0.4,0.3-0.6,0.3s-0.5-0.1-0.6-0.3C311.3,96.6,311.2,96.4,311.2,96z"/>
            <path fill={blue ? "#2D358C" : "#FFFFFF"} d="M319.4,96c-0.4,0.3-0.9,0.6-1.3,0.8c-0.4,0.2-0.9,0.3-1.4,0.3c-0.5,0-0.9-0.1-1.2-0.3
				c-0.4-0.2-0.6-0.4-0.8-0.8c-0.2-0.3-0.3-0.7-0.3-1c0-0.5,0.2-0.9,0.5-1.3c0.3-0.4,0.8-0.6,1.3-0.7c0.1,0,0.4-0.1,0.9-0.2
				c0.5-0.1,0.9-0.2,1.2-0.3c0.3-0.1,0.7-0.2,1.1-0.3c0-0.5-0.1-0.8-0.3-1.1c-0.2-0.2-0.5-0.3-1.1-0.3c-0.5,0-0.8,0.1-1,0.2
				s-0.4,0.3-0.6,0.6c-0.2,0.3-0.3,0.4-0.4,0.5s-0.2,0.1-0.4,0.1c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.5
				c0-0.3,0.1-0.6,0.3-0.9c0.2-0.3,0.6-0.6,1.1-0.7c0.5-0.2,1.1-0.3,1.8-0.3c0.8,0,1.4,0.1,1.9,0.3c0.5,0.2,0.8,0.5,1,0.9
				c0.2,0.4,0.3,1,0.3,1.6c0,0.4,0,0.8,0,1.1s0,0.6,0,1c0,0.3,0.1,0.7,0.2,1.1c0.1,0.4,0.2,0.6,0.2,0.7c0,0.2-0.1,0.4-0.3,0.5
				c-0.2,0.2-0.4,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3S319.6,96.4,319.4,96z M319.2,93.4c-0.3,0.1-0.7,0.2-1.2,0.3
				c-0.5,0.1-0.9,0.2-1.1,0.2c-0.2,0.1-0.4,0.2-0.6,0.3s-0.3,0.4-0.3,0.6c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.5,0.3,0.8,0.3
				c0.4,0,0.7-0.1,1-0.2c0.3-0.2,0.5-0.4,0.7-0.6c0.2-0.3,0.2-0.7,0.2-1.4V93.4z"/>
            <path fill={blue ? "#2D358C" : "#FFFFFF"} d="M329.4,91v5.3c0,0.6-0.1,1.1-0.2,1.6s-0.3,0.8-0.6,1.1c-0.3,0.3-0.7,0.5-1.1,0.6c-0.5,0.1-1,0.2-1.7,0.2
				c-0.6,0-1.2-0.1-1.7-0.3c-0.5-0.2-0.9-0.4-1.1-0.7c-0.3-0.3-0.4-0.6-0.4-0.9c0-0.2,0.1-0.4,0.2-0.5c0.2-0.1,0.3-0.2,0.5-0.2
				c0.3,0,0.5,0.1,0.7,0.4c0.1,0.1,0.2,0.2,0.3,0.4s0.2,0.2,0.3,0.3c0.1,0.1,0.3,0.2,0.5,0.2s0.4,0.1,0.6,0.1c0.5,0,0.8-0.1,1.1-0.2
				s0.4-0.3,0.5-0.6c0.1-0.2,0.2-0.5,0.2-0.8s0-0.7,0-1.3c-0.3,0.4-0.6,0.7-1,0.9c-0.4,0.2-0.8,0.3-1.3,0.3c-0.6,0-1.1-0.2-1.6-0.5
				c-0.5-0.3-0.8-0.7-1.1-1.3s-0.4-1.2-0.4-1.9c0-0.5,0.1-1,0.2-1.5c0.1-0.4,0.4-0.8,0.6-1.1c0.3-0.3,0.6-0.5,1-0.7s0.8-0.2,1.2-0.2
				c0.5,0,1,0.1,1.3,0.3c0.4,0.2,0.7,0.5,1.1,0.9v-0.2c0-0.3,0.1-0.6,0.2-0.7s0.4-0.3,0.6-0.3c0.4,0,0.6,0.1,0.7,0.3
				C329.3,90.2,329.4,90.6,329.4,91z M324.1,93.3c0,0.7,0.2,1.3,0.5,1.7c0.3,0.4,0.7,0.6,1.3,0.6c0.3,0,0.6-0.1,0.9-0.2
				c0.3-0.2,0.5-0.4,0.7-0.7s0.3-0.7,0.3-1.2c0-0.7-0.2-1.3-0.5-1.7s-0.8-0.6-1.3-0.6c-0.5,0-0.9,0.2-1.3,0.6
				C324.3,92,324.1,92.6,324.1,93.3z"/>
            <path fill={blue ? "#2D358C" : "#FFFFFF"} d="M336,93.8h-3.6c0,0.4,0.1,0.8,0.3,1.1s0.4,0.6,0.6,0.7c0.3,0.2,0.6,0.2,0.9,0.2c0.2,0,0.4,0,0.6-0.1
				c0.2-0.1,0.4-0.1,0.5-0.2c0.2-0.1,0.3-0.2,0.5-0.4c0.1-0.1,0.3-0.3,0.6-0.5c0.1-0.1,0.2-0.1,0.4-0.1c0.2,0,0.3,0.1,0.5,0.2
				c0.1,0.1,0.2,0.2,0.2,0.4c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.3,0.4-0.6,0.6s-0.6,0.4-1,0.5s-0.8,0.2-1.4,0.2c-1.2,0-2.1-0.3-2.7-1
				s-1-1.6-1-2.7c0-0.5,0.1-1,0.2-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.7-0.6,1.1-0.8c0.4-0.2,0.9-0.3,1.5-0.3c0.7,0,1.3,0.2,1.8,0.5
				c0.5,0.3,0.9,0.7,1.1,1.2s0.4,1,0.4,1.5c0,0.5-0.1,0.8-0.4,0.9C336.8,93.7,336.5,93.8,336,93.8z M332.4,92.8h3.3
				c0-0.6-0.2-1.1-0.5-1.4c-0.3-0.3-0.7-0.5-1.2-0.5c-0.5,0-0.8,0.2-1.1,0.5C332.7,91.7,332.5,92.2,332.4,92.8z"/>
		</g>
        <path fill={blue ? "#2D358C" : "#FFFFFF"} d="M140.7,22.3L140.7,22.3l-0.5,0c0,0,0,0,0,0c0,0,0,0,0,0h-18.5c-1.7,0-3,1.3-3,3v3.9c0,1.7,1.3,3,3,3h5.2
			h13.4h0c9.9,0,18,8.1,18,18c0,9.9-8.1,18-18,18h0h-11.6v-6.9c0-1.7-1.3-3-3-3h-3.9c-1.7,0-3,1.3-3,3v6.9V75c0,1.7,1.3,3,3,3h18.5
			c0,0,0,0,0,0c0,0,0,0,0,0h0.5v0c15.1-0.3,27.3-12.6,27.3-27.8S155.8,22.6,140.7,22.3z"/>
        <path fill={blue ? "#2D358C" : "#FFFFFF"} d="M200.5,22.3L200.5,22.3l-0.5,0c0,0,0,0,0,0c0,0,0,0,0,0h-18.5c-1.7,0-3,1.3-3,3v3.9c0,1.7,1.3,3,3,3h5.2
			h13.4h0c9.9,0,18,8.1,18,18c0,9.9-8.1,18-18,18h0h-11.6v-6.9c0-1.7-1.3-3-3-3h-3.9c-1.7,0-3,1.3-3,3v6.9V75c0,1.7,1.3,3,3,3h18.5
			c0,0,0,0,0,0s0,0,0,0h0.5v0c15.1-0.3,27.3-12.6,27.3-27.8S215.6,22.6,200.5,22.3z"/>
        <path fill={blue ? "#2D358C" : "#FFFFFF"} d="M286.4,39.9c-3-10.2-12.5-17.6-23.6-17.6c-11.2,0-20.6,7.4-23.6,17.6c-0.7,2.2-1,4.6-1,7.1V75
			c0,1.7,1.3,3,3,3h3.9c1.7,0,3-1.3,3-3v-6.9h6.9c1.7,0,3-1.3,3-3v-3.9c0-1.7-1.3-3-3-3H248v-11c0-8.1,6.3-14.8,14.4-15
			c8.3-0.2,15.1,6.5,15.1,14.8v19.8V75c0,1.7,1.3,3,3,3h3.9c1.7,0,3-1.3,3-3V46.9C287.4,44.5,287,42.1,286.4,39.9z"/>
	</g>
    <g>
		<path fill={blue ? "#2D358C" : "#FFFFFF"} d="M80.9,23.5C80.9,23.5,80.9,23.5,80.9,23.5C80.9,23.5,80.9,23.5,80.9,23.5c-2.2-3.2-5.1-5.7-8.6-7.4
			c-0.5-8-7-14.9-15-15.9c-7.5-1.1-15.2,3-18.7,9.7c-0.8,1.4-0.1,3.2,1.4,3.8c1.4,0.6,3-0.1,3.6-1.5c0,0,0,0,0,0
			c2.1-5.1,7.7-8.6,13.3-8.2c6.8,0.3,12.6,5.9,13.3,12.8c0,0.4,0.3,0.8,0.7,0.9c2.7,1.2,5.1,3,6.9,5.2H58.4H35.9L23.1,10.2
			c0.4-0.6,0.6-1.3,0.6-2.1c0-2.2-1.8-4-4-4s-4,1.8-4,4c0,2.2,1.8,4,4,4c0.6,0,1.2-0.2,1.8-0.4l13.2,13.2c0.2,0.2,0.5,0.3,0.8,0.3
			H61h19c0.2,0,0.4-0.1,0.6-0.2c0.1-0.1,0.2-0.1,0.2-0.2c0,0,0-0.1,0.1-0.1c0,0,0,0,0,0c0-0.1,0.1-0.1,0.1-0.2c0,0,0,0,0,0
			c0-0.1,0-0.1,0.1-0.2c0,0,0,0,0,0C81.1,24,81.1,23.7,80.9,23.5z M19.7,9.9c-1,0-1.8-0.8-1.8-1.8c0-1,0.8-1.8,1.8-1.8
			c1,0,1.8,0.8,1.8,1.8C21.5,9.1,20.7,9.9,19.7,9.9z"/>
        <path fill={blue ? "#2D358C" : "#FFFFFF"} d="M92.6,80.5c-0.2-0.1-0.4-0.2-0.6-0.2H66.4l-3.2-3.2L46.6,60.6c-0.2-0.2-0.5-0.3-0.8-0.3H31
			c-0.4-1.8-2-3.2-3.9-3.2c-2.2,0-4,1.8-4,4c0,2.2,1.8,4,4,4c1.7,0,3.1-1,3.7-2.5h14.6l16,16l3.8,3.8c0.2,0.2,0.5,0.3,0.8,0.3h24.6
			c-1.1,2.8-2.9,5.3-5.3,7.1c-2.7,2-6.2,3.1-9.6,3c-0.5,0-1.1,0.3-1.4,0.7c-4.1,5.9-11.6,8.8-18.6,7.2
			c-11.8-2.6-17.5-16.5-10.5-26.5c0.3-0.4,0.4-0.8,0.5-1.2c0.3-1.3-0.3-2.7-1.5-3.5c-1.6-1-3.6-0.5-4.6,1.1
			c-1.6,2.6-2.7,5.5-3.2,8.5C34.8,87.8,39,97.3,46.6,102c9.9,6.3,23.3,3.6,29.9-6c3.8-0.3,7.5-1.8,10.4-4.2
			c3.1-2.6,5.3-6.2,6.2-10.1c0,0,0,0,0,0c0-0.1,0-0.2,0-0.2C93.2,81.1,92.9,80.7,92.6,80.5z M27.1,62.8c-1,0-1.8-0.8-1.8-1.8
			c0-1,0.8-1.8,1.8-1.8c1,0,1.8,0.8,1.8,1.8C28.9,62,28.1,62.8,27.1,62.8z"/>
        <path fill={blue ? "#AAC2E6" :"#D7D8D8"} d="M53,20.5c0.1,0.7,0.8,1.3,1.5,1.3c1,0.1,2.5,0.3,4,1.2h19.3c-1.9-2.2-4.3-4-6.9-5.2
			c-0.4-0.2-0.6-0.5-0.7-0.9c-0.7-6.8-6.5-12.4-13.3-12.8c-5.6-0.4-11.2,3-13.3,8.2c1.7,0.1,4,0.6,6,2.4C51.9,16.6,52.7,19,53,20.5z
			"/>
        <path fill={blue ? "#AAC2E6" :"#D7D8D8"} d="M65.2,82.3l-3.8-3.8c-1.2,0.7-2.7,1.2-4.5,1.3c-4.5,0.3-8.9-2.4-11.2-6.9c-0.1,0.4-0.3,0.9-0.5,1.2
			c-7,10-1.3,23.8,10.5,26.5c7,1.6,14.5-1.3,18.6-7.2c0.3-0.5,0.9-0.8,1.4-0.7c3.4,0.1,6.8-1,9.6-3c2.4-1.8,4.3-4.3,5.3-7.1H66
			C65.7,82.6,65.4,82.5,65.2,82.3z"/>
        <path fill={blue ? "#AAC2E6" :"#D7D8D8"} d="M92.2,75.3c-0.5,0-1-0.4-1.1-0.9c0,0,0-0.1,0-0.1c0-0.2-0.1-0.3-0.1-0.5c0-0.1,0-0.1,0-0.2
			c0-0.1-0.1-0.3-0.1-0.4c0-0.1,0-0.1-0.1-0.2c0-0.1-0.1-0.2-0.1-0.4c0-0.1-0.1-0.2-0.1-0.2c0-0.1-0.1-0.2-0.1-0.3
			c0-0.1-0.1-0.2-0.1-0.2c0-0.1-0.1-0.2-0.1-0.3c0-0.1-0.1-0.2-0.1-0.3c0-0.1-0.1-0.2-0.1-0.3c0-0.1-0.1-0.2-0.1-0.3
			c0-0.1-0.1-0.2-0.1-0.3c0-0.1-0.1-0.2-0.1-0.3c0-0.1-0.1-0.2-0.1-0.3c-0.2-0.4-0.1-1,0.2-1.3c0.2-0.2,0.3-0.3,0.5-0.5
			c0.1-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.5-0.5,0.9-1,1.3-1.6c0.3-0.4,0.6-0.8,0.9-1.2c0,0,0,0,0,0
			c0.3-0.5,0.6-1,0.9-1.5c0-0.1,0.1-0.2,0.1-0.2c0.1-0.1,0.1-0.3,0.2-0.4c0.1-0.2,0.2-0.3,0.2-0.5c0.4-0.8,0.7-1.7,0.9-2.6
			c0-0.1,0-0.2,0.1-0.2c0.1-0.5,0.2-0.9,0.3-1.4c0.2-0.8,0.3-1.7,0.3-2.5c0-0.3,0-0.7,0-1c0-0.5,0-1.1-0.1-1.6
			c0-0.4-0.1-0.8-0.1-1.2c-0.1-0.8-0.3-1.5-0.5-2.2c-0.1-0.3-0.2-0.6-0.3-0.9c-0.3-0.9-0.7-1.8-1.1-2.7c0,0,0,0,0-0.1
			c0,0,0-0.1-0.1-0.1c-0.2-0.4-0.4-0.8-0.6-1.2c-0.2-0.3-0.4-0.6-0.5-0.9C92.4,43,92,42.5,91.6,42c-0.2-0.3-0.5-0.6-0.8-0.9
			c-0.1-0.1-0.1-0.2-0.2-0.2c-0.5-0.5-1-1-1.6-1.5c0,0,0,0,0,0c-0.3-0.3-0.7-0.6-1.1-0.8c0,0-0.1-0.1-0.1-0.1
			c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.3-0.2-0.4-0.3c-1.2-0.8-2.6-1.5-4-2c0,0,0,0,0,0c-0.4-0.2-0.7-0.5-0.7-1c0-0.1,0-0.1,0-0.2
			c0-0.2,0-0.3,0-0.5c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.4c0-0.1,0-0.3,0-0.4c0-0.1,0-0.2,0-0.3c0-0.1,0-0.3-0.1-0.4
			c0-0.1,0-0.2,0-0.2h-4h0H65.7h-3.4c-0.5,2.5-1.9,4.9-3.6,7.1c3-2,6.6-2.9,10.1-2.2c0.1,0,0.1,0,0.2,0c5.3,1.1,9.6,5.5,10,11.1
			c0,0.4,0.1,0.9,0.1,1.3c0,3-1.1,6-2.8,8.5c-0.2,0.2-0.4,0.4-0.7,0.4c-0.1,0.2-0.3,0.4-0.5,0.6c-0.6,0.7-0.7,1.6-0.4,2.5
			c0.3,0.9,0.6,2.2,0.6,3.6c-0.2,5-4.2,7.9-4.5,8c-1.6,1.1-3.3,1.5-4.5,1.6c-0.6,0.1-1.2,0.4-1.6,0.8c-0.4,0.4-0.9,0.9-1.5,1.4
			l3.2,3.2h25.6c0.2,0,0.4,0.1,0.6,0.2c0.5-1.9,0.5-3.8,0.2-5.4c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1,0-0.2,0.1
			C92.4,75.3,92.3,75.3,92.2,75.3z"/>
        <path fill={blue ? "#AAC2E6" :"#D7D8D8"} d="M83,30.2c-0.4-1.6-1-3.5-2.1-5.4c-0.1,0.1-0.1,0.1-0.2,0.2c-0.2,0.1-0.4,0.2-0.6,0.2H61
			c1.1,1.6,1.5,3.3,1.5,5H63H83z"/>
        <path fill={blue ? "#2D358C" : "#FFFFFF"} d="M91.9,69.7c4.1-4,6.4-9.6,6.4-15.3c0-8.9-5.5-16.9-13.7-20.1c-0.1-1.1-0.2-2.1-0.4-3.1
			c-0.1-0.5-0.6-0.9-1.1-0.9H83H63h-0.5H40.4c-0.3,0-0.6,0.1-0.8,0.3l-6.7,6.7h-25c-0.3-1.9-1.9-3.3-3.9-3.3c-2.2,0-4,1.8-4,4
			s1.8,4,4,4c1.6,0,3-1,3.6-2.4h25.7c0.3,0,0.6-0.1,0.8-0.3l6.7-6.7h21.4h3.4h12.5h4c0,0.1,0,0.2,0,0.2c0,0.1,0,0.3,0.1,0.4
			c0,0.1,0,0.2,0,0.3c0,0.1,0,0.3,0,0.4c0,0.1,0,0.2,0,0.4c0,0.1,0,0.2,0,0.3c0,0.2,0,0.3,0,0.5c0,0.1,0,0.1,0,0.2
			c0,0.4,0.3,0.8,0.7,1c0,0,0,0,0,0c1.4,0.5,2.8,1.2,4,2c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0,0.1,0.1,0.2,0.1c0,0,0.1,0.1,0.1,0.1
			c0.4,0.3,0.7,0.5,1.1,0.8c0,0,0,0,0,0c0.6,0.5,1.1,1,1.6,1.5c0.1,0.1,0.2,0.2,0.2,0.2c0.3,0.3,0.5,0.6,0.8,0.9
			c0.4,0.5,0.8,1,1.1,1.5c0.2,0.3,0.4,0.6,0.5,0.9c0.2,0.4,0.4,0.8,0.6,1.2c0,0,0,0.1,0.1,0.1c0,0,0,0,0,0.1
			c0.4,0.9,0.8,1.8,1.1,2.7c0.1,0.3,0.2,0.6,0.3,0.9c0.2,0.7,0.4,1.5,0.5,2.2c0.1,0.4,0.1,0.8,0.1,1.2c0,0.5,0.1,1.1,0.1,1.6
			c0,0.3,0,0.7,0,1c0,0.9-0.2,1.7-0.3,2.5c-0.1,0.5-0.2,0.9-0.3,1.4c0,0.1,0,0.2-0.1,0.2c-0.2,0.9-0.6,1.8-0.9,2.6
			c-0.1,0.2-0.1,0.3-0.2,0.5c-0.1,0.1-0.1,0.3-0.2,0.4c0,0.1-0.1,0.2-0.1,0.2c-0.3,0.5-0.6,1-0.9,1.5c0,0,0,0,0,0
			c-0.3,0.4-0.6,0.8-0.9,1.2c-0.4,0.5-0.9,1.1-1.3,1.6c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.1,0.1-0.2,0.2c-0.2,0.2-0.3,0.3-0.5,0.5
			c-0.4,0.3-0.5,0.9-0.2,1.3c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.1,0.3
			c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.1,0.2c0,0.1,0.1,0.2,0.1,0.3
			c0,0.1,0.1,0.2,0.1,0.2c0,0.1,0.1,0.2,0.1,0.4c0,0.1,0,0.1,0.1,0.2c0,0.1,0.1,0.3,0.1,0.4c0,0.1,0,0.1,0,0.2
			c0,0.2,0.1,0.3,0.1,0.5c0,0,0,0.1,0,0.1c0.1,0.5,0.6,0.9,1.1,0.9c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1
			c0.4-0.2,0.6-0.7,0.5-1.1C93,72.5,92.6,71,91.9,69.7z M4,39.6c-1,0-1.8-0.8-1.8-1.8S3,36.1,4,36.1s1.8,0.8,1.8,1.8
			S4.9,39.6,4,39.6z"/>
        <path fill={blue ? "#2D358C" : "#FFFFFF"} d="M79,48.4c-0.4-5.5-4.7-9.9-10-11.1c-0.1,0-0.1,0-0.2,0c-3.5-0.7-7.2,0.2-10.1,2.2c-1.6,1-2.9,2.4-4,3.9
			c-1.4,2.1,1.6,4.1,3,2.1c1.5-2.3,3.8-4.2,6.5-4.9c1.9-0.5,3.8-0.4,5.7,0.2c0.8,0.3,1.6,0.6,2.3,1.1c0.8,0.5,1.4,1,2,1.7
			c2.2,2.5,2.9,6.2,2,9.4c-0.3,1.2-0.8,2.4-1.5,3.5h-18l-7.1-7.1c-0.2-0.2-0.5-0.3-0.8-0.3c0,0-13.3,0-15.5,0
			c-0.4-1.8-2-3.1-3.9-3.1c-2.2,0-4,1.8-4,4c0,2.2,1.8,4,4,4c1.7,0,3.2-1.1,3.7-2.6h15.2c0.6,0.6,7.1,7.1,7.1,7.1
			c0.2,0.2,0.5,0.3,0.8,0.3c0,0,19.2,0,19.2,0c0.1,0,0.1,0,0.2,0c0.3-0.1,0.5-0.2,0.7-0.4c1.8-2.5,2.8-5.5,2.8-8.5
			C79.1,49.3,79.1,48.8,79,48.4z M29.4,51.6c-1,0-1.8-0.8-1.8-1.8c0-1,0.8-1.8,1.8-1.8c1,0,1.8,0.8,1.8,1.8
			C31.2,50.8,30.4,51.6,29.4,51.6z"/>
	</g>
</g>
</svg>
    )
}

export default LogoSvg