import {NavigationMobile} from "../components/common/navigation/navigationMobile";
import {NavigationBase, NavItemsNavigationBase} from "../components/common/navigation/navigationBase";
import {Footer} from "../components/common/navigation/footer";
import {Row} from "../components/common/grid/row";
import {Column} from "../components/common/grid/column";
import {Textbox} from "../components/common/inputs/textbox";
import {ButtonBase} from "../components/common/buttons/buttonBase";
import axios from "axios";
import React, {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import astronautConfirmation from "../../style/image/astronaute-confirmation.png";
import {useSavePosScrollBar} from "../helper/useSavePosScrollBar";
import {useRefreshItemNav} from "../helper/useRefreshItemNav";
import {Helmet} from "react-helmet";


type ContactData = {
    [key:string]: any;
    lastName: string,
    firstName: string,
    company: string,
    phone: string,
    email: string,
    subject: string,
    message: string
}

export const contactId = 'contact'

const Contact = () => {
    const {} = useSavePosScrollBar(contactId)
    const {} = useRefreshItemNav(contactId)
    const dataRegister = useRef<ContactData>({} as ContactData);
    const {t, i18n} = useTranslation()
    const [success, setSuccess] = useState<boolean>(false);
    let submit = (e:any) => {
        e.preventDefault()
        axios.post('/contacted', dataRegister.current).then(res => {
            console.log(res.data)
            setSuccess(true)
        }).catch(err => {
            setSuccess(false)
            console.log(err)
        })
    }
    let handleChange = (value:any, property : string) => {
        dataRegister.current[property] = value;
    }
    return (
        <>
            <NavigationMobile blue={true}/>
            <NavigationBase blue={true}/>
            <Helmet>
                <title>DDAL - Contact Dynamic Data Analysis Language Team</title>
                <meta name="description" content="Contact the DDAL EF Core team" />
            </Helmet>
            <div className='contact-container'>
                <Row customClass={'jc-center'}>
                    <Column customClass={'center-items-column'} xl={5}>
                        <h1>{t('contact:title')} <span>{t('contact:titleSpan')}</span></h1>
                        <p>{t('contact:subtitle')}</p>
                    </Column>
                </Row>
                <Row customClass={'jc-center'}>
                    <Column customClass={'center-items-column'} xl={5}>
                        {success ? <SuccessContact/> : <form id={"contact"} onSubmit={submit} style={{width: "100%"}}>
                            <div className={"double-textbox"}>
                                <Textbox id="lastName"
                                         change={(e) => {handleChange(e.currentTarget.value, "lastName");}}
                                         placeholder={t("customerArea:name")}></Textbox>
                                <Textbox id="firstName"
                                         divContainerClass={'margin-top-30-sm'}
                                         change={(e) => {handleChange(e.currentTarget.value, "firstName");}}
                                         placeholder={t("customerArea:firstName")}></Textbox>
                            </div>
                            <Textbox customClass="mb-30" id="society"
                                     change={(e) => {handleChange(e.currentTarget.value, "society");}}
                                     placeholder={t("input:company")}></Textbox>
                            <div className={"double-textbox"}>
                                <Textbox id="phoneNumber"
                                         change={(e) => {handleChange(e.currentTarget.value, "phoneNumber");}}
                                         placeholder={t("customerArea:phone")}></Textbox>
                                <Textbox id="email" change={(e) => {handleChange(e.currentTarget.value, "email");}}
                                         placeholder={t("customerArea:email")} divContainerClass={'margin-top-30-sm'}></Textbox>
                            </div>
                            <Textbox id="topic" change={(e) => {handleChange(e.currentTarget.value, "topic");}}
                                     customClass="mb-30" placeholder={t("contact:topic")}></Textbox>
                            <Textbox id="message" change={(e) => {handleChange(e.currentTarget.value, "message");}}
                                     customClass="mb-40" area={true} placeholder={t("contact:message")}></Textbox>
                            <ButtonBase labelMethod={submit} type={"submit"} id={"contact"}
                                        customClass="button-light">{t("contact:send")}</ButtonBase>
                        </form>}
                    </Column>
                </Row>
            </div>
            <Footer/>
        </>
    )
}

 const SuccessContact = () => {
     const {t, i18n} = useTranslation()
    return(
     <div className={'success-container'}>
         <p>{t("contact:successMessage")}</p>
         <img src={astronautConfirmation} />
     </div>
    )
 }

export default Contact