const ContextMarker = () => {
    return (
        <div className={'api'}>
            <h1>Context marker</h1>
            <p>The context marker is the first element in a filter request/expression. It is used to indicate the root entity or indeed context for the entire request.
                DDAL's syntax permits the marker to be omitted. In this case, the context will be implicit and will be inferred; although an error should and has to be thrown if this proves impossible.
                For example, in DDAL/EF, you can execute filter requests on their own, in which case the marker is required.
                You can, however, also use them embedded within analysis requests, in which case the context marker can be omitted.</p>
            <h2>Example of context marker</h2>
            <pre><code>Store;Name = 'Starbucks'</code></pre>
            <p>Here the context will be the <code>Store</code> entity/type/global context.
                The filter on the <code>Name</code> property refers to a data model property with its identifier being "Name" <em>within</em> the <code>Store</code> context.</p>
        </div>
    )
}

export default ContextMarker