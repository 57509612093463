import {NavigationMobile} from "../components/common/navigation/navigationMobile";
import {NavigationBase, NavItemsNavigationBase} from "../components/common/navigation/navigationBase";
import {Textbox} from "../components/common/inputs/textbox";
import {Row} from "../components/common/grid/row";
import {Column} from "../components/common/grid/column";
import {ButtonBase} from "../components/common/buttons/buttonBase";
import {Footer} from "../components/common/navigation/footer";
import React, {useEffect, useRef, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import axios from "axios";
import {useTranslation} from "react-i18next";
import astronautConfirmation from "../../style/image/astronaute-confirmation.png";
import {useSavePosScrollBar} from "../helper/useSavePosScrollBar";
import {useRefreshItemNav} from "../helper/useRefreshItemNav";

type LoginData = {
    [key:string]: any;
    email: string;
    password: string;
    remember: boolean;
    lng:string;
}

export const loginId = 'login'

const Connexion = () => {
    const {} = useSavePosScrollBar(loginId)
    const {} = useRefreshItemNav(loginId)
    const {t, i18n} = useTranslation()
    const dataLogin = useRef<LoginData>({
        email: "",
        password: "",
        remember: false,
        lng: i18n.language
    });
    const [error, setError] = useState<string>("");
    let navigate = useNavigate()
    const [forgotPassword, setForgotPassword] = useState<boolean>(false)

    let handleSubmit = (e:any) => {
        e.preventDefault();
        axios.post('/connect', dataLogin.current, {params:{doNotCatch :true}}).then(res => {
          if(res.status === 200){
              window.localStorage.setItem('token', res.data.token)
              axios.defaults.headers.common['Authorization'] = res.data.token;
              console.log(res.data)
              if(res.data.refreshToken != null) {
                  const expirationDate = new Date();
                  expirationDate.setDate(expirationDate.getDate() + 7);
                  document.cookie = `jwtToken=${res.data.refreshToken}; expires=${expirationDate.toUTCString()}; path=/; HttpOnly`;
              }
              navigate('/customer/?id=1')
          }
        }).catch(err => {
            setError(err.response.data.message);
        })
        return false;
    }

    let handleChange = (property:string, value:any) => {
        dataLogin.current[property] = value;
    }

    useEffect(() => {
        document.body.style.overflow = "auto"
    }, []);

    return (
        <>
            <NavigationMobile blue={true}/>
            <NavigationBase blue={true}/>
            <Row customClass={'connexion-container jc-center'}>
                <Column min={12} xl={9}>
                    {forgotPassword ? <ForgottenPasswordForm/> : <div className="column-container">
                        <h1><span>{t("login:content")}</span> {t("login:toSeeYouAgain")}</h1>
                        <h2>{t("login:subtitle")}</h2>
                        <form onSubmit={handleSubmit} id={"login"}>
                            <Textbox change={(e) => handleChange("email", e.currentTarget.value)} customClass="mb-30"
                                     placeholder={t("login:email")}></Textbox>
                            <Textbox change={(e) => handleChange("password", e.currentTarget.value)} type="password"
                                     placeholder={t("login:password")} password={true}></Textbox>
                            <div className="container-checkbox">
                                <div className={"ai-center d-flex"}>
                                    <input onChange={(e) => handleChange("remember", e.currentTarget.checked)}
                                           id={"remember-checkbox"} type={"checkbox"} className={"checkbox"}/>
                                    <label htmlFor={"remember-checkbox"}>{t("login:rememberMe")}</label>
                                </div>
                                <a onClick={()=>setForgotPassword(true)}>{t("login:forgotPassword")}</a>
                            </div>
                            <p className="label-error">{error}</p>
                            <ButtonBase type={"submit"} labelMethod={handleSubmit} form={"login"} customClass="button-light">{t("login:login")}</ButtonBase>
                        </form>
                        <p className="text-center">{t("login:noAccount")} <Link
                            to={"/register"}>{t("login:register")}</Link></p>
                    </div>}
                </Column>
            </Row>
            <Footer/>
        </>

    )
}

const ForgottenPasswordForm = () => {
    const [email, setEmail] = useState<string>("");
    const {t, i18n} = useTranslation()
    const [success, setSuccess] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    let handleSubmit = (e:any) => {
        e.preventDefault();
        axios.post('/forgotPasswordLink', {email: email},{params:{doNotCatch :true}}).then(res => {
            console.log(res.data)
            setSuccess(true)
        }).catch(err => {
            setMessage(err.response.data.message)
            console.log(err)
        })
        return false;
    }

 return(
     <>
         {success ? <SuccessForgottenPassword/> :
             <div className="column-container">
                 {<h1><span>{t("login:password")}</span> {t("login:worry")}</h1>}
                 <h2>{t("login:informMail")}</h2>
                 <form onSubmit={handleSubmit} id={"login"}>
                     <Textbox change={(e) => setEmail(e.currentTarget.value)} customClass="mb-30"
                              placeholder={"E-mail"}></Textbox>
                     <p className="label-error">{message}</p>
                     <p className="text-center">{t("register:noAccount")}<a href={"/login"}> {t("register:register")}</a></p>
                     <ButtonBase labelMethod={handleSubmit} type={"submit"} form={"login"}
                                 customClass="button-light">{t("login:reinitialise")}</ButtonBase>
                 </form>
             </div>}
     </>
 )
}

const SuccessForgottenPassword = () => {
    const {t, i18n} = useTranslation()
    return(
        <div className='success-container'>
            <p>{t('login:sendMailForgotPassword')}</p>
            <img src={astronautConfirmation} />
        </div>
    )

}

export default Connexion