import {ButtonBase} from "../common/buttons/buttonBase";
import {Link as LinkScroll} from "react-scroll";
import {useTranslation} from "react-i18next";

type HeaderProps = {
    preLaunch?: boolean;
    article?: string;
    titleArticle?: string;
}

const Header = ({preLaunch, article,titleArticle}:HeaderProps) => {
    const {t, i18n} = useTranslation()
    return (
        <div className={article ? "header header--article-" + article : "header"}>
            {article ? <h1>{titleArticle}</h1> : <div className="header-content">
                <h1>
                    {t("header:dynamicLng")}<br/>
                    {t("header:dataAnalysis")}
                </h1>
                <h2 className={'mb-50'}>Entity Framework Add-on</h2>
                {preLaunch ? <LinkScroll to={"newsletter"} spy={true} smooth={true}
                                         className="button-header button-light margin-button">
                        <span className="span-dark"></span> <span
                        className="span-light"></span><label>{t("header:subscribe")}</label>
                    </LinkScroll>
                    : <ButtonBase onClick={()=> window.location.href = '/payment?trial=true'} customClass="button-header button-orange margin-button">{t("header:trial")}</ButtonBase>}
            </div>}
        </div>
    )
}

export default Header