import React, {useContext, useEffect, useRef, useState} from "react";
import {NavigationBaseProps, NavItem} from "./navigationBase";
import LogoSvg from "../../../../style/svg/logo/logoSvg";
import {useStickyScrollBar} from "../../../helper/useStickyScrollBar";
import {NavContext, NavContextType} from "../../../../App";
import {Dropdown} from "../inputs/dropdown";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import ClientSvg from "../../../../style/svg/clientSvg";


const NavigationMobile = ({ blue}: NavigationBaseProps) => {
    const ulElement = useRef<HTMLUListElement>(null);
    const liElement = useRef<HTMLLIElement>(null);
    const navRef = useRef<HTMLDivElement>(null);
    const heightUl = useRef<number>(0);
    const [isOpen, setIsOpen] = useState<Boolean>(false);
    const {isSticky} = useStickyScrollBar();
    const svgRef = useRef<SVGSVGElement>(null);
    const {navItemsNavigationBase, setNavItemsNavigationBase, selectedNavItem} = useContext(NavContext) as NavContextType;
    const {t, i18n} = useTranslation()
    const [defaultLanguage, setDefaultLanguage] = useState<string>(i18n.language)

    let handleChangeLanguage = (item:any) => {
        i18n.changeLanguage(item.id)
        localStorage.setItem('language', item.id)
        setDefaultLanguage(i18n.language)
        window.location.reload()
    }

    useEffect(() => {
        if (ulElement.current === null) return;

        if (isOpen) {
            ulElement.current.style.height = `${heightUl.current}px`;
            navRef.current?.classList.add("navigation-view-mobile--sticky");
        }
        else {
            ulElement.current.style.height = `0px`;
            if(!isSticky) {
                navRef.current?.classList.remove("navigation-view-mobile--sticky");
            }
        }
    }, [isOpen, ulElement]);

    useEffect(() => {
        if(isSticky){
            navRef.current?.classList.add('navigation-view-mobile--sticky')
        }
        else{
            if(isOpen) return;
            navRef.current?.classList.remove('navigation-view-mobile--sticky')
        }
    },[isSticky,isOpen])

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (navRef.current && !navRef.current.contains(event.target)) {
                setIsOpen(false)
                svgRef.current?.classList.remove("active");
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [navRef]);

    useEffect(() => {
        function handleResize() {
            if (navItemsNavigationBase.length === 0 || liElement.current == null) return;
            heightUl.current = liElement.current.getBoundingClientRect().height * (navItemsNavigationBase.length + 3);
        }


        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };

    }, [liElement, navItemsNavigationBase]);

    let open = (e: any) => {
        e.currentTarget.classList.toggle("active");
        setIsOpen(!isOpen);
    };
    return (
        <nav ref={navRef} className={blue? "navigation-view-mobile navigation-view-mobile--blue" : "navigation-view-mobile"}>
            <div className={'d-flex ai-center p-20'}>
                <LogoSvg style={{height:isSticky ||isOpen ? '40px': "70px", marginRight:'auto'}} blue={blue ? blue : isSticky || !isOpen ? isSticky : !isSticky || isOpen ? isOpen : isSticky}/>
                <svg ref={svgRef} onClick={open} className="ham hamRotate ham4" viewBox="0 0 100 100" width="80">
                    <path
                        className="line top"
                        d="m 70,33 h -40 c 0,0 -8.5,-0.149796 -8.5,8.5 0,8.649796 8.5,8.5 8.5,8.5 h 20 v -20"/>
                    <path
                        className="line middle"
                        d="m 70,50 h -40"/>
                    <path
                        className="line bottom"
                        d="m 30,67 h 40 c 0,0 8.5,0.149796 8.5,-8.5 0,-8.649796 -8.5,-8.5 -8.5,-8.5 h -20 v 20"/>
                </svg>
            </div>

            <ul ref={ulElement}>
                <li> <Dropdown startAnimationTime={400} value={defaultLanguage} selected={handleChangeLanguage} style={{width:"100px"}} dataItem={[{id:'fr',name:"FR"},{id:'en',name:'EN'}]}/></li>
                {navItemsNavigationBase.map((item,index) => {
                    if(index >= navItemsNavigationBase.length - 1) {
                        return null
                    }
                    if(item.isVisible === false) return null;
                    return <li ref={liElement} key={item.label}><Link to={item.link} key={index} className={`item ${item.isActive ? "item--active" : ""}`}
                                                                      onClick={() => {
                                                                          window.localStorage.setItem(item.id as string, '0')
                                                                      }}>{item.label}</Link></li>;
                })}
                <li>{window.localStorage.getItem('token') ? <Link  to={'/customer/?id=1'}
                                                                   className={`item`}>{t('navigation:customerArea')}</Link> : <Link to={navItemsNavigationBase[navItemsNavigationBase.length -1].link} className={`item ${navItemsNavigationBase[navItemsNavigationBase.length -1].isActive ? "item--active" : ""}`}
                                                                                                                                    onClick={() => {
                                                                                                                                    }}>{navItemsNavigationBase[navItemsNavigationBase.length -1].label}</Link>}</li>
            </ul>
        </nav>
    );
};

export {NavigationMobile};