const InvoiceSvg = () => {
    return(
        <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 16.5 22" enableBackground="new 0 0 16.5 22" xmlSpace="preserve">
<g id="Groupe_33">
	<path id="Tracé_219" fill="#5F82C1" d="M4.8,9.6c-0.4,0-0.7,0.3-0.7,0.7S4.4,11,4.8,11h6.9c0.4,0,0.7-0.3,0.7-0.7
		s-0.3-0.7-0.7-0.7C11.7,9.6,4.8,9.6,4.8,9.6z M4.1,13.1c0-0.4,0.3-0.7,0.7-0.7l0,0h6.9c0.4,0,0.7,0.3,0.7,0.7s-0.3,0.7-0.7,0.7H4.8
		C4.4,13.8,4.1,13.4,4.1,13.1L4.1,13.1z M4.1,15.8c0-0.4,0.3-0.7,0.7-0.7l0,0h2.8c0.4,0,0.7,0.3,0.7,0.7c0,0.4-0.3,0.7-0.7,0.7H4.8
		C4.4,16.5,4.1,16.2,4.1,15.8z"/>
    <path id="Tracé_220" fill="#5F82C1" d="M9.1,0H2.8C1.2,0,0,1.2,0,2.8v16.5C0,20.8,1.2,22,2.8,22h11c1.5,0,2.8-1.2,2.8-2.8V7.4
		c0-0.8-0.3-1.5-0.8-2l-4.5-4.5C10.6,0.3,9.9,0,9.1,0z M9.3,1.4c0.6,0,1,0.4,1,1v1.7c0,1.1,0.9,2.1,2.1,2.1l0,0h1.7c0.6,0,1,0.4,1,1
		v12.1c0,0.8-0.6,1.4-1.4,1.4h-11c-0.8,0-1.4-0.6-1.4-1.4V2.8C1.4,2,2,1.4,2.8,1.4L9.3,1.4L9.3,1.4z"/>
</g>
</svg>
    )
}

export {InvoiceSvg}