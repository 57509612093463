import React, {createContext, useEffect, useState} from "react";
import './App.css';
import {Outlet, Routes, Route } from 'react-router-dom';
import Home from "./app/views/home";
import Connexion from "./app/views/connexion";
import {Price} from "./app/views/price";
import Contact from "./app/views/contact";
import {NavigationCustomer} from "./app/components/customerArea/navigationCustomer";
import {PersonalDataSvg} from "./style/svg/navigationCustomer/personalDataSvg";
import {SocietySvg} from "./style/svg/navigationCustomer/societySvg";
import {SubscriptionSvg} from "./style/svg/navigationCustomer/subscriptionSvg";
import {InvoiceSvg} from "./style/svg/navigationCustomer/invoiceSvg";
import {LogoutSvg} from "./style/svg/navigationCustomer/logoutSvg";
import {NavItem} from "./app/components/common/navigation/navigationBase";
import {Register} from "./app/views/register";
import MailValidation from "./app/views/mailValidation";
import PreLaunch from "./app/views/preLaunch";
import {Payment} from "./app/views/payment";
import {MySubscription} from "./app/components/customerArea/mySubscription";
import {DataPersonal} from "./app/components/customerArea/dataPersonal";
import {MySociety} from "./app/components/customerArea/mySociety";
import {CustomerArea} from "./app/views/customerArea";
import {useTranslation} from "react-i18next";
import {ConfirmationPayment} from "./app/views/confirmationPayment";
import {loadStripe} from "@stripe/stripe-js";
import ForgotPassword from "./app/views/forgotPassword";
import Exploration from "./app/views/api/DDAL_EF/Exploration";
import Deployment from "./app/views/api/DDAL_EF/deployment";
import ListingAnalysis from "./app/views/api/DDAL_EF/Analysis/listingAnalysis";
import Examples from "./app/views/api/DDAL_EF/Examples";
import Introduction from "./app/views/api/DDAL_EF/introduction";
import CrossAnalysis from "./app/views/api/DDAL_EF/Analysis/crossAnalysis";
import Properties from "./app/views/api/DDAL/analysis/properties";
import BasicsDDAL from "./app/views/api/DDAL/analysis/basic";
import ResponseMarker from "./app/views/api/DDAL/analysis/responseMarker";
import Basics from "./app/views/api/DDAL/filter/basics";
import CollectionSpecifiers from "./app/views/api/DDAL/filter/collectionSpecifiers";
import ComparisonOperators from "./app/views/api/DDAL/filter/comparisonOperators";
import Comparisons from "./app/views/api/DDAL/filter/comparisons";
import ContextMarker from "./app/views/api/DDAL/filter/contextMarker";
import EmbeddedFilters from "./app/views/api/DDAL/filter/embeddedFilters";
import Groups from "./app/views/api/DDAL/filter/groups";
import LogicalOperators from "./app/views/api/DDAL/filter/logicalOperators";
import Predicates from "./app/views/api/DDAL/filter/predicates";
import PropertyPaths from "./app/views/api/DDAL/filter/propertyPaths";
import Values from "./app/views/api/DDAL/filter/values";
import Base from "./app/views/api/base";
import IntroductionDDAL from "./app/views/api/DDAL/introductionDDAL";
import DDALArticle from "./app/views/articles/ddalArticle";
import DDALEFArticle from "./app/views/articles/DDALEFArticle";
import {Blocks} from "react-loader-spinner";
import Blog from "./app/views/blog";
import WhoWeAre from "./app/views/whoWeAre";

export type NavContextType = {
    navItemsNavigationBase:NavItem[];
    setNavItemsNavigationBase:React.Dispatch<React.SetStateAction<NavItem[]>>;
    selectedNavItem?:(item:NavItem) => void;
}
export const NavContext = createContext<NavContextType>({} as NavContextType)

export const stripePromise = loadStripe(process.env.REACT_APP_stripe_key ? process.env.REACT_APP_stripe_key : '');


function App() {
    const {t, i18n} = useTranslation()
    const [navItemsNavigationBase, setNavItemsNavigationBase] = useState<NavItem[]>([
        {id:'home', label:t('navigation:home'), link:'/', isActive:false, isVisible:true},
        {id:'price',label:t('navigation:price'), link:'/price', isActive:false, isVisible:true},
        {id:'blog',label:'Blog', link:'/blog', isActive:false, isVisible:true},
        {id:'whoWeAre',label:t('navigation:whoWeAre'), link:'/who', isActive:false, isVisible:true},
        {id:'contact',label:t('navigation:contact'), link:'/contact', isActive:false, isVisible:true},
        {id:'register',label:t('navigation:register'), link:'/register', isActive:false, isVisible:true},
        {id:'login',label:t('navigation:login'), link:'/login', isActive:false, isVisible:true},
    ]);

    let selectedNavItem = (item:NavItem) => {
        setNavItemsNavigationBase(prevState => {
            return prevState.map(prevItem => {
                if(prevItem.label === item.label){
                    return {...prevItem, isActive:true}
                }
                return {...prevItem, isActive:false}
            })
        })
    }

  return (
      <>
      <NavContext.Provider value={{navItemsNavigationBase, setNavItemsNavigationBase, selectedNavItem}}>
        <Routes>
        <Route path="/" element={<PreLaunch/>}/>
            <Route path="/login" element={<Connexion/>}/>
                <Route path="/price" element={<Price/>}/>
          <Route path="/contact" element={<Contact/>}/>
          <Route path="/customer" element={<CustomerArea/>}/>
          <Route path="/register" element={<Register/>}/>
          <Route path="/mailConfirmation" element={<MailValidation/>}/>
          <Route path={"payment"} element={<Payment/>}/>
            <Route path={"/blog"} element={<Blog/>}/>
            <Route path={"/who"} element={<WhoWeAre/>}/>

            <Route path={"/blog/ddal"} element={<DDALArticle/>}/>
            <Route path={"/blog/ddalef"} element={<DDALEFArticle/>}/>

            <Route path={"/passwordForgotten"} element={<ForgotPassword/>}/>
            <Route path={"/api/DDALEF/exploration"} element={<Exploration/>}/>
            <Route path={"/api/DDALEF/deployment"} element={<Deployment/>}/>
            <Route path={"/api/DDALEF/examples"} element={<Examples/>}/>
            <Route path={"/api/DDALEF/introduction"} element={<Introduction/>}/>

            <Route path={"/api/DDALEF/analysis/listing"} element={<ListingAnalysis/>}/>
            <Route path={"/api/DDALEF/analysis/cross"} element={<CrossAnalysis/>}/>

            <Route path={"/api/DDAL/introduction"} element={<IntroductionDDAL/>}/>

            <Route path={"/api/DDAL/analysis/properties"} element={<Properties/>}/>
            <Route path={"/api/DDAL/analysis/basics"} element={<BasicsDDAL/>}/>
            <Route path={"/api/DDAL/analysis/responseMarker"} element={<ResponseMarker/>}/>

            <Route path={"/api/DDAL/filter/basics"} element={<Basics/>}/>
            <Route path={"/api/DDAL/filter/collectionSpecifiers"} element={<CollectionSpecifiers/>}/>
            <Route path={"/api/DDAL/filter/comparisonOperators"} element={<ComparisonOperators/>}/>
            <Route path={"/api/DDAL/filter/comparisons"} element={<Comparisons/>}/>
            <Route path={"/api/DDAL/filter/contextMarker"} element={<ContextMarker/>}/>
            <Route path={"/api/DDAL/filter/embeddedFilters"} element={<EmbeddedFilters/>}/>
            <Route path={"/api/DDAL/filter/groups"} element={<Groups/>}/>
            <Route path={"/api/DDAL/filter/logicalOperators"} element={<LogicalOperators/>}/>
            <Route path={"/api/DDAL/filter/predicates"} element={<Predicates/>}/>
            <Route path={"/api/DDAL/filter/propertyPaths"} element={<PropertyPaths/>}/>
            <Route path={"/api/DDAL/filter/values"} element={<Values/>}/>

            <Route path={"/api/base"} element={<Base/>}/>
      </Routes>
        <Outlet/>
      </NavContext.Provider>
      </>
  );
}

export default App;
