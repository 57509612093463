const Base = () => {
    return (
        <div className={'api'}>
            <h1>API</h1>
            <h2>DDAL</h2>
            <a href={'/api/DDAL/introduction'}>Introduction</a>
            <h3>Filter</h3>
            <ul>
                <li><a href={'/api/DDAL/filter/basics'}>Filter basics</a></li>
                <li><a href={'/api/DDAL/filter/comparisonOperators'}>Comparison operators</a></li>
                <li><a href={'/api/DDAL/filter/logicalOperators'}>Logical operators</a></li>
                <li><a href={'/api/DDAL/filter/propertyPaths'}>Property paths</a></li>
                <li><a href={'/api/DDAL/filter/predicates'}>Predicates/compositions</a></li>
                <li><a href={'/api/DDAL/filter/values'}>Comparison values</a></li>
                <li><a href={'/api/DDAL/filter/groups'}>Groups</a></li>
                <li><a href={'/api/DDAL/filter/collectionSpecifiers'}>Collection specifiers</a></li>
                <li><a href={'/api/DDAL/filter/contextMarker'}>Context marker</a></li>
                <li><a href={'/api/DDAL/filter/comparisons'}>Comparisons</a></li>
                <li><a href={'/api/DDAL/filter/embeddedFilters'}>Embedded filters</a></li>
            </ul>
            <h3>Analysis</h3>
            <ul>
                <li><a href={'/api/DDAL/analysis/basics'}>Analysis basics</a></li>
                <li><a href={'/api/DDAL/analysis/properties'}>Properties</a></li>
                <li><a href={'/api/DDAL/analysis/responseMarker'}>Property paths</a></li>
            </ul>
            <h2>DDAL/EF</h2>
            <a href={'/api/DDALEF/introduction'}>Introduction</a>
            <ul>
                <li><a href={'/api/DDALEF/exploration'}>Exploration</a></li>
                <li><a href={'/api/DDALEF/deployment'}>Deployment</a></li>
                <li><a href={'/api/DDALEF/examples'}>Examples</a></li>
            </ul>
            <h3>Analysis</h3>
            <ul>
                <li><a href={'/api/DDALEF/analysis/listing'}>Listing analysis</a></li>
                <li><a href={'/api/DDALEF/analysis/cross'}>Cross analysis</a></li>
            </ul>
        </div>
    )
}

export default Base