const PaymentMethodSvg = () => {
    return(
        <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 21.4 22" enableBackground="new 0 0 21.4 22" xmlSpace="preserve">
<g id="card-outline" transform="translate(-5.75 -10.954)">
	<path id="Rectangle_265" fill="none" stroke="#5C83CE" strokeWidth="1.1182" strokeLinecap="round" strokeLinejoin="round" d="
		M9.9,14.4H23c1.7,0,3.2,1.4,3.2,3.2v8.8c0,1.7-1.4,3.2-3.2,3.2H9.9c-1.7,0-3.2-1.4-3.2-3.2v-8.8C6.7,15.8,8.2,14.4,9.9,14.4z"/>
    <path id="Tracé_626" fill="none" stroke="#5C83CE" strokeWidth="2.9818" strokeLinejoin="round" d="M6.7,18.8h19.4"/>
    <path id="Rectangle_266" fill="#5C83CE" d="M10.3,22.3h3.3c0.7,0,1.3,0.6,1.3,1.3v1.8c0,0.7-0.6,1.3-1.3,1.3h-3.3
		c-0.7,0-1.3-0.6-1.3-1.3v-1.8C9,22.9,9.6,22.3,10.3,22.3z"/>
</g>
</svg>
    )
}

export {PaymentMethodSvg}