import {Elements} from "@stripe/react-stripe-js";
import React, {useEffect, useState} from "react";
import {PaymentForm} from "../../../views/payment";
import {stripePromise} from "../../../../App";
import axios from "axios";

const AddPaymentMethod = () => {
    const [clientSecret, setClientSecret] = useState('');
    const [confirmationPayment, setConfirmationPayment] = useState<boolean>(false)

    useEffect(() => {
        axios.post('/addSetupPaymentIntent', {token:window.localStorage.getItem('token')}).then(res => {
          if (res.status === 200) {
            setClientSecret(res.data.clientSecret)
          }
        })
    },[])

    return (
        <div className={'add-payment-method-container'}>
                {clientSecret && (<Elements options={{clientSecret: clientSecret}} stripe={stripePromise}>
                    <PaymentForm add={true} setConfirmationPayment={setConfirmationPayment}/>
                </Elements>)}
        </div>
    )
}

export default AddPaymentMethod