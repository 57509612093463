import {SvgProps} from "./arrowSVG";

const FreeLogoSvg = ({...props}:SvgProps) => {
    return(
        <svg {...props} version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 97.3 105" enableBackground="new 0 0 97.3 105" xmlSpace="preserve">
<path fill="#2C358B" d="M91.8,26.9H73.6c2.7-2.9,4.4-6.7,4.4-11C78,7.1,70.8,0,62.1,0c0,0-0.1,0-0.1,0c-5.6,0-10.6,3-13.4,7.5
	c-0.6-1-1.4-2-2.2-2.8c-3-3-7-4.7-11.3-4.7c0,0,0,0,0,0c-4.2,0-8.2,1.7-11.3,4.7s-4.7,7-4.7,11.3c0,4.1,1.5,8,4.4,10.9h-18
	c-3,0-5.5,2.5-5.5,5.5v21.1c0,3,2.5,5.5,5.5,5.5h2.2v32c0,7.7,6.3,14,14,14h0h53.8h0c7.7,0,14-6.3,14-14V59h2.2c3,0,5.5-2.5,5.5-5.5
	V32.3C97.3,29.3,94.8,26.9,91.8,26.9z M51.2,15.9C51.2,9.9,56,5,62,5c0,0,0,0,0,0C68.1,5,73,9.9,73,15.9c0,6-4.8,10.9-10.9,11h-11
	V15.9z M27.4,23.6c-2.1-2.1-3.2-4.8-3.2-7.7s1.1-5.7,3.2-7.7C29.4,6.1,32.2,5,35.1,5l0,0c2.9,0,5.7,1.1,7.7,3.2S46,13,46,15.9v10.9
	H35.1l0,0C32.2,26.9,29.4,25.7,27.4,23.6z M5,53.5V32.3c0-0.3,0.2-0.5,0.5-0.5h29.6l0,0h11.1V54H5.5C5.2,54,5,53.7,5,53.5z
	 M21.8,100C21.8,100,21.8,100,21.8,100c-5,0-9-4-9-9V59h33.4v41H21.8z M84.6,91c0,5-4.1,9-9,9c0,0,0,0,0,0H51.2V59h33.4V91z
	 M92.3,53.5c0,0.3-0.2,0.5-0.5,0.5H51.2V31.9h11h29.7c0.3,0,0.5,0.2,0.5,0.5L92.3,53.5L92.3,53.5z"/>
</svg>
    )
}

export default FreeLogoSvg;