const FloatSvg = () => {
    return(
        <svg enableBackground="new 0 0 20 20" version="1.1" viewBox="0 0 20 20" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg">
<path d="M17.5,20h-15C1.1,20,0,18.9,0,17.5v-15C0,1.1,1.1,0,2.5,0h15C18.9,0,20,1.1,20,2.5v15  C20,18.9,18.9,20,17.5,20z" fill="#9A4DAD"/>
            <text transform="translate(-25.293 12.934)" fill="#FFFFFF" fontFamily="'Comfortaa-Bold'" fontSize="8px" stroke="#FFFFFF" strokeMiterlimit="10" strokeWidth=".75">STR</text>
            <g fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth=".4419">
<path d="m1.3 12.2s-0.1-0.1-0.1-0.2v-3.2c0-0.1 0-0.1 0.1-0.2 0 0 0.1-0.1 0.2-0.1h2.1c0.1 0 0.1 0 0.2 0.1 0 0 0.1 0.1 0.1 0.2s0 0.1-0.1 0.2h-0.2-1.9v1.1h1.4c0.1 0 0.1 0 0.2 0.1 0 0 0.1 0.1 0.1 0.2s0 0.1-0.1 0.2c0 0-0.1 0.1-0.2 0.1h-1.4v1.3c0 0.1 0 0.1-0.1 0.2 0 0-0.1 0.1-0.2 0.1 0 0 0-0.1-0.1-0.1z"/>
                <path d="m5 12.2s-0.1-0.1-0.1-0.2v-3.2c0-0.1 0-0.1 0.1-0.2 0 0 0.1-0.1 0.2-0.1s0.1 0 0.2 0.1c0 0 0.1 0.1 0.1 0.2v2.9h1.7c0.1 0 0.1 0 0.2 0.1 0 0 0.1 0.1 0.1 0.2s0 0.1-0.1 0.2c0 0-0.1 0.1-0.2 0.1h-2c-0.1 0-0.2-0.1-0.2-0.1z"/>
                <path d="m8.8 12.1c-0.3-0.2-0.5-0.4-0.7-0.7s-0.2-0.6-0.2-1 0.1-0.7 0.2-1 0.4-0.5 0.7-0.7 0.6-0.2 1-0.2 0.7 0.1 1 0.2c0.3 0.2 0.5 0.4 0.7 0.7s0.2 0.6 0.2 1-0.1 0.7-0.2 1c-0.2 0.3-0.4 0.5-0.7 0.7s-0.6 0.2-1 0.2-0.7-0.1-1-0.2zm1.7-0.4c0.2-0.1 0.4-0.3 0.5-0.5s0.2-0.5 0.2-0.7c0-0.3-0.1-0.5-0.2-0.7s-0.3-0.4-0.5-0.5-0.4-0.3-0.7-0.3-0.5 0-0.7 0.2c-0.2 0.1-0.4 0.3-0.5 0.5s-0.2 0.5-0.2 0.7c0 0.3 0.1 0.5 0.2 0.7s0.3 0.4 0.5 0.5 0.5 0.2 0.7 0.2c0.3 0.1 0.5 0 0.7-0.1z"/>
                <path d="m15.6 12c0 0.1 0 0.1-0.1 0.2 0 0-0.1 0.1-0.2 0.1h-0.1l-0.1-0.1-0.3-0.7h-1.7l-0.3 0.7-0.1 0.1h-0.1c-0.1 0-0.1 0-0.2-0.1 0 0-0.1-0.1-0.1-0.2v-0.1l1.3-3.2c0-0.1 0.1-0.2 0.2-0.2s0.2 0.1 0.2 0.2l1.3 3.2c0.3 0.1 0.3 0.1 0.3 0.1zm-2.3-1h1.4l-0.7-1.6-0.7 1.6z"/>
                <path d="m18.7 8.6s0.1 0.1 0.1 0.2 0 0.1-0.1 0.2c0 0-0.1 0.1-0.2 0.1h-1v3c0 0.1 0 0.1-0.1 0.2 0 0-0.1 0.1-0.2 0.1s-0.1 0-0.2-0.1c0 0-0.1-0.1-0.1-0.2v-3.1h-1c-0.1 0-0.1 0-0.2-0.1 0 0-0.1-0.1-0.1-0.2s0-0.1 0.1-0.2c0 0 0.1-0.1 0.2-0.1h2.5c0.2 0.2 0.2 0.2 0.3 0.2z"/>
</g>
</svg>
    )
}

export default FloatSvg;