import {ReactNode} from "react";

type ButtonBaseProps = {
    children: ReactNode;
    customClass?: string;
    labelMethod?: any;
    [propName: string]: any;
}

const ButtonBase = ({children,id, labelMethod, customClass = "", ...props}:ButtonBaseProps) => {
    return (
        <button  role={'button'} {...props} className={`${customClass}`}>
            <span className='span-dark'></span> <span className='span-light'></span><label onClick={labelMethod} >{children}</label>
        </button>
    );
};

export {ButtonBase};