import {useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {use} from "i18next";
import ReactCodeMirror from "@uiw/react-codemirror";
import {dracula} from "@uiw/codemirror-theme-dracula";
import {langs} from "@uiw/codemirror-extensions-langs";

const Properties = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const [scrollTo, setScrollTo] = useState<string | null>(null);
    useEffect(() => {
        if (searchParams.get('scrollTo') !== undefined && searchParams.get('scrollTo') !== null) {
            setScrollTo(searchParams.get("scrollTo"));
        }
    }, [searchParams])

    useEffect(() => {
if (scrollTo !== null) {
            let element = document.getElementById(scrollTo);
            if (element !== null) {
                element.scrollIntoView({behavior: "smooth"});
            }
        }

    },[scrollTo])

    return(
        <div className={'api'}>
            <h1>Properties</h1>
            <p>Properties are the absolute core of DDAL's analysis requests/expressions. A property is simply a name + a value; but those values can be very complex and even hold other properties themselves.
                This page will summarize and list all the possible values for properties, a bit below the current introduction.</p>
            <p>Property names are rather simple: they can contain any alphanumeric symbol as well as underscores (A-Za-z0-9_).
                They can be enclosed in simple quotes, but this is not necessary and not recommended by any means.</p>
            <p>Let us start with a non-trivial example:</p>
            <pre><code>{`my_property = dic
                {
                    inner_property_int = 1,
                    inner_property_bool = true
                }`}</code></pre>
            <p>What we have here is a property named "my_property" with a dictionary value consisting of two inner properties: an integer and a boolean.</p>
            <p>In and of itself, those properties mean nothing for data analysis. How they are interpreted and used is up to the specific implementation of DDAL.
                As a quick example, DDAL/EF uses property names like "aggregation", "groupby"...</p>
            <h2 id={'standard-property-values'}>Standard property values</h2>
            <p>Standard property values are those of the following types:</p>
            <ul>
                <li>Integer values.</li>
                <li>Decimal (float) values.</li>
                <li>Boolean values: <code>{`true`}</code>, <code>{`false`}</code>, <code>{`yes`}</code>, <code>{`no`}</code>.</li>
                <li>String values, preferably enclosed by simple quotes.</li>
            </ul>
            <p>A quick example:</p>
            <ReactCodeMirror theme={dracula} value={`example_container = dic
                {
                    integer_property = 1,
                    decimal_property = .89,
                    boolean_property = true,
                    string_property = 'something'
                }`} extensions={[langs.javascript()]} />
            <p>Other property values are technically standard (in terms of lexing and parsing) but are a bit more unusual. Those are:</p>
            <ul>
                <li>Period properties</li>
                <li>Aggregator properties</li>
                <li>Textual data properties</li>
            </ul>
            <p>A period property is simply used to specify a composite timestamp. For example:</p>
            <ReactCodeMirror theme={dracula} value={`example_container = dic
                {
                    period_years = years,
                    period_days = days,
                    period_months_and_years = months + years,
                    period_all = days + weeks + months + years
                }`} extensions={[langs.javascript()]} />
            <p>You can add and shuffle around those four period keywords with the <code>{`+`}</code>. It will be up to implementation to deal with it (or throw an error).</p>
            <p>An aggregator property is used mostly for optimization purposes by passing an enum value instead of a string.
                If your implementation requires non-standard aggregators, you can of course use textual properties.
                DDAL supports the following aggregators as part of its standard:</p>
            <ul>
                <li><code>{`count`}</code></li>
                <li><code>{`sum`}</code></li>
                <li><code>{`average`}</code> or <code>{`avg`}</code></li>
                <li><code>{`minimum`}</code> or <code>{`min`}</code></li>
                <li><code>{`maximum`}</code> or <code>{`max`}</code></li>
            </ul>
            <p>Quick example:</p>
            <ReactCodeMirror theme={dracula} value={`example_container = dic
                {
                    aggregator_count = count,
                    aggregator_maximum = max
                }`} extensions={[langs.javascript()]} />
            <p>Finally, textual data properties are straightforward. Those are simply property values enclosed in <em>double</em> quotes. You can use symbols, Unicode characters, etc in them.
                For example:</p>
            <pre><code>{`my_symbols = "*++☺++*"`}</code></pre>
            <h2 id={'property-paths'}>Property paths</h2>
            <p>Property paths are values very similar (in fact almost identical) to <a href="/api/DDAL/filter/propertyPaths">filter property paths</a>.
                It is perhaps a bit confusing, but an analysis property can have a property path <em>value</em>.</p>
            <p>Property path values must be declared using a special assignment operator (called the path assignment operator): <code>{`/=`}</code>.
                Each property in the path must be separated from the others by a dot. Quick example:</p>
            <pre><code>{`path_property /= Store.Owner.FullName`}</code></pre>
            <p>Property path items expected to be collections can also be followed with an inline filter expression enclosed by double quotes and brackets, like so:</p>
            <pre><code>{`path_property /= Store.Products["Name = Apples"].ExpirationDate`}</code></pre>
            <p>This is indeed very similar to standard property paths in filter requests. If you haven't, you should get familiar with it. <a href="/api/DDAL/filter/basics">This page</a> is a good starter.</p>
            <h2 id={'aggregations'}>Aggregations</h2>
            <p>Aggregations are special values used to mark an aggregation operation on a specific data point (property) in your data model.
                Like property paths, they must be assigned using the special path assignment operator.
                They are, put simply, an aggregator followed by a property path enclosed in parentheses. A quick example:</p>
            <ReactCodeMirror theme={dracula} value={`example_container = dic
                {
                    root = Store,
                    count_of_expired_apples /= count(Products["Name = Apples & ExpirationDate < TODAY"]),
                    total_revenue /= sum(Revenue)
                }`} extensions={[langs.javascript()]} />
            <p>Unfortunately, as of v1, DDAL's specification does not support custom aggregators with this syntax. If you need custom aggregators, you have to parse a textual value.</p>
                <h2 id={'filter-expression-properties'}>Filter expression properties</h2>
            <p>Those are properties used to input "raw" filter expressions. Their main aim is to be picked up and applied as a global filter.
                Filter expression properties are pretty easy to understand, you just have to type your filter expression enclosed in double quotes and use a special assignment operator.
                This special operator is called the "expression operator" (or filter operator in v1, because it's the only expression supported).
                Let's showcase a quick example of such a property:</p>
            <ReactCodeMirror theme={dracula} value={`enclosed_container = dic
                {
                    root = Store,
                    filter_with_context %= "Store;Products:count > 0",
                    filter_without_context %= "Products:count > 0"
                }`} extensions={[langs.javascript()]} />
            <p>The two filter properties above are equivalent. In the second one, the context marker has been omitted. This is valid as long as the implementation you're using supports implicit contexts.
                For a better understanding, you should read the documentation for the filter language. <a href="/api/DDAL/filter/basics">This page</a> is good to start.</p>
            <p>Filter expression properties will be parsed with the parent analysis request and reduced to an AST for processing.</p>
            <h2 id={'blocks'}>Blocks</h2>
            <p>Blocks are very much unlike other property values: they don't have an intrinsic value but hold other properties.
                There are different types of blocks, but they are ultimately all dictionaries where the keys are the property names and the values are the properties' values.
                Blocks are declared by putting their type after the standard assignment operator and then opening braces. The block is closed by a closing brace. For example:</p>
            <ReactCodeMirror theme={dracula} value={`block = dic
                {
                    property_1 = 1,
                    property_2 = '2'
                }`} extensions={[langs.javascript()]} />
            <p>The block types are:</p>
            <ul>
                <li><code>{`dic`}</code>, the standard generic block type, which stands for "dictionary"</li>
                <li><code>{`part`}</code>, generic part separator for subdivisions, especially useful for multipart analyses</li>
                <li><code>{`cross analysis`}</code>, used to declare a set of properties meant for complex analyses, usually meant for charting and graphical display</li>
                <li><code>{`listing analysis`}</code>, used to declare an analysis request meant for simple retrieval of data in the form of a list</li>
            </ul>
        </div>
    )
}

export default Properties