import {dracula} from "@uiw/codemirror-theme-dracula";
import {langs} from "@uiw/codemirror-extensions-langs";
import ReactCodeMirror from "@uiw/react-codemirror";

const Deployment = () => {
    return(
        <div className={'api'}>
            <h1>Deployment</h1>
            <p>DDAL/EF offers two main deployment platforms.
                The first one is a full integration with your own infrastructure using NuGet packages.
                The second one makes full use of Docker containers and can very quickly brought up and used in a microservice context.</p>
            <h2>NuGet packages</h2>
            <p>Deployment using NuGet packages is easy but requires a bit more work than the Docker deployment because you need to integrate it with your infrastructure.
                However you do it, there are a number of steps that you need to follow:</p>
            <ol>
                <li>You need to install the <code>{`DDAL.EF.Interface`}</code> package which should implicitly come with all needed dependencies.
                    This acts as the orchestrator and "developer"-facing architectural block.</li>
                <li>You need to register your license key:<pre><code className="language-csharp">{`
using DDAL.EF.Interface;`}</code></pre>
                </li>
            </ol>
            <p>License.Set(yourLicenseKey);</p>
            <ReactCodeMirror theme={dracula} value={`
Once that is done you will need a proper way to execute and process your requests through DDAL/EF.
The only need supported way to work with NuGet packages is to use a DI container to resolve internal services. To register the needed services, copy the below:
csharp
myServices.AddDDAL(builder =>
                {
                    builder
                        .AddLinqReferentials()
                        .AddRootTypes(myRootTypes)
                        .AddFiltering()
                        .AddAnalysis()
                        .AddEFCore<yourContextType>() // or AddEFCore(yourContextType)
                        .AddFilterExecutor()
                        .AddAnalysisExecutor();
                }`} extensions={[langs.javascript()]} />
            <p>Let's break down those services line by line:</p>
            <ul>
                <li>Linq referentials are a required internal service.</li>
                <li>Root types defines, indeed, the root types that are considered valid as root contexts for your cross analysis and listing analysis requests.
                    Any other context/entity than those registered here will throw an error.</li>
                <li><code>{`AddFiltering`}</code> registers filtering processors; you can customize some options there if you wish, and have them registered as a singleton or with a scoped lifetime if you like.</li>
                <li><code>{`AddAnalysis`}</code> does the same thing for analysis processors and options.</li>
                <li><code>{`AddEFCore`}</code> registers EFCore processors (those who translate LINQ into EF Core request delegates) for a given context type.
                    It is expected that your root types should be accessible directly from your context.</li>
                <li><code>{`AddFilterExecutor`}</code> registers the <code>{`FilteringExecutor`}</code> className which acts as the entrypoint for the processing and direct execution of your filter requests.
                    It is "optional" if you only wish to execute standalone analysis expressions.</li>
                <li><code>{`AddAnalysisExecutor`}</code> does the same thing for <code>{`AnalysisExecutor`}</code> and analysis expressions.
                    It is "optional" if you only wish to execute standalone filter expressions.</li>
            </ul>
            <p>If you use ASP .NET Core this would most likely look like:</p>
            <ReactCodeMirror theme={dracula} value={`builder.Services.AddDDAL(builder =>
                {
                    builder
                        .AddLinqReferentials()
                        .AddRootTypes(myRootTypes)
                        .AddFiltering()
                        .AddAnalysis()
                        .AddEFCore<yourContextType>() // or AddEFCore(yourContextType)
                        .AddFilterExecutor()
                        .AddAnalysisExecutor();
                }`} extensions={[langs.javascript()]} />
            <p>Once that is done, create your controllers or your application logic and use DI to resolve either a <code>{`FilterExecutor`}</code> or an <code>{`AnalysisExecutor`}</code> depending on what you need.
                You can then process requests like so:</p>
            <ReactCodeMirror theme={dracula} value={`var result = await executor.Process(request);`} extensions={[langs.javascript()]} />
            <h2>Docker container</h2>
            <p>Docker containers are the recommended deployment method for most use cases. It proves a nice wrapper and integrates well into modern microservice architectures.</p>
            <p>The only prerequisite is a compiled DLL file with your context type. If your context needs further setup, your should include another type which implements <code>{`IDbContextFactory<TContext>`}</code>.
                Root types will be all the types with an attached DbSet directly on your context.
                If you have external dependencies, the needed binaries (DLLs most likely) should be put right next to your model assembly/DLL.
                It is also required, for obvious reasons, that your context be properly built using Entity Framework Core's standard methods like <code>{`OnModelConfiguring`}</code>.
                Otherwise DDAL/EF will not be able to resolve column names, joins, etc.</p>
            <p>You may need to mount volumes to your container for your certificate (if needed) and the aforementioned model assembly.</p>
            <p>To set up your DDAL/EF Docker container, spin up a new container from the image <code>{`ddal-ef/webhost`}</code>. You only need to set the following environment variables:</p>
            <ul>
                <li><code>{`DDAL_URLS`}</code> = a semicolon separated list of the urls the web host should listen to.</li>
                <li><code>{`DDAL_CERTIFICATE_PATH`}</code> = the path to the certificate file if you're using HTTPS.</li>
                <li><code>{`DDAL_CERTIFICATE_PASSWORD`}</code> = the password of the certificate file if it's needed (PFX files for instance).</li>
                <li><code>{`DDAL_LICENSE_KEY`}</code> = your license key</li>
                <li><code>{`DDAL_MODEL_ASSEMBLY`}</code> = the path to the assembly which contains your context type</li>
                <li><code>{`DDAL_CONNSTRING`}</code> = the connection string to use for Entity Framework database calls</li>
                <li><code>{`DDAL_RELATIONAL_PROVIDER`}</code> = the provider for Entity Framework Core ; either <code>{`Npgsql`}</code>, <code>{`SqlServer`}</code>, <code>{`Sqlite`}</code> or <code>{`MySQL`}</code></li>
            </ul>
            <p>Once that is done your container should up, running and listening to the URL you gave it.
                You can then issue REST API calls (using HTTP or HTTPS) to the below endpoints.</p>
            <p>For general analysis executions:</p>
            <ul>
                <li>POST /analysis/execute with a raw text body containing your request.</li>
                <li>POST /analysis/execute/json with a json string body for your request.</li>
                <li>POST /analysis/execute/form with a single string input for your request.</li>
            </ul>
            <p>For listing executions if and only if you wish to retrieve the raw string projection, discarding the rest.
                Cell width arguments in the route allow you to increase the default maximum cell width of 30 for your cells. The items that are shorter will be padded with strings if necessary:</p>
            <ul>
                <li>{`POST /analysis/lstostr/{cellWidth} with a raw text body; cellWidth is the cell width of your string projection.`}</li>
                <li>{`POST / analysis / lstostr / json / {cellWidth} with a json string body.`}</li>
                <li>{`POST / analysis / lstostr / form / {cellWidth} with a single string input.`}</li>
            </ul>
            <p>For standalone filter expressions without selections; all fields of the root entity will be returned; no entities will be joined:</p>
            <ul>
                <li>POST /filter/execute with a raw text body.</li>
                <li>POST /filter/execute/json with a json string body.</li>
                <li>POST /filter/execute/form with a single string input.</li>
            </ul>
            <p>For standalone filter expressions with specific CLR property selections (nested entities not supported in v1):</p>
            <ul>
                <li>POST /filter/select/json with the following json schema:<ReactCodeMirror theme={dracula} value={`{
                    "input": "your-request-with-context-marker",
                    "selection": [
                    "property1",
                    "property2"
                    ]
                }`} extensions={[langs.javascript()]} />
                </li>
                <li>POST /filter/select/form with a string input for the request body and string array for each CLR property you want to retrieve.</li>
            </ul>
        </div>
    )
}

export default Deployment;