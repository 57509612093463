const ComparisonOperators = () => {
    return (
        <div className={'api'}>
            <h1>Comparison operators</h1>
            <p>Comparison operators are used to bind a property path pointing to a specific data in the model to a fixed value.
                A comparison is a predicate which can resolve either to true or false.</p>
            <p>Comparison operators determine if the property can be compared to the given value, and how it should be.
                For example, <code>{`Something > 1`}</code> is valid but <code>{`Something > 'text'`}</code> is not, but <code>{`Something = text`}</code> would be.
                Of course, the model data needs to have a valid type; a comparison can be syntactically valid but semantically improper.
                Both operands of a comparison need to have the same or at least compatible types. A <em>float</em> can be compared to an <em>integer</em>, but not to a string.</p>
            <p>You may want to check the pages for <a href="/api/DDAL/filter/propertyPaths">property paths</a> and <a href="/api/DDAL/filter/values">comparison values</a>.</p>
            <h2>EQUALS</h2>
            <p>The EQUALS operator is perhaps the more straightforward and simple one. It simply compares its two operands and checks if they are equal. This works for any type.</p>
            <p>The EQUALS operator can be written as:</p>
            <ul>
                <li><code>{`EQUALS`}</code></li>
                <li><code>{`IS`}</code>, makes boolean comparison more legible</li>
                <li><code>{`==`}</code></li>
                <li><code>{`=`}</code></li>
            </ul>
            <h2>DOES NOT EQUAL</h2>
            <p>This operator simply resolves to true if its operands do not have the same values. Can be written as:</p>
            <ul>
                <li><code>{`DOES NOT EQUAL`}</code></li>
                <li><code>{`≠`}</code>, the unicode character</li>
                <li><code>{`!=`}</code></li>
                <li><code>{`#`}</code></li>
            </ul>
            <h2>GREATER THAN</h2>
            <p>This operator resolves to true if the left arm (actual value) is greater than the left arm (compared value).
                This operator only works for numeric types (floats, integers...) or dates.</p>
            <p>It can be written in the following forms:</p>
            <ul>
                <li><code>{`GREATER THAN`}</code></li>
                <li><code>{`GT`}</code></li>
                <li><code>{`}>`}</code></li>
            </ul>
            <h2>LESS THAN</h2>
            <p>Resolves to true if the right arm (actual value) is less than the left arm (compared value). Only works for numeric types and dates.</p>
            <p>Can be written as:</p>
            <ul>
                <li><code>{`LESS THAN`}</code></li>
                <li><code>{`LT`}</code></li>
                <li><code>{`<`}</code></li>
            </ul>
            <h2>CONTAINS</h2>
            <p>Resolves to true if the left arm contains the right arm. For strings this means containing another string, for example.</p>
            <p>Can be written as:</p>
            <ul>
                <li><code>{`CONTAINS`}</code></li>
                <li><code>{`/`}</code></li>
            </ul>
            <h2>DOES NOT CONTAIN</h2>
            <p>Resolves to true if the left arm does NOT contain the right arm. For strings this means not containing another string, for example.</p>
            <p>Can be written as:</p>
            <ul>
                <li><code>{`DOES NOT CONTAIN`}</code></li>
                <li><code>{`!/`}</code></li>
            </ul>
            <h2>IN</h2>
            <p>Resolves to true if the <em>right arm</em> contains the <em>left arm</em>, which is the inverse of CONTAINS. For strings this means being included in another string, for example.</p>
            <p>Can be written as:</p>
            <ul>
                <li><code>{`IN`}</code></li>
                <li><code>{`\\`}</code></li>
            </ul>
            <h2>NOT IN</h2>
            <p>Resolves to true if <em>right arm</em> does NOT contain the <em>left arm</em>. For strings means this NOT being included in another string, for example.</p>
            <p>Can be written as:</p>
            <ul>
                <li><code>{`NOT IN`}</code></li>
                <li><code>{`!`}</code></li>
            </ul>
        </div>
    );
}

export default ComparisonOperators;