import {dracula} from "@uiw/codemirror-theme-dracula";
import {langs} from "@uiw/codemirror-extensions-langs";
import ReactCodeMirror from "@uiw/react-codemirror";

const Basics = () => {
    return(
        <div className={'api'}>
            <h1>Filter language basics</h1>
            <p>A filter expression/query is formed from two parts: a <a href="/api/DDAL/filter/contextMarker">context marker</a> and a <a href="/api/DDAL/filter/predicates">composition</a>.
                Context markers are optional when the context can be implictly deduced. This is the case for embedded requests, see <a href="/api/DDAL/filter/embeddedFilters">this page</a>.
                In some cases however, for instance when directly executing a filter request, it is required.</p>
            <p>The composition is what really interests us. A composition is a set of predicates linked by logical operators ; the predicates can be:</p>
            <ul>
                <li>A <a href="/api/DDAL/filter/groups">group</a> delimited by parentheses.</li>
                <li>A comparison which constitutes the core of filter requests by linking a unique data property to a compared value.</li>
            </ul>
            <p>For example, consider the following expression:</p>
            <ReactCodeMirror theme={dracula} value={`Owner;IsAlive is true & IsAlive != false | (Age > 5 | FullName = "John Smith")`} extensions={[langs.javascript()]} />
            <ul>
                <li><code>Owner</code> is the context marker. It indicates that our root context for our filter expression will be store owners.</li>
                <li><code>IsAlive = true</code> is a comparison predicate.</li>
                <li><code>{`(Age > 5 | FullName = 'John Smith')`}</code> is another predicate, but a group in this case. A group contains a composition.</li>
                <li>While the entirety of <code>{`IsAlive is true & IsAlive != false | (Age > 5 | FullName = 'John Smith')`}</code> is a composition. Refer to the <a href="/api/DDAL/filter/predicates">following page</a>.</li>
            </ul>
            <p>Priorities apply as is standard to <a href="/api/DDAL/filter/logicalOperators">logical operators</a>, and groups can be used to reorder those.
                In the above example, the <a href="/api/DDAL/filter/propertyPaths">properties</a> <code>IsAlive</code>, <code>Age</code> and <code>FullName</code> belong to the <code>Owner</code> entity.
                Those properties are then compared to a <a href="/api/DDAL/filter/values">value</a> using a comparison behaviour defined by the logical operator.</p>
        </div>
    )
}

export default Basics