const ClientSvg = () => {
    return (
<>
    <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
         x="0px" y="0px"
         viewBox="0 0 17.6 22" enableBackground='new 0 0 17.6 22' xmlSpace="preserve">
        <g id="user_solid" transform="translate(-4.8)">
	<path id="Tracé_166" fill='#F3F3F3' d="M4.8,18.84c0-3.11,2.53-5.64,5.64-5.64h6.32c3.11,0,5.64,2.53,5.64,5.64
		c0.03,1.28-0.88,2.38-2.14,2.61C18.06,21.81,15.83,22,13.6,22c-2.23,0-4.46-0.19-6.66-0.55C5.68,21.22,4.77,20.12,4.8,18.84z"/>
            <path id="Tracé_167" fill='#F3F3F3'
                  d="M8.1,5.5c0-3.04,2.46-5.5,5.5-5.5s5.5,2.46,5.5,5.5S16.64,11,13.6,11S8.1,8.54,8.1,5.5z"/>
</g>
</svg></>
    );
};

export default ClientSvg;
