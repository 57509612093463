import i18next from "i18next";
import I18NextHttpBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";

const loadPath = ( process.env.NODE_ENV === 'development' ? `${process.env.REACT_APP_ADRESS_SERVER}:${process.env.REACT_APP_PORT_SERVER_EXPRESS}/backend` : `${process.env.REACT_APP_ADRESS_SERVER}`) + "/localize/{{lng}}/{{ns}}";

i18next.use(I18NextHttpBackend).use(LanguageDetector).use(initReactI18next)
    .init({
        backend: {
            loadPath: loadPath,
        },
        fallbackLng: "fr",
        ns: ["prelaunch", "navigation", 'input', 'header', 'footer', 'newsletter', 'question', 'register', 'login', 'demonstrator', 'customerArea', 'payment', 'contact', 'price', 'blog', 'whoWeAre'],
        defaultNS: "prelaunch",
        supportedLngs: ["fr", "en"],
        debug: false,
        react: {
            useSuspense: true,
        }
    })