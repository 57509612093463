import {useCallback, useEffect, useReducer, useRef} from "react";
import {ArrowSVG} from "../../../../style/svg/arrowSVG";


type DropdownProps = {
    id?: string;
    startAnimationTime?: number;
    value?: any;
    dataItem: Item[];
    activeSearch?: boolean;
    customClass?: string;
    selected?: (item: Item) => void;
    [propName: string]: any;
}

export type Item = {
    id: any;
    name: string;
}

enum ActionType {
    SEARCH = "search",
    DATA = "data",
    OPEN = "isOpen",
    CLASS_INPUT_ACTIVE = "classInputActive",
}

function reducer(state: any, action: any) {
    switch (action.type) {
        case ActionType.SEARCH:
            return {
                ...state,
                searchText: action.payload
            };
        case ActionType.DATA:
            return {
                ...state,
                data: action.payload
            };
        case  ActionType.OPEN:
            return {
                ...state,
                isOpen: action.payload
            };
        case ActionType.CLASS_INPUT_ACTIVE:
            return {
                ...state,
                classInputActive: action.payload
            };
        default:
            return state;
    }
}


/**
 * Dropdown component
 * @param value - value of the dropdown
 * @param dataItem - data of the dropdown
 * @param activeSearch - active search
 * @param customClass - custom class
 * @param props - other props like onBlur, onFocus, etc.
 * @param startAnimationTime - start animation time
 * @constructor
 */
const Dropdown = ({
    id,
    startAnimationTime = 0,
    value,
    dataItem,
    activeSearch,
    customClass = "",
    selected,
    placeholder = "",
    ...props
}: DropdownProps) => {
    const dropdownRef = useRef<HTMLDivElement>(null);
    const secondaryContainerList = useRef<HTMLDivElement>(null);
    const ulRef = useRef<HTMLUListElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const divContainerListRef = useRef<HTMLDivElement>(null);
    const arrowRef = useRef<SVGSVGElement>(null);
    const timeoutAnim = useRef<any>(null);
    const navIndex = useRef<number>(-1);
    const liRef = useRef<(HTMLLIElement | null)[]>([]);
    const disabledScroll = useRef<boolean>(false);

    const [state, dispatch] = useReducer(reducer, {
        searchText: "",
        data: dataItem,
        isOpen: false,
        classInputActive: "input-dropdown "
    });

    let setHeight = useCallback((open: Boolean) => {
        if (ulRef.current && divContainerListRef.current && secondaryContainerList.current) {
            clearTimeout(timeoutAnim.current);
            if (!open) {
                let height = ulRef.current.getBoundingClientRect().height;
                if (ulRef.current.getBoundingClientRect().height > 200) {
                    height = 200;
                    secondaryContainerList.current.style.overflow="auto";
                }
                else{
                    secondaryContainerList.current.style.overflow="hidden";
                }
                divContainerListRef.current.style.height = height + "px";
                dispatch({
                    type: ActionType.CLASS_INPUT_ACTIVE,
                    payload: state.classInputActive + " input-dropdown--active"
                });
            }
            else {
                let classActive = state.classInputActive;
                classActive = classActive.replace(" input-dropdown--active", "");
                dispatch({type: ActionType.CLASS_INPUT_ACTIVE, payload: classActive});
                timeoutAnim.current = setTimeout(() => {
                    if (divContainerListRef.current) {
                        divContainerListRef.current.style.height = "0px";
                    }
                }, 400);
            }
        }
    }, [state.classInputActive, timeoutAnim, ulRef, divContainerListRef]);

    useEffect(() => {
        if (activeSearch) {
            dispatch({type: ActionType.CLASS_INPUT_ACTIVE, payload: "input-dropdown input-dropdown--search"});
        }
    }, [activeSearch]);

    useEffect(() => {
        dispatch({type: ActionType.DATA, payload: dataItem});
        return () => {
            dispatch({type: ActionType.DATA, payload: []});
        };
    }, [dataItem]);

    useEffect(() => {
        if (value && dataItem.length > 0) {
            let itemFound = dataItem.find((item) => item.id === value);
            dispatch({type: ActionType.SEARCH, payload: itemFound?.name});
            handleFocus();
        }
    }, [value, dataItem]);

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setHeight(true);
                dispatch({type: ActionType.OPEN, payload: false});
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [inputRef, setHeight]);

    useEffect(() => {

        function handleResize() {
            if (dropdownRef.current && secondaryContainerList.current) {
                secondaryContainerList.current.style.width = dropdownRef.current.getBoundingClientRect().width + "px";
            }
        }

        function handleKeyDown(event: any) {
            if (event.key === "ArrowDown" || event.key === "ArrowUp") {
                if (disabledScroll.current) {
                    event.preventDefault();
                    return;
                }
            }

        }

        setTimeout(handleResize, startAnimationTime);
        window.addEventListener("resize", handleResize);
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("resize", handleResize);
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    let openCloseList = () => {
        if (state.isOpen && activeSearch) {
            return;
        }
        inputRef.current?.focus();
        setHeight(state.isOpen);
        dispatch({type: ActionType.OPEN, payload: !state.isOpen});
    };

    let searchItem = (event: any) => {
        if (!state.isOpen) {
            openCloseList();
        }
        dispatch({type: ActionType.SEARCH, payload: event.target.value});
        if (event.target.value === "") {
            dispatch({type: ActionType.DATA, payload: dataItem});
        }
        else {
            let dataFilter = dataItem.filter((item) => {
                return item.name.toLowerCase().includes(event.target.value.toLowerCase());
            });
            dispatch({type: ActionType.DATA, payload: dataFilter});
        }
    };

    let selectItem = (item: Item) => {
        setHeight(true);
        dispatch({type: ActionType.SEARCH, payload: item.name});
        dispatch({type: ActionType.OPEN, payload: false});
        liRef.current.forEach((li) => {
            if (li?.id === item.id) {
                li?.classList.add("dropdown-list-item--active");
            }
            else{
                li?.classList.remove("dropdown-list-item--active");
            }
        });
        if(selected) {
            selected(item);
        }
    };

    let navigateWithKeyboard = (event: any) => {
        if (event.key === "ArrowDown" || event.key === "ArrowUp") {
            if (!state.isOpen) {
                openCloseList();
            }
            disabledScroll.current = true;
            if (event.key === "ArrowDown") {
                if (navIndex.current == state.data.length - 1) {
                    return;
                }
                navIndex.current++;
                if (navIndex.current != 0) {
                    liRef.current[navIndex.current - 1]?.classList.remove("dropdown-list-item--active");
                }
                liRef.current[navIndex.current]?.classList.add("dropdown-list-item--active");
                if (navIndex.current > 3) {
                    if (secondaryContainerList.current && liRef.current[0]) {
                        secondaryContainerList.current.scrollTop += liRef.current[0]?.getBoundingClientRect().height;
                    }
                }
            }
            if (event.key === "ArrowUp") {
                if (navIndex.current == 0) {
                    return;
                }
                navIndex.current--;
                if (navIndex.current != state.data.length - 1) {
                    liRef.current[navIndex.current + 1]?.classList.remove("dropdown-list-item--active");
                }
                liRef.current[navIndex.current]?.classList.add("dropdown-list-item--active");
                if (navIndex.current < state.data.length - 4) {
                    if (secondaryContainerList.current && liRef.current[0]) {
                        secondaryContainerList.current.scrollTop -= liRef.current[0]?.getBoundingClientRect().height;
                    }
                }
            }
        }
        else if (event.key === "Enter") {
            if (navIndex.current != -1) {
                selectItem(state.data[navIndex.current]);
            }
        }
    };

    let handleBlur = () => {
        disabledScroll.current = false;
    }

    let handleFocus = () => {
        dropdownRef.current?.classList.add("input-container--focus");
    }

    return (
        <div onKeyDown={navigateWithKeyboard} ref={dropdownRef} data-testid={"dropdown"}
             className={`pos-relative input-container ${customClass}`} {...props}>
            <label onClick={openCloseList}>{placeholder}</label>
            <input onBlur={handleBlur} onFocus={handleFocus} data-testid={"input-dropdown"} value={state.searchText} onChange={searchItem}
                   ref={inputRef}
                   readOnly={!activeSearch} onClick={openCloseList} className={state.classInputActive}/>
            <ArrowSVG refSvg={arrowRef} onClick={openCloseList}/>
            <div data-testid={"main-container-dropdown"} ref={divContainerListRef} style={{height: "0px"}}
                 className={`pos-absolute z-100`}>
                <div ref={secondaryContainerList} data-testid={"container-list-dropdown"}
                     className={state.isOpen ? `dropdown-list dropdown-list--open` : `dropdown-list dropdown-list--close`}>
                    <ul data-testid="list-dropdown" style={{height:"fit-content"}} ref={ulRef}>
                        {(state.data as Array<Item>).map((item, index) => {
                            return <li ref={ref => {
                                if (ref) {
                                    liRef.current[index] = ref;
                                }
                            }} key={id + '-' + item.id} id={item.id} data-testid={`item-dropdown-${item.id}`}
                                       onClick={() => {selectItem(item);}}>{item.name}</li>;
                        })
                        }
                    </ul>
                </div>
            </div>
        </div>
    );
};

export {Dropdown};