import {Row} from "../common/grid/row";
import {Column} from "../common/grid/column";
import syntaxe from "../../../style/image/syntaxe.png";
import {ButtonBase} from "../common/buttons/buttonBase";
import React from "react";
import {BenefitsEntityFrameworkBlock} from "./benefitsEntityFramework";
import {useTranslation} from "react-i18next";
import engrenages from "../../../style/image/engrenages.png";
import librairies from "../../../style/image/librairies.png";
import commercial from "../../../style/image/commercial.png";
import {Link} from "react-router-dom";
import ClientSvg from "../../../style/svg/clientSvg";
import {ddalArticleId} from "../../views/articles/ddalArticle";
const BenefitsDDAL = () => {
    const {t, i18n} = useTranslation()
    return(
        <div className="benefitsDDAL-container">
            <Row customClass={'jc-center mb-40'}>
                <Column min={12} xl={8}>
                    <Row>
                        <Column min={12}>
                            <h2>{t("prelaunch:DDALCommunity")}</h2>
                            <p>{t("prelaunch:DDALCommunityParagraph1")}</p>
                        </Column>
                    </Row>
                    <Row customClass={'common-block-tree mt-50 gap-10 jc-center'}>
                            <BenefitsEntityFrameworkBlock title={t('prelaunch:benefit1DDALCommunity')} img={engrenages}/>
                            <BenefitsEntityFrameworkBlock title={t('prelaunch:benefit2DDALCommunity')} img={librairies}/>
                            <BenefitsEntityFrameworkBlock title={t('prelaunch:benefit3DDALCommunity')} img={commercial}/>
                    </Row>
                </Column>
            </Row>
            <Row customClass={'jc-center gap-20'}>
                <Link style={{textDecoration:'none'}} to={'customer/?id=7'}
                      className="button-orange"><span className="span-dark"></span> <span
                    className="span-light"></span><label>{t("prelaunch:download")}</label></Link>
                <Link style={{ textDecoration:'none'}} onMouseDown={()=> window.localStorage.setItem(ddalArticleId,'0')} to={'blog/DDAL'}
                      className="button-orange"><span className="span-dark"></span> <span
                    className="span-light"></span><label>{t('prelaunch:moreInformation')}</label></Link>
            </Row>

        </div>
    )
}

export default BenefitsDDAL