import {Row} from "../grid/row";
import {Column} from "../grid/column";
import {useContext, useEffect, useState} from "react";
import ClientSvg from "../../../../style/svg/clientSvg";
import LogoSvg from "../../../../style/svg/logo/logoSvg";
import {useStickyScrollBar} from "../../../helper/useStickyScrollBar";
import {Link, useNavigate} from "react-router-dom";
import {NavContext, NavContextType} from "../../../../App";
import {Dropdown} from "../inputs/dropdown";
import {useTranslation} from "react-i18next";
import {use} from "i18next";

export type NavItem = {
    id?:string;
    label:string;
    link:string;
    isActive?:boolean;
    isVisible?:boolean;
}

export type NavigationBaseProps = {
    blue?:boolean;
}

const NavItemsNavigationBase:NavItem[] = [
    {label:'Accueil', link:'/'},
    {label:'Prix', link:'/price'},
    {label:'DDAL', link:'/blog/ddal'},
    {label:'DDALEF', link:'/blog/ddalef'},
    {label:'Nous contacter', link:'/contact'},
    {label:'S\'inscrire', link:'/'},
    {label:'Se connecter', link:'/login'}
]

const NavigationBase = ({blue}:NavigationBaseProps) => {
    const {navItemsNavigationBase, setNavItemsNavigationBase} = useContext(NavContext) as NavContextType;
    const [selectedItem, setSelectedItem] = useState<NavItem | null>(null);
    const {isSticky} = useStickyScrollBar();
    const navigate = useNavigate();
    const {t, i18n} = useTranslation()
    const [defaultLanguage, setDefaultLanguage] = useState<string>(i18n.language)

    useEffect(() => {
        if(isSticky){
            document.querySelector('nav.navigation-base')?.classList.add('navigation-base--sticky')
        }
        else{
            document.querySelector('nav.navigation-base')?.classList.remove('navigation-base--sticky')
        }
    },[isSticky])

    let handleChangeLanguage = (item:any) => {
        i18n.changeLanguage(item.id)
        localStorage.setItem('language', item.id)
        setDefaultLanguage(i18n.language)
        window.location.reload()
    }

    let refreshPage = (item:NavItem) => {
        if(item.isActive){
        }
    }

    let resetScrollBar = (id:string) => {
        window.localStorage.setItem(id, '0')
    }

    return (
        <nav className={blue ? `navigation-base navigation-base--blue`: `navigation-base`}>
                <div onClick={()=> {navigate("/");window.location.reload()}} className={'logo-container'}>
                    <LogoSvg blue={isSticky || blue} style={{height:isSticky ? '60px' :'70px', transition:'0.3s'}}/>
                </div>
                <div className={'ml-40 navigation-horizontal-menu jc-space-between'}>
                    {navItemsNavigationBase.map((item, index) => {
                        if(index >= navItemsNavigationBase.length - 2) {
                            return null
                        }
                        return (item.isVisible &&
                        <Link onMouseDown={()=>refreshPage(item)} to={item.link} key={index} className={`item ${item.isActive ? "item--active" : ""}`}
                              onClick={() => {
                                  resetScrollBar(item.id as string)
                              }}>{item.label}</Link>
                        )
                    })}
                    <Dropdown value={defaultLanguage} selected={handleChangeLanguage} style={{width:"100px"}} dataItem={[{id:'fr',name:"FR"},{id:'en',name:'EN'}]}/>
                    {navItemsNavigationBase[navItemsNavigationBase.length - 2]?.isVisible ? <Link to={navItemsNavigationBase[navItemsNavigationBase.length - 2]?.link}
                           style={{marginLeft: "auto"}}
                           className={`item ${navItemsNavigationBase[navItemsNavigationBase.length - 2]?.isActive ? "item--active" : ""}`}
                           onClick={() => resetScrollBar(navItemsNavigationBase[navItemsNavigationBase.length - 2].id as string)}>{navItemsNavigationBase[navItemsNavigationBase.length - 2]?.label}</Link> : null}
                    {navItemsNavigationBase[navItemsNavigationBase.length - 1]?.isVisible && !window.localStorage.getItem('token') ? <Link  to={navItemsNavigationBase[navItemsNavigationBase.length - 1]?.link}
                           className="button-light d-flex"><span className="span-dark"></span> <span
                        className="span-light"></span><ClientSvg/><label>{navItemsNavigationBase[navItemsNavigationBase.length - 1]?.label}</label></Link> : null}
                    {window.localStorage.getItem('token') ? <Link  to={'/customer/?id=1'}
                                                                                                   className="button-light d-flex"><span className="span-dark"></span> <span
                        className="span-light"></span><ClientSvg/><label>{t('navigation:customerArea')}</label></Link> : null}
                </div>
        </nav>
    );
};

export {NavigationBase, NavItemsNavigationBase};
